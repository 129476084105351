import React, { useState, useEffect, useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";

import { Container, Row, Col } from 'react-bootstrap';

import queryString from 'query-string';

// Constants
import constants from '../../Constants';
import { SORT_BY_DROPDOWN } from './Constants/Constants';

// Utils
import { apiRequest } from 'utils/ApiRequest';
import scrollToHandler from 'utils/ScrollHandler';
import removeProperty from 'utils/functions/removeProperty';

// Page Components
import RequestItem from './components/RequestItem/RequestItem';
import Pagination from 'general/Pagination/Pagination';
import KeywordFilter from './components/KeywordFilter/KeywordFilter';
import Select from '../../general/CustomSelect/CustomSelect';
import Subscribe from 'components/Subscribe/Subscribe';
import Loader from 'general/Loader/Loader';

// Interfaces
import { BuyRequestInterface } from 'interfaces/Interfaces';

// SCSS
import styles from './BuyRequests.module.scss';

const {
  apiConstants: { API_URL },
  pageConstants: { ITEMS_PER_PAGE },
} = constants;

const CONTEST_LIST_URL = `${API_URL}/requests/filter`;

const showPageCount = (activePage: number, totalCount: number = 0, results: Array<any> = []) => {
  let countStart = 0
  let countEnd = 0
  if (totalCount > 0) {
    if (totalCount < ITEMS_PER_PAGE) {
      countStart = 1
      countEnd = totalCount
    }
    else {
      countStart = (activePage - 1) * ITEMS_PER_PAGE + 1
      if (results.length < ITEMS_PER_PAGE) {
        countEnd = totalCount
      }
      else {
        countEnd = activePage * ITEMS_PER_PAGE
      }
    }
  }
  return `Showing ${countStart}-${countEnd} of ${totalCount}`
}

interface DropdownAndTextFilters {
  budget: string | Array<number>;
	desiredKeywords: string;
	businessIndustry: string;
}

interface AllFilters {
  sortBy: string;
  sortOrder: string;
  filters: DropdownAndTextFilters;
  skip: Object;
}

const BuyRequests: React.FunctionComponent = () => {
  const buyRequestsRef = React.useRef(null);

  let { state: filterByKeyword = '', search } = useLocation();

  const defaultFilters = {
    desiredKeywords: filterByKeyword || '',
    businessIndustry: '',
    budget: '',
  }

  const defaultSorting = {
    sortBy: queryString.parse(search).sortBy ? SORT_BY_DROPDOWN.find(s => s.value === queryString.parse(search).sortBy)?.label : 'submissions',
    sortOrder: queryString.parse(search).sortOrder || 'DESC',
  }

  const [activePage, setActivePage] = useState<number>(1);
  const [skipResults, setSkipResults] = useState<number>(0);

  const [filters, setFilters] = useState<DropdownAndTextFilters | any>(defaultFilters);

  const [sorting, setSorting] = useState<{sortBy: string | any, sortOrder: string | any}>(defaultSorting)

  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<Array<BuyRequestInterface> | null>(null);
  const [error, setError] = useState<string>('');

  const setPageAndSkip = (page: number) => {
    setActivePage(page);
    setSkipResults((page - 1) * ITEMS_PER_PAGE);
  }

  const { sortBy, sortOrder } = sorting;

  // const isBuyRequestActive = (targetDate: string): boolean => {
  //   return moment(targetDate).diff(moment(), 'days') > 0;
  // }

  const fetchBuyRequests = useCallback(async () => {
    setLoading(true);
    let filtersToSend = { ...filters };
    Object.keys(filters).forEach(filter => {
      if (filters[filter] === '' || filters[filter].length === 0) {
        filtersToSend =  removeProperty(filtersToSend, filter);
      }
    });
    
    let apiBody: any = {
      sortBy: SORT_BY_DROPDOWN.find(s => s.value === sortBy || s.label === sortBy) ? SORT_BY_DROPDOWN.find(s => s.value === sortBy || s.label === sortBy)?.key : 'submissions',
      sortOrder,
      filters: {
        ...filtersToSend,
      },
      skip: skipResults,
      limit: ITEMS_PER_PAGE,
    }
    try {
      const { response: apiResponse }: { response: Array<BuyRequestInterface>} = await apiRequest(CONTEST_LIST_URL, 'POST', JSON.stringify({
        ...apiBody,
      }))
      if (apiResponse) {
        const newRequests = apiResponse.map(
          request => request.plan.toLowerCase() === 'startup' || request.plan.toLowerCase() === 'corporate' ? { ...request, isRecommended: true } : { ...request }
        )
        setRequests(newRequests);
      }
    } catch (e) {
      setError(e.toString());
    }
    setLoading(false);
  }, [
    filters,
    sortBy,
    sortOrder,
    skipResults,
  ])

  useEffect(() => {
    fetchBuyRequests();
  }, [fetchBuyRequests])

  useEffect(() => {
    if (filterByKeyword || activePage !== 1) {
      scrollToHandler(buyRequestsRef);
    }
  }, [activePage, filterByKeyword]);

  useEffect(() => {
    scrollToHandler();
    // window.analytics.page('BuyRequestsListing', {
    //   title: 'Search Buy Requests | Buy Requests',
    //   path: '/requests',
    // });
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Search Buy Requests | Buy Requests",
    });
  }, []);

  const handleSortBy = (event: any) => {
    const { value } = event.target;
    let sortBy = value;
    let sortOrder = ''
    switch (value) {

    case 'submissions':
    case 'Most Popular': {
      sortOrder = 'DESC';
      break;
    }
      
    case 'createdAtDesc':
    case 'Most Recent': {
      sortOrder = 'DESC';
      break;
    }
      
    case 'budgetDesc':
    case 'Highest Budget': {
      sortOrder = 'DESC';
      break;
    }
      
    case 'budgetAsc':
    case 'Lowest Budget': {
      sortOrder = 'ASC';
      break;
    }

    case 'targetDate':
    case 'Ending Soon': {
      sortOrder = 'ASC';
      break;
    }
      
    default:
      sortOrder = 'DESC'
    }
    setPageAndSkip(1) //reset pagination
    setSorting({
      ...sorting,
      sortBy,
      sortOrder,
    })
  }

  return (
    <Container className="p-0" fluid>
      <Helmet>
        <title>
          Active Buy Requests | Brandmo
        </title>
      </Helmet>
      <Container className={styles.buyRequestSearch} fluid>

        <Container className={styles.pageTitleRegion} ref={buyRequestsRef}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.pageTitle}>Search Buy Requests</div>
              <div className={styles.pageDesc}>
                Search the latest buy requests and connect with real buyers seeking premium brands. Evaluate criteria like desired keywords and budget, and submit names matching the requirements. Negotiate directly with a range of end-users to sell your domains.
              </div>
            </Col>
          </Row>
        </Container>

        <Container className={styles.pageContentRegion}>
          <div className={styles.buyRequestSeachContent}>
            <Row>
              <Col xl={4} lg={4} md={12} sm={12}>
                <div className={styles.buyRequestSearchSidebar}>
                  <div className="keywordDiv">
                    <KeywordFilter
                      /* getFilters={handleKeywordAndDropdownFilter} */
                      getFilters={setFilters}
                      filterByKeyword={filterByKeyword || ''}
                    />
                  </div>
                </div>
              </Col>

              <Col xl={8} lg={8} md={12} sm={12}>
                <Row className="d-flex justify-content-center align-items-center">
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.searchLabel}>
                      {requests && showPageCount(activePage, Number(requests[0]?.total || 0), requests)}
                    </div>
                  </Col>

                  <Col className={styles.sortByWrap} xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.searchLabel}>Sort By</div>
                    {/* <Dropdown
                      options={SORT_BY_DROPDOWN}
                      value={sortBy}
                      className="dropdownSort"
                      onChange={(obj: any) => handleSortBy(obj.value)} /> */}

                    <div className={styles.addBorder}>
                      <Select id={sortBy}
                        selectItems={SORT_BY_DROPDOWN}
                        value={sortBy}
                        onChange={handleSortBy} />
                    </div>
                  </Col>
                </Row>
                {<div className={styles.buyRequestSearchList}>
                  {loading && <Loader />}
                  {!loading && !error && requests && requests.map((request: any) => (
                    <RequestItem
                      {...request}
                      id={request.id}
                      key={request.id}
                      createdAt={request.createdAt}
                      targetDate={request.targetDate}
                      userName={request.userName}
                      name={request.name}
                      title={request.title}
                      projectType={request.projectType}
                      businessDescription={request.businessDescription}
                      budget={request.budget}
                      desiredKeywords={request.desiredKeywords}
                      submitCount={request.submissions}
                      isFavorite={request.isFavorite ? (request.isFavorite === '0' ? false : true) : false}
                      addToFavouriteURL={`${API_URL}/requests/favorite`}
                      isExpired={request.isExpired}
                    />
                  ))}
                  {!loading && !requests && error && (
                    <div className={styles.error}>{error}</div>
                  )}
                </div>
                }

                <Pagination
                  totalCount={requests && requests.length ? Number(requests[0]?.total) : 0}
                  itemsPerPage={ITEMS_PER_PAGE}
                  pageCount={requests && requests[0]?.total ? Math.ceil(parseInt(requests[0].total) / ITEMS_PER_PAGE) : 0}
                  windowSize={5}
                  page={activePage}
                  getActivePage={setPageAndSkip}
                />
              </Col>
            </Row>
          </div>

        </Container>
      </Container>

      <Subscribe />

    </Container>
  );
};

export default React.memo(BuyRequests);
