import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// SCSS
import styles from './PasswordStrength.module.scss';

interface PasswordStrength {
  strength?: string;
}

// Password Strength Indicator
// @param  strength: { none | weak | ok | good | strong }
// @return  css targets strength amount to change colors & fill in blocks.
const PasswordStrength = (props: PasswordStrength) => {
  return (
    <div className={`${styles.passwordStrength}`} data-strength={props.strength}>
      <Container fluid className="p-0">
        <Row className="m-0">
          <Col className="mx-auto" xl={10} lg={10} md={10} sm={10}>
            <Row className="justify-content-center align-items-center">
              <Col className={`p-0 ${styles.strBlockFirst} ${styles.strBlock}`}>
                <div className={styles.strIndicator}></div>
              </Col>
              <Col className={`p-0 ${styles.strBlockSecond} ${styles.strBlock}`}>
                <div className={styles.strIndicator}></div>
              </Col>
              <Col className={`p-0 ${styles.strBlockThird} ${styles.strBlock}`}>
                <div className={styles.strIndicator}></div>
              </Col>
              <Col className={`p-0 ${styles.strBlockFourth} ${styles.strBlock}`}>
                <div className={styles.strIndicator}></div>
              </Col>
              <Col className={`p-0 ${styles.strBlockLast} ${styles.strBlock}`}>
                <div className={styles.strIndicator}></div>
              </Col>
              <Col className={`p-0 ${styles.strLabel}`}>
                <div className={styles.strLabelResult}></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default React.memo(PasswordStrength);
