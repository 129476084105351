import React, { useState, useEffect } from 'react';

import { Row, Col, Button, Pagination } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

// import styles from './Pagination.module.scss';

interface IPaginationProps {
  totalCount: number; //total items count
  itemsPerPage: number; //per page items,
  pageCount?: number;
  windowSize: number; // no of page buttons to show at bottom
  /* showPrevBtn?: boolean;
  showNextBtn?: boolean; */
  getActivePage: (number: number) => void,
  page: number;
}

const handlePageClick = (page: number, props: IPaginationProps, setActivePage: any) => {
  const { getActivePage } = props
  setActivePage(page)
  getActivePage(page)
}

const CustomPagination: React.FunctionComponent<IPaginationProps> = (props: IPaginationProps) => {
  const [activePage, setActivePage] = useState<number>(1);

  const { page, windowSize = 5 } = props;

  useEffect(() => {
    if (page) {
      setActivePage(page)
    }
  }, [page]);

  const renderPageNumbers = () => {
    let items: any = [];
    const {
      itemsPerPage,
      totalCount } = props
    const noOfPages = (totalCount < itemsPerPage) ? 1 : Math.ceil(totalCount / itemsPerPage);
    let maxLeft = (activePage - Math.floor(windowSize / 2));
    let maxRight = (activePage + Math.floor(windowSize / 2));

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = windowSize;
    }

    if (maxRight > noOfPages) {
      maxLeft = noOfPages - (windowSize - 1);

      maxRight = noOfPages;

      if (maxLeft < 1) {
        maxLeft = 1;
      }
    }
    for (let i = maxLeft; i <= maxRight; i++) {
      items.push(
        <Pagination.Item
          key={i}
          onClick={() => handlePageClick(i, props, setActivePage)}
          active={i === activePage}
        >
          {i}
        </Pagination.Item>
      );
    }
    return items;
  };
  return (
    <>
      {Math.ceil(props.totalCount / props.itemsPerPage) > 1 ? (
        <Row className="py-3 d-flex align-items-center">
          <Col className="previous text-xl-left text-lg-left text-md-center text-sm-center text-center" xl={3} lg={3} md={12} sm={12}>
            <Button
              className="bm-btn px-5 py-2 go-back"
              variant="primary"
              disabled={activePage === 1}
              onClick={() => handlePageClick(page - 1, props, setActivePage)}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} />
                &nbsp; Prev
            </Button>
          </Col>
          <Col className="pagination d-flex justify-content-center align-items-center" xl={6} lg={6} md={12} sm={12}>
            {renderPageNumbers()}
          </Col>
          <Col className="next text-xl-right text-lg-right text-md-center text-sm-center text-center" xl={3} lg={3} md={12} sm={12}>
            <Button
              className="bm-btn px-5 py-2 ml-auto go-forward"
              variant="primary"
              disabled={activePage === props.pageCount}
              onClick={() => handlePageClick(page + 1, props, setActivePage)}
            >
                Next &nbsp;
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
    
  );
};

export default React.memo(CustomPagination);
