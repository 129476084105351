import React from 'react';
import { Row, Col } from 'react-bootstrap';

// SCSS
import styles from './MessageReplyAuthor.module.scss';

interface MessageReplyAuthor {
  avatar: string;
  message: string;
  timeAgo: string;
  userName: string;
}

const MessageReplyAuthor = (props: MessageReplyAuthor) => {
  return (
    <Row className={`${styles.replyMessage} ${styles.replyAuthor}`}>
      <Col className="p-0 col-xl-1 col-lg-1 col-2">
        <div className={`d-xl-block d-lg-block d-md-block d-sm-none d-none ${styles.messageAvatar}`}>
          {/* <img src="https://placehold.co/55x55" alt="Lorem" /> */}
          <img src={props.avatar} alt={props.userName} />
        </div>
      </Col>
      <Col className={`col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12  ${styles.messageReply}`}>
        <div className={styles.message}>
          {/* That could be awesome. How does $250 sound? */}
          {props.message}
        </div>
        <div className={styles.messageAgo}>
          {props.timeAgo}
        </div>
        <div className={styles.messageFrom}>
          {props.userName}
        </div>
      </Col>
      <Col className="col-xl-1 col-lg-1 d-xl-block d-lg-block d-md-none d-sm-none d-none"></Col>
    </Row>
  )
}

export default React.memo(MessageReplyAuthor);
