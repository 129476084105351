import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Button, Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// Utilities
import { checkPasswordStrength } from 'utils/functions/checkPasswordStrength';
import { apiRequest } from 'utils/ApiRequest';

//Constants
import constants from 'Constants';

// Page Components
import FormInput from 'general/FormInput/FormInput';
import PasswordStrength from 'pages/Registration/components/PasswordStrength/PasswordStrength';

// SCSS
import styles from './ResetPassword.module.scss';

// SVGs
import LoginLogo from 'assets/svgs/Branding/logo-login.svg';

const { apiConstants: { API_URL } } = constants;

const ResetPassword: React.FunctionComponent = () => {
  const { token } = useParams();
  const RESET_PASSWORD_URL = `${API_URL}/reset-password/${token}`;

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordStrength, setPassworStrength] = useState<string>('none');

  const [error, setError] = useState<string>('');

  const [loading, setLaoding] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (name === 'password') {
      setPassword(value);
      setPassworStrength(value ? checkPasswordStrength(value) : 'none')
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  }

  const validatePasswords = (): boolean | string => {
    setError('');
    if (password && confirmPassword && password !== confirmPassword) {
      setError('Password does not match');
      return false;
    } else if (!password || !confirmPassword){
      setError('Please enter passwords.');
      return false;
    }
    return (password
      && confirmPassword
      && (password === confirmPassword)
    );
  }

  const setNewPassword = async () => {
    setLaoding(true);
    const { response, error } = await apiRequest(`${RESET_PASSWORD_URL}`, 'POST', JSON.stringify({
      password
    }))
    if (response) {
      setIsChanged(true);
    } else if (error) {
      setApiError(error);
    }
    setLaoding(false);
  }

  const handleSubmit = (): void => {
    setApiError('');
    if (validatePasswords()) {
      setNewPassword();
    }
  }

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Reset Password",
    });
  }, [])

  return (
    <Container className={`p-0 ${styles.authBG}`} fluid>
      <Row className={styles.authWrap}>
        <Col>
          <div className={styles.resetPassword}>

            <div className={styles.authLogo}>
              <SVG
                baseURL="/"
                cacheRequests={true}
                className="site-logo-login"
                description="Brandmo"
                loader={<span>Loading...</span>}
                src={LoginLogo}
                title="Brandmo"
              />
            </div>

            {!isChanged ? (<form>
              <div className={styles.authTitle}>Reset Password</div>
              <Container fluid>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <FormInput
                      type="password"
                      name="password"
                      label="New Password" // ???
                      placeholder="Enter new password"
                      value={password}
                      onChange={e => handleChange(e)}
                      // required
                    />
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <FormInput
                      type="password"
                      name="confirmPassword"
                      label="Confirm New Password" // ???
                      placeholder="Re-enter new password"
                      value={confirmPassword}
                      onChange={e => handleChange(e)}
                      // required
                    />
                  </Col>

                  <Col className="mt-3 mb-3" xl={12} lg={12} md={12} sm={12}>
                    <PasswordStrength strength={passwordStrength} />
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <Button
                      className={`bm-btn ${styles.changePassword}`}
                      type="button"
                      variant="primary"
                      onClick={!loading ? handleSubmit : () => {}}
                    >
                      {!loading ?  'Change Password' : 'loading...'}
                    </Button>
                    {error && <div className={styles.error}>{error}</div>}
                    {apiError && <div className={styles.error}>{apiError}</div>}
                  </Col>

                </Row>
              </Container>

            </form>) : (
              <form>
                <div className={styles.authTitle}>Forgot Password</div>
                <Container>
                  <Row>
                    <Col className={styles.successMessage}>
                      Your password is changed successfully, please click <Link to="/login">here</Link> to login.
                    </Col>
                  </Row>
                </Container>
              </form>
            )}

            <div className={styles.formBottom}>
              Try to use a creative & strong password.
            </div>

          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(ResetPassword);
