import React, { useState, useEffect, useCallback } from 'react';

import { Container, Row } from 'react-bootstrap';

// Const
import Constants from '../../../../Constants';

// Utils
import { apiRequest } from 'utils/ApiRequest';

import DomainCard from 'components/DomainCard/DomainCard';
import Loader from 'general/Loader/Loader';

// SCSS
import styles from './FavoritePremiumDomains.module.scss';


interface Domain {
  id: string;
  name: string;
  price: number;
  category: string;
  keywords: string;
  images: Array<string>;
  extension: string;
  total: string;
  verificationCode: string;
  isDeleted: string;
  createdAt: string;
  updatedAt: string;
  isVerified: string;
}

interface DomainFilters {
  keywords: string;
  extension: string;
  length: Array<Number>;
  category: string;
}

const DOMAINS_PER_PAGE = 32;
const { apiConstants: { API_URL } } = Constants;

const FETCH_DOMAINS_URL = `${API_URL}/premium-domains/filter`;

const FavoritePremiumDomains: React.FunctionComponent = () => {

  const [domains, setDomains] = useState<Array<Domain> | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const fetchDomains = useCallback(async() => {
    let filtersToSend = {
      isFavorite: true
    };
    setLoading(true);
    const { response, error } = await apiRequest(FETCH_DOMAINS_URL, 'POST', JSON.stringify({
      filters: {
        ...filtersToSend,
      },
      skip: 0,
      limit: DOMAINS_PER_PAGE,
    }));
    if (response) {
      setDomains(response);
    } else if (error) {
      setDomains(null);
      setError(`Error occurred while fetching the domains due to: ${error}`);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  return (
    <Container id={styles.pageContainer} className="p-0" fluid>
      <Row className={styles.domainListing}>
        {loading && <Loader />}
        {!loading && !error && domains && domains.map(domain => (
          <DomainCard
            key={domain.id}
            id={domain.id}
            preview={(domain.images && domain?.images[0]) || 'https://placehold.co/270x200'}
            title={domain.name}
            price={domain.price}
            listing
          />
        ))}
        {!loading && domains && !domains.length && (
          <div className={styles.noEntries}>No Favorite Domains yet</div>
        )}
        {!loading && !domains && error && (<div className={styles.error}>
          {error}
        </div>)}
      </Row>
    </Container>
  );
};

export default React.memo(FavoritePremiumDomains);
