import React, { useState } from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';

import Radio from 'general/CustomRadio/CustomRadio';
import Checkbox from 'general/CustomCheckbox/CustomCheckbox';
import UploadFile from 'general/CustomFile/CustomFile';
import Input from 'general/FormInput/FormInput';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

// Page 'QUESTIONNAIRE' Component
import Question from '../Question/Question'

import {
  MAX_LENGTH_FOR_DOMAIN_OPTIONS,
  DOMAIN_EXTESNIONS,
} from '../../../constants/Contants';

import {
  Page3Questionnaire,
} from '../../../constants/Types';


interface Props {
  currentPageNumber: number;
  answers: Page3Questionnaire | any;
  setPage: (page: number) => void;
  setAnswers: (page1: Page3Questionnaire) => void;
  handlePreviousClick: () => void;
}

// eslint-disable-next-line
const DATE_CHEKER = /^(?:(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)[0-9]{2})$/

const STEP3_ERRORS: any = {
  domainMaxLength: 'Please select or enter the maximum characters you want in your domain name.',
  TLDs: 'Please select the extensions you\'re interested in.',
  budget: 'Please enter your maximum budget.',
}

const Page3Questions: React.FunctionComponent<Props> = ({
  currentPageNumber,
  answers,
  setPage,
  setAnswers,
  handlePreviousClick,
}: Props) => {

  const [errors, setErrors] = useState<Page3Questionnaire>({
    domainMaxLength: '',
    TLDs: '',
    budget: '',
    targetDate: '',
    additionalComments: '',
    file: null,
  })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'budget') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: STEP3_ERRORS[name],
        })
      } else {
        if (!/^[0-9]*$/.test(value)) {
          return;
        } else {
          setErrors({
            ...errors,
            [name]: '',
          })
        }
      }
    }

    if (name === 'otherDomainMaxLength') {
      if (value.length > 50) {
        setErrors({
          ...errors,
          domainMaxLength: 'Maximum 50 characters are allowed',
        })
      } else {
        setErrors({
          ...errors,
          domainMaxLength: '',
        })
      }
    }

    if (name === 'additionalComments') {
      if (value.length > 5000) {
        setErrors({
          ...errors,
          [name]: 'Maximum 5000 characters are allowed',
        })
      } else {
        setErrors({
          ...errors,
          [name]: '',
        })
      }
    }

    setAnswers({
      ...answers,
      [name]: value,
    })
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, id } = event.target;
    if (id !== 'Other') {
      setAnswers({
        ...answers,
        [name]: id,
        otherDomainMaxLength: '',
      })
      setErrors({
        ...errors,
        [name]: '',
      })
    } else {
      setAnswers({
        ...answers,
        otherDomainMaxLength: ' ',
        domainMaxLength: '',
      })
    }
  }

  const isCheckboxChecked = (name: string) => {
    const { TLDs } = answers;
    const extensionsArr = TLDs.split(',')
    return extensionsArr.includes(name)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const { TLDs } = answers;

    let extensionArr = TLDs.length ? TLDs.split(','): [];
    if (extensionArr.includes(name)) {
      const index = extensionArr.indexOf(name);
      extensionArr.splice(index, 1);
    } else {
      extensionArr.push(name);
    }
    setAnswers({
      ...answers,
      TLDs: extensionArr.join(',')
    })

    setErrors({
      ...errors,
      TLDs: !extensionArr.length ? STEP3_ERRORS['TLDs'] : '',
    })
  }

  const handleFileChange = (event: any) => {
    const { files } = event.target;
    setAnswers({
      ...answers,
      file: files[0],
    })
  }

  const handleTargetDateChange = (date: any) => {
    setAnswers({
      ...answers,
      targetDate: date,
    })
  }

  const validateStep3 = () => {
    const {
      domainMaxLength,
      otherDomainMaxLength,
      TLDs,
      budget,
      additionalComments,
    } = answers;
    if ((domainMaxLength
        || (otherDomainMaxLength && otherDomainMaxLength.length <= 50))
        && TLDs
        && TLDs.length <= 100
        && budget
    ) {
      if (additionalComments && additionalComments.length > 5000) {
        return false;
      }
      return true;
    }
    if (!domainMaxLength && !TLDs && !budget) {
      setErrors({
        ...errors,
        ...STEP3_ERRORS,
      })
      return false;
    }

    let formErrors: any = {}
    Object.keys(answers).map(field => {
      if (!answers[field]) {
        switch (field) {
        case 'projectType': {
          if (!answers.domainMaxLength && !answers.otherDomainMaxLength) {
            formErrors[field] = STEP3_ERRORS['domainMaxLength']
          }
          break;
        }
          
        case 'TLDs':
          formErrors[field] = STEP3_ERRORS['TLDs']
          break;
          
        case 'budget':
          formErrors[field] = STEP3_ERRORS['budget']
          break;


        default:
          break;
        }
        setErrors({
          ...errors,
          ...formErrors,
        })
      }
      return field;
    })
    return false;
  }

  const handleNextClick = () => {
    if (validateStep3()) {
      setPage(currentPageNumber + 1);
    }
  }

  const ExampleCustomInput = ({ value, onClick }: any) => (
    <div onClick={onClick}>
      <Input placeholder="mm/dd/yyyy" value={value} name="targetDate" onChange={() => {}}/>
    </div>
  );

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  }

  return (
    <>
      <Container id="questions">
        <Row>
          <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
            Domain Name
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="questionAsked">Are there any character constraints for the new domain name? *</div>
            {MAX_LENGTH_FOR_DOMAIN_OPTIONS.map(domain => (
              <Radio
                key={domain.id}
                id={domain.id}
                name={domain.id !== 'Other' ? 'domainMaxLength': 'otherDomainMaxLength'}
                label={domain.id !== 'Other' ? domain.id : 'Other: '}
                isChecked={domain.id !== 'Other' ? domain.id === answers.domainMaxLength : !!answers.otherDomainMaxLength}
                value={answers.otherDomainMaxLength}
                changeCallback={handleRadioChange}
                changeOtherCallback={domain.id === 'Other' ? handleInputChange : () => {}}
                other={domain.id === 'Other'}
              />
            ))}
            {/* {MAX_LENGTH_FOR_DOMAIN_OPTIONS.map((domain, index) => (
              <Radio
                key={`${domain.label}${index}`}
                id={domain.id}
                name={domain.name}
                label={domain.label}
                changeCallback={handleRadioChange}
              />
            ))}
            <Radio
              id="other"
              name="domainMaxLength"
              label="Other:"
              value={answers.domainMaxLength}
              changeCallback={handleRadioChange}
              changeOtherCallback={handleInputChange}
              other 
            /> */}
            {errors.domainMaxLength && <div className="error">{errors.domainMaxLength}</div>}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="questionAsked">Which extensions are you interested in? *</div>
            <small>Please select all that apply, and use &quot;other&quot; if you would like us to research an extension you don&apos;t see here.</small>
            {DOMAIN_EXTESNIONS.map((ext, index) => (
              <Checkbox
                key={`${ext.id}${ext.name}${index}`}
                id={ext.id}
                name={ext.name}
                label={ext.label}
                isChecked={isCheckboxChecked(ext.name)}
                changeCallback={handleCheckboxChange}
              />
            ))}
            {errors.TLDs && <div className="error">{errors.TLDs}</div>}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="What is your max budget? *"
              name="budget"
              placeholder="Your answer."
              label="An aria-label."
              type="textfield"
              value={answers.budget.toString()}
              handleInputChange={handleInputChange}
            />
            {errors.budget && <div className="error">{errors.budget}</div>}
          </Col>

          <Col className="step-name mt-5" xl={12} lg={12} md={12} sm={12}>
            Deadlines & Miscellaneous
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="By which date do you need a finalized brand?"
              type='datePicker'
              name="targetDate"
              selected={answers.targetDate}
              minDate={new Date()}
              maxDate={addMonths(new Date(), 3)}
              customInput={<ExampleCustomInput />}
              handleDateChange={handleTargetDateChange}

            />
            {errors.targetDate && <div className="error">{errors.targetDate}</div>}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="Anything else you would like us to know before we get started?"
              name="additionalComments"
              placeholder="Your Answer"
              label="An aria-label."
              type="textfield"
              value={answers.additionalComments}
              handleInputChange={handleInputChange}
            />
            {errors.additionalComments && <div className="error">{errors.additionalComments}</div>}
          </Col>

          <Col>
            <div className="questionAsked">Attach file</div>
            <UploadFile
              file={answers.file}
              handleFileChnage={handleFileChange}
            />
          </Col>

        </Row>

        <Row>
          <Col className="step-controller">
            <Button
              className="bm-btn px-5 py-2 go-back"
              variant="primary"
              onClick={handlePreviousClick}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} />
              Back
            </Button>
            <Button
              className="bm-btn px-5 py-2 ml-auto go-forward"
              variant="primary"
              onClick={handleNextClick}
            >
              Next
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default React.memo(Page3Questions);
