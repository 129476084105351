import React, { Fragment } from 'react';
import constants from '../../Constants';
import { Button } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login';
import { apiRequest } from 'utils/ApiRequest';

import styles from './FacebookLogin.module.scss';

interface Props {
	onClick: () => void;
	onSuccess: (res: any) => void;
	onError: (error: string) => void;
	isLoading: boolean;
}

export const FBLogin = (props: Props) => {
	
  const { FB_APP_ID, apiConstants: { API_URL } } = constants;

  const FACEBOOK_AUTH_API_URL = `${API_URL}/auth/facebook`;

  const responseFacebook = async (response) => {
    const { onError, onSuccess } = props;
    if (!response || response.status || !response.id) {
      onError('Could not sign you in.');
    }
    else if (!response.email || !response.name) {
      onError('Could not retrieve your name and email from facebook.');
    }
    else {
      try {
        const { response: res, error } = await apiRequest(FACEBOOK_AUTH_API_URL, 'POST', JSON.stringify(response));
        if (res) {
          onSuccess(res);
        } else if (error) {
          onError(error);
        }
      } catch(e) {
        onError(e);
      }
    }
  }

  return (
    <Fragment>
      {!props.isLoading ?
        <FacebookLogin
          appId={FB_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          cssClass={`bm-btn ${styles.loginFacebook}`}
          onClick={props.onClick}
          callback={responseFacebook}
        />
        :
        <Button className={`bm-btn ${styles.loginFacebook}`} type="button" variant="primary" disabled={true}>Loading...</Button>
      }
    </Fragment>
  )

};