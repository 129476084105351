import React from 'react';
import { Form, FormControl } from 'react-bootstrap';

// SCSS
import styles from './CustomRadio.module.scss';

interface InputRadio {
  key?: string;
  label: string;
  name: string;
  id: string;
  changeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void;
  other?: boolean;
  isChecked?: boolean;
  value?: string;
  changeOtherCallback?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputRadio = (props: InputRadio) => {
  // Comment for Devs - Unsure if you want the entire event returned or if you want the value string
  // with the added condition of the "other" text field having input. Leaving it up to you.
  const handleOther = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.changeOtherCallback) {
      props.changeOtherCallback(event);
    }
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.changeCallback(event);
  }

  return (
    <div className={`row mx-0 mb-1 mt-0 ${styles.bmRadio}`}>

      <div className="input-group d-flex align-items-center">

        <div className="input-group-prepend">
          <Form.Check
            // key={props.key}
            className={`col p-0 ${styles.bmRadioInput}`}
            type="radio"
            label={props.label}
            name={props.name}
            id={props.id}
            onChange={handleCheck}
            checked={props.isChecked}
          />
        </div>

        {props.other &&
          <div className={`col ${styles.bmRadioOther}`}>
            <FormControl
              aria-label="Other"
              name={props.name}
              value={props.value}
              onChange={handleOther}
            />
          </div>
        }

      </div>
    </div>
  )
}

export default React.memo(InputRadio);
