import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import SelectDomain from 'general/CustomSelect/CustomSelect';
import FormInput from 'general/FormInput/FormInput';
import TextArea from 'general/CustomTextArea/CustomTextArea';
import Checkbox from 'general/CustomCheckbox/CustomCheckbox';
import { parse } from 'tldjs';

import { apiRequest } from 'utils/ApiRequest';

// Interface
import { User } from 'interfaces/Interfaces';

import styles from './SubmitEntry.module.scss';

interface Props {
  show: boolean;
  hideModal: (hide: boolean) => void;
  fetchDomainsURL: string;
  createNewEntryURL: string;
  buyRequest: string;
  fetchEntriesURL: string;
  setEntriesLoader: (loader: boolean) => void;
  setEntries: (response: any, error: string) => void;
  onCloseModal: () => void;
}

interface FormData {
  domainId: string;
  newDomain: string;
  price?: number | string;
  message?: string;
  agreeToTnC: boolean | string;
}

interface Domain {
  id: string;
  name: string;
  isDeleted: boolean;
  verificationCode: any;
  price: number;
  createdAt: string;
  updatedAt: string;
  user: User;
}

interface BuyRequest {
  TLDs: string;
  additionalComments: string;
  brandFeelings: string;
  budget: number;
  businessDescription: string;
  businessIndustry: string;
  createdAt: string;
  desiredKeywords: string;
  domainMaxLength: string;
  file: string | null;
  geographicRegions: string;
  id: string;
  isDeleted: boolean;
  isExpired: boolean;
  name: string;
  title?: string;
  plan: string;
  projectType: string;
  status: number;
  targetCustomers: string;
  targetDate: string;
  updatedAt: string;
  user: User;
}

interface EntryResponse {
  buyRequest: BuyRequest;
  createdAt: string;
  domain: Domain;
  id: string;
  isDeleted: boolean;
  owner: User;
  updatedAt: string;
}

const SubmitEntry: React.FunctionComponent<Props> = ({
  show,
  hideModal,
  fetchDomainsURL,
  createNewEntryURL,
  buyRequest,
  fetchEntriesURL,
  setEntriesLoader,
  setEntries,
  onCloseModal,
}: Props) => {

  const [formData, setFormData] = useState<FormData | any>({
    domainId: '',
    newDomain: '',
    price: '',
    message: '',
    agreeToTnC: false
  });

  const [formErrors, setFormErrors] = useState<FormData>({
    domainId: '',
    newDomain: '',
    agreeToTnC: '',
    price: ''
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [domains, setDomains] = useState<Array<{ label: string, id: string }> | null>([]);
  const [apiError, setApiError] = useState<string>('');

  const [adding, setAdding] = useState<boolean>(false);
  const [isNewEntryAdded, setIsNewEntryAdded] = useState<boolean>(false);
  const [newEntryError, setNewEntryError] = useState<string>('');

  const handleClose = () => {
    if (isNewEntryAdded) {
      fetchEntries();
      onCloseModal();
    }
    hideModal(false);
  }

  const handleSelectDomain = (event: any) => {
    const { value } = event.target;
    if (value) {
      const getDomainId = domains && domains.filter(d => d.label === value)[0].id;
      setFormData({
        ...formData,
        domainId: getDomainId,
      })
      setFormErrors({
        ...formErrors,
        newDomain: '',
      })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'newDomain') {
      if (!value || !parse(value) || !parse(value).domain) {
        setFormErrors({
          ...formErrors,
          [name]: 'Please enter a valid domain',
        })
      } else {
        setFormErrors({
          ...formErrors,
          [name]: '',
        })
      }
    }

    if (name === 'price') {
      if (value) {
        if (!/^[0-9]*$/.test(value)) {
          return;
        } else {
          setFormErrors({
            ...formErrors,
            [name]: '',
          })
        }
      } else {
        setFormErrors({
          ...formErrors,
          [name]: '',
        })
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFormData({
      ...formData,
      agreeToTnC: checked,
    })
    if (checked) {
      setFormErrors({
        ...formErrors,
        agreeToTnC: '',
      })
    }
  }

  const validateEntry = () => {
    const { domainId, newDomain, agreeToTnC } = formData;
    if ((domainId || (newDomain && parse(newDomain) && parse(newDomain).domain)) && agreeToTnC) {
      return true;
    }
    let errors: any = {};
    Object.keys(formData).map(field => {
      if (!formData[field]) {
        switch (field) {
        case 'newDomain': {
          if (!formData['domainId']) {
            errors[field] = 'Please enter a valid domain';
          }
          break;
        }
        case 'agreeToTnC':
          errors[field] = 'Please agree to the Brandmo\'s terms & conditions to submit an entry';
          break;

        default:
          break;
        }
        setFormErrors({
          ...formErrors,
          ...errors,
        })
      }
      return field;
    })
  }

  useEffect(() => {
    const fetchDomains = async () => {
      const { response: fetchedDomains, error } = await apiRequest(fetchDomainsURL, 'GET');
      if (fetchedDomains && fetchedDomains.length) {
        const domains = fetchedDomains.map(((domain: { name: any; id: any; }) => ({
          label: domain.name,
          id: domain.id,
        })))
        setDomains([{ label: 'Select', id: '' }, ...domains]);
      } else if (error) {
        setApiError(error);
      }
      setLoading(false);
    }

    setLoading(true);
    fetchDomains()
  }, [fetchDomainsURL]);

  const fetchEntries = async () => {
    setEntriesLoader(true);
    const { response, error } = await apiRequest(`${fetchEntriesURL}?buyRequestId=${buyRequest}`, 'GET');
    if (response) {
      setEntries(response, '');
    } else if (error) {
      setEntries(null, error);
    }
    setEntriesLoader(false);
  }

  const buttonLabel = () => {
    let btnLabel = 'Submit and Verify';
    if (isNewEntryAdded && !adding) {
      btnLabel = 'Close';
    }
    if (adding) {
      btnLabel = 'Loading...';
    }
    return btnLabel;
  }

  const callAnalytics = (response: EntryResponse) => {
    const { buyRequest, domain, owner } = response;
    ReactGA.event('Entry Submitted on buy request', {
      submitterEmail: owner.email,
      buyRequestName: buyRequest.title || buyRequest.name,
      buyRequestId: buyRequest.id,
      domainName: domain.name,
      offerAmount: domain.price,
    })
  }

  const createNewEntry = async () => {
    const { domainId, newDomain, price, message } = formData;
    let domain = domainId ? domainId : newDomain;
    let create = domainId ? false : true;

    if (newDomain) {
      const isNewDomainPresent = domains?.filter(domain => domain.label === newDomain)
      if (isNewDomainPresent && isNewDomainPresent.length) {
        domain = isNewDomainPresent[0].id;
        create = false;
      }
    }

    const body = {
      domain,
      create,
      price: (price ? (typeof price === 'number' ? price : parseInt(price)) : ''),
      message,
      buyRequest,
    }
    const { response: newEntryResponse, error } = await apiRequest(createNewEntryURL, 'POST', JSON.stringify({
      ...body,
    }));
    if (newEntryResponse) {
      setIsNewEntryAdded(true);
      callAnalytics(newEntryResponse);
    } else if (error) {
      setNewEntryError(error);
    }
    setAdding(false);
  }

  const submitNewEntry = async () => {
    if (validateEntry()) {
      setAdding(true);
      createNewEntry();
    }
  }

  return (
    <Modal show={show} onHide={handleClose} className={styles.submitEntry}>
      <Modal.Header className={styles.entryHeader} closeButton>
        <Modal.Title className={styles.entryHeaderTitle}>Submit Your Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className={styles.stickyNote}>
          Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scelerisque nec pharetra id, tempor a tortor.
        </div> */}
        {!isNewEntryAdded && (<form>
          {loading && (
            <div className="loader">
              <Spinner animation="border" variant="primary" role="status" />
            </div>
          )}
          {!loading && !domains && apiError && (<div style={{ color: 'red', fontSize: '12px' }}>{apiError}</div>)}
          {!loading && domains && domains.length ? (
            <div className={styles.selectDomain}>
              <div>Select a domain</div>
              <SelectDomain
                id={formData.domainId}
                value={(domains
                  && domains.filter(d => d.id === formData.domainId)[0]
                  && domains.filter(d => d.id === formData.domainId)[0].label) || ''}
                // title={formData.domainId ? domains.filter(d => d.id === formData.domainId)[0].label : 'https://mydomain.net'}
                selectItems={domains}
                onChange={handleSelectDomain}
              />
            </div>
          ): null}
          {formErrors.domainId && (<div style={{ color: 'red', fontSize: '12px', }}>{formErrors.domainId}</div>)}
          <div className={styles.inputLabel}>Add a new domain</div>

          <div className={styles.addStyle}>
            <FormInput
              name="newDomain"
              label="newDomain"
              value={formData.newDomain}
              placeholder=""
              readonly={!!formData.domainId}
              onChange={handleChange}
            />
          </div>
          {formErrors.newDomain && (<div style={{ color: 'red', fontSize: '12px', }}>{formErrors.newDomain}</div>)}
          <div className={styles.inputLabel}>Offer Price (Optional)</div>
          <div className={styles.addStyle}>
            <FormInput
              name="price"
              label="offerPrice"
              value={formData.price}
              placeholder=""
              onChange={handleChange}
            />
          </div>
          {formErrors.price && (<div style={{ color: 'red', fontSize: '12px', }}>{formErrors.price}</div>)}
          <div className={styles.inputLabel}>Message (Optional)</div>
          <TextArea
            name="message"
            placeholder=""
            value={formData.message}
            onChange={handleChange}
          />

          <div className={styles.addCheckStyle}>
            <Checkbox
              label="Check to agree to Brandmo's Terms of Service"
              name="terms"
              id="terms"
              isChecked={formData.agreeToTnC}
              changeCallback={handleCheckboxChange}
            />
          </div>
          {formErrors.agreeToTnC && (<div style={{ color: 'red', fontSize: '12px', }}>{formErrors.agreeToTnC}</div>)}
        </form>)}
        {!adding && isNewEntryAdded && <div className="alert alert-success">Thanks, Your submission has been entered successfully</div>}
        {!adding && !isNewEntryAdded && newEntryError && (<div style={{ color: 'red', fontSize: '12px', }}>{newEntryError}</div>)}
      </Modal.Body>
      <Modal.Footer className={styles.entryFooter}>

        <Button
          variant="primary"
          className={styles.entrySubmitButton}
          onClick={isNewEntryAdded ? handleClose : submitNewEntry}
        >

          {buttonLabel()}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(SubmitEntry)
