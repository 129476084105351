import React from 'react';

import { Col } from 'react-bootstrap';

import styles from './TotalCount.module.scss';


interface Props {
  total: number;
}

const TotalCount: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Col className="mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3" xl={2} lg={2} md={12} sm={12}>
      <div className={styles.totalEntries}>
        <div className={styles.label}>Total Entries</div>
        <div className={styles.result}>{props.total}</div>
      </div>
    </Col>
  )
}

export default React.memo(TotalCount);
