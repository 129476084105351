import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';

import styles from './GetInTouch.module.scss';

const GetInTouch: React.FunctionComponent = () => {
  return (
    <Container className={styles.getInTouch}>

      <Row className={styles.row}>

        <Col xl={8} lg={8} md={12} sm={12}>
          <div className={styles.title}>Need a team of brand name experts?</div>
          <div className={styles.subtitle}>Contact us for a free custom quote.</div>
        </Col>

        <Col className={styles.btnContainer} xl={4} lg={4} md={12} sm={12}>
          <Link to="/contact">
            <Button className="bm-btn" variant="primary">Get In Touch</Button>
          </Link>
        </Col>

      </Row>

    </Container>
  );
};

export default React.memo(GetInTouch);
