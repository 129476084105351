import React from 'react';

import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import Tooltip from './Tooltip/Tooltip';

// SCSS
import './Table.scss';

const Table : React.FunctionComponent = () => {
  return (
    <div className="pricing-table">

      {/* Table */}
      <table className="table brandmo-table">

        {/* Header */}
        <thead className="head">
          <tr className="trow">
            <th className="th border-0"></th>
            <th className="th basic">Basic</th>
            <th className="th plus">Plus</th>
            <th className="th startup">Startup</th>
            <th className="th corporate">Corporate</th>
          </tr>
        </thead>

        {/* Body */}
        <tbody className="tbody">
          <tr className="tr trow">
            <td className="td title-cell" data-label="">Listing Fees</td>
            <td className="td cell amount" data-label="Basic">Free</td>
            <td className="td cell amount" data-label="Plus">$149</td>
            <td className="td cell amount" data-label="Startup">$450</td>
            <td className="td cell amount" data-label="Corporate">$950</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Est. # of Submissions
              <Tooltip
                description="Most buy requests over deliver"
                key="numberOfSubmissions"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic">50 - 100</td>
            <td className="td cell" data-label="Plus">100 - 200</td>
            <td className="td cell" data-label="Startup">200 - 300</td>
            <td className="td cell" data-label="Corporate">300 - 600</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Duration
              <Tooltip
                description="Buy requests may accept submissions for up to 90 days"
                key="duration"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic">90 Days</td>
            <td className="td cell" data-label="Plus">90 Days</td>
            <td className="td cell" data-label="Startup">90 Days</td>
            <td className="td cell" data-label="Corporate">90 Days</td>
          </tr>
        </tbody>
      </table>

      <div className="table-title">
        Promotion
      </div>

      <table className="table brandmo-table">
        <tbody className="tbody">
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Newsletter Send
              <Tooltip
                description="Request featured in our weekly newsletter"
                key="newsletterSend"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus">Included</td>
            <td className="td cell" data-label="Startup">Included</td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Social Media Blast
              <Tooltip
                description="We'll post your buy request to our social media"
                key="socialMediaBlast"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus">Included</td>
            <td className="td cell" data-label="Startup">Included</td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Stand Out Ribbon
              <Tooltip
                description="Request receives a standout ribbon in search results"
                key="standOutRibbon"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup">Included</td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Advertising Push
              <Tooltip
                description="We'll run an advertising campaign for your buy request"
                key="advertisingPush"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup">Included</td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Homepage Feature
              <Tooltip
                description="Buy request featured on the Brandmo homepage"
                key="homepageFeature"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Private Network Outreach
              <Tooltip
                description="Exclusive network invited to participate in your buy request"
                key="privateNetworkReach"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Dedicated Email
              <Tooltip
                description="Dedicated email to our subscribers about your request"
                key="dedicatedEmail"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
        </tbody>
      </table>

      <div className="table-title">
        Brandmo Services
      </div>

      <table className="table brandmo-table">
        <tbody className="tbody">
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Dedicated Consultation
              <Tooltip
                description="Schedule one-on-one consultation with a brand strategist"
                key="dedicatedConsultation"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus">Included</td>
            <td className="td cell" data-label="Startup">Included</td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Brief Editing & Optimization
              <Tooltip
                description="We'll review & optimize your brief"
                key="briefEditing"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic">Included</td>
            <td className="td cell" data-label="Plus">Included</td>
            <td className="td cell" data-label="Startup">Included</td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
        </tbody>
      </table>

      <div className="table-title">
        Research & Due Diligence
      </div>

      <table className="table brandmo-table">
        <tbody className="tbody">
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Trademark Report
              <Tooltip
                description="Discover any potential trademark conflicts"
                key="trademarkReport"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup">One</td>
            <td className="td cell" data-label="Corporate">Two</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Domain Appraisal
              <Tooltip
                description="Receive a professional valuation"
                key="domainAppraisal"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup">One</td>
            <td className="td cell" data-label="Corporate">Two</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">
              Risk Assessment
              <Tooltip
                description="Investigate a domain name's history & usage"
                key="riskAssessment"
                name="basic-tooltip"
              />
            </td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Corporate">One</td>
          </tr>
        </tbody>
      </table>

      {/* <div className="table-title">
        Privacy
      </div>

      <table className="table brandmo-table">
        <tbody className="tbody">
          <tr className="tr trow">
            <td className="td title-cell" data-label="">NDA</td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup">Included</td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">Private Listing</td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup">Included</td>
            <td className="td cell" data-label="Corporate">Included</td>
          </tr>
          <tr className="tr trow">
            <td className="td title-cell" data-label="">Domain Appraisal</td>
            <td className="td cell" data-label="Basic"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Plus"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Startup"><FontAwesomeIcon icon={faTimes}/></td>
            <td className="td cell" data-label="Corporate">One</td>
          </tr>
        </tbody>
      </table> */}

      <table className="table brandmo-table">
        <tbody>
          <tr className="tr trow get-started-row">
            <td className="td cell border-0" data-label=""></td>
            <td className="td cell border-top-0" data-label="Basic">
              <Link to="/questionnaire?selectedPlan=basic">
                <Button className="bm-btn basic" variant="outline-primary">
                Get Started
                </Button>
              </Link>
            </td>
            <td className="td cell border-top-0" data-label="Plus">
              <Link to="/questionnaire?selectedPlan=plus">
                <Button className="bm-btn plus" variant="primary">
                Get Started
                </Button>
              </Link>
            </td>
            <td className="td cell border-top-0" data-label="Startup">
              <Link to="/questionnaire?selectedPlan=startup">
                <Button className="bm-btn startup" variant="primary">
                Get Started
                </Button>
              </Link>
            </td>
            <td className="td cell border-top-0" data-label="Corporate">
              <Link to="/questionnaire?selectedPlan=corporate">
                <Button className="bm-btn corporate" variant="primary">
                Get Started
                </Button>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="notice">*There is a 10% success fee for acquisitions made using the buy request tool. </div>

    </div>
  );
};

export default React.memo(Table);
