import React, { useState } from 'react';

import { Container, Row, Col, Button, FormControl } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import styles from './SearchList.module.scss';

interface Props {
  showExport?: boolean;
  searchText?: string;
  setSearchText?: (text: string) => void;
  searchList?: (searchText: string) => void;
  resetFilters?: () => void;
}

const OnlySearchBarProps = {
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
}

const SearchBarWithExportProps = {
  xl: 10,
  lg: 10,
  md: 10,
  sm: 10,
}

const SearchList: React.FunctionComponent<Props> = (props: Props) => {

  const [searchText, setSearchText] = useState<string>('');

  const searchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.which === 13 || event.charCode === 13) && props.searchList) {
      props.searchList(searchText);
    }
  }

  const handleSearchClick = () => {
    if (props.searchList) {
      props.searchList(searchText);
    }
  }

  /* const resetAllFilters = () => {
    setSearchText('');
    if (props.resetFilters) {
      props.resetFilters();
    }
  } */

  return (
    <div className={styles.searchList}>
      <Container className="p-0" fluid>
        <Row>

          <Col {...(props.showExport ? {...SearchBarWithExportProps} : {...OnlySearchBarProps})}>
            <FormControl
              placeholder="Search Entries"
              aria-label="Search Entries"
              value={searchText}
              onChange={searchTextChange}
              onKeyPress={handleKeyPress}
            />
            <Button
              variant="outline-secondary"
              className={styles.entrySearchSubmit}
              onClick={handleSearchClick}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </Col>

          {props.showExport && (<Col className="pl-xl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 mt-3" xl={2} lg={2} md={2} sm={2}>
            <Button
              variant="outline-primary"
              className={styles.export}
            >
              Export
            </Button>
          </Col>)}

          {/* {(<Col className="pl-xl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 mt-3" xl={2} lg={2} md={2} sm={2}>
            <Button
              variant="outline-primary"
              className={styles.resetFilters}
              onClick={resetAllFilters}
            >
              All
            </Button>
          </Col>)} */}

        </Row>
      </Container>
    </div>
  )
}

export default React.memo(SearchList);
