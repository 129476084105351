import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { Router } from 'react-router-dom';
import history from './utils/History';

// Context
import GlobalState from './contexts/Global.state';
import GlobalContext from './contexts/Global.context';

import { App } from './App';

import './styles/brandmo.scss';

ReactGA.initialize('G-BE7B7F6ZCQ');

ReactDOM.render(
  <Router history={history}>
    <GlobalState>
      <GlobalContext.Consumer>
        {() => (
          <App />
        )}
      </GlobalContext.Consumer>
    </GlobalState>
  </Router>,
  document.getElementById('root')
);
