import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

// Page 'QUESTIONNAIRE' Component
import Question from '../Question/Question'

import {
  Page2Questionnaire,
} from '../../../constants/Types';


interface Props {
  currentPageNumber: number;
  answers: Page2Questionnaire | any;
  setPage: (page: number) => void;
  setAnswers: (page1: Page2Questionnaire) => void;
  handlePreviousClick: () => void;
}

/* const STEP2_ERRORS: any = {
  brandFeelings: 'Please enter the emotion you want to communicate with the brand',
  desiredKeywords: 'Please enter the desired words you want to include in the brand name',
  targetCustomers: 'Please provide the details about the targeted audience',
  geographicRegions: 'Please enter the locations where you plan to operate in'
} */

const Page1Questions: React.FunctionComponent<Props> = ({
  currentPageNumber,
  setPage,
  answers,
  setAnswers,
  handlePreviousClick,
}: Props) => {

  const [errors, setErrors] = useState<Page2Questionnaire>({
    brandFeelings: '',
    desiredKeywords: '',
    targetCustomers: '',
    geographicRegions: '',
  })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'brandFeelings') {
      /* if (!value) {
        setErrors({
          ...errors,
          [name]: 'Please enter the emotion you want to communicate with the brand',
        })
      } else { */
      if (value.length > 5000) {
        setErrors({
          ...errors,
          [name]: 'Maximum 5000 characters are allowed',
        })
      } else {
        setErrors({
          ...errors,
          [name]: '',
        })
      }
    }

    if (name === 'desiredKeywords') {
      /* if (!value) {
        setErrors({
          ...errors,
          [name]: STEP2_ERRORS[name],
        })
      } else { */
      if (value.length > 1000) {
        setErrors({
          ...errors,
          [name]: 'Maximum 1000 characters are allowed',
        })
      } else {
        setErrors({
          ...errors,
          [name]: '',
        })
      }
    }

    if (name === 'targetCustomers') {
      /* if (!value) {
        setErrors({
          ...errors,
          [name]: STEP2_ERRORS[name],
        })
      } else { */
      if (value.length > 5000) {
        setErrors({
          ...errors,
          [name]: 'Maximum 5000 characters are allowed',
        })
      } else {
        setErrors({
          ...errors,
          [name]: '',
        })
      }
    }

    if (name === 'geographicRegions') {
      /* if (!value) {
        setErrors({
          ...errors,
          [name]: STEP2_ERRORS[name],
        })
      } else { */
      if (value.length > 5000) {
        setErrors({
          ...errors,
          [name]: 'Maximum 5000 characters are allowed',
        })
      } else {
        setErrors({
          ...errors,
          [name]: '',
        })
      }
    }
    
    setAnswers({
      ...answers,
      [name]: value,
    })
  }

  const validateStep2 = () => {
    const { brandFeelings, desiredKeywords, targetCustomers, geographicRegions } = answers;
    if (brandFeelings.length <= 5000
      && desiredKeywords.length <= 1000
      && targetCustomers.length <= 5000
      && geographicRegions.length <= 5000
    ) {
      return true;
    }
    /* if (!brandFeelings && !desiredKeywords && !targetCustomers && !geographicRegions) {
      setErrors({
        ...errors,
        ...STEP2_ERRORS,
      })
      return false;
    } */

    /* let formErrors: any = {}
    Object.keys(answers).map(field => {
      if (!answers[field]) {
        switch (field) {
        case 'brandFeelings': {
          formErrors[field] = 'Please enter the emotion you want to communicate with the brand'
          break;
        }
          
        case 'desiredKeywords':
          formErrors[field] = 'Please enter the desired words you want to include in the brand name'
          break;
          
        case 'targetCustomers':
          formErrors[field] = 'Please provide the details about the targeted audience'
          break;

        case 'geographicRegions':
          formErrors[field] = 'Please enter the locations where you plan to operate in'
          break;

        default:
          break;
        }
        setErrors({
          ...errors,
          ...formErrors,
        })
      }
      return field;
    }) */
    return false;
  }

  const handleNextClick = () => {
    if (validateStep2()) {
      setPage(currentPageNumber + 1);
    }
  }

  return (
    <>
      <Container id="questions">
        <Row>
          <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
            Brand Vision
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="What kind of emotion or feelings do you want to communicate with your brand?"
              name="brandFeelings"
              placeholder="Your Answer"
              label="Aria-label."
              type="textfield"
              value={answers.brandFeelings}
              handleInputChange={handleInputChange}
            />
            {errors.brandFeelings && (<div className="error">{errors.brandFeelings}</div>)}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="Please provide any specific words you want to explore incorporating in the new name"
              name="desiredKeywords"
              placeholder="Your Answer"
              label="Aria-label."
              type="textfield"
              value={answers.desiredKeywords}
              handleInputChange={handleInputChange}
            />
            {errors.desiredKeywords && (<div className="error">{errors.desiredKeywords}</div>)}
          </Col>

          <Col className="step-name mt-5" xl={12} lg={12} md={12} sm={12}>
            Customers & Demographics
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="Who are your target customers?"
              name="targetCustomers"
              placeholder="Your answer."
              label="An aria-label."
              type="textfield"
              value={answers.targetCustomers}
              handleInputChange={handleInputChange}
            />
            {errors.targetCustomers && (<div className="error">{errors.targetCustomers}</div>)}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="What geographic regions will you operate in?"
              name="geographicRegions"
              placeholder="Your answer."
              label="An aria-label."
              type="textfield"
              value={answers.geographicRegions}
              handleInputChange={handleInputChange}
            />
            {errors.geographicRegions && (<div className="error">{errors.geographicRegions}</div>)}
          </Col>

        </Row>

        <Row>
          <Col className="step-controller">
            <Button
              className="bm-btn px-5 py-2 go-back"
              variant="primary"
              onClick={handlePreviousClick}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} /> 
              Back
            </Button>
            <Button
              className="bm-btn px-5 py-2 ml-auto go-forward"
              variant="primary"
              onClick={handleNextClick}
            >
              Next 
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default React.memo(Page1Questions);
