import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { apiRequest } from 'utils/ApiRequest';

// Constants
import constants from 'Constants';

import PopularContest from './PopularContest/PopularContest';
import Loader from 'general/Loader/Loader';

// SVGs
import TopWave from 'assets/svgs/Home/PopularContests/top-wave.svg';
import SVG from 'react-inlinesvg'

// SCSS
import styles from './PopularContests.module.scss';

interface Contest {
  id: string;
  projectType: string;
  name?: string;
  businessDescription: string;
  businessIndustry: string;
  brandFeelings: string;
  desiredKeywords: string;
  domainMaxLength: string;
  TLDs: string;
  budget: number;
  targetCustomers: string;
  geographicRegions: string;
  targetDate: string;
  additionalComments: string;
  status: number;
  isDeleted: boolean;
  file: string;
  isExpired: boolean;
  createdAt: string;
  updatedAt: string;
  userId: string;
  userName: string;
  submissions: string;
  total: string;
  title?: string;
  isFeatured?: boolean;
}

const { apiConstants: { API_URL } } = constants;

const POPULAR_CONTESTS_URL = `${API_URL}/requests/filter`

const DEFAULT_PARAMS_FOR_API = {
  sortBy: 'isFeatured',
  filters: {
    isExpired: false
  }, 
  sortOrder: 'DESC',
}

const PopularContests: React.FunctionComponent = () => {
  const [popularContests, setPopularContests] = useState<Array<Contest> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // Fetch popular contests on mount
  useEffect(() => {
    const fetchPopularContests = async () => {
      try {
        setLoading(true);
        const { response } = await apiRequest(POPULAR_CONTESTS_URL, 'POST', JSON.stringify({
          ...DEFAULT_PARAMS_FOR_API,
        }));
        setLoading(false);
        if (response && response.length) {
          setPopularContests(response.splice(0, 3));
        }
      } catch (err) {
        setLoading(false);
        setError(`Error occured while fetching the contest: ${err.toString()}`)
      }
    }

    fetchPopularContests();
  }, [])


  return (
    <Container id={styles.popularWrap} className="p-0" fluid>
      <Container id={styles.topWave} className="p-0" fluid>
        <SVG
          baseURL="/"
          cacheRequests={true}
          description="BG Img"
          loader={<span>Loading...</span>}
          src={TopWave}
          title=""
        />
      </Container>
      <Container id={styles.popularContests} fluid>
        <Container>

          <Row className={styles.titleRegion}>
            <Col>
              <div className={styles.title}>Popular Buy Requests</div>
              <div className={styles.subtitle}>The most popular and active buy requests on Brandmo.</div>
            </Col>
          </Row>

          <Row>
            {loading && <Loader />}
            {!loading && !error && popularContests && popularContests.map((contest) => (
              <PopularContest
                key={contest.id}
                id={contest.id}
                title={contest.title || contest.name}
                description={contest.businessDescription}
                submissions={contest.submissions}
                targetDate={contest.targetDate}
                budget={contest.budget}
              />
            ))}
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default React.memo(PopularContests);
