import React from 'react';
import { Form } from 'react-bootstrap';

// SCSS
import styles from './CustomTextArea.module.scss';

interface CustomTextArea {
  marginBottom?: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const CustomTextArea = (props: CustomTextArea) => {
  return (
    <div className={`${props.marginBottom || 'mb-4'}  ${styles.bmTextArea}`}>
      <Form.Control
        as="textarea"
        rows="3"
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  )
}

export default React.memo(CustomTextArea);
