import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// SCSS
import styles from './DesignConcept.module.scss';

interface Props {
  title: string;
  conceptImages: Array<string>;
}

const DesignConcept: React.FunctionComponent<Props> = ({ title, conceptImages }: Props) => {
  return (
    <Container className={styles.designConcept}>

      <Row>
        <Col>
          <div className={styles.conceptTitle}>
            Design Concepts for {title}
          </div>
        </Col>
      </Row>

      <Row>
        <Col className={styles.conceptBlock} xl={6} lg={6} md={12} sm={12}>
          <div className={styles.conceptImage}>
            <img
              className="img-fluid"
              src={conceptImages && conceptImages.length ? conceptImages[0] : 'https://placehold.co/570x405'}
              alt="Design concept logo 1"
            />
          </div>

          <div className={styles.conceptData}>
            <div className={styles.conceptDataTitle}>
              Design Concepts
            </div>

            <div className={styles.conceptDataDescription}>
              {'Kickstart your brand\'s identity with design concepts and logos. They are handcrafted by our team of professionals and delivered via email upon the successful offer and purchase of a domain name.'}
            </div>
          </div>
        </Col>

        <Col className={styles.conceptBlock} xl={6} lg={6} md={12} sm={12}>
          <div className={styles.conceptImage}>
            <img
              className="img-fluid"
              src={conceptImages && conceptImages.length ? conceptImages[1] : 'https://placehold.co/570x405'}
              alt="Design concept logo 2"
            />
          </div>

          <div className={styles.conceptData}>
            <div className={styles.conceptDataTitle}>
              Resources
            </div>

            <div className={styles.conceptDataDescription}>
              Research this brand on the web using the helpful resources below to uncover more information. 

              
            </div>

            <div className={styles.conceptDataDescription}>
              Whois Lookup for <a href={`https://whois.domaintools.com/${title}`} target="_blank" rel="noopener noreferrer">{title}</a>  
            </div>

            <div className={styles.conceptDataDescription}>
              Google Search for <a href={`http://www.google.com/search?q=${title}`} target="_blank" rel="noopener noreferrer">{title}</a>  
            </div>

            <div className={styles.conceptDataDescription}>
              Twitter Search for <a href={`https://twitter.com/search/?q=${title}`} target="_blank" rel="noopener noreferrer">{title}</a>  
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(DesignConcept);
