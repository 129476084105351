import  React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

// Page Components
import QA from '../../../../../general/FAQ/FAQ';

// SCSS
import styles from './FAQBlock.module.scss';

const FAQ: React.FunctionComponent = () => {
  return (
    <Container className={styles.faqRegion}>

      <Row>
        <Col className={styles.faqRegionTitle} xl={12} lg={12} md={12} sm={12}>
          FAQ
        </Col>
      </Row>

      <Row>
        <Col className={styles.faqWrapper}>
          <Accordion defaultActiveKey="0">

            <QA
              eventKey="0"
              question="How long will it take to get my name?"
              answer="When you buy a domain name on the Brandmo marketplace we will email you within 24 hours to guide you through the steps of accepting the name into your account. The process is typically completed within 1 to 3 business days."
            />

            <QA
              eventKey="1"
              question="What is Brandmo's refund policy?"
              answer="Given the nature of domain names we do not allow refunds or exchanges for acquisitions made on the Brandmo marketplace. All sales are considered final once the name has arrived in your account."
            />

            <QA
              eventKey="2"
              question="What do I get when I buy a domain name?"
              answer="Along with your domain name your purchase may include a logo and/or design concepts. Please refer to the 'what's included included' section located at the top right of the listing page to find out what is included with each domain that is listed for sale on the Brandmo marketplace."
            />

            <QA
              eventKey="3"
              question="Can you help me name my business? "
              answer="If you are having trouble finding the right domain name for your business we encourage you to consider launching a brand request and our community of brand name experts will send you hand selected domain names matching your criteria. Find a name you like, make and an offer and secure your brand safely using escrow. If you have other custom branding projects you would like to discuss, please reach out via the contact page."
            />

            <QA
              eventKey="4"
              question="I have more questions can I contact you? "
              answer="Yes, please send all inquiries via our contact page and we will be happy to answer your questions. "
            />

          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
