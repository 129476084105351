import React, { useState, useEffect, useCallback } from 'react';

import { withRouter } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

import { Button, Container, Row, Col } from 'react-bootstrap';

import queryString from 'query-string';

// Const
import Constants from '../../Constants';

// Utils
import { apiRequest } from 'utils/ApiRequest';
import scrollToHandler from 'utils/ScrollHandler';

// Page Components
import Pagination from 'general/Pagination/Pagination';
import DomainCard from '../../components/DomainCard/DomainCard';
import FormInput from '../../general/FormInput/FormInput';
import FormSelect from '../../general/CustomSelect/CustomSelect';
import FormRange from 'general/CustomInputRange/CustomInputRange';
import SearchByCategory from './components/SearchByCategory/SearchByCategory';
import RecentlySold from '../../components/RecentlySold/RecentlySold';
import Subscribe from '../../components/Subscribe/Subscribe';
import Loader from 'general/Loader/Loader';

// Utils
import removeProperty from 'utils/functions/removeProperty';

// SCSS
import styles from './DomainListing.module.scss';


interface Domain {
  id: string;
  name: string;
  price?: number;
  category: string;
  keywords: string;
  images: Array<string>;
  extension: string;
  total: string;
  verificationCode: string;
  isDeleted: string;
  createdAt: string;
  updatedAt: string;
  isVerified: string;
  url?: string;
}

interface DomainFilters {
  keywords: string;
  extension: string;
  length: Array<Number>;
  category: string;
}

const { pageConstants: { INDUSTRY_DROPDOWN, DOMAINS_PER_PAGE } } = Constants;
const { apiConstants: { API_URL } } = Constants;

const FETCH_DOMAINS_URL = `${API_URL}/premium-domains/filter`;

const MAX_DOMAIN_PRICE = 999999999999;

const ExtensionSelect = [
  { label: 'All' },
  { label: '.com' },
  { label: '.net' },
  { label: '.org' },
  { label: '.io' },
  { label: '.us' },
  { label: '.gg' },
  { label: '.ca' },
  { label: '.xyz' },
]

const SORT_BY = [
  { key: 'likes', value: 'likes', label: 'Most Popular' },
  { key: 'createdAt', value:'createdAt', label: 'Most Recent' },
  { key: 'price', value: 'priceLTH', label: 'Price: Low to High' },
  { key: 'price', value: 'priceHTL', label: 'Price: High to Low' },
]

const DomainListing = withRouter(({ location }) => {

  const domainListRef = React.useRef(null);
  const { hash, search } = location;

  const filterByKeyword = queryString.parse(search).filterByKeyword || '';
  const filterByCategory = queryString.parse(search)?.filterByCategory?.toString().trim().replace(/--/g, '&') || '';

  const defaultFilterValues = {
    keywords: filterByKeyword || '',
    extension: '',
    // length: [],
    category: filterByCategory || '',
  }

  const defaultSorting = {
    sortBy: queryString.parse(search).sortBy ? SORT_BY.filter(s => s.value === queryString.parse(search).sortBy)[0].label : 'Most Popular',
    sortOrder: 'DESC',
  }

  const [activePage, setActivePage] = useState<number>(1);
  const [skipResults, setSkipResults] = useState<number>(0);

  const [searchText, setSearchText] = useState<any>(filterByKeyword || '');
  const [filters, setFilters] = useState<DomainFilters | any>(defaultFilterValues);

  const [length, setLength] = useState<Array<number>>([0, 15]);

  /* const [minLength, setMinLength] = useState<number>(0);
  const [maxLength, setMaxLength] = useState<number>(15); */
  const [selectedLength, setSelectedLength] = useState<number>(15);

  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [price, setPrice] = useState<Array<number>>([]);

  const [sorting, setSorting] = useState<{sortBy: any, sortOrder: string}>(defaultSorting)

  const [domains, setDomains] = useState<Array<Domain> | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const setPageAndSkip = (page: number) => {
    setActivePage(page);
    setSkipResults((page - 1) * DOMAINS_PER_PAGE);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (name === 'keywords') {
      setSearchText(value);
    }

    if (name === 'minimum') {
      if (value) {
        setMinPrice(parseInt(value));
      } else {
        setMinPrice(0);
      }
    }

    if (name === 'maximum') {
      if (value) {
        setMaxPrice(parseInt(value));
      } else {
        setMaxPrice(0);
      }
    }
  }

  const handlePriceBlur = () => {
    if (minPrice && maxPrice) {
      const priceFilter = [minPrice, maxPrice];
      setPrice(priceFilter);
    }

    if (minPrice && !maxPrice) {
      setPrice([minPrice, MAX_DOMAIN_PRICE]);
    }

    if ((!minPrice && maxPrice)) {
      setPrice([0, maxPrice]);
    }

    if (!minPrice && !maxPrice) {
      setPrice([0, MAX_DOMAIN_PRICE]);
    }
  }

  const handleCategoryChange = (event: any) => {
    const { value } = event.target;
    if (value !== 'Select') {
      setFilters({
        ...filters,
        category: value,
      })
    } else {
      setFilters({
        ...filters,
        category: '',
      })
    }
    
  }

  const handleExtensionChange = (event: any) => {
    const { value } = event.target;
    if (value !== 'All') {
      setFilters({
        ...filters,
        extension: value,
      })
    } else {
      setFilters({
        ...filters,
        extension: '',
      })
    }
  }

  const searchByCategory = (categorySelected: string) => {
    setFilters({
      ...filters,
      category: categorySelected,
    })
    setSorting({
      ...defaultSorting,
    })
  }

  const handleSortByChange = (event: any) => {
    const { value } = event.target;

    let sortBy = value;
    let sortOrder = 'DESC';

    switch(value) {

    case 'Price: Low to High': {
      sortOrder='ASC';
      break;
    }

    default:
      break;
    }

    setSorting({
      ...sorting,
      sortBy,
      sortOrder,
    })
  }

  const searchDomains = () => {
    setFilters({
      ...filters,
      keywords: searchText,
    })
  }

  const searchOnEnterOrReturn = (event: any) => {
    if (event.which === 13 || event.charCode === 13) {
      setFilters({
        ...filters,
        keywords: searchText,
      })
    }
  }

  const handleRangeChange = (value: number) => {
    setSelectedLength(value)
  }

  const setDomainLengthFilter = (value: number) => {
    setLength([0, value]);
  }

  const { sortBy, sortOrder } = sorting;

  const fetchDomains = useCallback(async () => {
    let filtersToSend = {...filters};
    Object.keys(filters).forEach(filter => {
      if (filters[filter] === '' || filters[filter].length === 0) {
        filtersToSend =  removeProperty(filtersToSend, filter);
      }
    });
    if (price && price.length === 2) {
      filtersToSend = {
        ...filtersToSend,
        price,
      }
    }
    if (length) {
      filtersToSend = {
        ...filtersToSend,
        length,
      }
    }
    setLoading(true);
    const { response, error } = await apiRequest(FETCH_DOMAINS_URL, 'POST', JSON.stringify({
      filters: {
        ...filtersToSend,
      },
      sortBy: SORT_BY.filter(s => s.label === sortBy)[0].key,
      sortOrder,
      skip: skipResults,
      limit: DOMAINS_PER_PAGE,
    }));
    if (response) {
      setDomains(response);
    } else if (error) {
      setDomains(null);
      setError(`Error occurred while fetching the domains due to: ${error}`);
    }
    setLoading(false);
  }, [
    filters,
    sortBy,
    sortOrder,
    skipResults,
    length,
    price,
  ]);

  useEffect(() => {
    fetchDomains();
    // scrollToHandler();
  }, [fetchDomains]);

  useEffect(() => {
    if (location.hash || activePage !== 1) {
      scrollToHandler(domainListRef);
    }
  }, [location, activePage]);

  useEffect(() => {
    scrollToHandler();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Domain Listing",
    });
  }, [])

  const currentPageRecords = (activePage: number, totalCount: number, results: Array<any> = []) => {
    let countStart = 0
    let countEnd = 0
    if (totalCount > 0) {
      if (totalCount < DOMAINS_PER_PAGE) {
        countStart = 1
        countEnd = totalCount
      }
      else {
        countStart = (activePage - 1) * DOMAINS_PER_PAGE + 1
        if (results.length < DOMAINS_PER_PAGE) {
          countEnd = totalCount
        }
        else {
          countEnd = activePage * DOMAINS_PER_PAGE
        }
      }
    }
    return `Showing ${countStart}-${countEnd} of ${totalCount}`;
  }

  return (
    <Container id={styles.pageContainer} className="p-0" fluid>
      <Helmet>
        <title>
          Premium Brands For Sale | Buy & Sell | Brandmo
        </title>
      </Helmet>
      <Container className={styles.domListing} fluid>
        <Container>
          <Row className={styles.domListingHeader}>
            <Col xl={5} lg={5} md={12} sm={12}>
              <div className={styles.domListingTitle}>
                Business names from <span>Brandmo</span>
              </div>
            </Col>

            <Col xl={7} lg={7} md={12} sm={12}>
              <div className={styles.domListingDescription}>
                Great businesses start with great names.  Finding the right brand name with a matching domain has never been easier.  Our hand-picked selection of unique, catchy and creative business names will make your company or app stand out.
              </div>
            </Col>
          </Row>

          <Row id={hash} ref={domainListRef} className={`d-flex justify-content-center align-items-center ${styles.domListingSearchOptions}`}>
            <Col className={styles.addBorder} xl={12} lg={12} md={12} sm={12}>
              <div className={styles.domListingSearchLabel}>Search Keyword</div>
              <Row className={styles.domListingSearchKeyWrap}>
                <Col className="col-8" xl={10} lg={10}>
                  <FormInput
                    name="keywords"
                    label="Enter a keyword or category"
                    value={searchText}
                    placeholder="Enter a keyword or category"
                    onChange={e => handleInputChange(e)}
                    onKeyPress={searchOnEnterOrReturn}
                  />
                </Col>

                <Col className="col-4" xl={2} lg={2}>
                  <Button
                    className="btn bm-btn"
                    onClick={searchDomains}
                  >
                    Search
                  </Button>
                </Col>

              </Row>
            </Col>

            <Col className={styles.addBorder} xl={3} lg={3} md={6} sm={12}>
              <div className={styles.domListingSearchLabel}>Extensions</div>
              <FormSelect
                id={filters.extension}
                value={filters.extension}
                selectItems={ExtensionSelect}
                onChange={e => handleExtensionChange(e)}
              />
            </Col>

            <Col xl={3} lg={3} md={6} sm={12}>
              <div className={styles.domListingSearchLabel}>Domain Length</div>
              <FormRange
                classes="mb-4 pb-4"
                max={15}
                min={0}
                value={selectedLength}
                onChange={handleRangeChange}
                onChangeComplete={setDomainLengthFilter}
                // onChange={value =>props.onChange(value)} 
              />
            </Col>

            <Col className="p-0" xl={3} lg={3} md={6} sm={21}>
              <Row className="m-0">
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.domListingSearchLabel}>Price Range</div>
                </Col>

                <Col className={`pr-0 ${styles.addBorder}`}>
                  <FormInput
                    name="minimum"
                    label="Minimum Price"
                    value={minPrice !== 0 ? minPrice.toString() : ''}
                    placeholder="$Min"
                    onChange={e => handleInputChange(e)}
                    onBlur={handlePriceBlur}
                  />
                </Col>

                <Col className={`px-0 mb-3 ${styles.inputTo}`}>
                  to
                </Col>

                <Col className={`pl-0 ${styles.addBorder}`}>
                  <FormInput
                    name="maximum"
                    label="Maximum Price"
                    value={maxPrice !== 0 ? maxPrice.toString() : ''}
                    placeholder="$Max"
                    onChange={e => handleInputChange(e)}
                    onBlur={handlePriceBlur}
                  />
                </Col>

              </Row>
            </Col>

            <Col className={styles.addBorder} xl={3} lg={3} md={6} sm={12}>
              <div className={styles.domListingSearchLabel}>Categories</div>
              <FormSelect
                id={filters.category}
                value={filters.category}
                selectItems={INDUSTRY_DROPDOWN}
                onChange={e => handleCategoryChange(e)}
              />
            </Col>

          </Row>

          {domains && (<Row className={`m-0 ${styles.domListingSortBy}`}>
            <Col className="p-0" xl={6} lg={6} md={12} sm={12}>
              <div
                className={styles.domListingSortTotal}
              >
                {currentPageRecords(activePage, parseInt((domains && domains[0]?.total) || '0'), domains)}
              </div>
            </Col>

            <Col className="p-0" xl={6} lg={6} md={12} sm={12}>
              <Row>
                <Col className={`ml-auto ${styles.domListingSortBox}`} xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.domListingSortLabel}>Sort by</div>
                  <div className={styles.addBorder}>
                    <FormSelect
                      id="sortBy"
                      value={sorting.sortBy}
                      selectItems={SORT_BY}
                      className={styles.sortBy}
                      onChange={e => handleSortByChange(e)}
                    />
                  </div>
                </Col>

              </Row>
            </Col>

          </Row>)}

          <Row className={styles.domainList}>
            {loading && <Loader />}
            {!loading && !error && domains && domains.map(domain => (
              <DomainCard
                key={domain.id}
                id={domain.id}
                preview={(domain.images && domain?.images[0]) || 'https://placehold.co/270x200'}
                title={domain.name}
                price={domain.price}
                url={domain.url}
                listing
              />
            ))}
            {!loading && !domains && error && (<div className={styles.error} style={{
              fontSize: '14px',
            }}>
              {error}
            </div>)}
          </Row>

          {domains && (
            <Pagination
              totalCount={domains.length ? parseInt(domains[0]?.total) : 0}
              itemsPerPage={DOMAINS_PER_PAGE}
              pageCount={domains && domains[0]?.total ? Math.ceil(parseInt(domains[0].total) / DOMAINS_PER_PAGE) : 0}
              windowSize={4}
              page={activePage}
              getActivePage={setPageAndSkip}
            />
          )}

        </Container>

      </Container>

      <SearchByCategory
        categories={INDUSTRY_DROPDOWN.slice(1)}
        searchByCategory={searchByCategory}
      />

      <RecentlySold />

      <Subscribe />

    </Container>
  );
});

export default React.memo(DomainListing);
