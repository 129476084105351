import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';

import numeral from 'numeral';

import { Col } from 'react-bootstrap';
import cx from 'classnames';

// SCSS
import styles from './DomainCard.module.scss';

interface DomainCard {
  id?: string;
  preview: string;
  title: string;
  price?: number | string;
  sold?: boolean;
  listing?: boolean;
  url?: string;
}

const DomainCard = (props: DomainCard) => {
  const wrapperClass = cx({
    [styles.sold] : props.sold,
    [styles.listing] : props.listing
  });

  return (
    <Fragment>
      <Col className={styles.domainCard} xl={3} lg={3} md={6} sm={12}>
        <Link key={props.id} className={props.sold ? styles.sold : ''} to={props.sold ? '/domain-listing' : `/domain-listing/${props.url}`}>
          <div className={`${styles.domainCardWrap} ${wrapperClass}`}>
            <div className={styles.preview} style={{backgroundImage: `url(${props.preview})`}}></div>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.price}>{props.price !== null ? numeral(props.price).format('$0,0') : 'Make Offer'}</div>
          </div>
        </Link>
      </Col>
    </Fragment>
  )
}

export default React.memo(DomainCard);
