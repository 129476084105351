export const uploadFile = async (
  url: string,
  method: string,
  bodyParams?: any
): Promise<any> => {
  const response = await fetch(url, {
    method,
    body: bodyParams ? bodyParams : undefined
  })
  if (response) {
    if (!response.ok) {
      return { error: await response.clone().text() || 'Internal server error' }
    } else {
      return { response: 'success' }
    }
  }
};
    