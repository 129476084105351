import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'

// Utils
import { apiRequest } from 'utils/ApiRequest';

// Constants
import constants from '../../../../Constants';

// Page Components
import Header from '../SettingsHeader/Header';
import SubmitButton from 'general/SubmitButton/SubmitButton';
import FormInput from 'general/FormInput/FormInput';

// SCSS
import styles from './ChangeEmail.module.scss';

const {
  errorConstants: { EMAIL_NOT_ENTERED_ERROR, EMAIL_IS_NOT_VALID },
  regExValidators: { EMAIL_VALIDATOR_REGEX },
} = constants;

interface Props {
  currentEmail: string;
  apiURL: string,
  setNewToken: (token: string) => void;
}

interface APIResponse {
  token: string;
  id: string;
}

const ChangeEmail: React.FC<Props> = ({ currentEmail, apiURL, setNewToken }: Props) => {
  const [email, setEmail] = useState<string>(currentEmail);
  const [error, setError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const [updateSuccessfull, setUpdateSuccessfull] = useState<boolean>(false);

  const apiConfig = {
    method: 'PUT',
    body: JSON.stringify({ email }),
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      if (!EMAIL_VALIDATOR_REGEX.test(value)) {
        setError(EMAIL_IS_NOT_VALID);
      } else {
        if (value === currentEmail) {
          setError('Please enter a different email id');
        } else {
          setError('');
        }
      }
    } else {
      setError(EMAIL_NOT_ENTERED_ERROR);
    }
    setEmail(value);
  }

  const validateData = () => {
    if (currentEmail !== email && EMAIL_VALIDATOR_REGEX.test(email)) {
      return true;
    } else if (currentEmail === email) {
      setError('Please enter a different email id');
      return false;
    } else if (!EMAIL_VALIDATOR_REGEX.test(email)) {
      setError(EMAIL_IS_NOT_VALID);
      return false;
    }
  }

  const updateEmail = async () => {
    setLoading(true);
    setUpdateSuccessfull(false);
    const { response, error } = await apiRequest(apiURL, apiConfig.method, apiConfig.body);
    if (response) {
      setUpdateSuccessfull(true);
      const { token } = response;
      if (token !== localStorage.getItem('token')) {
        setNewToken(token);
      }
    } else if (error) {
      setApiError(error);
    }
    setLoading(false);
  }

  const handleSubmit = () => {
    if (validateData()) {
      updateEmail();
    }
  }

  return(
    <Container className={styles.changeEmail}>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Col xl={12} lg={12} md={12} sm={12}>
            <form>
              <Header title="CHANGE EMAIL" />
              <div className={styles.prefLabel}>Email Address</div>
              <FormInput
                name="email"
                placeholder="Email"
                label="account-email"
                value={email}
                onChange={handleChange}
                large
              />

              {error && <div className={`${styles.error} mb-2`}>{error}</div>}
              <SubmitButton isLoading={loading} onSubmit={handleSubmit} />
              {apiError && <div className={`${styles.error} ${styles.apiError}`}>{apiError}</div>}
              {updateSuccessfull && <div className={`${styles.updatedSuccessfully}`}>Information Updated Successfully</div>}
            </form>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

export default ChangeEmail;
