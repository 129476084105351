import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Container, Row, Col, Button } from 'react-bootstrap';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

// Component
import DomainCard from 'components/DomainCard/DomainCard';
import Loader from 'general/Loader/Loader';

// SCSS
import styles from './PopularNames.module.scss';

interface Domain {
  id: string;
  name: string;
  price: number;
  category: string;
  keywords: string;
  images: Array<string>;
  extension: string;
  total: string;
  verificationCode: string;
  isDeleted: string;
  createdAt: string;
  updatedAt: string;
  isVerified: string;
  isFeatured?: boolean;
  url?: string;
}

const { apiConstants: { API_URL } } = Constants;

const FETCH_DOMAINS_URL = `${API_URL}/premium-domains/filter`;

const PopularNames: React.FunctionComponent = () => {

  const [domains, setDomains] = useState<Array<Domain> | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchPopularNames = async () => {
      setLoading(true);
      const { response, error } = await apiRequest(FETCH_DOMAINS_URL, 'POST', JSON.stringify({
        sortBy: 'isFeatured',
        sortOrder: 'DESC',
        limit: 4,
      }));
      if (response) {
        setDomains(response);
      } else if (error) {
        setError(`Popular names could not be fetched due to: ${error}`);
      }
      setLoading(false);
    }

    fetchPopularNames();
  }, []);

  return (
    <Container id={styles.popularNames} fluid>
      <Container>

        <Row className={styles.titleRegion}>
          <Col>
            <div className={styles.title}>Popular Names</div>
            <div className={styles.subtitle}>The most exclusive selection of business names for sale on Brandmo.</div>
          </Col>
        </Row>

        <Row className={styles.popularDomains}>
          {loading && <Loader />}
          {!loading && !error && domains && domains.map((domain: any) => (
            <DomainCard
              key={domain.id}
              id={domain.id}
              url={domain.url}
              preview={(domain.images && domain?.images[0]) || 'https://placehold.co/270x200'}
              title={domain.name}
              price={domain.price}
            />
          ))}
        </Row>

        <Row>
          {!loading && domains && (<Col className="text-center mt-4" xl={12} lg={12} md={12} sm={12}>
            <Link to="/domain-listing">
              <Button
                className="bm-btn md"
                variant="primary"
                disabled={domains?.length < 4}
              >
              See Most Popular Names For Sale
              </Button>
            </Link>
            
          </Col>)}
        </Row>

      </Container>
    </Container>
  );
};

export default React.memo(PopularNames);
