import React from 'react';
import { Form, FormControl } from 'react-bootstrap';

// SCSS
import styles from './CustomCheckbox.module.scss';

interface InputCheck {
  label: string;
  name: string;
  isChecked?: boolean;
  id: string;
  changeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void;
  other?: boolean;
  otherValue?: string;
}

const InputCheck = (props: InputCheck) => {
  // Comment for Devs - Unsure if you want the entire event returned or if you want the value string
  // with the added condition of the "other" text field having input. Leaving it up to you.
  const handleOther = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.changeCallback(event);
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.changeCallback(event);
  }

  return (
    <div className={`row mx-0 mb-1 mt-0 ${styles.bmCheckbox}`}>

      <div className="input-group d-flex align-items-center">

        <div className="input-group-prepend">
          <Form.Check
            className={`col p-0 ${styles.bmCheckInput}`}
            type="checkbox"
            label={props.label}
            name={props.name}
            checked={props.isChecked}
            id={props.id}
            onChange={handleCheck}
          />
        </div>

        {props.other && props.isChecked &&
          <div className={`col ${styles.bmCheckOther}`}>
            <FormControl
              aria-label="Other"
              onChange={handleOther}
              value={props.otherValue}
            />
          </div>
        }
      </div>
    </div>
  )
}

export default React.memo(InputCheck);
