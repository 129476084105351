import React from 'react';

import { Container, Row, Col, Button, FormControl } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import styles from './SearchBuyRequests.module.scss';

interface Props {
  showExport?: boolean;
  searchText?: string;
  setSearchText?: (text: string) => void;
  searchList?: () => void;
}

const OnlySearchBarProps = {
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
}

const SearchBuyRequest: React.FunctionComponent<Props> = (props: Props) => {

  const searchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.setSearchText) {
      props.setSearchText(event.target.value);
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.which === 13 || event.charCode === 13) && props.searchList) {
      props.searchList();
    }
  }

  const handleSearchClick = () => {
    if (props.searchList) {
      props.searchList();
    }
  }

  return (
    <div className={styles.searchBuyRequest}>
      <Container className="p-0" fluid>
        <Row>
          <Col {...OnlySearchBarProps}>
            <FormControl
              placeholder="Search by name"
              aria-label="Search by name"
              value={props.searchText}
              onChange={searchTextChange}
              onKeyPress={handleKeyPress}
            />
            <Button
              variant="outline-secondary"
              className={styles.buyRequestSubmit}
              onClick={handleSearchClick}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default React.memo(SearchBuyRequest);
