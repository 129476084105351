import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SVG from 'react-inlinesvg';
import constants from 'Constants';

import { apiRequest } from 'utils/ApiRequest';

// Page Components
import FormInput from 'general/FormInput/FormInput';

// SCSS
import styles from './ForgotPassword.module.scss';

// SVGs
import LoginLogo from 'assets/svgs/Branding/logo-login.svg';

const {
  apiConstants: { API_URL },
  regExValidators: { EMAIL_VALIDATOR_REGEX },
  errorConstants: { EMAIL_IS_NOT_VALID, EMAIL_NOT_ENTERED_ERROR },
} = constants

const FORGOT_PASSWORD_URL = `${API_URL}/forgot-password`;

const ForgotPassword: React.FunctionComponent = () => {

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event && event.target;
    if (value) {
      setEmail(value);
      if (!EMAIL_VALIDATOR_REGEX.test(value)) {
        setError(EMAIL_IS_NOT_VALID);
      } else {
        setError('');
      }
    } else {
      setEmail(value);
      setError(EMAIL_NOT_ENTERED_ERROR);
    }
  }

  const sendResetPasswordLink = async () => {
    setLoading(true);
    const { response, error } = await apiRequest(FORGOT_PASSWORD_URL, 'POST', JSON.stringify({
      email,
    }))
    if (response) {
      setIsEmailSent(true);
    } else if (error) {
      setApiError(error);
    }
    setLoading(false);
  }

  const handleSubmit = (): void => {
    setApiError('');
    if (email && EMAIL_VALIDATOR_REGEX.test(email)) {
      sendResetPasswordLink();
    } else {
      if (!email) {
        setError(EMAIL_NOT_ENTERED_ERROR);
      } else if (!EMAIL_VALIDATOR_REGEX.test(email)) {
        setError(EMAIL_IS_NOT_VALID);
      }
    }
  }

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Forgot Password",
    });
  }, [])

  return (
    <Container className={`p-0 ${styles.authBG}`} fluid>
      <Row className={styles.authWrap}>
        <Col>

          <div className={styles.forgotPassword}>
            <div className={styles.authLogo}>
              <SVG
                baseURL="/"
                cacheRequests={true}
                className="site-logo-login"
                description="Brandmo"
                loader={<span>Loading...</span>}
                src={LoginLogo}
                title="Brandmo"
              />
            </div>

            {!isEmailSent ? (<form className="forgot-password-form">
              <div className={styles.authTitle}>Forgot Password</div>
              <Container>
                <Row>
                  <Col className={styles.forgotLabel}>Please enter a valid email address to receive a reset password link.</Col>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <FormInput
                      // type="text"
                      name="email"
                      label="Email"
                      placeholder="Enter email"
                      value={email}
                      onChange={e => handleChange(e)}
                      // required
                    />
                    {error && <div className={styles.error}>{error}</div>}
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <Button
                      className={`bm-btn ${styles.sendEmail}`}
                      type="button"
                      variant="primary"
                      onClick={!loading ? handleSubmit : () => {}}
                    >
                      {!loading ? 'Send Email' : 'loading...'}
                    </Button>
                  </Col>
                  {apiError && <div className={styles.error}>{apiError}</div>}
                </Row>
              </Container>
            </form>): (
              <form className="forgot-password-form">
                <div className={styles.authTitle}>Forgot Password</div>
                <Container>
                  <Row>
                    <Col className={styles.forgotLabel}>
                    An email has been sent to you, please click reset your password link to change your password.
                    </Col>
                  </Row>
                </Container>
              </form>
            )}

            <div className={styles.formBottom}>
              <Link to="/login">Already have a Brandmo account? Log in</Link>
            </div>

          </div>

        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(ForgotPassword);
