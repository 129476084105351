import React, { useState } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';

import TotalCount from '../TotalCount/TotalCount';

import { RatingStats } from 'interfaces/Interfaces';

import './RatingFilters.scss';

interface Props {
  showTotalCountOnLeft?: boolean;
  showTotalCountOnRight?: boolean;
  ratingStats?: RatingStats | null;
  entriesCount?: number;
  setRatingFilter?: (selectedRating: number) => void;
  resetFilters?: () => void;
}

const RATINGS = [
  { id: 'onestar', value: 1 },
  { id: 'twostar', value: 2 },
  { id: 'threestar', value: 3 },
  { id: 'fourstar', value: 4 },
  { id: 'fivestar', value: 5 },
];

const OnlyRatingFilter = {
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
}

const RatingFilterWithTotalCountProps = {
  xl: 10,
  lg: 10,
  md: 12,
  sm: 12,
} 

const RatingFilters = ({
  showTotalCountOnLeft,
  showTotalCountOnRight,
  ratingStats,
  entriesCount,
  setRatingFilter = () => {},
  resetFilters = () => {},
}: Props) => {

  const [selectedRatingFilter, setSelectedRatingFilter] = useState<string>('');

  const handleRatingFilter = (ratingId: string, ratingNumber: number) => {
    setSelectedRatingFilter(ratingId);
    if (ratingId === 'All' && ratingNumber === 0) {
      resetFilters();
    } else {
      setRatingFilter(ratingNumber);
    }
  }

  return (
    <div className="rating-filters">
      <Row>
        {showTotalCountOnLeft && (
          <TotalCount total={entriesCount || 0} />
        )}
        <Col {...(showTotalCountOnLeft || showTotalCountOnRight ? {...RatingFilterWithTotalCountProps} : {...OnlyRatingFilter})}>
          <Nav fill variant="pills" defaultActiveKey="#">
            <Nav.Item>
              <Nav.Link
                className={`rank-pill ${selectedRatingFilter === 'All' ? 'selected' : ''}`}
                onClick={() => handleRatingFilter('All', 0)}
              >
                All
              </Nav.Link>
            </Nav.Item>
            {RATINGS.map(rating => (
              <Nav.Item key={rating.id}>
                <Nav.Link
                  className={`rank-pill ${selectedRatingFilter === rating.id ? 'selected' : ''}`}
                  onClick={() => handleRatingFilter(rating.id, rating.value)}
                >
                  {ratingStats ?
                    (rating.id === 'unrated' ?
                      `${rating.id} (${ratingStats[rating.id] || 0})` :
                      `${rating.value} Star (${ratingStats[rating.id] || 0})`
                    ) : (rating.id === 'unrated' ?
                      `${rating.id} (0)` :
                      `${rating.value} Star (0)`
                    )
                  }
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        {showTotalCountOnRight && (
          <TotalCount total={entriesCount || 0} />
        )}
      </Row>
      
    </div>
    
  )
}

export default RatingFilters;
