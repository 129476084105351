import React, { Fragment } from 'react';

import Input from 'general/FormInput/FormInput';
import TextArea from 'general/CustomTextArea/CustomTextArea';
import Select, { SelectItem } from 'general/CustomSelect/CustomSelect';

import DateInput from 'general/FormDateInput/FormDateInput';
import DatePicker from 'react-datepicker';

// SCSS
import styles from './Question.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface QuestionTextarea {
  type: 'textarea';
  question: string;
  name: string;
  placeholder: string;
  value: string;
  handleTextareaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

interface QuestionSelect {
  type: 'select';
  question: string;
  selectItems: Array<SelectItem>;
  value?: string;
  handleSelectChange: (selectedIndex: number) => void;
}

interface QuestionTextfield {
  type: 'textfield';
  question: string;
  name: string;
  placeholder: string;
  label: string;
  value: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface QuestionTextDateField {
  type: 'textDateField';
  question: string;
  name: string;
  placeholder: string;
  mask: string;
  label: string;
  value: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface QuestionDatePicker {
  type: 'datePicker';
  question: string;
  name: string;
  selected: string;
  minDate: any;
  maxDate?: any;
  customInput?: any;
  handleDateChange: (date: any) => void;
}

type Question =
  | QuestionSelect
  | QuestionTextarea
  | QuestionTextfield
  | QuestionTextDateField
  | QuestionDatePicker

const Question = (props: Question) => {
  return (
    <div className={styles.bmQuestion}>

      <div className="question">
        <div className={styles.questionAsked}>
          {props.question}
          {/* @TODO: Need to implement [optional] sub-text. */}
        </div>

        {props.type === 'textfield' &&
          <Input
            name={props.name}
            placeholder={props.placeholder}
            label={props.label}
            value={props.value}
            onChange={props.handleInputChange}
          />
        }

        {props.type === 'textarea' &&
          <Fragment>
            <TextArea
              name={props.name}
              placeholder={props.placeholder}
              value={props.value}
              onChange={props.handleTextareaChange}
            />
          </Fragment>
        }

        {props.type === 'select' &&
          <Fragment>
            <Select id="exampleSelect"
              selectItems={props.selectItems}
              onChange={props.handleSelectChange}
              value={props.value}
            />
          </Fragment>
        }

        {props.type === 'textDateField' &&
          <DateInput
            name={props.name}
            placeholder={props.placeholder}
            label={props.label}
            mask={props.mask}
            value={props.value}
            onChange={props.handleInputChange}
          />
        }

        {props.type === 'datePicker' &&
          <DatePicker
            wrapperClassName={styles.reactDatepickerWrapper}
            name={props.name}
            placeholderText="Select date"
            selected={props.selected}
            minDate={props.minDate}
            maxDate={props.maxDate}
            customInput={props.customInput}
            onChange={date => props.handleDateChange(date)}
            showDisabledMonthNavigation
          />
        }
      </div>

    </div>
  )
}

export default React.memo(Question);
