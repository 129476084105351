import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';

import styles from './CustomSelect.module.scss';

export interface SelectItem {
  label: string;
  id?: string;
}

interface Props {
  id: string;
  name?: string;
  value?: string;
  selectItems: Array<SelectItem>;
  onChange: (event: any) => void;
  className?: string;
}

const CustomSelect: React.FunctionComponent<Props> = (props: Props) => {
  const handleOnChange = (event: any) => {
    props.onChange(event);
  }
  return (
    <div className={`select ${styles.bmSelect}`}>
      <Form.Control as="select" value={props.value} id={props.id} name={props.name} onChange={handleOnChange} className={props.className}>
        {props.selectItems.map((item, index) => (
          <Fragment key={index}>
            <option
              key={`${index}${item.label}`}
            >
              {item.label}
            </option>
          </Fragment>
        ))}
      </Form.Control>
    </div>
  );
};

export default React.memo(CustomSelect);
