import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import styles from './NeedHelp.module.scss';

const NeedHelp: React.FunctionComponent = () => {
  return (
    <Container className={styles.needHelp}>

      <Row className={styles.row}>

        <Col xl={7} lg={7} md={12} sm={12}>
          <div className={styles.title}>Can&apos;t find the right business name?</div>
          <div className={styles.subtitle}>Launch a buy request or connect with one of our naming specialists now.</div>
        </Col>

        <Col className={styles.btnContainer} xl={4} lg={4} md={12} sm={12}>
          <Link to="/contact"><Button className="bm-btn" variant="primary">Get Branding Help</Button></Link>
        </Col>

      </Row>

    </Container>
  );
};

export default React.memo(NeedHelp);
