import React, { useState, useContext } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

// Form Components
import Radio from 'general/CustomRadio/CustomRadio';

// Context
import GlobalContext from 'contexts/Global.context';

// Page 'QUESTIONNAIRE' Component
import Question from '../Question/Question'

import Constants from '../../../../../Constants'

import {
  Page1Questionnaire,
} from '../../../constants/Types';

interface Props {
  currentPageNumber: number;
  answers: Page1Questionnaire | any;
  setPage: (page: number) => void;
  setAnswers: (page1: Page1Questionnaire) => void;
}

const { pageConstants: { INDUSTRY_DROPDOWN } } = Constants

const Page1Questions: React.FunctionComponent<Props> = ({
  currentPageNumber,
  setPage,
  answers,
  setAnswers,
}: Props) => {

  const globalContext = useContext(GlobalContext);

  const { userDetails } = globalContext;

  const { isVerified } = userDetails;

  const [errors, setErrors] = useState<Page1Questionnaire>({
    projectType: '',
    otherProjectType: '',
    businessDescription: '',
    businessIndustry: '',
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, id } = event.target;
    if (id !== 'Other') {
      setAnswers({
        ...answers,
        [name]: id,
        otherProjectType: '',
      })
    } else {
      setAnswers({
        ...answers,
        otherProjectType: ' ',
        projectType: '',
      })
    }
    setErrors({
      ...errors,
      projectType: '',
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'businessDescription') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: 'Please provide a brief description about the business'
        })
      } else {
        if (value.length > 5000) {
          setErrors({
            ...errors,
            [name]: 'Maximum 5000 characters are allowed'
          })
        } else {
          setErrors({
            ...errors,
            [name]: ''
          })
        }
      }
    }

    if (name === 'otherProjectType') {
      if (value.length > 500) {
        setErrors({
          ...errors,
          projectType: 'Maximum 500 characters are allowed',
        })
      } else {
        setErrors({
          ...errors,
          projectType: '',
        })
      }
    }
    setAnswers({
      ...answers,
      [name]: value,
    })
  }

  const handleSelectChange = (event: any) => {
    const { value } = event.target;
    setAnswers({
      ...answers,
      businessIndustry: value,
    })
    setErrors({
      ...errors,
      businessIndustry: '',
    })
  }

  const validateStep1 = () => {
    const { projectType, otherProjectType, businessDescription, businessIndustry } = answers;
    if ((projectType || (otherProjectType && otherProjectType.length <= 500)) && businessDescription && businessDescription.length <= 5000 && businessIndustry) {
      return true;
    }
    if (!projectType && !businessDescription && !businessIndustry) {
      setErrors({
        ...errors,
        projectType: 'Please select the type of project',
        businessDescription: 'Please provide a brief description about the business',
        businessIndustry: 'Please select the industry, you plan to operate in',
      })
      return false;
    }

    let formErrors: any = {}
    Object.keys(answers).map(field => {
      if (!answers[field]) {
        switch (field) {
        case 'projectType': {
          if (!answers.projectType && !answers.otherProjectType) {
            formErrors[field] = 'Please select/enter the project type'
          }
          break;
        }
          
        case 'businessDescription':
          formErrors[field] = 'Please provide a brief description about the business'
          break;
          
        case 'businessIndustry':
          formErrors[field] = 'Please select the industry, you plan to operate in'
          break;

        default:
          break;
        }
        setErrors({
          ...errors,
          ...formErrors,
        })
      }
      return field;
    })
    return false;
  }

  const handleNextClick = () => {
    if (isVerified && validateStep1()) {
      setPage(currentPageNumber + 1);
    }
  }

  return (
    <>
      {/* PAGE ONE */}
      <Container id="questions">
        <Row>
          <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
            Business Overview
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="questionAsked">Is this project for a startup or rebrand of a current business? *</div>
            {/* <Radio
              id="Startup"
              name="projectType"
              label="Startup"
              value={answers.projectType}
              changeCallback={handleRadioChange}
            />
            <Radio
              id="Rebrand"
              name="projectType"
              label="Rebrand"
              value={answers.projectType}
              changeCallback={handleRadioChange}
            /> */}
            {['Startup', 'Rebrand', 'Other'].map(i => (
              <Radio
                key={i}
                id={i}
                name={i !== 'Other' ? 'projectType': 'otherProjectType'}
                label={i !== 'Other' ? i : 'Other: '}
                isChecked={i !== 'Other' ? i === answers.projectType : !!answers.otherProjectType}
                value={answers.otherProjectType}
                changeCallback={handleRadioChange}
                changeOtherCallback={i === 'Other' ? handleInputChange : () => {}}
                other={i === 'Other'}
              />
            ))}
            {/* <Radio
              id="other"
              name="projectType"
              label="Other:"
              // value={answers.projectType}
              changeCallback={handleRadioChange}
              changeOtherCallback={handleInputChange}
              other 
            /> */}
            {errors.projectType && <div className="error">{errors.projectType}</div>}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="Please briefly describe the business and your products or services. *"
              name="businessDescription"
              placeholder="Type here..."
              type="textarea"
              value={answers.businessDescription}
              handleTextareaChange={handleInputChange}
            />
            {errors.businessDescription && <div className="error">{errors.businessDescription}</div>}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <Question
              question="Which category best describes your business? *"
              selectItems={INDUSTRY_DROPDOWN}
              type="select"
              value={answers.businessIndustry}
              handleSelectChange={handleSelectChange}
            />
            {errors.businessIndustry && <div className="error">{errors.businessIndustry}</div>}
          </Col>
        </Row>

        <Row>
          <Col className="step-controller">
            <Button
              className="bm-btn px-5 py-2 ml-auto go-forward"
              variant="primary"
              onClick={handleNextClick}
              disabled={!isVerified}
            >
                Next 
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default React.memo(Page1Questions);
