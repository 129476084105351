import React, { useState, useContext } from 'react';

import moment from 'moment';
import { Container, Row, Col, Button, Spinner, Accordion, Modal } from 'react-bootstrap';

import numeral from 'numeral';

import ReactGA from 'react-ga4';

import cx from 'classnames';

import _find from 'lodash/find';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

// Interfaces
import { Offer } from 'interfaces/Interfaces';

import SendMessage from './SendMessage/SendMessage';

// Context
import GloabalContext from 'contexts/Global.context';

import Loader from 'general/Loader/Loader';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComment, faChevronDown, faHeart } from '@fortawesome/free-solid-svg-icons';

// Styles
import './CustomRow.scss';

interface Message {
  body: string;
  createdAt: string;
  sender: string;
  photo?: string;
}

interface Props {
  showBuyNowOption?: boolean;
  showSendMessageOption?: boolean;
  id: string;
  buyRequestId: string;
  ownerId: string;
  title: string;
  userName: string;
  date: string;
  price?: number;
  isFavorite: boolean;
  ratings: number;
  comments: number;
  messages: Array<Message> | null;
  showFavIcon?: boolean;
  offers: Array<Offer>;
  areActionsAllowed?: boolean;
  updateRatingLoader?: boolean;
  requestId: string;
  requestName: string;
  requestTitle?: string;
  email: string;
  updateRating?: (buyRequestId: string, selectedRating: number) => void;
  sendMessage?: (entryId: string, entryOwnerId: string, message: string) => void;
  updateOffersOnEntry?: (entryId: string, offer: Offer) => void;
  loggedInUserId?: string;
  loggedInEmailId?: string;
  buyRequestCreatorId?: string;
  buyRequestCreatorEmail?: string;
  onOfferAccept?: (response: any) => void;
  message?: string;
  profilePicURL?: string;
  onEntryDeleted?: (entryId: string) => void;
}

const RATINGS = [1,2,3,4,5];

const wrapperClass = cx({
  'contest-entry': true,
});

const { apiConstants: { API_URL }, regExValidators: { NUMBER_VALIDATOR } } = Constants;
const GIVE_A_RATING_TO_ENTRY_URL = `${API_URL}/entries/ratings`;
const ADD_ENTRY_TO_FAV_URL = `${API_URL}/entries/favorite`;

const SUBMIT_COUNTER_OFFER_URL = `${API_URL}/entries/offer`;

const ACCEPT_OFFER_URL = `${API_URL}/entries/offer/accept`;

const CustomRow: React.FunctionComponent<Props> = (props: Props) => {

  const gloabalContext = useContext(GloabalContext);

  const { userDetails } = gloabalContext;

  const { isVerified } = userDetails;

  const [ratingLoader, setRatingLoader] = useState<boolean>(false);

  const [isAdded, setIsAdded] = useState<boolean | number | undefined>(props.isFavorite);
  const [favLoading, setFavLoading] = useState<boolean>(false);

  const [counterOfferAmount, setCounterOfferAmount] = useState<number | string>('');

  const [counterLoading, setCounterLoading] = useState<boolean>(false);

  const [acceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [acceptError, setAcceptError] = useState<string>('')

  const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState<boolean>(false);

  const [ isEntryDeleted, setIsEntryDeleted ] = useState<boolean>(false);

  const [ deleteEntryLoader, setDeleteEntryLoader ] = useState<boolean>(false);

  const [ deleteEntryError, setDeleteEntryError ] = useState<string>('');

  const DELETE_ENTRY_URL = `${API_URL}/entries/${props.id}`;

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!NUMBER_VALIDATOR.test(value)) {
      return;
    }
    setCounterOfferAmount(value);
  }

  const giveARatingToAnEntry = async (selectedRating: number) => {
    if (!isVerified) {
      return;
    }
    setRatingLoader(true);
    const apiBody = {
      ratings: 0,
      buyRequestEntry: props.id,
    }
    const { response } = await apiRequest(GIVE_A_RATING_TO_ENTRY_URL, 'POST', JSON.stringify({
      ...apiBody,
      ratings: selectedRating,
    }));
    if (response) {
      if (props.updateRating) {
        props.updateRating(props.id, selectedRating);
      }
    }
    setRatingLoader(false);
  }

  const toggleFavourite = async () => {
    if (!isVerified) {
      return;
    }
    setFavLoading(true);
    const { response } = await apiRequest(ADD_ENTRY_TO_FAV_URL, 'POST', JSON.stringify({
      id: props.id,
    }))
    if (response && response.success) {
      if (isAdded) {
        setIsAdded(false);
      } else {
        setIsAdded(true);
      }
    }
    setFavLoading(false);
  }

  const submitCounterOffer = async () => {
    setCounterLoading(true);
    const { response } = await apiRequest(SUBMIT_COUNTER_OFFER_URL, 'POST', JSON.stringify({
      amount: counterOfferAmount,
      buyRequestEntry: props.id,
    }))
    if (response) {
      if (props.updateOffersOnEntry) {
        props.updateOffersOnEntry(props.id, response);
      }
    }
    setCounterLoading(false);
    setCounterOfferAmount('');
  }

  const callAnalytics = () => {
    ReactGA.event('Offer Accepted and Escrow payment is initiated', {
      buyRequestName: props.requestTitle || props.requestName,
      buyRequestCreatorEmail: props.buyRequestCreatorEmail,
      sellerEmail: props.email,
      amountAgreedUpon: props.offers[props.offers.length - 1].amount,
    })
  }

  const offerAccepted = async (offer: Offer | null) => {
    if (offer) {
      setAcceptLoading(true);
      setAcceptError('');
      const { response, error } = await apiRequest(ACCEPT_OFFER_URL, 'POST', JSON.stringify({
        offerId: offer.id,
        partnerId: props.loggedInUserId === props.buyRequestCreatorId ? props.ownerId : props.buyRequestCreatorId,
        role: props.loggedInUserId === props.ownerId ? 'seller' : 'buyer',
        domain: props.title,
      }));
  
      if (response) {
        callAnalytics();
        if (props.onOfferAccept) {
          props.onOfferAccept(response);
        }
      } else if (error) {
        setAcceptError(error);
      }
      setAcceptLoading(false);
    }
  }

  const submitOfferButtonLabel = () => {
    let label = 'Submit Counter';
    if (props.loggedInUserId === props.ownerId) {
      label = 'Update Offer';
    }
    if (props.loggedInUserId === props.buyRequestCreatorId) {
      label = 'Submit Counter';
    }
    if (!props.offers || !props.offers.length) {
      label = 'Make An Offer'
    }
    if (counterLoading) {
      label = 'loading...'
    }
    return label;
  }

  const acceptOfferButtonLabel = () => {
    let label = 'Accept & Buy Now';

    if (props.loggedInUserId === props.ownerId) {
      label = 'Accept Offer';
    }

    if (acceptLoading) {
      label = 'loading...';
    }
    return label;
  }

  const lastOffer = (offers: Array<Offer>) => {
    return offers[offers.length - 1];
  }

  const isOfferAccepted = (offers: Array<Offer>) => {
    return _find(offers, { isAccepted: true });
  }

  const handleEntryModalClose = () => {
    if (deleteEntryLoader) {
      return;
    }
    setIsEntryDeleted(false);
    setShowDeleteConfirmation(false);
    setDeleteEntryError('');
  }

  const deleteEntry = async () => {
    setDeleteEntryLoader(true);
    setIsEntryDeleted(false);
    setDeleteEntryError('');
    const { response, error } = await apiRequest(DELETE_ENTRY_URL, 'DELETE');
    if (response) {
      if (props.onEntryDeleted) {
        setIsEntryDeleted(true);
        props.onEntryDeleted(props.id);
      }
    }
    else if (error) {
      setDeleteEntryError(error);
    }
    setDeleteEntryLoader(false);
  }

  const renderEntryOffers = (offers: Array<Offer>) => {
    return (
      <div>
        {showDeleteConfirmation &&
          <div className="delete-card-modal">
            <Modal
              size="sm"
              show={showDeleteConfirmation}
              onHide={handleEntryModalClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>Delete Submission</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {deleteEntryLoader && <div className="loader-wrap"><Loader /></div>}
                {!isEntryDeleted && !deleteEntryLoader && !deleteEntryError && (
                  <div className="delete-card">
                    Are you sure you want to proceed? This action cannot be undone.
                  </div>
                )} 
                {isEntryDeleted && !deleteEntryLoader &&
                (
                  <div className="alert alert-success">
                      Your submission has been deleted successfully.
                  </div>
                )}
                {deleteEntryError && !deleteEntryLoader &&
                (
                  <div className="alert alert-danger">
                    {deleteEntryError}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {!isEntryDeleted && <Button disabled={deleteEntryLoader} variant="primary" onClick={() => deleteEntry()}>
                  { deleteEntryLoader ? 'Deleting...' : 'Delete' }
                </Button>}
                <Button variant="secondary" disabled={deleteEntryLoader} onClick={handleEntryModalClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        }
        {offers && offers.map((offer, index) => (
          <Row key={offer.id} className="mt-3">
            <Col xl={12} lg={12} md={12} sm={12}>
              <div style={{
                fontSize: '16px',
              }}>
                <>
                  {index === 0 ? (
                    <>
                      {offer.offeredBy} has offered the domain
                      <span className="font-weight-bold">
                        { /* eslint-disable-next-line react/prop-types */ }
                        {` ${props.title}`}
                      </span> for 
                      <span className="font-weight-bold">
                        {` ${numeral(offer.amount).format('$0,0')}`}.
                      </span>
                    </>
                  ) : (
                    <>
                      {offer.offeredBy} counter offered with
                      <span className="font-weight-bold">
                        {` ${numeral(offer.amount).format('$0,0')}`}.
                      </span>
                    </>
                  )}
                  
                </>
              </div>
            </Col>
            {!offer.isAccepted
              && index === offers.length - 1
              // eslint-disable-next-line react/prop-types
              && props.areActionsAllowed
              // eslint-disable-next-line react/prop-types
              && offer.offeredById !== props.loggedInUserId && (
              <Col xl={12} lg={12} md={12} sm={12}>
                <Button
                  variant="primary"
                  className="bm-btn assigned-winner"
                  style={{ width: '100%', }}
                  disabled={acceptLoading}
                  onClick={() => offerAccepted(offer)}
                >
                  {acceptOfferButtonLabel()}
                </Button>
                {acceptError && <div className="error">{acceptError}</div>}
              </Col>
            )}
            {!offer.isAccepted
              && index === offers.length - 1 
              // eslint-disable-next-line react/prop-types
              && props.loggedInUserId === props.ownerId 
              // eslint-disable-next-line react/prop-types
              && offer.offeredById !== props.loggedInUserId && (
              <Col xl={12} lg={12} md={12} sm={12}>
                <Button
                  variant="primary"
                  className="bm-btn assigned-winner"
                  style={{ width: '100%', }}
                  disabled={acceptLoading}
                  onClick={() => offerAccepted(offer)}
                >
                  {acceptOfferButtonLabel()}
                </Button>
                {acceptError && <div className="error">{acceptError}</div>}
              </Col>
            )}
            {offer.isAccepted && <Col className="offer-accepted">Offer has been accepted.</Col>}
          </Row>
        ))}
      </div>
    )
  }

  return (
    <Accordion className={wrapperClass}>
      <Row>
        <Col xl={6} lg={6} md={12} sm={12}>
          <Row>
            <Col xl={6} lg={6} md={6} sm={6} className="description entry-data">
              <div className="title">{props.title}</div>
              <div className="days-ago"><b>{props.userName}</b> {moment(props.date).fromNow()}</div>
            </Col>

            <Col xl={4} lg={4} md={6} sm={6} className="py-xl-0 py-lg-0 py-md-3 py-sm-3 py-3 rating entry-data">
              {ratingLoader && (
                <div id="loader">
                  <Spinner animation="border" variant="primary" role="status" />
                </div>
              )}
              {!ratingLoader && RATINGS.map((rat, idx) => (
                <FontAwesomeIcon
                  key={`${rat}${idx}`}
                  className={`${rat <= props?.ratings ? 'rated' : 'unrated'} ${props.loggedInUserId !== props.ownerId && props.areActionsAllowed ? 'rating-allowed' : ''}`} 
                  icon={faStar}
                  onClick={props.loggedInUserId !== props.ownerId && props.areActionsAllowed ? () => giveARatingToAnEntry(rat) : () => {}}
                />
              ))}
            </Col>

          </Row>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12}>
          <Row className="mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3">

            <Col xl={2} lg={2} md={3} sm={3} className="col-3 order-0 amount entry-data">
              {props && props.offers && props.offers.length ? <div
                className="amount-integer"
              >
                {numeral(lastOffer(props.offers).amount).format('$0,0')}
              </div> : ''}
            </Col>

            {props && props.areActionsAllowed && props.offers && props.offers.length && !props.offers.filter(o => o.isAccepted).length && (
              <Col xl={3} lg={3} md={4} sm={4} className="buy-now entry-data">
                {/* <a href="/" className="buy-now-label">Buy Now</a> */}
                <button
                  className="buy-now-label"
                  disabled={acceptLoading
                    || (props && props.offers && props.offers.length ? lastOffer(props.offers).offeredById === props.buyRequestCreatorId : false)
                  }
                  onClick={() => offerAccepted(props && props.offers && props.offers.length ? lastOffer(props.offers) : null)}
                >
                  {acceptLoading ? 'loading...' : 'Buy Now'}
                </button>
              </Col>
            )}

            {props && props.areActionsAllowed && props.offers && props.offers.length && props.offers.filter(o => o.isAccepted).length ? (
              <Col xl={3} lg={3} md={4} sm={4} className="buy-now entry-data purchased">
                <div className="purchased">Purchased</div>
              </Col>
            ): null}

            {props && !props.areActionsAllowed && (
              <Col xl={2} lg={2} md={4} sm={4} />
            )}

            <Col xl={3} lg={3} md={6} sm={6}
              className="col-6 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2 favorite entry-data"
            >
              <span className={!props.areActionsAllowed ? 'disabled-fav' : ''} onClick={toggleFavourite}>
                {favLoading && (
                  <div id="loader">
                    <Spinner animation="border" variant="primary" role="status" />
                  </div>
                )}
                {!favLoading && <FontAwesomeIcon className={isAdded ? 'heart-fav' : ''} icon={faHeart} />}
              </span>
            </Col>

            <Col xl={2} lg={2} md={3} sm={3} className="col-3 order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1 p-0 comments entry-data">
              {props.comments} &nbsp; <FontAwesomeIcon className="entry-comments" icon={faComment} />
            </Col>

            <Col xl={2} lg={2} md={12} sm={12} className="col-12 order-3 p-0 my-xl-0 my-lg-0 my-md-3 my-sm-3 my-3 open-entry entry-data">
              <Accordion.Toggle as="span" variant="link" eventKey="0">
                <FontAwesomeIcon className="entry-chevron" icon={faChevronDown} />
              </Accordion.Toggle>
            </Col>
          </Row>
        </Col>

        <Col xl={12} lg={12} md={12} sm={12}>
          <Accordion.Collapse eventKey="0">
            <Container fluid>
              {props.ownerId === props.loggedInUserId && !isOfferAccepted(props.offers) && <Row className='pt-3'>
                <Col md={12}>
                  <span className="delete-btn" onClick={() => setShowDeleteConfirmation(true)}>Delete</span>
                </Col>
                <Col md={12}>
                  <div className="delete-bottom-row"></div>
                </Col>
              </Row>}
              <Row className='pt-3'>
                <Col className="user-interact p-0 .offset-md-2" xl={5} lg={5} md={12} sm={12}>
                  {renderEntryOffers(props && props.offers ? props.offers : [])}
                </Col>

                <Col>
                  <div className="col-md-2 offset-md-2"></div>
                </Col>

                {(props.areActionsAllowed || props.loggedInUserId === props.ownerId)
                && !(props && props.offers && props.offers.length && props.offers.filter(offer => offer.isAccepted).length)
                && props.offers && props.offers.length
                && (
                  <Col xl={5} lg={5} md={12} sm={12} className="counter-offer-block">
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <input
                          type="text"
                          style={{
                            border: '1px solid #0078ff',
                            width: '100%',
                            height: '40px',
                            marginBottom: '10px',
                            padding: '10px',
                          }}
                          value={counterOfferAmount}
                          onChange={handleAmountChange}
                        />
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <Button
                          variant="primary"
                          className="bm-btn assigned-winner"
                          style={{ width: '100%', backgroundColor: '#101B48', padding: '10px', }}
                          disabled={!counterOfferAmount}
                          onClick={submitCounterOffer}
                        >
                          {submitOfferButtonLabel()}
                        </Button>
                      </Col>
                    </Row>
                  </Col>)}
              </Row>
              {(props.message || props.messages?.length || (props.loggedInUserId !== props.ownerId)) ? <Col xl={12} lg={12} md={12} sm={12} className="border-top my-3"></Col> : null}
              <Row>
                <SendMessage
                  entryId={props.id}
                  entryOwnerId={props.ownerId}
                  messages={props.messages}
                  sendMessage={props.sendMessage}
                  loggedInUserId={props.loggedInUserId}
                  message={props.message}
                  profilePicURL={props.profilePicURL}
                  userName={props.userName}
                  createdAt={props.date}
                  isVerified={isVerified}
                />
              </Row>
            </Container>
          </Accordion.Collapse>
        </Col>

      </Row>
    </Accordion>
  )
}

export default React.memo(CustomRow);
