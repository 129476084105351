import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import GlobalContext from '../../contexts/Global.context';
import constants from 'Constants';
import History from 'utils/History';

import { apiRequest } from 'utils/ApiRequest';

import SVG from 'react-inlinesvg';
import LoginLogo from 'assets/svgs/Branding/logo-login.svg';

import styles from './Verify.module.scss';


const { apiConstants: { API_URL } } = constants;

const Verify: React.FunctionComponent = () => {
  const { token } = useParams();
  const { setAuth } = useContext(GlobalContext);
  const VERIFY_USER_URL = `${API_URL}/verify/${token}`;

  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const verifyUser = useCallback(async () => {
    const { response, error } = await apiRequest(VERIFY_USER_URL, 'GET');
    if (response) {
      const { token} = response;
      setAuth(token);
      History.push('/dashboard');
    } else if (error) {
      setApiError(error);
    }
    setLoading(false);
  }, [ VERIFY_USER_URL, setAuth ]);

  useEffect(() => {
    setLoading(true);
    verifyUser();
  }, [ verifyUser ]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Verify User",
    });
  }, [])

  return (
    <Container className={`p-0 ${styles.authBG}`} fluid>
      <Row className={styles.authWrap}>
        <Col>
          <div className={styles.verifyUser}>
            <div className={styles.authLogo}>
              <SVG
                baseURL="/"
                cacheRequests={true}
                className="site-logo-login"
                description="Brandmo"
                loader={<span>Loading...</span>}
                src={LoginLogo}
                title="Brandmo"
              />
            </div>
            {loading && !apiError && (
              <form className="forgot-password-form">
                <div className={styles.authTitle}>Account Verification</div>
                <Container>
                  <Row>
                    <Col className={styles.forgotLabel}>
                      Verifying...
                    </Col>
                  </Row>
                </Container>
              </form>)}
            {apiError && !loading && (
              <form className="forgot-password-form">
                <div className={styles.authTitle}>Account Verification</div>
                <Container>
                  <Row>
                    <Col className={styles.forgotLabel}>
                      <div className={styles.error}>
                        {`Error occured while verifying: ${apiError}, please try again.`}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </form>)}
          </div>

        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(Verify)
