import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment'
import numeral from 'numeral';
import { Col, Button } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faClock } from '@fortawesome/free-solid-svg-icons'

// SCSS
import styles from './PopularContest.module.scss'

interface Props {
  key: string;
  id: string;
  title?: string;
  description: string;
  submissions: string;
  targetDate: string;
  budget: number;
}

const PopularContest: React.FunctionComponent<Props> = ({
  id,
  title,
  description,
  submissions,
  targetDate,
  budget,
}: Props) => {
  return (
    <>
      <Col className={styles.buyRequestBlock} xl={4} lg={4} md={12} sm={12}>
        <div className={styles.buyRequestBlockWrap}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
          <div className={styles.info}>
            <span className={styles.submitted}>
              <FontAwesomeIcon icon={faUser} />
              <span className={styles.integer}>
                {` ${submissions}`}
              </span>
              {' submissions'}
            </span>
            <span className={styles.timeRemaining}><FontAwesomeIcon icon={faClock} />
              <span className={styles.timeLeft}>{` ${moment(targetDate).diff(moment(), 'days')} days `}</span> left</span>
            <div className={styles.budget}>Budget <span className="integer">{numeral(budget).format('$0,0')}</span></div>
          </div>
          <Link to={`/requests/${id}`}>
            <Button
              className={`bm-btn sm ${styles.enterBuyRequest}`}
              variant="primary"
            >
              View Buy Request
            </Button>
          </Link>
          
        </div>
      </Col>
    </>
  )
}

export default PopularContest
