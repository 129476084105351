import React from 'react';
import { Row, Col } from 'react-bootstrap';

// SCSS
import styles from './MessageOriginal.module.scss';

interface MessageOriginal {
  avatar: string;
  message: string;
  timeAgo: string;
  userName: string;
}

const MessageOriginal = (props: MessageOriginal) => {
  return (
    <Row className={styles.originalMessage}>
      <Col className={`${styles.messageText} col-xl-11 col-lg-11 col-md-10 col-sm-12 col-12`}>
        <div className={styles.messageFull}>
          {props.message}
        </div>
        <div className={styles.messageAgo}>
          {props.timeAgo}
        </div>
        <div className={styles.messageFrom}>
          {props.userName}
        </div>
      </Col>
      <Col className="pr-0 col-xl-1 col-lg-1 col-2">
        <div className={`d-xl-block d-lg-block d-md-block d-sm-none d-none ${styles.messageAvatar}`}>
          {/* <img src="https://placehold.co/55x55" alt="Lorem" /> */}
          <img src={props.avatar} alt={props.userName} />
        </div>
      </Col>
    </Row>
  )
}

export default React.memo(MessageOriginal);
