export const scorePassword = (password: string) => {
  //Regular Expressions.
  let regex: any = [];
  let score = 0;

  regex.push('[A-Z]'); //Uppercase Alphabet.
  regex.push('[a-z]'); //Lowercase Alphabet.
  regex.push('[0-9]'); //Digit.
  regex.push('[$@$!%*#?&]'); //Special Character.


  for (var i = 0; i < regex.length; i++) {
    if (new RegExp(regex[i]).test(password)) {
      score++;
    }
  }

  if (score > 2 && password.length > 8) {
    score++;
  }

  if (score > 4 && password.length > 12) {
    score++;
  }

  return score;
}

export const checkPasswordStrength = (password: string) => {
  const score = scorePassword(password);
  let strength = '';
  switch (score) {
  case 0:
    strength = 'none';
    break;
  case 1:
  case 2:    
    strength = 'weak';
    break;
  case 3:
    strength = 'ok';
    break;
  case 4:
    strength = 'good';
    break;
  case 5:
  case 6:
    strength = 'strong';
    break;
  }
  return strength;
}
