import React, { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

import numeral from 'numeral';

import { Container, Row, Col, Button } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// Page Components
import PopularContests from './components/PopularContests/PopularContests';
import WhyUseBrandmo from './components/WhyUseBrandmo/WhyUseBrandmo';
import PopularNames from './components/PopularNames/PopularNames';
// import Testimonials from './components/Testimonials/Testimonials';
import Testimonials from './components/Testimonials/Testimonials'
import RecentlySold from '../../components/RecentlySold/RecentlySold';
import Subscribe from '~/../components/Subscribe/Subscribe';

// Utils
import scrollToHandler from 'utils/ScrollHandler';
import { apiRequest } from 'utils/ApiRequest';

// Constant
import Constants from 'Constants';

// Context
import GlobalContext from 'contexts/Global.context';

// SCSS
import styles from './Home.module.scss';

// SVGs
import Tablet from 'assets/imgs/Home/tabletbg.png';
import TabletCard from 'assets/svgs/Home/Focus/tabletcard.svg';
import RiverLogo from 'assets/svgs/Home/Focus/river-financial.svg';
import KFCLogo from 'assets/svgs/Home/Focus/kfc.svg';
import TargetLogo from 'assets/svgs/Home/Focus/target.svg';

const { apiConstants: { API_URL }, pageConstants: { INDUSTRY_DROPDOWN } } = Constants;

const FETCH_SITE_STATS = `${API_URL}/stats`;

const Home: React.FunctionComponent = () => {

  const { hash } = useLocation();

  const globalContext = useContext(GlobalContext);

  const { userDetails, isLoggedIn } = globalContext;

  const { isVerified } = userDetails;

  const testimonialsRef = useRef(null);
  const recentlySoldRef = useRef(null);

  const [siteStats, setSiteStats] = useState<any | null>(null);

  useEffect(() => {
    const fetchSiteStats = async () => {
      const { response } = await apiRequest(FETCH_SITE_STATS, 'GET');
      if (response) {
        setSiteStats(response);
      }
    }

    fetchSiteStats();
  }, []);

  useEffect(() => {
    let scrollToSection: React.MutableRefObject<null> | null = null;

    if (hash.includes('testimonials')) {
      scrollToSection = testimonialsRef;
    }

    if (hash.includes('recentlySold')) {
      scrollToSection = recentlySoldRef;
    }

    scrollToHandler(scrollToSection)
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Welcome | Brandmo",
    });
  }, [hash])

  return (
    <Fragment>
      <Container>
        <Row>
          <Helmet>
            <title>
              Let&apos;s Name Your Business | Brandmo
            </title>
          </Helmet>
          <Col className={styles.homeHeader} xl={6} lg={6} md={12} sm={12}>
            <div className={styles.title}>Every business starts with a <span>brand</span>.</div>
            <div className={styles.description}>
            Buy and sell premium brandable domain names using our marketplace, or launch a buy request and our brand naming experts will send you exclusive handpicked business names available for acquisition.
            </div>
            <div className={styles.buttonRow}>
              <Link to="/questionnaire">
                <Button disabled={isLoggedIn && !isVerified} className="bm-btn md" variant="primary">Launch Buy Request</Button>
              </Link>
              <Link to="/domain-listing">
                <Button className="bm-btn md" variant="outline-primary">Explore All Names For Sale</Button>
              </Link>

            </div>
            {siteStats && (<Row className={styles.brandmoStats}>
              <Col xl={4} lg={4} md={4} sm={4}>
                <div className={styles.integer}>{numeral(siteStats.submissions).format('0,0')}</div>
                <div className={styles.label}>Submissions</div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4}>
                <div className={styles.integer}>{numeral(siteStats.users).format('0,0')}</div>
                <div className={styles.label}>Experts</div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4}>
                <div className={styles.integer}>{INDUSTRY_DROPDOWN.length}</div>
                <div className={styles.label}>Industries</div>
              </Col>
            </Row>)}
            <Row className={styles.brandmoTopClients}>
              <Col className="p-0" xl={12} lg={12} md={12} sm={12}>
                Top Clients
              </Col>
            </Row>
            <Row>
              <Col className={styles.topClientSVG} xl={4} lg={4} md={4} sm={4}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.topClientSVGRF}
                  description="River Financial"
                  loader={<span>Loading...</span>}
                  src={RiverLogo}
                  title="River Financial"
                />
              </Col>
              <Col className={`offset-xl-1 offset-lg-1 ${styles.topClientSVG}`} xl={4} lg={4} md={4} sm={4}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.topClientSVGT}
                  description="Target"
                  loader={<span>Loading...</span>}
                  src={TargetLogo}
                  title="Target"
                />
              </Col>
              <Col className={styles.topClientSVG} xl={3} lg={3} md={4} sm={4}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.topClientSVGKFC}
                  description="Kentucky Fried Chicken"
                  loader={<span>Loading...</span>}
                  src={KFCLogo}
                  title="Kentucky Fried Chicken"
                />
              </Col>
            </Row>
          </Col>
          <Col className={`d-xl-block d-lg-block d-md-none d-sm-none d-none ${styles.homeTablet}`} xl={6} lg={6}>
            <div className={styles.tabletWrap}>
              <div className={styles.tabletCard}>
                <div className={styles.tabletCardBG}>
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className={styles.tabletCardSVG}
                    description=""
                    loader={<span>Loading...</span>}
                    src={TabletCard}
                    title=""
                  />
                </div>
              </div>
              <div className={styles.tabletBG}>
                <img src={Tablet} className={styles.tabletSVG} alt="background" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <PopularContests />

      <WhyUseBrandmo />

      <PopularNames />

      <div ref={testimonialsRef}>
        <Testimonials />
      </div>

      <div ref={recentlySoldRef}> 
        <RecentlySold />
      </div>

      <Subscribe />
    </Fragment>
  );
};

export default React.memo(Home);
