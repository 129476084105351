import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

// Util
import { apiRequest } from 'utils/ApiRequest';
import { FavoriteDomainsContext } from 'contexts/FavoriteDomains.context';

// Const
import GlobalContext from 'contexts/Global.context';
import Constants from 'Constants';

// Page Components
import CustomListing from 'general/CustomListing/CustomListing';

import { RatingStats } from 'interfaces/Interfaces';

// SCSS
import styles from './MyDomains.module.scss';

// API
const { apiConstants: { API_URL } } = Constants;
const SEND_MESSAGE_URL = `${API_URL}/messages`;

const MyDomains: React.FunctionComponent = () => {

  const history = useHistory();

  const globalContext = useContext(GlobalContext);

  const favDomainsContext = useContext(FavoriteDomainsContext);

  const {
    favoriteDomains,
    setFavoriteDomains,
    setRatingToFavoriteDomain,
    setOffersOnFavoriteDomain,
    setMessageOnFavoriteDomain,
  } = favDomainsContext;


  const { userDetails: { id, email } } = globalContext;
  const FETCH_MY_DOMAINS_URL = `${API_URL}/entries?userId=${id}`;
  const [fetchListURL, setFetchListURL] = useState<string>(FETCH_MY_DOMAINS_URL);
  const newUrl = new URL(fetchListURL);
  const [fetchListLoader, setFetchListLoader] = useState<boolean>(false);

  const [ratingStats, setRatingStats] = useState<RatingStats | null>(null);

  const fetchList = useCallback(async (url: string) => {
    setFetchListLoader(true);
    const { response, error } = await apiRequest(url, 'GET');
    if (response) {
      const { entries, stats } = response;
      setFavoriteDomains(entries, '');
      setRatingStats(stats);
    } else if (error) {
      setFavoriteDomains(null, error);
    }
    setFetchListLoader(false);
  }, [setFetchListLoader, setFavoriteDomains])

  const setRatingFilter = (selectedRating: number) => {
    if (newUrl.searchParams.has('ratings')) {
      newUrl.searchParams.set('ratings', selectedRating.toString());
      setFetchListURL(newUrl.href);
    } else {
      newUrl.searchParams.append('ratings', selectedRating.toString());
      setFetchListURL(newUrl.href);
    }
  }

  const searchList = (searchText: string) => {
    if (newUrl.searchParams.has('domain')) {
      newUrl.searchParams.set('domain', searchText);
      setFetchListURL(newUrl.href);
    } else {
      newUrl.searchParams.append('domain', searchText);
      setFetchListURL(newUrl.href);
    }
  }

  const giveARatingToDomain = (buyRequestEntry: string, updatedRating: number) => {
    setRatingToFavoriteDomain(buyRequestEntry, updatedRating, '');
    fetchList(fetchListURL);
  }

  const updateOffersOnDomain = (buyRequestEntry: string, offer: any) => {
    setOffersOnFavoriteDomain(buyRequestEntry, offer);
  }

  const onOfferAccept = (response: any) => {
    if (response.success) {
      history.push(`/purchase-status/${response.transactionId}`);
    }
  }

  const sendMessage = async (buyRequestEntry: string, entryOwnerId: string, message: string) => {
    const sendMessageBody = {
      receiver: entryOwnerId,
      submissionId: buyRequestEntry,
      body: message,
    }
    if (message) {
      const { response } = await apiRequest(SEND_MESSAGE_URL, 'POST', JSON.stringify({
        ...sendMessageBody,
      }));
      if (response) {
        const messageBody = {
          body: response.body,
          sender: response.sender.userName,
        }
        setMessageOnFavoriteDomain(buyRequestEntry, {...messageBody}, '');
      }
    }
  }

  const resetFilters = () => {
    setFetchListURL(FETCH_MY_DOMAINS_URL);
  }

  const refetchAllData = () => {
    fetchList(fetchListURL);
  };

  useEffect(() => {
    fetchList(fetchListURL);
  }, [fetchListURL, setFavoriteDomains, fetchList])

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "My Domains",
    });
  }, [])

  return (
    <Container className={styles.dashWrapper} fluid>
      <Helmet>
        <title>
          My Domains | Brandmo
        </title>
      </Helmet>
      <Container className={styles.dashInner}>

        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.sectionTitle}>My Domains</div>
            <CustomListing
              // Ratings filter Props
              showTotalCountOnLeft
              showRatingFilter
              setRatingFilter={setRatingFilter}
              // Search filter Props
              resetFilters={resetFilters}
              showSearchBar
              // List related props
              areActionsAllowed
              loggedInUserId={id}
              loggedInEmailId={email}
              buyRequestCreatorId={id}
              updateRating={giveARatingToDomain}
              updateOffersOnEntry={updateOffersOnDomain}

              onOfferAccept={onOfferAccept}
              searchList={searchList}
              fetchListUrl={fetchListURL}
              fetchListLoader={fetchListLoader}
              list={favoriteDomains}
              ratingStats={ratingStats}
              showSendMessageOption
              sendMessage={sendMessage}
              onEntryDeleted={refetchAllData}
            />
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default React.memo(MyDomains);
