import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
/* import SVG from 'react-inlinesvg' */

// Costants
import Constants from 'Constants';

// Page Components
import Header from '../SettingsHeader/Header';
import SubmitButton from 'general/SubmitButton/SubmitButton';
import FormInput from 'general/FormInput/FormInput';
import Select from 'general/CustomSelect/CustomSelect';

// Utils
import { apiRequest } from 'utils/ApiRequest';

// SCSS
import styles from './BillingDetails.module.scss';

/* // SVGs
import CreditCard from 'assets/svgs/Questionnaire/creditcard.svg';
import PayPal from 'assets/svgs/Questionnaire/paypal.svg';
import GooglePay from 'assets/svgs/Questionnaire/google-pay.svg'; */

interface BillingDetailsProps {
  billingPaymentId: string;
  bName: string;
  bAddress: string;
  bState: string;
  bZip: string;
  bCountry: string;
}

interface Props {
  billingDetailsProps: BillingDetailsProps;
  apiURL: string;
  setNewToken: (token: string) => void;
}

interface BillingDetails {
  /* paymentMethod: object, */
  name: string;
  address: string;
  state: string;
  zipCode: string;
  country: string;
}

interface APIResponse {
  token: string;
  id: string;
}

const USA = 'United States';

const BILLING_DETAILS_ERRORS: any = {
  name: 'Please enter the billing name',
  address: 'Please enter the address',
  state: 'Please select the state',
  zipCode: 'Please enter the zip code',
  country: 'Please select the Country',
}

const { pageConstants: { STATE_DROPDOWN, COUNTRY_DROPDOWN } } = Constants;

const BillingDetails: React.FC<Props> = ({ billingDetailsProps, apiURL, setNewToken }: Props) => {
  const [billingDetails, setBillingDetails] = useState<BillingDetails | any>({
    name: billingDetailsProps.bName,
    address: billingDetailsProps.bAddress,
    state: billingDetailsProps.bState,
    zipCode: billingDetailsProps.bZip,
    country: billingDetailsProps.bCountry || COUNTRY_DROPDOWN[0].value,
  })

  const [errors, setErrors] = useState({
    name: '',
    address: '',
    state: '',
    zipCode: '',
    country: '',
  })

  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const [updateSuccessfull, setUpdateSuccessfull] = useState<boolean>(false);

  const apiConfig = {
    method: 'PUT',
    body: JSON.stringify({
      billingName: billingDetails.name,
      billingAddress: billingDetails.address,
      billingState: billingDetails.state,
      billingZIP: billingDetails.zipCode,
      billingCountry: billingDetails.country,
    }),
  }

  const updateBillingDetails = async () => {
    setLoading(true);
    setUpdateSuccessfull(false);
    try {
      const { response, error } = await apiRequest(apiURL, apiConfig.method, apiConfig.body);
      if (response) {
        const { token } = response;
        if (token !== localStorage.getItem('token')) {
          setNewToken(token);
        }
        setUpdateSuccessfull(true);
      } else if (error) {
        setApiError(error);
      }
    } catch(e) {
      setApiError(e.message.toString());
    }
    
    setLoading(false);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'name') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: BILLING_DETAILS_ERRORS[name],
        })
      } else {
        if (value.length > 40) {
          setErrors({
            ...errors,
            [name]: 'Maximum 40 characters are allowed',
          })
        } else {
          setErrors({
            ...errors,
            [name]: '',
          })
        }
      }
    }

    if (name === 'address') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: BILLING_DETAILS_ERRORS[name],
        })
      } else {
        if (value.length > 75) {
          setErrors({
            ...errors,
            [name]: 'Maximum 75 characters are allowed',
          })
        } else {
          setErrors({
            ...errors,
            [name]: '',
          })
        }
      }
    }

    if (name === 'zipCode') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: BILLING_DETAILS_ERRORS[name],
        })
      } else {
        if (value.length > 10) {
          setErrors({
            ...errors,
            [name]: 'Maximum 10 characters are allowed',
          })
        } else {
          setErrors({
            ...errors,
            [name]: '',
          })
        }
      }
    }

    if (name === 'state') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: 'Please enter province or state',
        })
      } else {
        setErrors({
          ...errors,
          [name]: '',
        })
      }
    }
    /* if (!value) {
      setErrors({
        ...errors,
        [name]: BILLING_DETAILS_ERRORS[name],
      })
    } else {
      setErrors({
        ...errors,
        [name]: '',
      })
    } */
    setBillingDetails({
      ...billingDetails,
      [name]: value,
    })
  }

  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    let newBillingDetails = { ...billingDetails };
    if (name === 'country') {
      newBillingDetails = {  ...newBillingDetails, state: '' };
    }
    if (value === 'Select') {
      setErrors({
        ...errors,
        [name]: `Please select the ${name}`,
      })
    } else {
      setErrors({
        ...errors,
        [name]: '',
      })
    }
    newBillingDetails = { ...newBillingDetails, [name]: value };
    setBillingDetails(newBillingDetails);
  }

  const validateData = () => {
    const { name, address, state, zipCode, country } = billingDetails;
    if (name
        && name.length <= 40
        && address
        && address.length <= 75
        && state
        && zipCode
        && zipCode.length <= 10
        && country
    ) {
      return true;
    }

    let formErrors: any = {}
    Object.keys(billingDetails).map(field => {
      if (!billingDetails[field]) {
        switch (field) {
        case 'name':
          formErrors[field] = BILLING_DETAILS_ERRORS[field]
          break;
          
        case 'address':
          formErrors[field] = BILLING_DETAILS_ERRORS[field]
          break;
          
        case 'state':
          if (billingDetails.country !== USA) {
            formErrors[field] = 'Please enter province or state';
          } else {
            formErrors[field] = BILLING_DETAILS_ERRORS[field];
          }
          break;

        case 'zipCode':
          formErrors[field] = BILLING_DETAILS_ERRORS[field]
          break;

        case 'country':
          formErrors[field] = BILLING_DETAILS_ERRORS[field]
          break;

        default:
          break;
        }
        setErrors({
          ...errors,
          ...formErrors,
        })
      }
      return field;
    })
    return false;
  }

  const handleSubmit = () => {
    if (validateData()) {
      updateBillingDetails();
    }
  }

  return(
    <Container className={styles.billingDetails}>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Col xl={12} lg={12} md={12} sm={12}>
            <form>

              <Header title="BILLING" />

              {/* <div className={styles.prefLabel}>Payment Method</div>
              <div className={styles.paymentMethod}>
                <div className={`${styles.paymentItem} ${styles.active}`}>
                  <div className={styles.logo}>
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.paymentMethodLogo}
                      description="Credit Card"
                      loader={<span>Loading...</span>}
                      src={CreditCard}
                      title="Pay with credit card"
                    />
                  </div>
                  <div className={styles.title}>Pay with credit card</div>
                </div>

                <div className={`${styles.paymentItem}`}>
                  <div className={styles.logo}>
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.paymentMethodLogo}
                      description="PayPal"
                      loader={<span>Loading...</span>}
                      src={PayPal}
                      title="Pay with PayPal"
                    />
                  </div>
                  <div className={styles.title}>Pay with PayPal</div>
                </div>

                <div className={`${styles.paymentItem}`}>
                  <div className={styles.logo}>
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.paymentMethodLogo}
                      description="Google Pay"
                      loader={<span>Loading...</span>}
                      src={GooglePay}
                      title="Pay with Google Pay"
                    />
                  </div>
                  <div className={styles.title}>Pay with Google Pay</div>
                </div>
              </div> */}

              <div className={styles.prefLabel}>Name</div>
              <FormInput
                name="name"
                placeholder="Name"
                label="billing-name"
                value={billingDetails.name}
                onChange={handleChange}
                large
              />
              {errors.name && <div className={styles.error}>{errors.name}</div>}

              <div className={styles.prefLabel}>Address</div>
              <FormInput
                name="address"
                placeholder="Address"
                label="billing-address"
                value={billingDetails.address}
                onChange={handleChange}
                large
              />
              {errors.address && <div className={styles.error}>{errors.address}</div>}

              <Row>
                {billingDetails.country === USA ? (
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.prefLabel}>State</div>
                    <Select
                      id="state"
                      name="state"
                      selectItems={STATE_DROPDOWN}
                      value={billingDetails.state}
                      onChange={handleSelectChange}
                    />
                    {errors.state && <div className={styles.error}>{errors.state}</div>}
                  </Col>
                ) : (
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.prefLabel}>Province</div>
                    <FormInput
                      name="state"
                      placeholder="Province"
                      label="billing-state"
                      value={billingDetails.state}
                      onChange={handleChange}
                      large
                    />
                    {errors.state && <div className={styles.error}>{errors.state}</div>}
                  </Col>
                )}

                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.prefLabel}>Zip Code</div>
                  <FormInput
                    name="zipCode"
                    placeholder="Zip Code"
                    label="billing-zip"
                    value={billingDetails.zipCode}
                    onChange={handleChange}
                    large
                  />
                  {errors.zipCode && <div className={styles.error}>{errors.zipCode}</div>}
                </Col>
              </Row>

              <div className={styles.prefLabel}>Country</div>
              {/* <FormInput
                name="country"
                placeholder="Country"
                label="billing-country"
                value={billingDetails.country}
                onChange={handleChange}
                large
              /> */}
              <Select
                id="country"
                name="country"
                selectItems={COUNTRY_DROPDOWN}
                value={billingDetails.country}
                onChange={handleSelectChange}
              />
              {errors.country && <div className={`${styles.error} mb-3`}>{errors.country}</div>}

              <SubmitButton isLoading={loading} onSubmit={handleSubmit}/>
              {apiError && <div className={`${styles.error} ${styles.apiError}`}>{apiError}</div>}
              {updateSuccessfull && <div className={`${styles.updatedSuccessfully}`}>Information Updated Successfully</div>}
            </form>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

export default BillingDetails;
