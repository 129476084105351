import React from 'react';

// Interfaces
import { Domain, RatingStats } from 'interfaces/Interfaces';

import RatingsFilter from './RatingFilters/RatingFilters';
import SearchBar from './SearchList/SearchList';
import CustomRow from './CustomRow/CustomRow';
import Loader from 'general/Loader/Loader';

import './CustomListing.scss';

interface Data {
  id: string;
  title: string;
  userName: string;
  date: string;
  price: number;
  message?: string;
  comments: Array<string>;
}

interface Props {
  showTotalCountOnLeft?: boolean;
  showTotalCountOnRight?: boolean;

  showRatingFilter?: boolean;
  setRatingFilter?: (selectedRating: number) => void;

  showSearchBar?: boolean;
  showExportList?: boolean;
  setSearchText?: (text: string) => void;
  searchList?: (searchText: string) => void;
  resetFilters?: () => void;

  showBuyNowOption?: boolean;
  fetchListUrl: string;
  fetchListLoader?: boolean;
  list: Array<Domain> | null;
  ratingStats?: RatingStats | null;

  areActionsAllowed?: boolean;

  updateRating?: (entryId: string, rating: number) => void;

  loggedInUserId?: string;
  loggedInEmailId?: string;
  buyRequestCreatorId?: string;
  buyRequestCreatorEmail?: string;
  onOfferAccept?: (response: any) => void;
  updateOffersOnEntry?: (entryId: string, offer: any) => void;
  onEntryDeleted?: (entryId: string) => void;
  showSendMessageOption?: boolean;
  sendMessageLoader?: boolean;
  sendMessage?: (entryId: string, entryOwnerId: string, message: string) => void;
}

const CustomListing: React.FunctionComponent<Props> = ({
  showTotalCountOnLeft,
  showTotalCountOnRight,
  showRatingFilter,
  setRatingFilter,
  showSearchBar,
  showExportList,
  setSearchText,
  searchList,
  resetFilters,
  fetchListLoader,
  list,
  ratingStats,
  areActionsAllowed,
  updateRating,
  loggedInUserId,
  loggedInEmailId,
  buyRequestCreatorId,
  buyRequestCreatorEmail,
  onOfferAccept,
  updateOffersOnEntry,
  showBuyNowOption,
  showSendMessageOption,
  sendMessage,
  onEntryDeleted,
}: Props) => {


  return (
    <div className="custom-listing">
      {showRatingFilter && (
        <RatingsFilter
          showTotalCountOnLeft={showTotalCountOnLeft}
          showTotalCountOnRight={showTotalCountOnRight}
          ratingStats={ratingStats}
          entriesCount={list && list[0] ? list[0].total : 0}
          setRatingFilter={setRatingFilter}
          resetFilters={resetFilters}
        />
      )}
      {showSearchBar && (
        <SearchBar
          showExport={showExportList}
          setSearchText={setSearchText}
          searchList={searchList}
        />
      )}
      <div className="border-top my-3"></div>
      
      <div className="entries">
        {fetchListLoader && (<Loader />)}
        {!fetchListLoader && list && (
          list.map((data: any) => (
            <CustomRow
              {...data}
              key={data.id}
              id={data.id}
              title={data.name}
              userName={data.userName}
              date={data.createdAt}
              message={data.message}
              profilePicURL={data.profilePicURL}
              price={data.offers && data.offers.length ? data.offers[0].amount : null}
              isFavorite={data.isFavorite && data.isFavorite === '1' ? true : false}
              offers={data.offers}
              // comments={data.message ? 1 : 0}
              comments={((data.message ? 1 : 0) + (data.messages ? data.messages.length : 0))}
              areActionsAllowed={areActionsAllowed}
              updateRating={updateRating}
              loggedInUserId={loggedInUserId}
              loggedInEmailId={loggedInEmailId}
              buyRequestCreatorId={buyRequestCreatorId}
              buyRequestCreatorEmail={buyRequestCreatorEmail}
              onOfferAccept={onOfferAccept}
              updateOffersOnEntry={updateOffersOnEntry}
              showBuyNowOption={showBuyNowOption}
              showSendMessageOption={showSendMessageOption}
              sendMessage={sendMessage}
              onEntryDeleted={onEntryDeleted}
            />
          ))
        )}
        {!fetchListLoader && list && !list.length && (
          <div className="no-entries">No Entries yet</div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CustomListing);
