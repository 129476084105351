import React, { useState, useContext } from 'react';

import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import ReactGA from 'react-ga4';

import GlobalContext from 'contexts/Global.context';

import { apiRequest } from 'utils/ApiRequest';
import { uploadFile } from 'utils/UploadFile';

import { Alert } from'react-bootstrap'; 

import Page1Questions from './Page1Questions/Page1Questions';
import Page2Questions from './Page2Questions/Page2Questions';
import Page3Questions from './Page3Questions/Page3Questions';
import Page4Questions from './Page4Questions/Page4Questions';

import {
  Page1Questionnaire,
  Page2Questionnaire,
  Page3Questionnaire,
  Page4Questionnaire,
} from '../../constants/Types';

import constants from 'Constants';

// SCSS
import './QuestionnaireForm.scss';

const { apiConstants: { API_URL } } = constants;

const CREATE_BUY_REQUEST_URL = `${API_URL}/requests`;
const SIGNED_REQUEST_URL = `${API_URL}/get-attachment-url`;

interface Props {
  page: number;
  setCurrentPage: (page: number) => void;
  setIsBuyRequestCreated: (created: boolean) => void;
}

interface APIResponse {
  TLDs: string;
  additionalComments: string;
  brandFeelings: string;
  budget: number;
  businessDescription: string;
  businessIndustry: string;
  createdAt: string;
  desiredKeywords: string;
  domainMaxLength: string;
  file: string | null;
  geographicRegions: string;
  id: string;
  isDeleted: boolean;
  isExpired: boolean;
  name: string;
  plan: string;
  projectType: string;
  status: number;
  targetCustomers: string;
  targetDate: string;
  updatedAt: string;
  user: {
    billingAddress: string;
    billingCountry: string;
    billingName: string;
    billingState: string;
    billingZIP: string;
    createdAt: string;
    email: string;
    firstName: string;
    iat: number;
    id: string;
    isAdmin: boolean;
    isDeleted: boolean;
    isVerified: boolean;
    lastName: string;
    paymentId: string;
    profilePicURL: string;
    updatedAt: string;
    userName: string;
  }
}

const QuestionnaireForm: React.FunctionComponent<Props> = ({
  page,
  setCurrentPage,
  setIsBuyRequestCreated,
}: Props) => {

  const { search } = useLocation();
  const selectedPlan = search && search.indexOf('selectedPlan') ? search.substr(search.indexOf('=') + 1) : '';

  const globalContext = useContext(GlobalContext);
  const { userDetails: { id }} = globalContext;
  const UPDATE_USER_DETAIL_URL = `${API_URL}/users/${id}`;

  const [defaultCard, setDefaultCard] = useState<string>('');

  const [page1, setPage1Answers] = useState<Page1Questionnaire>({
    projectType: '',
    businessDescription: '',
    businessIndustry: '',
  });

  const [page2, setPage2Answers] = useState<Page2Questionnaire>({
    brandFeelings: '',
    desiredKeywords: '',
    targetCustomers: '',
    geographicRegions: '',
  });

  const [page3, setPage3Answers] = useState<Page3Questionnaire>({
    domainMaxLength: '',
    TLDs: '',
    budget: '',
    targetDate: '',
    additionalComments: '',
    file: null,
  });

  const [page4, setPage4Answers] = useState<Page4Questionnaire>({
    plan: selectedPlan,
    amount: 0,
    cardId: '',
    billingName: '',
    billingAddress: '',
    billingState: '',
    billingZIP: '',
    billingCountry: '',
    agreeToTAndC: '',
  });

  // const [response, setResponse] = useState<APIResponse | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [buyRequestId, setBuyRequestId] = useState<string>('');

  const apiConfig = {
    method: 'POST',
    body: {
      ...page1,
      ...page2,
      ...page3,
      budget: Number(page3.budget),
      targetDate: page3.targetDate ? moment(page3.targetDate).utc() : moment().add(3, 'months'),
      ...page4,
      name: page1.projectType || page1.otherProjectType,
      projectType: page1.projectType || page1.otherProjectType,
      domainMaxLength: page3.domainMaxLength || page3.otherDomainMaxLength,
      cardId: page4.cardId || defaultCard || '',
    }
  }

  const uploadAttachment = async (signedRequest: string) => {
    const { response: uploadResponse, error } = await uploadFile(signedRequest, 'PUT', page3.file);
    return {
      uploadResponse,
      uploadError: error,
    }
  }

  const getSignedRequest = async (fileToUpload: any) => {
    // setLoading(true);
    const { name, type } = fileToUpload;
    const fileExtension = name.substr(name.lastIndexOf('.'))
    const fileName = page1.projectType + (new Date().getTime().toString()) + fileExtension
    const apiURL = `${SIGNED_REQUEST_URL}?fileName=${fileName}&fileType=${type}`
    const { response, error } = await apiRequest(apiURL, 'GET');
    // setLoading(false);
    return {
      signedRequestResponse: response,
      signedRequestError: error,
    }
  }

  const updateUserDetails = async () => {
    const { response, error } = await apiRequest(UPDATE_USER_DETAIL_URL, 'PUT', JSON.stringify({
      ...page4,
    }))
    if (response) {
      const { token } = response;
      globalContext.setAuth(token);
    } else if (error) {
      setError(`Error occured while updating user details: ${error}`);
    }
  }

  const callAnalytics = (response: APIResponse) => {
    const { name, budget, user, id  } = response;
    ReactGA.event('Buy request created', {
      buyRequestName: name,
      budget: budget,
      email: user.email,
      userEmail: user.email,
      requestId: id,
    })
  }

  const createBuyRequest = async () => {
    setError('');
    setLoading(true);
    // first upload file to the S3 and get string value
    if (page3.file) {
      const { signedRequestResponse, signedRequestError } = await getSignedRequest(page3.file);
      if (signedRequestResponse) {
        const { signedRequest, url } = signedRequestResponse;
        const { uploadResponse, uploadError } = await uploadAttachment(signedRequest);
        if (uploadResponse) {
          setPage3Answers({
            ...page3,
            file: url,
          })
          const { response, error } = await apiRequest(
            CREATE_BUY_REQUEST_URL,
            apiConfig.method,
            JSON.stringify({ ...apiConfig.body, file: url }),
          );
          if (response) {
            updateUserDetails();
            setBuyRequestId(response.id);
            setIsBuyRequestCreated(true);
            callAnalytics(response);
          } else if (error) {
            setError(error);
          }
        } else if (uploadError) {
          setError(`An error occurred while uploading a file due to ${uploadError}`);
        }
      } else if (signedRequestError) {
        setError(`An error occurred while uploading a file due to ${signedRequestError}`);
      }
      setLoading(false);
    } else {
      const { response, error } = await apiRequest(
        CREATE_BUY_REQUEST_URL,
        apiConfig.method,
        JSON.stringify({ ...apiConfig.body })
      );
      if (response) {
        updateUserDetails();
        setBuyRequestId(response.id);
        setIsBuyRequestCreated(true);
        callAnalytics(response);
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    }
  }

  const handlePreviousClick = () => {
    if (page !== 1) {
      setCurrentPage(page - 1)
    }
  }

  const handleSubmit = () => {
    createBuyRequest();
  }

  const pageToBeShown = () => {
    switch (page) {
    case 1:
      return (
        <Page1Questions
          currentPageNumber={page}
          answers={page1}
          setAnswers={setPage1Answers}
          setPage={setCurrentPage}
        />
      )
    
    case 2:
      return (
        <Page2Questions
          currentPageNumber={page}
          answers={page2}
          setAnswers={setPage2Answers}
          setPage={setCurrentPage}
          handlePreviousClick={handlePreviousClick}
        />
      )
    
    case 3:
      return (
        <Page3Questions
          currentPageNumber={page}
          answers={page3}
          setAnswers={setPage3Answers}
          setPage={setCurrentPage}
          handlePreviousClick={handlePreviousClick}
        />
      )
    
    case 4:
      return (
        <Page4Questions
          currentPageNumber={page}
          answers={page4}
          setAnswers={setPage4Answers}
          setPage={setCurrentPage}
          handlePreviousClick={handlePreviousClick}
          handleSubmit={handleSubmit}
          setDefaultCard={setDefaultCard}
          isLoading={loading}
          apiError={error}
        />
      )
    
    default:
      return (
        <Page1Questions
          currentPageNumber={page}
          answers={page1}
          setAnswers={setPage1Answers}
          setPage={setCurrentPage}
        />
      )
    }

  }

  return (
    <>
      {!buyRequestId && <div>
        {pageToBeShown()}
      </div>}
      {buyRequestId && (
        <Alert variant="success" className="buy-request-success">
          Buy Request created successfully, Click
          <Link to={`/requests/${buyRequestId}`}>
            {' here '} 
          </Link>
          to view the buy request details.
        </Alert>
      )}
    </>
  )
};

export default React.memo(QuestionnaireForm);
