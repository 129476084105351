import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// Constants
import Constants from 'Constants';

// Context
import { FavoriteDomainsContext } from 'contexts/FavoriteDomains.context';
import GlobalContext from 'contexts/Global.context';

// Intefaces
import { RatingStats } from 'interfaces/Interfaces';

// Utils
import { apiRequest } from 'utils/ApiRequest';

// Page Components
import CustomListing from 'general/CustomListing/CustomListing';


const { apiConstants: { API_URL } } = Constants;
const FETCH_FAV_DOMAINS_URL = `${API_URL}/entries?isFavorite=true`;
const SEND_MESSAGE_URL = `${API_URL}/messages`;

const FavoriteDomains: React.FunctionComponent = () => {

  const history = useHistory();

  const globalContext = useContext(GlobalContext);
  const favDomainsContext = useContext(FavoriteDomainsContext);

  const { userDetails: { id: loggedInUserId, email: loggedInEmail } } = globalContext;

  const {
    favoriteDomains,
    setFavoriteDomains,
    setRatingToFavoriteDomain,
    setOffersOnFavoriteDomain,
    setMessageOnFavoriteDomain,
  } = favDomainsContext;

  const [fetchListURL, setFetchListURL] = useState<string>(FETCH_FAV_DOMAINS_URL);

  const newUrl = new URL(fetchListURL);

  const [fetchListLoader, setFetchListLoader] = useState<boolean>(false);

  const [ratingStats, setRatingStats] = useState<RatingStats | null>(null);

  const fetchList = useCallback(async (url: string) => {
    setFetchListLoader(true);

    const { response, error } = await apiRequest(url, 'GET');
    if (response) {
      const { entries, stats } = response;
      setFavoriteDomains(entries, '');
      setRatingStats(stats);
    } else if (error) {
      setFavoriteDomains(null, error);
    }
    setFetchListLoader(false);
  }, [setFetchListLoader, setFavoriteDomains])

  const setRatingFilter = (selectedRating: number) => {
    if (newUrl.searchParams.has('ratings')) {
      newUrl.searchParams.set('ratings', selectedRating.toString());
      setFetchListURL(newUrl.href);
    } else {
      newUrl.searchParams.append('ratings', selectedRating.toString());
      setFetchListURL(newUrl.href);
    }
  }

  const searchList = (searchText: string) => {
    if (newUrl.searchParams.has('domain')) {
      newUrl.searchParams.set('domain', searchText);
      setFetchListURL(newUrl.href);
    } else {
      newUrl.searchParams.append('domain', searchText);
      setFetchListURL(newUrl.href);
    }
  }

  const giveARatingToDomain = (buyRequestEntry: string, updatedRating: number) => {
    setRatingToFavoriteDomain(buyRequestEntry, updatedRating, '');
    fetchList(fetchListURL);
  }

  const refetchAllData = () => {
    fetchList(fetchListURL);
  };

  const updateOffersOnDomain = (buyRequestEntry: string, offer: any) => {
    setOffersOnFavoriteDomain(buyRequestEntry, offer)
  }

  const onOfferAccept = (response: any) => {
    if (response.success) {
      history.push(`/purchase-status/${response.transactionId}`);
    }
  }

  const sendMessage = async (buyRequestEntry: string, entryOwnerId: string, message: string) => {
    const sendMessageBody = {
      receiver: entryOwnerId,
      submissionId: buyRequestEntry,
      body: message,
    }
    if (message) {
      const { response } = await apiRequest(SEND_MESSAGE_URL, 'POST', JSON.stringify({
        ...sendMessageBody,
      }));
      if (response) {
        const messageBody = {
          body: response.body,
          sender: response.sender.userName,
        }
        setMessageOnFavoriteDomain(buyRequestEntry, {...messageBody}, '');
      }
    }
  }

  const resetFilters = () => {
    setFetchListURL(FETCH_FAV_DOMAINS_URL);
  }


  useEffect(() => {
    fetchList(fetchListURL);
  }, [fetchListURL, fetchList])

  return (
    <div className="favorite-domains">
      <CustomListing
        // Ratings filter Props
        showTotalCountOnLeft
        showRatingFilter
        setRatingFilter={setRatingFilter}
        // Search filter Props
        showSearchBar
        resetFilters={resetFilters}
        // List related props
        searchList={searchList}
        fetchListUrl={fetchListURL}
        fetchListLoader={fetchListLoader}
        list={favoriteDomains}
        ratingStats={ratingStats}
        areActionsAllowed
        showBuyNowOption
        loggedInUserId={loggedInUserId}
        loggedInEmailId={loggedInEmail}
        buyRequestCreatorId={loggedInUserId}
        onOfferAccept={onOfferAccept}

        updateRating={giveARatingToDomain}
        updateOffersOnEntry={updateOffersOnDomain}

        showSendMessageOption
        sendMessage={sendMessage}

        onEntryDeleted={refetchAllData}
      />
    </div>
  )
}

export default React.memo(FavoriteDomains);
