import React, { Fragment, useContext, useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Badge, Button } from 'react-bootstrap';

import GlobalContext from 'contexts/Global.context';

// Utils
import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

// SCSS
import styles from './DashboardMenu.module.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTrophy, faListAlt, faEnvelope, faBell, faExchangeAlt, faRocket, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

interface Props {
  url?: string;
}

const { apiConstants: { API_URL }, pageConstants: { ITEMS_ON_DASHBOARD }} = Constants;
const MESSAGES_URL = `${API_URL}/messages?limit=${ITEMS_ON_DASHBOARD}&skip=0&unread=true`;

const DashboardMenu: React.FunctionComponent<Props> = ({ url }: Props) => {
  const { pathname } = useLocation();

  const { userDetails, logout } = useContext(GlobalContext);

  const { isVerified } = userDetails;

  const isSelected = (checkFor: string) => {
    return pathname.includes(checkFor.toLowerCase());
  }

  const [ureadMessages, setUnreadMessages] = useState<number>(0);

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      const { response } = await apiRequest(MESSAGES_URL, 'GET');
      if (response) {
        setUnreadMessages(response.length);
      }
    }
    fetchUnreadMessages();
  }, [])

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      const { response } = await apiRequest(MESSAGES_URL, 'GET');
      if (response) {
        setUnreadMessages(response.length);
      }
    }
    if (pathname.indexOf('messages') > -1) {
      fetchUnreadMessages();
    }
  }, [pathname])

  return (
    <Fragment>
      <div className={styles.menuTitle}>Dashboard Menu</div>
      <ul className={styles.dashMenu}>
        <li
          className={`${styles.dashMenuItem} ${isSelected('favorites') || pathname === '/dashboard' ? styles.selected : ''}`}
        >
          <Link to={`${url}/favorites`}>
            <FontAwesomeIcon icon={faStar} /> Favorites
          </Link>
        </li>
        <li
          className={`${styles.dashMenuItem} ${isSelected('my-buy-requests') ? styles.selected : ''}`}
        >
          <Link to={`${url}/my-buy-requests`}>
            <FontAwesomeIcon icon={faTrophy} /> My Buy Requests
          </Link>
        </li>
        <li
          className={`${styles.dashMenuItem} ${isSelected('my-domains') ? styles.selected : ''}`}
        >
          <Link to={`${url}/my-domains`}>
            <FontAwesomeIcon icon={faListAlt} />
            My Domains
          </Link>
        </li>
        <li
          className={`${styles.dashMenuItem} ${isSelected('messages') ? styles.selected : ''}`}
        >
          <Link to={{
            pathname: `${url}/messages`,
            state: ureadMessages,
          }}>
            <FontAwesomeIcon icon={faEnvelope} />
            Messages
            <Badge className={styles.messageBadge} variant="primary">{ureadMessages}</Badge>
          </Link>
        </li>
        <li
          className={`${styles.dashMenuItem} ${isSelected('notifications') ? styles.selected : ''}`}
        >
          <Link to={`${url}/notifications`}>
            <FontAwesomeIcon icon={faBell} /> 
            Notifications
          </Link>
        </li>

        <li
          className={`${styles.dashMenuItem} ${isSelected('transactions') ? styles.selected : ''}`}
        >
          <Link to={`${url}/transactions-list`}>
            <FontAwesomeIcon icon={faExchangeAlt} />
              Transactions
          </Link>
        </li>
      </ul>

      <div className={styles.menuTitle}>User Menu</div>
      <ul className={styles.dashMenu}>
        <li
          className={`${styles.dashMenuItem} ${isSelected('Launch Buy Request') ? styles.selected : ''}`}
        >
          <Link to="/questionnaire">
            <Button
              variant="primary"
              className={styles.transparentBtn}
              disabled={!isVerified}
            >
              <FontAwesomeIcon icon={faRocket} />
              Launch Buy Request
            </Button>
          </Link>
        </li>
        <li
          className={`${styles.dashMenuItem} ${isSelected('account-preferences') ? styles.selected : ''}`}
        >
          <Link to={`${url}/account-preferences`}>
            <FontAwesomeIcon icon={faCog} />
            Account Preferences
          </Link>
        </li>
        <li
          className={`${styles.dashMenuItem} ${isSelected('Sign Out') ? styles.selected : ''}`}
        >
          <Link to="/" onClick={() => logout('/')}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            Sign Out
          </Link>
        </li>
      </ul>
    </Fragment>
  );
};

export default React.memo(DashboardMenu);
