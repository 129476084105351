import React, { createContext, useReducer, useCallback } from 'react';

// Reducers
import FavoriteDomainsReducer from './Reducers/FavoriteDomainsReducer';

// Interfaces
import { Offer } from 'interfaces/Interfaces';

interface Message {
  sender: string;
  body: string;
}

const initialState: any = {
  favoriteDomains: null,
  error: null,
  loading: false,
  favoriteDomainsLoader: false,
}

export const FavoriteDomainsContext = createContext(initialState);

export const FavoriteDomainsProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(FavoriteDomainsReducer, initialState);

  const setFavoriteDomainsLoader = useCallback((loader: boolean) => {
    dispatch({
      type: 'SET_FAVORITE_DOMAINS_LOADER',
      payload: { loader }
    })
  }, []);

  const setFavoriteDomains = useCallback((favoriteDomains: any, error: string) => {
    if (favoriteDomains) {
      dispatch({
        type: 'SET_FAVORITE_DOMAINS',
        payload: { favoriteDomains },
      })
    } else if (error) {
      dispatch({
        type: 'SET_FAVORITE_DOMAINS_ERROR',
        payload: { error },
      })
    }
  }, []);

  const setRatingToFavoriteDomain = useCallback((entryId: string, rating: number, error: string) => {
    if (rating) {
      dispatch({
        type: 'SET_RATING_TO_FAVORITE_DOMAIN',
        payload: { entryId, rating },
      })
    } else if (error) {
      dispatch({
        type: 'SET_RATING_TO_FAVORITE_DOMAIN_ERROR',
        payload: { error },
      })
    }
  }, []);

  const setMessageOnFavoriteDomain = useCallback((entryId: string, message: Message, error: string) => {
    if (message) {
      dispatch({
        type: 'SET_MESSAGE_ON_FAVORITE_DOMAIN',
        payload: { entryId, message },
      })
    } else if (error) {
      dispatch({
        type: 'SET_MESSAGE_ON_FAVORITE_DOMAIN_ERROR',
        payload: { error },
      })
    }
  }, []);

  const setOffersOnFavoriteDomain = useCallback((entryId: string, offer: Offer, error?: string) => {
    if (offer) {
      dispatch({
        type: 'SET_OFFERS_ON_FAVORITE_DOMAIN',
        payload: { entryId, offer },
      })
    } else if (error) {
      dispatch({
        type: 'SET_OFFERS_ON_FAVORITE_DOMAIN_ERROR',
        payload: { error },
      })
    }
  }, []);

  return (
    <FavoriteDomainsContext.Provider value={{
      error: state.error,
      loading: state.loading,
      favoriteDomains: state.favoriteDomains,
      favoriteDomainsLoader: state.favoriteDomainsLoader,
      setFavoriteDomainsLoader: setFavoriteDomainsLoader,
      setFavoriteDomains,
      setRatingToFavoriteDomain,
      setMessageOnFavoriteDomain,
      setOffersOnFavoriteDomain,
    }}>
      {children}
    </FavoriteDomainsContext.Provider>)
}
