import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

/* import SVG from 'react-inlinesvg'

// Components
import BillingDetails from './BillingDetails/BillingDetails';

// SVGs
import CreditCard from 'assets/svgs/Questionnaire/creditcard.svg';
import PayPal from 'assets/svgs/Questionnaire/paypal.svg';
import GooglePay from 'assets/svgs/Questionnaire/google-pay.svg'; */

import PaymentMethods from './PaymentMethods/PaymentMethods';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

import {
  Page4Questionnaire,
} from '../../../constants/Types';
import { Link } from 'react-router-dom';


interface Props {
  currentPageNumber: number;
  answers:   Page4Questionnaire;
  setPage: (page: number) => void;
  setAnswers: (page4: Page4Questionnaire) => void;
  handlePreviousClick: () => void;
  handleSubmit: () => void;
  setDefaultCard: (cardId: string) => void;
  apiError?: string;
  isLoading: boolean;
}

const AVAILABLE_PLANS = [
  { plan: 'Basic', price: 0 },
  { plan: 'Plus', price: 149 },
  { plan: 'Startup', price: 450 },
  { plan: 'Corporate', price: 950 },
]

const Page4Questions: React.FunctionComponent<Props> = ({
  answers,
  setAnswers,
  handlePreviousClick,
  handleSubmit,
  setDefaultCard,
  apiError,
  isLoading,
}: Props) => {

  const [errors, setErrors] = useState<string>();

  const selectPlan = (plan: string, price: number) => {
    setAnswers({
      ...answers,
      plan,
      amount: price,
    })
  }

  const cardSelectedForPayment = (selectedCardId: string) => {
    setAnswers({
      ...answers,
      cardId: selectedCardId,
    })
  }

  const handleFinalSubmit = () => {
    if (answers.plan) {
      handleSubmit();
    }
    if (!answers.plan) {
      setErrors('Please select a plan');
    }
  }

  return (
    <>
      <Container id="questions">
        <Row>
          <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
            Billing
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="questionAsked">
              Select your plan
              <span className="compare-plans float-right">
                <Link to="/pricing" target="_blank">
                  Compare Plans
                </Link>
              </span>
            </div>
            <Row className="select-plan">
              {AVAILABLE_PLANS.map((plan) => (
                <Col
                  xl={3}
                  lg={3}
                  md={6}
                  sm={6}
                  key={`${plan.plan} - ${plan.price}`}
                >
                  <div
                    className={`item ${plan.plan} ${
                      plan.plan === "Startup" ? "recommended" : ""
                    } ${
                      plan.plan.toLowerCase() === answers.plan
                        ? "selected-plan"
                        : ""
                    }`}
                    onClick={() =>
                      selectPlan(plan.plan.toLowerCase(), plan.price)
                    }
                  >
                    <div className="title">{plan.plan}</div>
                    <div className="price">{`$${plan.price || "Free"}`}</div>
                  </div>
                </Col>
              ))}

              {/* <Col xl={3} lg={3} md={6} sm={6}>
                <div className="item basic" onClick={() => selectPlan('Basic')}>
                  <div className="title">Basic</div>
                  <div className="price">$49</div>
                </div>
              </Col>

              <Col xl={3} lg={3} md={6} sm={6}>
                <div className="item plus" onClick={() => selectPlan('Plus')}>
                  <div className="title">Plus</div>
                  <div className="price">$149</div>
                </div>
              </Col>

              <Col xl={3} lg={3} md={6} sm={6}>
                <div className="item startup recommended" onClick={() => selectPlan('Startup')}>
                  <div className="title">Startup</div>
                  <div className="price">$450</div>
                </div>
              </Col>

              <Col xl={3} lg={3} md={6} sm={6}>
                <div className="item corporate" onClick={() => selectPlan('Corporate')}>
                  <div className="title">Corporate</div>
                  <div className="price">$950</div>
                </div>
              </Col> */}
            </Row>
            {errors && <div className="mt-2 error">{errors}</div>}
          </Col>

          {answers.plan !== 'basic' && (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className="questionAsked mt-5">Payment Method</div>
              <div className="payment-method">
                {/* <div className="item credit-card active" onClick={() => selectPaymentMode('card')}>
                <div className="logo">
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className="payment-method-logo"
                    description="Credit Card"
                    loader={<span>Loading...</span>}
                    src={CreditCard}
                    title="Pay with credit card"
                  />
                </div>
                <div className="title">Pay with credit card</div>
              </div> */}

                {/* <div className="item paypal" onClick={() => selectPaymentMode('paypal')}>
                <div className="logo">
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className="payment-method-logo"
                    description="PayPal"
                    loader={<span>Loading...</span>}
                    src={PayPal}
                    title="Pay with PayPal"
                  />
                </div>
                <div className="title">Pay with PayPal</div>
              </div>

              <div className="item google-pay" onClick={() => selectPaymentMode('Google Pay')}>
                <div className="logo">
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className="payment-method-logo"
                    description="Google Pay"
                    loader={<span>Loading...</span>}
                    src={GooglePay}
                    title="Pay with Google Pay"
                  />
                </div>
                <div className="title">Pay with Google Pay</div>
              </div> */}
              </div>
            </Col>
          )}
        </Row>

        {/* <Row>
          <Col>
            <BillingDetails
              billingName={answers.billingName}
              billingAddress={answers.billingAddress}
              billingState={answers.billingState}
              billingZIP={answers.billingZIP}
              billingCountry={answers.billingCountry}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
            />
          </Col>
        </Row> */}
        {answers.plan !== 'basic' && (
          <Row>
            <Col>
              <PaymentMethods
                cardSelectedForPayment={cardSelectedForPayment}
                setDefaultCard={setDefaultCard}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col className="step-controller">
            <Button
              className="bm-btn px-5 py-2 go-back"
              variant="primary"
              onClick={handlePreviousClick}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} />
              Back
            </Button>
            <Button
              className="bm-btn px-5 py-2 ml-auto go-forward"
              variant="primary"
              disabled={isLoading}
              onClick={handleFinalSubmit}
            >
              {isLoading ? "Loading..." : "Submit"}
              {!isLoading ? (
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              ) : null}
            </Button>
          </Col>
        </Row>
        {apiError && <div className="error api-error">{apiError}</div>}
      </Container>
    </>
  );
}

export default React.memo(Page4Questions);
