import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import styles from './Tooltip.module.scss';

interface TableTooltip {
  key: string;
  name: string;
  description: string;
}

const TableTooltip = (props: TableTooltip) => {
  return (
    <div className={`float-right d-xl-block d-lg-block d-md-none d-sm-none d-none ${styles.tableTooltip}`}>
      <OverlayTrigger
        key={props.key}
        placement='right'
        overlay={
          <Tooltip id={`tooltip-${props.name}`}>
            {props.description}
          </Tooltip>
        }
      >
        <FontAwesomeIcon className={styles.toolTipIcon} icon={faQuestionCircle} />
      </OverlayTrigger>
    </div>
  )
}

export default React.memo(TableTooltip);
