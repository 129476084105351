import React from 'react';
import { Nav } from 'react-bootstrap';

import './Stepper.scss';

interface Props {
  stepsCompleted: Array<number>;
}

const PageCounter: React.FunctionComponent<Props> = ({ stepsCompleted = [] }: Props) => {
  return (
    <Nav variant="pills" defaultActiveKey={'#step-1'}>
      <Nav.Item>
        <Nav.Link href="#step-1" className={stepsCompleted.includes(1) ? 'active': ''}>
          1
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="#step-2" className={stepsCompleted.includes(2) ? 'active': ''}>
          2
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="#step-3" className={stepsCompleted.includes(3) ? 'active': ''}>
          3
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="#step-4" className={stepsCompleted.includes(4) ? 'active': ''}>
          4
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default React.memo(PageCounter);
