import React, { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

import GlobalContext from 'contexts/Global.context';

import styles from './Verification.module.scss';

// Page Components
import Subscribe from '~/../components/Subscribe/Subscribe';

// SVGs
import Mail from 'assets/svgs/Verification/mail.svg';

const Verification = () => {
  const { userDetails: { email } } = useContext(GlobalContext);
  return (
    <Fragment>
      <Container className="p-0" fluid>
        <Helmet>
          <title>
            Verify Your Email | Brandmo
          </title>
        </Helmet>
        <Container fluid>
          <div className={styles.verificationPage}>
            <div className={styles.imgWrapper}>

              <SVG
                description="Mail"
                src={Mail}
                title="Mail"
              />
            </div>
            <h1>Almost There!</h1>
            <p>We&apos;ve sent a verification email to <strong>{email}</strong>.</p>
            <p>Please check your email to complete verification and finish signing up.</p>
          </div>
        </Container>
      </Container>
      <Subscribe />
    </Fragment>
  );
};

export default Verification;