
interface Message {
  sender: string;
  body: string;
  createdAt?: string;
  photo?: string;
  profilePicURL?: string;
}

interface Offer {
  amount: number;
  offeredBy: string;
}
  
const updateDomainWithNewRating = (entries: any, entryId: string, rating: number) => {
  let entryToBeUpdated = entries.filter((en: any) => en.id === entryId)[0];
  
  if (entryToBeUpdated) {
    entries.filter((en: any) => en.id === entryId)[0].ratings = rating;
  }
  
  return entries;
}
  
const updateDomainWithNewMessage = (entries: any, entryId: string, message: Message) => {
  let entryToBeUpdated = entries.filter((en: any) => en.id === entryId)[0];
  const newMessages: any = []
  if (entryToBeUpdated) {
    if (entryToBeUpdated.messages) {
      entryToBeUpdated.messages.unshift(message);
    } else {
      newMessages.push(message);
      entryToBeUpdated.messages = newMessages;
    }
  }
  
  return entries;
}
  
const updateDomainWithNewOffers = (entries: any, entryId: string, offer: Offer) => {
  let entryToBeUpdated = entries.filter((en: any) => en.id === entryId)[0];
  const newMessages: any = []
  if (entryToBeUpdated) {
    if (entryToBeUpdated.offers) {
      entryToBeUpdated.offers.push(offer);
    } else {
      newMessages.push(offer);
      entryToBeUpdated.offers = newMessages;
    }
  }
  
  return entries;
}
  
export default (state: any, action: { type: string; payload: any }) => {
  switch(action.type) {
  
  case 'SET_FAVORITE_DOMAINS_LOADER': {
    return {
      ...state,
      loading: action.payload.loader,
    }
  }
  
  /* case 'SET_FAVORITE_DOMAINS_DETAILS': {
    return {
      ...state,
      buyRequestDetails: action.payload.details,
    }
  } */
  
  /* case 'SET_FAVORITE_DOMAINS_DETAILS_ERROR': {
    return {
      ...state,
      detailsError: `Error occured while fetching buy request details due to: ${action.payload.error}`,
    }
  } */
  
  case 'ENTRIES_LOADER': {
    return {
      ...state,
      entriesLoader: action.payload.loader,
    }
  }
  
  case 'SET_FAVORITE_DOMAINS': {
    return {
      ...state,
      favoriteDomains: action.payload.favoriteDomains,
    }
  }
  
  case 'SET_FAVORITE_DOMAINS_ERROR': {
    return {
      ...state,
      entriesError: `Error occured while fetching entries due to: ${action.payload.error}`,
    }
  }
  
  case 'SET_RATING_TO_FAVORITE_DOMAIN': {
    return {
      ...state,
      favoriteDomains: updateDomainWithNewRating(state.favoriteDomains, action.payload.entryId, action.payload.rating),
    }
  }
  
  case 'SET_MESSAGE_ON_FAVORITE_DOMAIN': {
    return {
      ...state,
      favoriteDomains: updateDomainWithNewMessage(state.favoriteDomains, action.payload.entryId, action.payload.message),
    }
  }
  
  case 'SET_OFFERS_ON_FAVORITE_DOMAIN': {
    return {
      ...state,
      favoriteDomains: updateDomainWithNewOffers(state.favoriteDomains, action.payload.entryId, action.payload.offer),
    }
  }
  
  default:
    return state;
  }
}
  