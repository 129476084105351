import React from 'react';
import { Col } from 'react-bootstrap';
import cx from 'classnames';

// SCSS
import styles from './Notify.module.scss';

interface Notify {
  title: string;
  time: string;
  read?: boolean;
}

const Notify = (props: Notify) => {
  const wrapperClass = cx({
    [styles.read] : props.read,
  });

  function createMarkup(title: string) {
    return { __html: title };
  }

  return (
    <Col className={`${styles.notification} ${wrapperClass}`} xl={12} lg={12} md={12} sm={12}>
      <div className={styles.notifTitle}>
        <div dangerouslySetInnerHTML={createMarkup(props.title)} />
      </div>
      <div className={styles.timeAgo}>
        {props.time}
      </div>
    </Col>
  )
}

export default React.memo(Notify);
