import React, { useState } from 'react';
import moment from 'moment';
import { Col, InputGroup, FormControl, Button } from 'react-bootstrap';

interface Message {
  body: string;
  createdAt: string;
  sender: string;
  photo?: string;
}

interface Props {
  entryId: string;
  entryOwnerId: string;
  messages: Array<Message> | null;
  sendMessage?: (entryId: string, entryOwnerId: string, message: string) => void;
  loggedInUserId?: string;
  message?: string;
  profilePicURL?: string;
  userName: string;
  createdAt?: string;
  isVerified: boolean;
}

const SendMessage: React.FunctionComponent<Props> = ({
  entryId,
  entryOwnerId,
  messages,
  sendMessage,
  loggedInUserId,
  message: initialMessage,
  profilePicURL,
  userName,
  createdAt,
  isVerified,
}: Props) => {

  const [message, setMessage] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }

  const handleMessageSend = () => {
    if (sendMessage) {
      sendMessage(entryId, entryOwnerId, message.trim());
      setMessage('');
    }
  }

  return (
    <Col className="buy-request-messages" xl={12} lg={12} md={12} sm={12}>
      {((messages && messages.length) || (entryOwnerId !== loggedInUserId)) && <Col className="user-send-msg p-0" xl={12} lg={12} md={12} sm={12}>
        <img className="avatar d-xl-inline-block d-lg-inline-block d-md-none d-sm-none d-none" src="https://placehold.co/48x48" alt="Avatar" />
        <InputGroup>
          <FormControl
            className="user-msg-field"
            name="message"
            placeholder="Write a message"
            aria-label="Write a message"
            value={message}
            onChange={handleChange}
          />
          
          {/* {loading ? (
            <div className="loader">
              <Spinner animation="border" variant="primary" role="status" />
            </div>
          ) : ( */}
          <InputGroup.Append>
            <Button
              variant="primary"
              className="bm-btn sm"
              onClick={handleMessageSend}
              disabled={!(message.trim().length > 0) || !isVerified}
            >
              Send Message
            </Button>
          </InputGroup.Append>

          {/* )} */}
            
        </InputGroup>
      </Col>}
      {(messages || initialMessage) && (
        <div className="messages">
          {messages && messages.map((message, index) => (
            <Col key={index} className="user-send-msg p-0" xl={12} lg={12} md={12} sm={12}>
              <div className="sender-block">
                <img className="avatar" src={message.photo ? message.photo : 'https://placehold.co/48x48'} alt="userName" />
                <div className="message-sender">{message.sender}</div>
                <div className="message-time">{moment.utc(message.createdAt).fromNow()}</div>
              </div>
              <div className="message">
                {message.body}
              </div>
            </Col>
          ))}
          {initialMessage &&
            <Col className="user-send-msg p-0" xl={12} lg={12} md={12} sm={12}>
              <div className="sender-block">
                <img className="avatar" src={profilePicURL ? profilePicURL : 'https://placehold.co/48x48'} alt="userName" />
                <div className="message-sender">{userName}</div>
                <div className="message-time">{moment(createdAt).fromNow()}</div>
              </div>
              <div className="message">
                {initialMessage}
              </div>
            </Col>
          }
        </div>
      )}
    </Col>
  )
}

export default React.memo(SendMessage);
