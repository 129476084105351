import React, { useState, useContext, useEffect, useCallback } from 'react';

import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import ReactGA from 'react-ga4';

import { Container, Row, Col, Button } from 'react-bootstrap';

// Context
import GlobalContext from 'contexts/Global.context';

// Utils
import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

// Page Components
import TextArea from '../../../../general/CustomTextArea/CustomTextArea';
import MessageOriginal from './components/MessageOriginal/MessageOriginal';
import MessageReplyAuthor from './components/MessageReplyAuthor/MessageReplyAuthor';
import MessageReplyInquirer from './components/MessageReplyInquirer/MessageReplyInquirer';

// SCSS
import styles from './MessageInner.module.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Loader from 'general/Loader/Loader';

interface Communicator {
  activePlan: string;
  billingAddress: string;
  billingCountry: string;
  billingName: string;
  billingState: string;
  billingZIP: string;
  createdAt: string;
  email: string;
  escrowId: number;
  firstName: string;
  id: string;
  isAdmin: boolean;
  isDeleted: boolean;
  isVerified: boolean;
  lastName: string;
  paymentId: string;
  paymentMethod: string;
  profilePicURL: string;
  updatedAt: string;
  userName: string;
}

interface Message {
  body: string;
  buyRequestEntryId: string;
  createdAt: string;
  id: string;
  isDeleted: boolean;
  parentId: string | null;
  profilePicURL: string;
  read: boolean;
  receiver: Communicator
  sender: Communicator;
  total: number;
  escrowId?: string;
  submissionId?: string;
  threadTitle?: string;
  updatedAt: string;
  userName: string;
}

const { apiConstants: { API_URL } } = Constants;
const SEND_MESSAGE_URL = `${API_URL}/messages`;

const READ_THREAD_URL = `${API_URL}/messages/read`;

const MessageInner: React.FunctionComponent = () => {

  const { partnerId } = useParams();

  const { search } = window.location;

  const MESSAGES_URL = `${API_URL}/messages/conversation?partnerId=${partnerId}`;

  const globalContext = useContext(GlobalContext);

  const { userDetails: { id: loggedInUserId } } = globalContext;

  const [messages, setMessages] = useState<Array<Message> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [writeMessage, setWriteMessage] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === 'writeMessage') {
      setWriteMessage(value);
    }
  }

  const fetchMessages = useCallback(async (init: boolean = false) => {
    setLoading(true);
    const { response } = await apiRequest(`${MESSAGES_URL}&${search.replace('?', '&')}`, 'GET');
    if (response) {
      setMessages(response);
      if (response && response.length) {
        const markReadBody = {
          partnerId
        };
        const lastMessage = response[response.length - 1];
        if (lastMessage.submissionId) {
          markReadBody['submissionId'] = lastMessage.submissionId;
        }
        if (lastMessage.escrowId) {
          markReadBody['escrowId'] = lastMessage.escrowId;
        }
        if (init) {
          await apiRequest(READ_THREAD_URL, 'PUT', JSON.stringify(markReadBody));
        }
      }
    }
    setLoading(false);
  }, [MESSAGES_URL, partnerId, search]);

  const handleMessageSend = async () => {
    if (messages && messages.length) {
      const lastMessage = messages[messages.length - 1];
      const sendMessageBody = {
        receiver: loggedInUserId === lastMessage.receiver.id ? lastMessage.sender.id : lastMessage.receiver.id,
        // buyRequestEntry: entryId,
        body: writeMessage,
      }
      if (lastMessage.submissionId) {
        sendMessageBody['submissionId'] = lastMessage.submissionId;
      }
      if (lastMessage.escrowId) {
        sendMessageBody['escrowId'] = lastMessage.escrowId;
      }
      if (writeMessage) {
        const { response } = await apiRequest(SEND_MESSAGE_URL, 'POST', JSON.stringify({
          ...sendMessageBody,
        }));
        if (response) {
          fetchMessages();
        }
      }
      setWriteMessage('');
    }
  }

  useEffect(() => {
    fetchMessages(true);
  }, [fetchMessages])

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Messages",
    });
  }, [])

  return (
    <Container className={styles.dashWrapper} fluid>
      <Container className={styles.dashInner}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Row className="m-0">
              <Col className={styles.innerMessageContainer}>
                <div className={styles.innerMessageWrapper}>

                  <Row>
                    <Col className={styles.innerMessageHeader}>
                      <div className={`${styles.backToAll}`}>
                        <Link to="/dashboard/messages">🠔 Back to All Messages</Link>
                      </div>
                      <a href="/#" className={`btn sm ${styles.reportUser}`}>
                        Report This User
                      </a>
                    </Col>
                  </Row>
                  {loading && <Loader />}
                  {messages && messages.length && <h4>{messages[0].threadTitle}</h4>}
                  {messages && messages.length && (<MessageOriginal
                    message={messages[0].body}
                    timeAgo={moment.utc(messages[0].createdAt).fromNow()}
                    userName={messages[0].sender.userName}
                    avatar={messages[0].sender.profilePicURL || 'https://placehold.co/55x55'}
                  />)}

                  {!loading && messages && messages.slice(1).map(m => {
                    if (loggedInUserId === m.sender.id) {
                      return (
                        <MessageReplyAuthor
                          key={m.id}
                          message={m.body}
                          timeAgo={moment.utc(m.createdAt).fromNow()}
                          userName={m.sender.userName}
                          avatar={m.sender.profilePicURL || 'https://placehold.co/55x55'}
                        />
                      )
                    } else {
                      return (
                        <MessageReplyInquirer
                          message={m.body}
                          key={m.id}
                          timeAgo={moment.utc(m.createdAt).fromNow()}
                          userName={m.sender.userName}
                          avatar={m.sender.profilePicURL || 'https://placehold.co/55x55'}
                        />
                      )
                    }
                  })}

                  {/* <MessageReplyAuthor
                    message="That could be awesome. How does $250 sound?"
                    timeAgo="19 mins ago"
                    userName="James22"
                    avatar="https://placehold.co/55x55"
                  />

                  <MessageReplyInquirer
                    message="Yeah that sounds fine. Let's lorem ipsum where the lorem is ipsum."
                    timeAgo="15 mins ago"
                    userName="Matt222"
                    avatar="https://placehold.co/55x55"
                  />

                  <MessageReplyInquirer
                    message="Sorry, I'll have to delay the meeting. Will tomorrow do?"
                    timeAgo="10 mins ago"
                    userName="Matt222"
                    avatar="https://placehold.co/55x55"
                  />

                  <MessageReplyAuthor
                    message="Yeah that's completely fine with me, let me know what time works best."
                    timeAgo="2 mins ago"
                    userName="James22"
                    avatar="https://placehold.co/55x55"
                  /> */}

                  <Row className={styles.writeMessage}>
                    <Col className={`p-0 ${styles.textArea}`} xl={12} lg={12} md={12} sm={12}>
                      <TextArea
                        name="writeMessage"
                        placeholder="Write a message"
                        value={writeMessage}
                        onChange={handleInputChange}
                      />

                      <a className={styles.messageAttach} href="/#"><FontAwesomeIcon icon={faPaperclip} /></a>
                    </Col>
                    <Button 
                      className={`bm-btn ${styles.sendMessage}`}
                      disabled={!(writeMessage.trim().length > 0)}
                      onClick={handleMessageSend}
                    >
                        Send
                    </Button>
                  </Row>

                </div>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(MessageInner);
