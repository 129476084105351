import React from 'react';
import { Row, Col } from 'react-bootstrap';

// SCSS
import styles from './MessageReplyInquirer.module.scss';

interface MessageReplyInquirer {
  avatar: string;
  message: string;
  timeAgo: string;
  userName: string;
}

const MessageReplyInquirer = (props: MessageReplyInquirer) => {
  return (
    <Row className={`${styles.replyMessage} ${styles.replyInquirer}`}>
      <Col className="col-xl-1 col-lg-1 d-xl-block d-lg-block d-md-none d-sm-none d-none"></Col>
      <Col className={`col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 ${styles.messageReply}`}>
        <div className={styles.messageTitle}>
          {props.message}
        </div>
        <div className={styles.messageAgo}>
          {props.timeAgo}
        </div>
        <div className={styles.messageFrom}>
          {props.userName}
        </div>
      </Col>
      <Col className="p-0 col-xl-1 col-lg-1 col-2">
        <div className={`d-xl-block d-lg-block d-md-block d-sm-none d-none ${styles.messageAvatar}`}>
          <img src={props.avatar} alt={props.userName} />
        </div>
      </Col>
    </Row>
  )
}

export default React.memo(MessageReplyInquirer);
