import React from 'react';

import { Spinner } from 'react-bootstrap';

import styles from './Loader.module.scss';

interface Props {
  style?: any;
}

const Dashboard: React.FunctionComponent<Props> = ({ style }: Props) => {
  return (
    <div className={styles.loader} style={style}>
      <Spinner animation="border" variant="primary" role="status" />
    </div>
  );
};

export default React.memo(Dashboard);
