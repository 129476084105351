import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Component
import DomainCard from '../DomainCard/DomainCard';
import Loader from 'general/Loader/Loader';

// Constants
import Constants from 'Constants';

import { apiRequest } from 'utils/ApiRequest';

// SCSS
import styles from './RecentlySold.module.scss';

interface Domain {
  id: string;
  name: string;
  price: number;
  category: string;
  keywords: string;
  images: Array<string>;
  extension: string;
  total: string;
  verificationCode: string;
  isDeleted: string;
  createdAt: string;
  updatedAt: string;
  isVerified: string;
}

const { apiConstants: { API_URL } } = Constants;

const FETCH_DOMAINS_URL = `${API_URL}/premium-domains/filter`;

const RecentlySold: React.FunctionComponent = () => {

  const [recentlySoldDomains, setRecentlySoldDomains] = useState<Array<Domain> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchRecentlySoldDomains = async () => {
      setLoading(true);
      const { response, error } = await apiRequest(FETCH_DOMAINS_URL, 'POST', JSON.stringify({
        sortBy: 'isRecentlySold',
        sortOrder: 'DESC',
        filters: {
          soldAt: true,
        },
        limit: 4,
      }));
      if (response) {
        setRecentlySoldDomains(response);
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    }

    fetchRecentlySoldDomains();
  }, []);

  return (
    <Container id={styles.recentlySold} fluid>
      <Container>

        <Row className={styles.titleRegion}>
          <Col>
            <div className={styles.title}>Recently Sold</div>
          </Col>
        </Row>

        <Row>
          {loading && <Loader />}
          {!loading && !recentlySoldDomains && error && (<div>
            {error}
          </div>)}
          {!loading &&
          !error &&
          recentlySoldDomains &&
          recentlySoldDomains.map(domain => (
            <DomainCard
              key={domain.id}
              id={domain.id}
              preview={domain.images && domain.images.length ? domain.images[0] : 'https://placehold.co/270x200'}
              title={domain.name}
              price={domain.price}
              sold
            />
          ))}
        </Row>

      </Container>
    </Container>
  );
};

export default React.memo(RecentlySold);
