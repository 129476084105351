import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';

// SCSS
import styles from './ImageUpload.module.scss';

interface FileInfo {
  name: string;
  type: string;
}

interface Props {
  imageInfo: FileInfo;
  onImageChange: (file: File, fileInfo: FileInfo) => void;
}

const ImageUploader: React.FC<Props> = ({ imageInfo, onImageChange }: Props) => {

  const inputFileRef = useRef<any>(null);

  const handleOnClick = () => {
    if (inputFileRef) {
      inputFileRef.current.click();
    }
  }

  const handleFileChange = (e: any) => {
    const { files } = e.target;
    const { name, type } = files[0];
    const fileInfo = {
      name,
      type,
    };
    onImageChange(files[0], fileInfo);
  }

  return (
    <div className={styles.imageUpload}>
      <Button
        className="btn bm-btn"
        type="button"
        variant="outline-primary"
        onClick={handleOnClick}
      >
        Upload Profile Picture
      </Button>
      {imageInfo.name && (
        <span className={styles.imagePreviewBeforeUpload}>
          {imageInfo.name}
        </span>)}
      <input
        id={styles.imageUpload}
        type="file"
        accept="image/*"
        ref={inputFileRef}
        onChange={handleFileChange}
      />
    </div>
  )
}

export default ImageUploader
