import  React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
// Context
import GlobalContext from 'contexts/Global.context';
// SCSS
import styles from './NamingExamples.module.scss';

const BUY_REQUEST_EXAMPLES = [
  { title: 'Apparelx.com', url: 'https://apparelx.com' },
  { title: 'Conditionerd.com', url: 'https://www.conditionerd.com/' },
  { title: 'River.com', url: 'https://river.com' },
  { title: 'LanguageCrush.com', url: 'https://languagecrush.com/' },
  { title: 'Greatfully.com', url: 'https://greatfully.com' },
  { title: 'CrmVet.com', url: 'https://crmvet.com/' },
  { title: 'Landilla.com', url: 'https://landilla.com' },
  { title: 'AcademySync.com', url: 'https://academysync.com/' },
  { title: 'Dash.fi', url: 'https://www.dash.fi/' },
  { title: 'Essentialtopia.com', url: 'https://essentialtopia.com' },
]

const NamingExamples: React.FunctionComponent = () => {

  const globalContext = useContext(GlobalContext);

  const { userDetails, isLoggedIn } = globalContext;

  const { isVerified } = userDetails;

  return (
    <Container className={styles.neRegion} fluid>

      <Row>
        <Col className={styles.neRegionTitle} xl={12} lg={12} md={12} sm={12}>
          Buy Request Examples
        </Col>
      </Row>

      <Container className={styles.neService}>
        <Row>
          {BUY_REQUEST_EXAMPLES.map((example, index) => {
            if (index === 4) {
              return (
                <>
                  <Col key={example.title} className={styles.buyRequestExample} xl={true} lg={true} md={6} sm={12}>
                    <a href={example.url} target="_blank" rel="noopener noreferrer">{example.title}</a>
                  </Col>
                  <Col className="p-2 d-xl-block d-lg-block d-md-none d-sm-none d-none" xl={12} lg={12} md={12} sm={12}></Col>
                </>
              )
            } return (
              <Col key={example.title} className={styles.buyRequestExample} xl={true} lg={true} md={6} sm={12}>
                <a href={example.url} target="_blank" rel="noopener noreferrer">{example.title}</a>
              </Col>
            )
          })}
          <Col className="text-center" xl={12} lg={12} md={12} sm={12}>
            <Link to="/questionnaire">
              <Button disabled={isLoggedIn && !isVerified} className="bm-btn px-5 py-3 mt-4" variant="primary">Launch</Button>
            </Link>
          </Col>
        </Row>
      </Container>

    </Container>
  );
};

export default React.memo(NamingExamples);
