import React, { useState } from 'react';

import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

import Loader from 'general/Loader/Loader';
import Radio from 'general/CustomRadio/CustomRadio';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';


interface Card {
  address_city: string | null;
  address_country: string | null;
  address_line1: string | null;
  address_line1_check: string | null;
  address_line2: string | null;
  address_state: string | null;
  address_zip: string | null;
  address_zip_check: string | null;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: string | null;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  id: string;
  last4: string;
  metadata: object;
  name: string;
  object: string;
  tokenization_method: string | null;
}

interface Props {
  card: Card;
  selectedCard: string;
  setSelectedCard: (id: string) => void;
  cardSelectedForPayment: (id: string) => void;
  fetchUserCards: () => void;
}

const { apiConstants: { API_URL } } = Constants;
const GET_USERS_CARDS_URL = `${API_URL}/cards`;

const PaymentMethod: React.FunctionComponent<Props> = ({
  card,
  selectedCard,
  setSelectedCard,
  cardSelectedForPayment,
  fetchUserCards,
}: Props) => {

  const [deleteCardLoader, setDeleteCardLoader] = useState<boolean>(false);

  const [showDeleteCardPrompt, setShowDeleteCardPrompt] = useState<boolean>(false);
  const [isCardDeleted, setIsCardDeleted] = useState<boolean>(false);
  
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    setSelectedCard(id);
    cardSelectedForPayment(id);
  }

  const deleteCard = async (cardId: string) => {
    setDeleteCardLoader(true);
    const { response } = await apiRequest(`${GET_USERS_CARDS_URL}/${cardId}`, 'DELETE');
    if (response && response.deleted) {
      setIsCardDeleted(true);
    }
    setDeleteCardLoader(false);
  }

  const handleClose = () => {
    setIsCardDeleted(false);
    setShowDeleteCardPrompt(false);
    fetchUserCards();
  }

  return (
    <Container>
      <Row key={card.id} className="align-items-center">
        <Col xl={6} lg={6} md={6} sm={6}>
          <Radio
            id={card.id}
            name={card.id}
            label={`${card.brand} XXXX-XXXX-XXXX-${card.last4}`}
            isChecked={card.id === selectedCard}
            changeCallback={handleRadioChange}
          />
        </Col>

        <Col xl={2} lg={2} md={6} sm={6}>
          <Button
            className="bm-btn px-1 py-1 d-xl-block d-lg-block d-md-none sm d-sm-none d-none"
            variant="outline-primary"
            onClick={() => setShowDeleteCardPrompt(true)}
          >
            Delete
          </Button>
        </Col>
      </Row>
      <div className="delete-card-modal">
        <Modal
          size="sm"
          show={showDeleteCardPrompt}
          onHide={isCardDeleted ? handleClose : () => setShowDeleteCardPrompt(false)}
          // dialogClassName={styles.modal90}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteCardLoader && <Loader />}
            {!isCardDeleted && !deleteCardLoader && (
              <div className="delete-card">
                Are you sure you want to delete this credit card from your account?
              </div>
            )} 
            {isCardDeleted && !deleteCardLoader &&
            (
              <div className="alert alert-success">
                  Your payment method is deleted successfully.
              </div>
            )}
            
          </Modal.Body>
          <Modal.Footer>
            {!isCardDeleted && <Button variant="primary" onClick={() => deleteCard(card.id)}>
              { deleteCardLoader ? 'Deleting...' : 'Delete' }
            </Button>}
            <Button variant="secondary" onClick={isCardDeleted ? handleClose : () => setShowDeleteCardPrompt(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
    
  )
}

export default React.memo(PaymentMethod);
