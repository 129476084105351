import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Page Components
import Header from '../SettingsHeader/Header';
import FormInput from 'general/FormInput/FormInput';
import SubmitButton from 'general/SubmitButton/SubmitButton';

// Utils
import { apiRequest } from 'utils/ApiRequest';

import styles from './ChangePassword.module.scss';

interface Props {
  password: string;
  apiURL: string;
  setNewToken: (token: string) => void;
}

interface Passwords {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface APIResponse {
  token: string;
  id: string;
}

const ChangePassword: React.FC<Props> = ({ apiURL, password, setNewToken }: Props) => {
  const [passwords, setPasswords] = useState<Passwords>({
    oldPassword: password,
    newPassword: '',
    confirmPassword: '',
  })

  const [errors, setErrors] = useState<{ newPassword: string, confirmPassword: string }>({
    newPassword: '',
    confirmPassword: '',
  })

  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const [updateSuccessfull, setUpdateSuccessfull] = useState<boolean>(false);

  const apiConfig = {
    method: 'PUT',
    body: JSON.stringify({ password: passwords.newPassword, oldPassword: passwords.oldPassword }),
  }

  const updatePassword = async () => {
    setLoading(true);
    setUpdateSuccessfull(false);
    setApiError('');
    const { response, error } = await apiRequest(apiURL, apiConfig.method, apiConfig.body);
    if (response && response.token) {
      const { token } = response;
      if (token !== localStorage.getItem('token')) {
        setNewToken(token);
      }
      setUpdateSuccessfull(true);
    } else if (error) {
      setApiError(error)
    }
    setLoading(false);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'newPassword') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: 'Please enter password',
        })
      } else {
        if (value.length < 8) {
          setErrors({
            ...errors,
            [name]: 'Please enter minimum 8 characters',
          })
        } else {
          setErrors({
            ...errors,
            [name]: '',
          })
        }
      }
    }

    if (name === 'confirmPassword') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: 'Please enter confirm password'
        })
      } else {
        if (value !== passwords.newPassword) {
          setErrors({
            ...errors,
            [name]: 'Passwords do not match'
          })
        } else {
          setErrors({
            ...errors,
            [name]: ''
          })
        }
      }
    }
    setPasswords({
      ...passwords,
      [name]: value,
    })
  }

  const validateData = () => {
    const { newPassword, confirmPassword } = passwords;
    if ((newPassword === confirmPassword) && newPassword.length > 7) {
      return true;
    }

    if (!newPassword && !confirmPassword) {
      setErrors({
        ...errors,
        newPassword: 'Please enter password',
        confirmPassword: 'Please confirm new password',
      })
    }

    if (password && confirmPassword && password !== confirmPassword) {
      setErrors({
        ...errors,
        confirmPassword: 'Passwords do not match',
      })
    }

    return false;
  }

  const handleSubmit = () => {
    if (validateData()){
      updatePassword();
    }
  }

  return(
    <Container className={styles.changePassword}>
      <Row>

        <Col xl={12} lg={12} md={12} sm={12}>
          <Col xl={12} lg={12} md={12} sm={12}>
            <form>
              <Header title="CHANGE PASSWORD" />
              <div className={styles.prefLabel}>Old Password</div>
              <FormInput
                name="oldPassword"
                type="password"
                placeholder="Old Password"
                label="account-old-password"
                value={passwords.oldPassword}
                onChange={handleChange}
                large
              />

              <div className={styles.prefLabel}>New Password</div>
              <FormInput
                name="newPassword"
                type="password"
                placeholder="New Password"
                label="account-new-password"
                value={passwords.newPassword}
                onChange={handleChange}
                large
              />
              {errors.newPassword && <div className={styles.error}>{errors.newPassword}</div>}

              <div className={styles.prefLabel}>Confirm Password</div>
              <FormInput
                name="confirmPassword"
                type="password"
                placeholder="Confirm New Password"
                label="account-confirm-new-password"
                value={passwords.confirmPassword}
                onChange={handleChange}
                large
              />
              {errors.confirmPassword && <div className={`${styles.error} mb-2`}>{errors.confirmPassword}</div>}

              <SubmitButton isLoading={loading} onSubmit={handleSubmit} />
              {apiError && <div className={`${styles.error} ${styles.apiError}`}>{apiError}</div>}
              {updateSuccessfull && <div className={`${styles.updatedSuccessfully}`}>Information Updated Successfully</div>}
            </form>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

export default ChangePassword;
