import  React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// SVGs
import TopWave from 'assets/svgs/Pricing/HowItWorks/top-wave.svg';
import Blob from 'assets/svgs/Pricing/HowItWorks/blob.svg';
import IMG1 from 'assets/svgs/Pricing/HowItWorks/icon1.svg';
import IMG2 from 'assets/svgs/Pricing/HowItWorks/icon2.svg';
import IMG3 from 'assets/svgs/Pricing/HowItWorks/icon3.svg';

// SCSS
import styles from './HowItWorks.module.scss';

const HowItWorks: React.FunctionComponent = () => {
  return (
    <div className={styles.hiwRegion}>

      <SVG
        baseURL="/"
        cacheRequests={true}
        className={styles.TopWave}
        description=""
        loader={<span>Loading...</span>}
        src={TopWave}
        title=""
      />

      <Container className={styles.hiwBanner} fluid>
        <Container>

          <Row>
            <Col>
              <div className={styles.hiwRegionTitle}>How It Works</div>
            </Col>
          </Row>

          <Row>
            <Col className={styles.hiwBlock} xl={4} lg={4} md={12} sm={12}>
              <div className={styles.hiwIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.hiwBlob}
                  description=""
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="choose-package"
                  description="Provide Naming Criteria"
                  loader={<span>Loading...</span>}
                  src={IMG2}
                  title="Provide Naming Criteria"
                />
              </div>
              <div className={styles.hiwTitle}>Provide Naming Criteria</div>
              <div className={styles.hiwDescription}>Provide the requirements for your new brand. Discuss your budget, preferred keywords, target customers, brands you admire, and more. </div>
            </Col>
            <Col className={styles.hiwBlock} xl={4} lg={4} md={12} sm={12}>
              <div className={styles.hiwIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.hiwBlob}
                  description=""
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="naming-criteria"
                  description="Rank & Review Names"
                  loader={<span>Loading...</span>}
                  src={IMG3}
                  title="Rank & Review Names"
                />
              </div>
              <div className={styles.hiwTitle}>Rank & Review Names</div>
              <div className={styles.hiwDescription}>Naming experts will send you names matching your criteria, while you collaborate with detailed rankings & feedback to aid the search.</div>
            </Col>
            <Col className={styles.hiwBlock} xl={4} lg={4} md={12} sm={12}>
              <div className={styles.hiwIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.hiwBlob}
                  description=""
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="rank-review-names"
                  description="Acquire Your Brand"
                  loader={<span>Loading...</span>}
                  src={IMG1}
                  title="Acquire Your Brand"
                />
              </div>
              <div className={styles.hiwTitle}>Acquire Your Brand</div>
              <div className={styles.hiwDescription}>Find a name you love and submit an offer. If accepted, safely fund escrow and receive the secure transfer of the domain to your account.</div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default React.memo(HowItWorks);
