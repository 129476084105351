import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// SCSS
import styles from './WhyUseBrandmo.module.scss';

// SVGs
import Blob from 'assets/svgs/Home/WhyUseBrandmo/blob.svg';
import WhyIcon1 from 'assets/svgs/Home/WhyUseBrandmo/business-team-ideas.svg';
import WhyIcon2 from 'assets/svgs/Home/WhyUseBrandmo/business-open-markets.svg';
import WhyIcon3 from 'assets/svgs/Home/WhyUseBrandmo/business-growth.svg';

const WhyUseBrandmo: React.FunctionComponent = () => {
  return (
    <Container id={styles.whyUseBrandmo}>
      <Container>

        <Row>
          <Col>
            <div className={styles.title}>Why should I use Brandmo?</div>
          </Col>
        </Row>

        <Row>

          <Col className={styles.whyBlock} xl={4} lg={4} md={12} sm={12}>
            <div className={styles.whyBlockWrap}>
              <div className={styles.whyBlockIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  description="BG Img"
                  className={styles.blockBlob}
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  description="BG Img"
                  loader={<span>Loading...</span>}
                  src={WhyIcon1}
                  title=""
                />
              </div>
              <div className={styles.whyBlockTitle}>Crowd Sourced Branding</div>
              <div className={styles.whyBlockDescription}>Put your brand requirements out to the crowd and negotiate to acquire premium domain names matching your criteria. Our community of branding professionals includes domain name owners, brokers, portfolio holders, and more.</div>
            </div>
          </Col>

          <Col className={styles.whyBlock} xl={4} lg={4} md={12} sm={12}>
            <div className={styles.whyBlockWrap}>
              <div className={styles.whyBlockIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  description="BG Img"
                  className={styles.blockBlob}
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  description="BG Img"
                  loader={<span>Loading...</span>}
                  src={WhyIcon2}
                  title=""
                />
              </div>
              <div className={styles.whyBlockTitle}>Curated Domain Marketplace</div>
              <div className={styles.whyBlockDescription}>Great brands start with great names. Finding the right brand name with a matching domain has never been easier. Search our hand-picked selection of unique, catchy and creative domain names that will make your brand stand out.</div>
            </div>
          </Col>

          <Col className={styles.whyBlock} xl={4} lg={4} md={12} sm={12}>
            <div className={styles.whyBlockWrap}>
              <div className={styles.whyBlockIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  description="BG Img"
                  className={styles.blockBlob}
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  description="BG Img"
                  loader={<span>Loading...</span>}
                  src={WhyIcon3}
                  title=""
                />
              </div>
              <div className={styles.whyBlockTitle}>Custom Brand Solutions</div>
              <div className={styles.whyBlockDescription}>Brandmo offers a wide range of custom branding solutions including brand acquisitions, product naming, tagline creation, logo design, brand identity development, and more. Explore your project during a free consultation.</div>
            </div>
          </Col>

        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(WhyUseBrandmo);
