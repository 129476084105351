import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

import numeral from 'numeral';
import GlobalContext from 'contexts/Global.context';

import { Container, Row, Col, Button } from 'react-bootstrap';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

import './PurchaseStatus.scss';
import Loader from 'general/Loader/Loader';

const { apiConstants: { API_URL } } = Constants;
// const ESCROW_URL = 'https://my.escrow.com/transaction.asp';

const PurchaseStatus = () => {

  const { transactionId } = useParams();
  const { userDetails: { id: loggedInUserId } } = useContext(GlobalContext);

  const fetchTransactionDetailsURL = `${API_URL}/transactions?transactionId=${transactionId}`;

  const [loading, setLoading] = useState<boolean>(false);
  const [transactionDetails, setTransactionDetails] = useState<any | null>(null);
  const [transactionFetchError, setTransactionFetchError] = useState<string>('');

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      setLoading(true);
      const { response, error } = await apiRequest(fetchTransactionDetailsURL, 'GET');
      if (response) {
        const { transactions } = response;
        setTransactionDetails(transactions[0]);
      } else if (error) {
        setTransactionFetchError(error);
      }
      setLoading(false);
    }

    fetchTransactionDetails();
  }, [fetchTransactionDetailsURL])

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Purchase Status",
    });
  }, [])

  const sendMessageBtnLabel = () => {
    let label = '';
    if (transactionDetails.type === 'escrow') {
      label = `Message ${loggedInUserId === transactionDetails.seller.id 
        ? transactionDetails.buyer.userName
        : transactionDetails.seller.userName }`
    } else if (transactionDetails.type === 'stripe') {
      label = 'Message Brandmo'
    }

    return label;
  }

  return (
    <Container id="purchase-status">
      <Container id="header">
        <Row>

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="title">Purchase Status</div>
          </Col>

        </Row>
      </Container>

      {loading && <Loader />}

      {!loading && transactionDetails ? (
        <Container id="purchase-details" className="ml-3">
          <Row>
            <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
              DOMAIN
            </Col>
            <Col  xl={12} lg={12} md={12} sm={12}>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className="questionAsked">{transactionDetails.domain}</div>
                </Col>

                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className="questionAsked text-right">{numeral(transactionDetails.amount).format('$0,0')}</div>
                </Col>
              </Row>
            </Col>
            <Col>
              Seller: 
              <span className="seller-name">
                {` ${transactionDetails.seller.userName}`}
              </span>
            </Col>
          </Row>

          <hr className="my-5"/>

          {transactionDetails.type === 'escrow' && <Row>
            <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
            ESCROW
            </Col>

            <Col className="mt-2" xl={12} lg={12} md={12} sm={12}>
              <Row>
                <Col className="sub-text" xl={5} lg={5} md={12} sm={12}>
                  Select the link to the right to access
                  your personal escrow link.
                </Col>

                <Col xl={2} lg={2}>
                </Col>

                <Col className="text-right" xl={5} lg={5} md={12} sm={12}>
                  <a href={loggedInUserId === transactionDetails.buyer.id ? transactionDetails.buyerLink : transactionDetails.sellerLink} target='_blank' rel="noopener noreferrer">
                    <Button size="lg" className="iniiate-escrow-btn">
                      Initiate Escrow via Escrow.com
                    </Button>
                  </a>
                  
                </Col>
              </Row>
            </Col>

          </Row>}

          {transactionDetails.type === 'escrow' && <hr className="my-5"/>}


          {transactionDetails.type === 'escrow' && <Row>
            <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
              STATUS
            </Col>

            <Col className="mt-2" xl={12} lg={12} md={12} sm={12}>
              <Row>
                <Col className="status-text" xl={5} lg={5} md={12} sm={12}>
                  {transactionDetails.status}
                </Col>

                <Col xl={2} lg={2}>
                </Col>

                <Col className="text-right status-update-text" xl={5} lg={5} md={12} sm={12}>
                  Status Update from Escrow.com
                </Col>
              </Row>
            </Col>
          </Row>}

          {transactionDetails.type === 'escrow' && <hr className="my-5"/>}

          <Row>
            <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
              DISCUSS
            </Col>

            <Col className="mt-2" xl={12} lg={12} md={12} sm={12}>
              <Row>
                <Col className="sub-text" xl={5} lg={5} md={12} sm={12}>
                  Continue the discussion in your message system.
                </Col>

                <Col xl={2} lg={2}>
                </Col>

                <Col className="text-right" xl={5} lg={5} md={12} sm={12}>
                  <Link to="/dashboard/messages">
                    <Button size="lg" className="iniiate-escrow-btn">
                      {sendMessageBtnLabel()}
                    </Button>
                  </Link>
                </Col>
              </Row>

            </Col>
          </Row>
        </Container>): null}

      {transactionFetchError && <div className="error">{transactionFetchError}</div>}
      

    </Container>
  )
}

export default PurchaseStatus;
