import React from 'react';

// SCSS
import styles from './ImagePreview.module.scss';

interface Props {
  imageURL?: string,
}

const ImagePreview: React.FC<Props> = ({ imageURL} : Props) => {
  if (imageURL) {
    return (
      <div className={styles.imagePreview}>
        <img src={imageURL} alt={imageURL} />
      </div>
    )
  }
  return (
    <svg className={styles.defaultImage} viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="75" cy="75" r="74.5" fill="#F1F1F1" stroke="#DFDFDF"/>
    </svg>
  )
}

export default ImagePreview
