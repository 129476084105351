import React from 'react';
import './Testimonial.scss';

interface Props {
  testimonial: string,
}

const Testimonial: React.FunctionComponent<Props> = ({ testimonial }: Props) => {
  return (
    <div className="carousel-testimonial">
      <div className="testimonial-text">
        {testimonial}
      </div>
    </div>
  )
}

export default React.memo(Testimonial);