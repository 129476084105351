import React, { useState } from 'react';
import { Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import SVG from 'react-inlinesvg'
import ReactGA from 'react-ga4';

// Constants
import Constants from 'Constants';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

// SCSS
import styles from './Subscribe.module.scss';

// SVGs
import BGImg from 'assets/svgs/Components/Subscribe/sub-bg.svg';
import TopWave from 'assets/svgs/Components/Subscribe/top-wave.svg';
import BottomWave from 'assets/svgs/Components/Subscribe/bot-wave.svg';

const { regExValidators: { EMAIL_VALIDATOR_REGEX }} = Constants;

const Subscribe: React.FunctionComponent = () => {

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setEmail(event.target.value);
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.which === 13 || event.charCode === 13)) {
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    setError('');
    setIsSubscribed(false);
    if (email) {
      if (!EMAIL_VALIDATOR_REGEX.test(email)) {
        setError('Please enter a valid email address');
      } else {
        ReactGA.event('newsletter', {
          email,
        })
        setIsSubscribed(true);
      }
    } else {
      setError('Please enter an email address');
    }
  }

  return (
    <div className={styles.subscribeRegion}>

      <SVG
        baseURL="/"
        cacheRequests={true}
        className={styles.subTopWave}
        description="Waves"
        loader={<span>Loading...</span>}
        src={TopWave}
        title=""
      />

      <Container id={styles.subscribe} fluid>

        <Container className={styles.subscribeWrapper}>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12}>
              <div className={styles.title}>Receive the latest listings, buy requests & more.</div>
              <div className={styles.subtitle}>Once per week. No spam.  Unsubscribe anytime.</div>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <div className={styles.subInputWrap}>
                <div className={styles.subLabel}>Enter Email to Subscribe</div>
                <InputGroup size="lg">
                  <FormControl
                    className={styles.subInput}
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="example@email.com"
                    disabled={isSubscribed}
                    value={email}
                    onChange={handleEmailChange}
                    onKeyPress={handleKeyPress}
                  />
                  <Button
                    variant="primary"
                    className={styles.subSubmit}
                    disabled={isSubscribed}
                    onClick={handleSubmit}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                  </Button>
                </InputGroup>
                {isSubscribed && <div className={styles.subtitle}>{'Thanks! We\'ve added you to our newsletter!'}</div>}
                {error && <div className={styles.error}>{error}</div>}
              </div>
            </Col>
          </Row>
        </Container>

        <div className={styles.bgImg}>
          <SVG
            baseURL="/"
            cacheRequests={true}
            description="BG Img"
            loader={<span>Loading...</span>}
            src={BGImg}
            title=""
          />
        </div>

      </Container>

      <SVG
        baseURL="/"
        cacheRequests={true}
        className={styles.subBotWave}
        description="Waves"
        loader={<span>Loading...</span>}
        src={BottomWave}
        title=""
      />

    </div>
  );
};

export default React.memo(Subscribe);
