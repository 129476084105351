import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { NavDropdown } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconLookup,
  IconDefinition,
  findIconDefinition
} from '@fortawesome/fontawesome-svg-core'

// import {
//   faGlobe,
//   faChartLine,
//   faMousePointer,
//   faTag,
//   faPlusSquare,
//   faThumbsUp,
//   faHourglassHalf,
//   faFileAlt
//} from '@fortawesome/free-solid-svg-icons';

interface DropdownItem {
  type: string;
  href?: string;
  label: string;
  icon?: string;
  onClick?: () => void;
}

interface Props {
  title: string;
  id: string;
  dropdownItems: Array<DropdownItem>;
}

const CustomNavDropdown: React.FunctionComponent<Props> = (props: Props) => {
  let timeout: any = 0;

  const [showDropdown, setShowDropdown] = useState(false);

  const showMenuItems = () => {
    clearTimeout(timeout);
    setShowDropdown(true);
  }

  const hideMenuItems = () => {
    timeout = setTimeout(() => setShowDropdown(false), 200);
  }

  const getIconDefinition = (icon: any): any => {
    //console.log(icon);
    const iconLookup: IconLookup = { prefix: 'fas', iconName: icon }
    const iconDefinition: IconDefinition = findIconDefinition(iconLookup);
    return iconDefinition;
  }

  return (
    <NavDropdown
      title={props.title}
      id={props.id}
      onMouseEnter={showMenuItems}
      onMouseLeave={hideMenuItems}
      show={showDropdown}
    >
      {props.dropdownItems.map((item, index) => (
        <Fragment key={index}>
          {item.type === 'item' ? (
            <NavDropdown.Item
              key={`${index}${item.label}`}
              as={Link}
              to={item.href || '/'}
              onClick={item.onClick ? item.onClick : () => setShowDropdown(false)}
            >
              {item.icon && <FontAwesomeIcon icon={getIconDefinition(item.icon)} />} {item.label}
            </NavDropdown.Item>
          ) : (
            <div>
              <div className="dropdown-title">{item.label}</div>
            </div>
          )}
        </Fragment>
      ))}
    </NavDropdown>
  );
};

export default React.memo(CustomNavDropdown);
