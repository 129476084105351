import React from 'react';
import { Button } from 'react-bootstrap';

import styles from './SubmitButton.module.scss';

interface Props {
  caption?: string;
  isLoading?: boolean;
  onSubmit: () => void;
  btnType?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
}

const SubmitButton: React.FC<Props> = ({
  caption = 'Save',
  isLoading,
  onSubmit,
  btnType = 'button',
  disabled,
}: Props) => {

  const handleSubmit = () => {
    onSubmit();
  }
  return(
    <div className={styles.submitBtnWrap}>

      <Button
        className={`bm-btn ${styles.submitButton}`}
        variant="primary"
        type={btnType || 'button'}
        onClick={!isLoading ? handleSubmit : () => {}}
        disabled={disabled}
      >

        {isLoading ? 'Loading...' : caption }

      </Button>
    </div>
  )
}

export default SubmitButton;
