import React, { useState } from 'react';
import numeral from 'numeral';
import { Container, Row, Col, Button } from 'react-bootstrap';

import './ContestBrief.scss';
import moment from 'moment';

interface Creator {
  profilePicURL: string;
  username: string;
  country: string;
  requests: string;
  budget: number;
  totalAwarded: number;
  signedUp: string;
}

interface Props {
  file: string | null;
  description: string;
  creator: Creator;
  extensions: string;
}

const ContestBrief: React.FunctionComponent<Props> = ({ file, description, creator, extensions }: Props) => {

  const [showAllExtensions, setShowAllExtensions] = useState<boolean>(false);

  const renderExtensions = () => {
    const extentionsArr = extensions ? extensions.toString().split(',') : []
    return (
      <ul className="extensions">
        {!showAllExtensions && 
          extentionsArr.slice(0, 3).map((extension: string, index: number) => (
            <li key={`${extension} - ${index}`} className="extension">{extension}</li>
          ))}
        {showAllExtensions && 
          extentionsArr.map((extension: string, index: number) => (
            <li key={`${extension} - ${index}`} className="extension">{extension}</li>
          ))}
        {extentionsArr.length > 3 && !showAllExtensions && (
          <li className="more" onClick={() => setShowAllExtensions(true)}>
            {`+ ${extentionsArr.length - 3} more`}
          </li>)}
      </ul>
    )

  }

  return (
    <div id="contestBrief">
      <div className="brief-content">
        <div className="brief-description">
          <p>{description}</p>
        </div>

        <div className="brief-info">
          <Container className="p-0">
            <Row>
              <Col xl={4} lg={4} md={12} sm={12}>
                <div className="label">Premium Names</div>
                <div className="result">Yes, Max {numeral(creator.budget).format('$0,0')}</div>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12}>
                <div className="label">Acceptable Extensions</div>
                {renderExtensions()}
              </Col>
                
              {file && (<Col xl={4} lg={4} md={12} sm={12}>
                <div className="label">Request Attachments</div>
                <div className="result download-file">
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    <Button
                      className="bm-btn sm" 
                      variant="primary"
                    >
                      Download File
                    </Button>
                  </a>
                </div>
              </Col>)}
            </Row>
          </Container>
        </div>
      </div>

      <div className="creator">
        <div className="title">Meet The Creator</div>

        <Container className="creator-info">
          <Row>
            <Col xl={5} lg={5} md={12} sm={12}>
              <Row>
                <Col xl={3} lg={3} md={12} sm={12}>
                  <div className="avatar">
                    <img src={creator.profilePicURL ? creator.profilePicURL : 'https://placehold.co/55x55'} alt="Avatar" />
                  </div>
                </Col>
                <Col xl={9} lg={9} md={12} sm={12}>
                  <div className="result">{creator.username}</div>
                  <div className="last-active">Signed up {moment(creator.signedUp).fromNow()}</div>
                </Col>
              </Row>
            </Col>

            <Col xl={7} lg={7} md={12} sm={12} className="p-0">
              <Row className="m-0">

                {/* <Col xl={4} lg={4} md={12} sm={12}>
                  <div className="label">Country</div>
                  <div className="result">{creator.country}</div>
                </Col> */}
                <Col xl={4} lg={4} md={12} sm={12}>
                  <div className="label">Buy Requests</div>
                  <div className="result">{creator.requests}</div>
                </Col>
                {/* <Col xl={4} lg={4} md={12} sm={12}>
                  <div className="label">Total Awarded</div>
                  <div className="result">{`${numeral(creator.totalAwarded).format('$0,0')}`}</div>
                </Col> */}

              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default React.memo(ContestBrief);
