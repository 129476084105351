import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { BuyRequestContext } from 'contexts/BuyRequest.context';

import CustomListing from 'general/CustomListing/CustomListing';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';
import { Button } from 'react-bootstrap';
import Pagination from 'general/Pagination/Pagination';

import styles from './ViewEntries.module.scss';

interface Props {
  isLoggedIn: boolean;
  loggedInUserId: string;
  loggedInEmail: string;
  buyRequestCreatorId: string;
  fetchEntriesURL: string;
  buyRequestCreatorEmail: string;
  isVerified: boolean;
  refetchAllData: () => void;
}
const { apiConstants: { API_URL } } = Constants;

// const GIVE_A_RATING_TO_ENTRY_URL = `${API_URL}/entries/ratings`;
const SEND_MESSAGE_URL = `${API_URL}/messages`;

const ITEMS_PER_PAGE = 10;

const ViewEntries: React.FunctionComponent<Props> = ({
  isLoggedIn,
  loggedInUserId,
  loggedInEmail,
  buyRequestCreatorId,
  buyRequestCreatorEmail,
  fetchEntriesURL,
  isVerified,
  refetchAllData,
}: Props) => {
  const history = useHistory();

  const buyRequestContext = useContext(BuyRequestContext);

  const {
    entriesLoader: fetchListLoader,
    setEntriesLoader,
    entries,
    ratingStats,
    setEntries,
  } = useContext(BuyRequestContext);


  const { setRatingToAnEntry, sendMessageOnBuyRequest, setOffersOnEntry } = buyRequestContext;

  const [fetchListURL, setFetchListURL] = useState<string>(`${fetchEntriesURL}&limit=5`);

  const newUrl = new URL(fetchListURL);

  const [viewAll, setViewAll] = useState<boolean>(false);

  const [activePage, setActivePage] = useState<number>(1);

  const setRatingFilter = (selectedRating: number) => {
    if (newUrl.searchParams.has('ratings')) {
      newUrl.searchParams.set('ratings', selectedRating.toString());
      setFetchListURL(newUrl.href);
    } else {
      newUrl.searchParams.append('ratings', selectedRating.toString());
      setFetchListURL(newUrl.href);
    }
  }

  const searchList = (searchText: string) => {
    if (newUrl.searchParams.has('domain')) {
      newUrl.searchParams.set('domain', searchText);
      setFetchListURL(newUrl.href);
    } else {
      newUrl.searchParams.append('domain', searchText);
      setFetchListURL(newUrl.href);
    }
  }

  const resetFilters = () => {
    // setSearchText('');
    setViewAll(false);
    setFetchListURL(`${fetchEntriesURL}&limit=5`);
  }

  const fetchEntries = useCallback(async (url: string) => {
    setEntriesLoader(true);
    const { response, error } = await apiRequest(url, 'GET');
    if (response) {
      setEntries(response, '');
    } else if (error) {
      setEntries(null, error);
    }
    setEntriesLoader(false);
  }, [setEntriesLoader, setEntries])

  const viewAllSubmissions = () => {
    setViewAll(true);
    newUrl.searchParams.set('limit', String(ITEMS_PER_PAGE));
    setFetchListURL(newUrl.href);
  }

  const giveARatingToAnEntry = (buyRequestEntry: string, updatedRating: number) => {
    setRatingToAnEntry(buyRequestEntry, updatedRating, '');
    fetchEntries(fetchListURL);
  }

  const updateOffersOnEntry = (buyRequestEntry: string, offer: any) => {
    setOffersOnEntry(buyRequestEntry, offer)
  }

  const onOfferAccept = (response: any) => {
    if (response.success) {
      history.push(`/purchase-status/${response.transactionId}`);
    }
  }

  const onEntryDeleted = () => {
    refetchAllData();
    fetchEntries(fetchListURL);
  }

  const sendMessage = async (buyRequestEntry: string, entryOwnerId: string, message: string) => {
    const sendMessageBody = {
      receiver: loggedInUserId === buyRequestCreatorId ? entryOwnerId : buyRequestCreatorId,
      submissionId: buyRequestEntry,
      body: message,
    }
    if (isLoggedIn) {
      if (message && isVerified) {
        const { response } = await apiRequest(SEND_MESSAGE_URL, 'POST', JSON.stringify({
          ...sendMessageBody,
        }));
        if (response) {
          const messageBody = {
            body: response.body,
            sender: response.sender.userName,
            createdAt: new Date(),
            photo: response.sender.profilePicURL
          }
          sendMessageOnBuyRequest(buyRequestEntry, {...messageBody}, '');
          // history.push('/dashboard/messages');
        }
      }
    } else {
      history.push('/login?destination='+window.location.pathname);
    }
  }

  const setPageAndSkip = (page: number) => {
    setActivePage(page);
    newUrl.searchParams.set('skip', String((page - 1) * ITEMS_PER_PAGE));
    setFetchListURL(newUrl.href);
  }

  useEffect(() => {
    fetchEntries(fetchListURL);
  }, [fetchListURL, fetchEntries]);

  return (
    <div className={styles.entries}>
      <CustomListing
        // Ratings filter Props
        showTotalCountOnRight
        showRatingFilter
        setRatingFilter={setRatingFilter}
        // Search filter Props
        showSearchBar
        searchList={searchList}
        resetFilters={resetFilters}
        // List related props
        fetchListUrl={fetchListURL}
        fetchListLoader={fetchListLoader}
        list={entries}
        ratingStats={ratingStats}

        areActionsAllowed={isLoggedIn && loggedInUserId === buyRequestCreatorId}
        loggedInUserId={loggedInUserId}
        loggedInEmailId={loggedInEmail}
        buyRequestCreatorId={buyRequestCreatorId}
        buyRequestCreatorEmail={buyRequestCreatorEmail}
        onOfferAccept={onOfferAccept}
        updateRating={giveARatingToAnEntry}
        updateOffersOnEntry={updateOffersOnEntry}
        showSendMessageOption
        sendMessage={sendMessage}
        onEntryDeleted={onEntryDeleted}
      />
      {!fetchListLoader && !viewAll && (
        <Button
          className={`${styles.viewAllSubmissions} py-3`}
          disabled={(entries && entries.length) ? (entries[0].total < 5 || viewAll) : true}
          onClick={viewAllSubmissions}
        >
        View All Submissions
        </Button>
      )}
      {viewAll ? (<Pagination
        totalCount={entries && entries.length ? Number(entries[0]?.total) : 0}
        itemsPerPage={ITEMS_PER_PAGE}
        pageCount={entries && entries[0]?.total ? Math.ceil(parseInt(entries[0].total) / ITEMS_PER_PAGE) : 0}
        windowSize={5}
        page={activePage}
        getActivePage={setPageAndSkip}
      />) : null}
    </div>
  )
}

export default React.memo(ViewEntries);
