import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Utils
import { apiRequest } from 'utils/ApiRequest';
import { uploadFile } from 'utils/UploadFile';

// Page Components
import Header from '../SettingsHeader/Header';
import ImageUploader from './ImageUpload/ImageUpload';
import ImagePreview from './ImagePreview/ImagePreview';
import SubmitButton from 'general/SubmitButton/SubmitButton';
import FormInput from 'general/FormInput/FormInput';

// SCSS
import styles from './UserProfile.module.scss';

const DOMAIN_URL = 'brandmo.com/'

interface ImageInfo {
  name: string;
  type: string;
}

interface Props {
  apiURL: string;
  profilePictureURL: string;
  uploadProfilePicURL: string;
  userName: string;
  setNewToken: (token: string) => void;
}

const UserProfile: React.FC<Props> = ({ apiURL, profilePictureURL, uploadProfilePicURL, userName, setNewToken }: Props) => {
  const [image, setImage] = useState<string | File>('');
  const [imageInfo, setImageInfo] = useState<ImageInfo>({
    name: '',
    type: '',
  })
  const [username, setUsername] = useState<string>(userName);

  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const [usernameError, setUsernameError] = useState<string>('');

  const [updateSuccessfull, setUpdateSuccessfull] = useState<boolean>(false);

  const uploadImage = async (signedRequest: string, url: string) => {
    const { response: uploadResponse } = await uploadFile(signedRequest, 'PUT', image);
    if (uploadResponse) {
      updateProfile(url);
    }
  }

  const updateProfile = async (url: string = '') => {
    try {
      const body = username !== userName ? { userName: username, profilePicURL: url } : { profilePicURL: url };
      const { response } = await apiRequest(apiURL, 'PUT', JSON.stringify({
        ...body,
      }));
      if (response) {
        setUpdateSuccessfull(true);
        const { token } = response;
        if (token) {
          setNewToken(token);
        }
      }
    } catch (e) {
      setError(e.toString())
    }
    setLoading(false);
    setImage('');
    setImageInfo({
      name: '',
      type: '',
    })
  }

  const getSignedRequest = async () => {
    setLoading(true);
    setError('');
    setUpdateSuccessfull(false);
    const { name, type } = imageInfo;
    const fileExtension = name.substr(name.lastIndexOf('.'))
    const fileName = username + (new Date().getTime().toString()) + fileExtension
    const apiURL = `${uploadProfilePicURL}?fileName=${fileName}&fileType=${type}`
    try {
      const { response, error } = await apiRequest(apiURL, 'GET');
      if (response) {
        const { signedRequest, url } = response;
        if (signedRequest) {
          uploadImage(signedRequest, url);
        }
      } else if (error) {
        setError(error);
      }
    } catch (e) {
      setError(e.toString());
    }
  }

  const updateUserName = async () => {
    setLoading(true);
    setError('');
    setUpdateSuccessfull(false);
    try {
      const { response, error } = await apiRequest(apiURL, 'PUT', JSON.stringify({ userName: username }));
      if (response) {
        setUpdateSuccessfull(true);
        const { token } = response;
        if (token) {
          setNewToken(token);
        }
      } else if (error) {
        setError(error);
      }
    } catch (e) {
      setError(e.toString())
    }
    setLoading(false);
  }

  const handleImageChange = (uploadedImage: File, fileInfo: ImageInfo) => {
    setImage(uploadedImage);
    setImageInfo(fileInfo);
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event && event.target;
    if (!value) {
      setUsernameError('Please enter username');
    } else {
      setUsernameError('');
    }
    setUsername(value);
  }

  const validateFields = () => {
    if (!username && !image) {
      return false;
    }
    return (username || image)
  }

  const handleSubmit = () => {
    if (validateFields()) {
      if (image && username !== userName) {
        getSignedRequest();
      }
      if (username && username !== userName && !image) {
        setLoading(true);
        updateUserName()
      }
      if (image && username === userName) {
        getSignedRequest();
      }
    }
  }

  return (
    <Container className={styles.userProfile}>
      <Row className={styles.userProfileForm}>

        <Col xl={12} lg={12} md={12} sm={12}>
          <form>
            <Col xl={12} lg={12} md={12} sm={12}>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <Header title="PROFILE" />
                </Col>
                <Col xl={9} lg={9} md={9} sm={9} className="order-xl-2 order-lg-2 order-md-2 order-sm-2 order-3">
                  <div className={`mt-3 ${styles.imageUploader}`}>
                    <div className={styles.prefLabel}>Profile Picture</div>
                    <ImageUploader
                      onImageChange={handleImageChange}
                      imageInfo={imageInfo}
                    />
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} className="text-center order-xl-3 order-lg-3 order-md-3 order-sm-3 order-2">
                  <ImagePreview imageURL={profilePictureURL}/>
                </Col>
              </Row>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.changeUsername}>
                <div className={styles.prefLabel}>Change Username</div>
                <div className={styles.prefDesc}>Username should be in custom URL format</div>
                <div className={styles.domainAndUsername}>
                  <span className={styles.fixedDomain}>{DOMAIN_URL}</span>
                  <div className={styles.addedStyling}>
                    <FormInput
                      name="username"
                      placeholder="Username"
                      label="account-username"
                      value={username}
                      onChange={handleUsernameChange}
                      large
                    />
                    {usernameError && <div className={styles.error}>{usernameError}</div>}
                  </div>
                </div>
              </div>

              <SubmitButton isLoading={loading} onSubmit={handleSubmit}/>
              {error && <div className={`${styles.error} ${styles.apiError}`}>{error || 'An error occurred while updating, please try again later'}</div>}
              {updateSuccessfull && <div className={`${styles.updatedSuccessfully}`}>Information Updated Successfully</div>}
            </Col>
          </form>
        </Col>

      </Row>
    </Container>
  )
}

export default UserProfile
