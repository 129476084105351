import React from 'react';
import './Header.scss';

interface Props {
  title: string,
}

const Header: React.FC<Props> = ({ title }: Props) => {
  return (
    <div className="header">{title}</div>
  )
}

export default Header