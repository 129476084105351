import React, { useState, useContext, useEffect } from 'react';
// import jwt from 'jwt-decode';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { Link, Redirect } from 'react-router-dom';
import GlobalContext from '../../contexts/Global.context';
import constants from '../../Constants';
import { apiRequest } from 'utils/ApiRequest';
import { checkPasswordStrength } from 'utils/functions/checkPasswordStrength';
import SVG from 'react-inlinesvg';
import { FBLogin } from 'components/FacebookLogin/FacebookLogin';

// Page Components
import SubmitButton from 'general/SubmitButton/SubmitButton';
import FormInput from 'general/FormInput/FormInput';
import PasswordStrength from './components/PasswordStrength/PasswordStrength';

// Interfaces
// import { User } from 'interfaces/Interfaces';

// SCSS
import styles from './Registration.module.scss';

// SVGs
import LoginLogo from 'assets/svgs/Branding/logo-login.svg';

interface FormData {
  firstName: string,
  lastName: string,
  userName: string;
  email: string;
  password: string;
}

interface FormErrors {
  firstName: string,
  lastName: string,
  userName: string;
  email: string;
  password: string;
}

interface APIResponse {
  accessToken?: string;
  token: string;
  id: string;
  isNew?: boolean;
}

const { apiConstants, errorConstants, regExValidators } = constants;

const REGISTER_USER_API_URL = `${apiConstants.API_URL}/users`;

const {
  FISRT_NAME_NOT_ENTERED,
  LAST_NAME_NOT_ENTERED,
  USER_NAME_NOT_ENTERED,
  EMAIL_NOT_ENTERED_ERROR,
  EMAIL_IS_NOT_VALID,
  PASSWORD_NOT_ENTERED_ERROR,
} = errorConstants;

const { EMAIL_VALIDATOR_REGEX, USER_NAME_VALIDATOR } = regExValidators

const Registration: React.FunctionComponent = () => {
  const globalContext = useContext(GlobalContext);
  const { isLoggedIn } = globalContext;

  const [formData, setFormData] = useState<FormData | any>({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState<FormErrors>({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
  });

  const [passwordStength, setPasswordStrength] = useState<string>('');

  const [response, setResponse] = useState<APIResponse | null>(null);
  const [apiError, setApiError] = useState<string>('');

  const apiConfig = {
    method: 'POST',
    body: JSON.stringify({...formData }),
  }

  // This method is used to update the form data
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    let fieldErrors: any = {};
    switch (name) {
    case 'firstName': {
      if (!value) {
        fieldErrors[name] = FISRT_NAME_NOT_ENTERED
      } else {
        if (value.length > 20) {
          fieldErrors[name] = 'Maximum 20 characters are allowed';
        } else {
          fieldErrors[name] = '';
        }
      }
      break;
    }

    case 'lastName': {
      if (!value) {
        fieldErrors[name] = LAST_NAME_NOT_ENTERED
      } else {
        if (value.length > 20) {
          fieldErrors[name] = 'Maximum 20 characters are allowed';
        } else {
          fieldErrors[name] = '';
        }
      }
      break;
    }

    case 'userName': {
      if (!value) {
        fieldErrors[name] = USER_NAME_NOT_ENTERED
      } else {
        if (value.length > 30) {
          fieldErrors[name] = 'Maximum 30 characters are allowed';
        } else if (!USER_NAME_VALIDATOR.test(value)) {
          fieldErrors[name] = 'Accepted characters are A-Z, a-z, 0-9, _, -';
        }
        else {
          fieldErrors[name] = '';
        }
      }
      break;
    }

    case 'email': {
      if (!value) {
        fieldErrors[name] = EMAIL_NOT_ENTERED_ERROR
      } else {
        if (!EMAIL_VALIDATOR_REGEX.test(value)) {
          fieldErrors[name] = EMAIL_IS_NOT_VALID;
        } else {
          fieldErrors[name] = '';
        }
      }
      break;
    }

    case 'password':
      fieldErrors[name] = !value.length ? PASSWORD_NOT_ENTERED_ERROR : '';
      setPasswordStrength(value ? checkPasswordStrength(value): 'none');
      break;

    default:
      break;
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      ...fieldErrors,
    })
  }

  const handleKeyPress = (event: any) => {
    if (event.which === 13 || event.charCode === 13) {
      handleSubmit();
    }
  }

  const handleOnBlur = (event: any) => {
    const { value } = event.target;
    if (value.length < 8) {
      setErrors({
        ...errors,
        password: 'Password should have atleast 8 characters',
      })
    }
  }

  const isDisabled = () => {
    const { firstName, lastName, userName, email, password } = formData;
    if (firstName
      && firstName.length <= 20
      && lastName
      && lastName.length <= 20
      && userName
      && userName.length <= 30
      && USER_NAME_VALIDATOR.test(userName)
      && email
      && EMAIL_VALIDATOR_REGEX.test(email)
      && password
      && password.length > 7) {
      return false;
    } else {
      return true;
    }
  }

  // The below method will validate the form data
  const validateFormData = (): boolean | string => {
    const { firstName, lastName, userName, email, password } = formData;
    if (firstName
      && firstName.length <= 20
      && lastName
      && lastName.length <= 20
      && userName
      && userName.length <= 30
      && USER_NAME_VALIDATOR.test(userName)
      && email
      && EMAIL_VALIDATOR_REGEX.test(email)
      && password
      && password.length > 7) {
      return true;
    }

    let formErrors: any = {}
    Object.keys(formData).map(field => {
      if (!formData[field]) {
        switch (field) {
        case 'firstName':
          formErrors[field] = 'Please enter username'
          break;
          
        case 'lastName':
          formErrors[field] = 'Please enter lastname'
          break;
          
        case 'userName':
          formErrors[field] = 'Please enter username'
          break;

        case 'email':
          formErrors[field] = 'Please enter an email address'
          break;

        case 'password':
          formErrors[field] = 'Please enter password'
          break;

        default:
          break;
        }
        setErrors({
          ...errors,
          ...formErrors,
        })
      }
      return field;
    })
    return false;
  }

  // The below method is used to submit the user registration data to the API once the data is validated
  const handleSubmit = (): void => {
    if (validateFormData()) {
      setApiError('');
      registerUser();
    }
  }

  // const callAnalytics = (response: APIResponse) => {
  //   const { token } = response;
  //   const user: User = jwt(token);
  //   window.analytics.identify({
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     email: user.email,
  //     username: user.userName,
  //     id: user.id,
  //     userId: user.id,
  //   })
  // }

  const registerUser = async () => {
    globalContext.setLoader(true);
    try {
      const { response, error } = await apiRequest(REGISTER_USER_API_URL, apiConfig.method, apiConfig.body);
      if (response) {
        setResponse(response);
        // callAnalytics(response);
      } else if (error) {
        // setApiError(`An error occured while registering due to: ${error}, please try again.`);
        setApiError(error);
      }
    } catch(e) {
      setApiError(e);
    }
    
    globalContext.setLoader(false);
  }

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Sign Up | Registration",
    });
  }, [])

  if (isLoggedIn) {
    return (
      <Redirect to="/dashboard" />
    )
  }

  if (response) {
    const { token, accessToken, isNew } = response;
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    }
    if (token) {
      globalContext.setAuth(token);
      if (isNew) {
        return (
          <Redirect to="/verification" />
        )
      }
      else {
        return (
          <Redirect to="/dashboard" />
        )
      }
    }
  }

  const onFBLoginSuccess = (res: APIResponse) => {
    globalContext.setLoader(false);
    setResponse(res);
    // callAnalytics(res);
  }

  return (
    <Container className={`p-0 ${styles.authBG}`} fluid>
      <Row className={styles.authWrap}>
        <Col>
          <div className={styles.registration}>
            <div className={styles.authLogo}>
              <Link to="/">
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="site-logo-login"
                  description="Brandmo"
                  loader={<span>Loading...</span>}
                  src={LoginLogo}
                  title="Brandmo"
                />
              </Link>
              
            </div>

            <form className={styles.authForm}>
              <div className={styles.authTitle}>Sign Up</div>

              <Container fluid>
                <Row>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.inputWrap}>
                      <FormInput
                        // type="text"
                        marginBottom='mb-2'
                        name="email"
                        label="Email"
                        placeholder="Your email"
                        value={formData.email}
                        onChange={e => handleChange(e)}
                      />
                      {errors.email && (<div className={styles.error}>{errors.email}</div>)}
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className={styles.inputWrap}>
                      <FormInput
                        // type="text"
                        marginBottom='mb-2'
                        name="firstName"
                        label="FirstName"
                        placeholder="Your first name"
                        value={formData.firstName}
                        onChange={e => handleChange(e)}
                      />
                      {errors.firstName && (<div className={styles.error}>{errors.firstName}</div>)}
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className={styles.inputWrap}>
                      <FormInput
                        // type="text"
                        marginBottom='mb-2'
                        name="lastName"
                        label="LastName"
                        placeholder="Your last name"
                        value={formData.lastName}
                        onChange={e => handleChange(e)}
                      />
                      {errors.lastName && (<div className={styles.error}>{errors.lastName}</div>)}
                    </div>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.inputWrap}>
                      <FormInput
                        // type="text"
                        marginBottom='mb-2'
                        name="userName"
                        label="Username"
                        placeholder="Username"
                        value={formData.userName}
                        onChange={e => handleChange(e)}
                      />
                      {errors.userName && (<div className={styles.error}>{errors.userName}</div>)}
                    </div>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.inputWrap}>
                      <FormInput
                        marginBottom='mb-2'
                        type="password"
                        name="password"
                        label="Password" // ???
                        placeholder="Create password"
                        value={formData.password}
                        onChange={e => handleChange(e)}
                        onKeyPress={handleKeyPress}
                        onBlur={handleOnBlur}
                      />
                      {errors.password && (<div className={styles.error}>{errors.password}</div>)}
                    </div>
                  </Col>

                  <Col className="mt-1" xl={12} lg={12} md={12} sm={12}>
                    <PasswordStrength strength={passwordStength} />
                  </Col>

                  <Col className="mt-1 mb-2" xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.eula}>
                      By signing up, you confirm that you’ve read <br />
                      and accepted our <a href="#1">User Notice</a> and <a href="#1">Privacy Policy</a>.
                    </div>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <SubmitButton
                      disabled={isDisabled()}
                      isLoading={globalContext.isLoading}
                      onSubmit={handleSubmit}
                      caption="Sign Up"
                    />
                  </Col>
                  {apiError && <div className={`${styles.error} ${styles.apiError}`}>{apiError}</div>}

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.orLabel}>or</div>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <FBLogin
                      onClick={() => globalContext.setLoader(true)}
                      onSuccess={onFBLoginSuccess}
                      onError={(error) => {
                        globalContext.setLoader(false);
                        setApiError(error);
                      }}
                      isLoading={globalContext.isLoading}
                    />
                  </Col>

                </Row>
              </Container>

            </form>

            <div className={styles.formBottom}>
              <Link to="/login">Already have a Brandmo account? Log in</Link>
            </div>

          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(Registration)
