import React, { useEffect, useContext, useState } from 'react';

import { Row, Col, Button } from 'react-bootstrap';

import Loader from 'general/Loader/Loader';
import PaymentMethod from './PaymentMethod/PaymentMethod';

import {
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';

import { Token, StripeError } from '@stripe/stripe-js';

import GlobalContext from 'contexts/Global.context';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

/* import styles from './PaymentMethods.module.scss'; */
import './PaymentMethods.scss';

interface Card {
  address_city: string | null;
  address_country: string | null;
  address_line1: string | null;
  address_line1_check: string | null;
  address_line2: string | null;
  address_state: string | null;
  address_zip: string | null;
  address_zip_check: string | null;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: string | null;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  id: string;
  last4: string;
  metadata: object;
  name: string;
  object: string;
  tokenization_method: string | null;
}

interface NewCardError {
  code: string;
  message: string;
  type: string;
}

interface Props {
  cardSelectedForPayment: (cardId: string) => void;
  setDefaultCard: (cardId: string) => void;
}

const { apiConstants: { API_URL } } = Constants;
const GET_USERS_CARDS_URL = `${API_URL}/cards`;

const STRIPE_CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#111b47',
      '::placeholder': {
        color: '#111b47',
      },
    }
  },
  hidePostalCode: true,
}

const PaymentMethods: React.FunctionComponent<Props> = ({ cardSelectedForPayment, setDefaultCard }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const globalContext = useContext(GlobalContext);
  const { userDetails: { paymentId }, setAuth} = globalContext;

  const [userCards, setUserCards] = useState<null | Array<Card>>(null);
  const [fetchingCards, setFetchingCards] = useState<boolean>(false);

  const [selectedCard, setSelectedCard] = useState<string>('');

  const [showNewCardOption, setShowNewCardOption] = useState<boolean>(false);

  const [newCardLoader, setNewCardLoader] = useState<boolean>(false);
  const [newCardError, setNewCardError] = useState<string>('');

  const fetchUserCards = async () => {
    setFetchingCards(true);
    const { response } = await apiRequest(GET_USERS_CARDS_URL, 'GET');
    if (response && response.sources && response.sources.data) {
      setUserCards(response.sources.data);
      if (response.sources.data.length) {
        setSelectedCard(response.sources.data[0].id);
        cardSelectedForPayment(response.sources.data[0].id);
      } else {
        setSelectedCard('');
        cardSelectedForPayment('');
      }
    }
    setFetchingCards(false);
  }

  useEffect(() => {
    const fetchCards = async () => {
      setFetchingCards(true);
      const { response } = await apiRequest(GET_USERS_CARDS_URL, 'GET');
      if (response && response.sources && response.sources.data && response.sources.data.length) {
        setUserCards(response.sources.data);
        setSelectedCard(response.sources.data[0].id);
        setDefaultCard(response.sources.data[0].id);
      }
      setFetchingCards(false);
    }

    if (paymentId) {
      fetchCards()
    }
  }, [paymentId, setDefaultCard]);

  const addNewCard = async () => {
    setNewCardError('');
    if (!stripe && !elements) {
      return;
    }

    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        setNewCardLoader(true);
        const { token, error }: { token?: Token | undefined; error?: StripeError | undefined; } = await stripe.createToken(cardElement);
        if (token) {
          const { response } = await apiRequest(GET_USERS_CARDS_URL, 'POST', JSON.stringify({
            token: token?.id,
          }))
          if (response && response.token) {
            setAuth(response.token);
          }
          fetchUserCards();
          setShowNewCardOption(false);
        } else if (error && error.message) {
          setNewCardError(error?.message);
        }
        setNewCardLoader(false);
      }
    }
  }
  
  return (
    <Row className="checkout-form">
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className="user-cards" style={{
          position: 'relative',
        }}>
          {fetchingCards && <Loader />}
          {!fetchingCards && userCards && userCards.map(card => (
            <PaymentMethod
              key={card.id}
              card={card}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              cardSelectedForPayment={cardSelectedForPayment}
              fetchUserCards={fetchUserCards}
            />
          ))}
        </div>
        {showNewCardOption || (!userCards) || (userCards && !userCards.length) ? (
          <form className="new-card-form" style={{margin: '20px 0'}}>
            <Row className="align-items-center ml-2">
              <Col xl={8} lg={8} md={12} sm={12}>
                <CardElement options={STRIPE_CARD_ELEMENT_OPTIONS} onChange={() => setNewCardError('')} />
              </Col>
              <Col xl={4} lg={4} md={12} sm={12}>
                {!newCardLoader ? (<Button
                  className="bm-btn px-3 py-2 ml-auto sm d-inline-block"
                  variant="primary"
                  onClick={addNewCard}
                >
                Add Card
                </Button>) : <Loader />}
              </Col>
              {newCardError && <div className="ml-3" style={{
                color: 'red',
                fontSize: '14px',
              }}>
                {newCardError}
              </div>}
            </Row>
          </form>): null}
        
        {(!showNewCardOption || (userCards && userCards.length === 0)) &&
        !fetchingCards &&
        !newCardLoader && (
          <Button
            className="bm-btn px-3 py-2 ml-auto mt-3 sm"
            variant="primary"
            onClick={() => setShowNewCardOption(true)}
          >
              Add new card
          </Button>
        )}
      </Col>
    </Row>
      
  );
};

export default PaymentMethods;
