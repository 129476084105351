import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Helmet from 'react-helmet';


// Page Components
import Input from '../../general/FormInput/FormInput';
import Select from '../../general/CustomSelect/CustomSelect';
import TextArea from '../../general/CustomTextArea/CustomTextArea';

// Context
import GlobalContext from 'contexts/Global.context';

// Constans
import Constants from 'Constants';

// Utils
import { apiRequest } from 'utils/ApiRequest';
import scrollToHandler from 'utils/ScrollHandler';

// SCSS
import styles from './Contact.module.scss';

interface FormData {
  email: string;
  phone: string;
  issueType: string;
  name: string;
  description: string;
}

const TYPES_OF_INQUIRIES = [
  { label: 'General'},
  { label: 'Selling on Brandmo' },
  { label: 'Question About a Domain Name' },
  { label: 'Buy Request Questions' },
  { label: 'Bug Report' },
  { label: 'Other'},
]

const { apiConstants: { API_URL }, regExValidators: { EMAIL_VALIDATOR_REGEX } } = Constants;
const CONTACT_URL = `${API_URL}/contact-us`;

const FORM_ERRORS = {
  name: 'Please enter your name',
  email: 'Please enter an email address',
  issueType: 'Please select an issue',
  phone: 'Please enter phone number',
  description: 'Please enter some description'
}

const Contact: React.FunctionComponent = () => {

  const { userDetails: { email, firstName, lastName }, isLoggedIn } = useContext(GlobalContext);

  const [formData, setFormData] = useState<FormData>({
    email: isLoggedIn ? email : '',
    phone: '',
    issueType: 'General',
    name: isLoggedIn ? `${firstName} ${lastName}`: '',
    description: '',
  });

  const [formErrors, setFormErrors] = useState<FormData>({
    email: '',
    phone: '',
    issueType: '',
    name: '',
    description: '',
  })

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (!value) {
      setFormErrors({
        ...formErrors,
        [name]: FORM_ERRORS[name],
      })
    } else {
      if (name === 'email') {
        if (!EMAIL_VALIDATOR_REGEX.test(value)) {
          setFormErrors({
            ...formErrors,
            email: 'Please enter a valid email address',
          })
        } else {
          setFormErrors({
            ...formErrors,
            [name]: '',
          })
        }
      } else {
        setFormErrors({
          ...formErrors,
          [name]: '',
        })
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      issueType: value,
    })
    if (value !== 'Select') {
      setFormErrors({
        ...formErrors,
        issueType: '',
      })
    } else {
      setFormErrors({
        ...formErrors,
        issueType: FORM_ERRORS[name],
      })
    }
  }

  const validateData = () => {
    const { name, email, phone, issueType, description } = formData;
    if (name
      && email
      && EMAIL_VALIDATOR_REGEX.test(email)
      && phone
      && issueType
      && issueType !== 'Select'
      && description) {
      return true;
    }

    let errors: any = {}
    Object.keys(formData).map(field => {
      if (!formData[field]) {
        switch (field) {
        case 'name':
          formErrors[field] = FORM_ERRORS[field];
          break;
          
        case 'email':
          formErrors[field] = FORM_ERRORS[field];
          break;
          
        case 'issueType':
          formErrors[field] = FORM_ERRORS[field];
          break;

        case 'phone':
          formErrors[field] = FORM_ERRORS[field];
          break;

        case 'description':
          formErrors[field] = FORM_ERRORS[field];
          break;

        default:
          break;
        }
        setFormErrors({
          ...formErrors,
          ...errors,
        })
      }
      return field;
    })
  }

  const contactBrandmo = async () => {
    setError('');
    setLoading(true);
    const { response, error } = await apiRequest(CONTACT_URL, 'POST', JSON.stringify({
      ...formData,
    }))

    if (response) {
      const { result } = response;
      if (result === 'success') {
        setResponse(true);
      }
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }

  const handleSubmit = () => {
    if (validateData()) {
      contactBrandmo();
    }
  }

  useEffect(() => {
    scrollToHandler();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setFormData(formData => ({
        ...formData,
        email,
        name: `${firstName} ${lastName}`,
      }))
    }
    
  }, [isLoggedIn, firstName, lastName, email])

  return (
    <Container className={styles.contactPage}>
      <Helmet>
        <title>
          Contact Us | Brandmo
        </title>
      </Helmet>
      <Row className={styles.headerWrap}>
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.headerTitle}>
            We want to help.  Contact us anytime you need.
          </div>
        </Col>
      </Row>

      {!response ? (<div className={styles.contactForm}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Row>
              <Col className={styles.inputCol} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.formLabel}>Name</div>
                <div className={styles.addInput}>
                  <Input
                    name="name"
                    label="Name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={e => handleInputChange(e)}
                  />
                </div>
                {formErrors.name && <div className={styles.error}>{formErrors.name}</div>}
              </Col>

              <Col className={styles.inputCol} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.formLabel}>E-Mail Address</div>
                <div className={styles.addInput}>
                  <Input
                    name="email"
                    label="E-mail Address"
                    placeholder="E-mail Address"
                    value={formData.email}
                    onChange={e => handleInputChange(e)}
                  />
                </div>
                {formErrors.email && <div className={styles.error}>{formErrors.email}</div>}
              </Col>

              <Col className={styles.inputCol} xl={6} lg={6} md={6} sm={12}>
                <div className={styles.formLabel}>Type of Inquiries</div>
                <div className={styles.addSelect}>
                  <Select
                    id={formData.issueType}
                    name="issueType"
                    selectItems={TYPES_OF_INQUIRIES}
                    value={formData.issueType}
                    onChange={e => handleSelectChange(e)}
                  />
                </div>
                {formErrors.issueType && <div className={styles.error}>{formErrors.issueType}</div>}
              </Col>

              <Col className={styles.inputCol} xl={6} lg={6} md={6} sm={12}>
                <div className={styles.formLabel}>Phone Number</div>
                <div className={styles.addInput}>
                  <Input
                    name="phone"
                    label="Phone Number"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={e => handleInputChange(e)}
                  />
                </div>
                {formErrors.phone && <div className={styles.error}>{formErrors.phone}</div>}
              </Col>

              <Col className={styles.inputCol} xl={12} lg={12} md={12} sm={12}>
                <div className={styles.formLabel}>Description</div>
                <div className={styles.addTextArea}>
                  <TextArea
                    name="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={e => handleInputChange(e)}
                  />
                </div>
                {formErrors.description && <div className={styles.error}>{formErrors.description}</div>}
              </Col>

              <Col className={styles.formSubmit}>
                <Button
                  className="bm-btn sm"
                  variant="primary"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? 'loading...': 'Send Message'}
                </Button>
                {error && <div className={styles.apiError}>{error}</div>}
              </Col>
            </Row>
          </Col>

        </Row>
      </div>) : (
        <div className={styles.success}>
          {'Thanks! Your message has been sent. We\'ll be in touch soon'}
        </div>
      )}

    </Container>
  );
};

export default React.memo(Contact);
