import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import { Helmet } from 'react-helmet';

import moment from 'moment';

// Page Components
import Notify from './components/Notify/Notify'
import Loader from 'general/Loader/Loader';

// Utils
import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

// SCSS
import styles from './Notifications.module.scss';

interface Notification {
  body: string;
  createdAt: string;
  read: boolean;
}

const { apiConstants: { API_URL } } = Constants;
const GET_NOTIFICATIONS_URL = `${API_URL}/notifications`;


const Notifications: React.FunctionComponent = () => {

  const [notifications, setNotifications] = useState<Array<Notification> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const markAllNotificationsRead = async () => {
    setLoading(true);
    const { response } = await apiRequest(`${GET_NOTIFICATIONS_URL}/read`, 'PUT');
    if (response && response.success && notifications) {
      const readNotifications = notifications && notifications.map(n => {
        return {
          ...n,
          read: true,
        }
      })
      setNotifications(readNotifications);
    }
    setLoading(false);
  }

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      const { response, error } = await apiRequest(GET_NOTIFICATIONS_URL, 'GET');
      if (response) {
        setNotifications(response);
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    }
    fetchNotifications();
  }, []);

  return (
    <Container className={styles.dashWrapper} fluid>
      <Helmet>
        <title>
          Notifications | Brandmo
        </title>
      </Helmet>
      <Container className={styles.dashInner}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.titleRegion}>
              <div className={styles.notifTitle}>
                Notifications
                <Button
                  variant="primary"
                  className={styles.markAllRead}
                  onClick={markAllNotificationsRead}
                >
                  Mark All As Read
                </Button>
              </div>
            </div>

            <div className={styles.notifItems}>
              {loading && <Loader />}
              {!loading && notifications && (notifications.map((notification, index) => (
                <Notify
                  key={`${notification.body} - ${index}`}
                  title={notification.body}
                  time={moment(notification.createdAt).fromNow()}
                  read={notification.read}
                />
              )))}
              {!loading && !notifications && error && (
                <div className={styles.error}>{error}</div>
              )}
              {!loading && notifications && !notifications.length && (
                <div className={styles.noNotifications}>No Notifications Yet</div>
              )}
            </div>
          </Col>

        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Notifications);
