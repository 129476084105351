import React, { useState, useEffect } from 'react';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

import BuyRequestsListing from 'components/BuyRequestsListing/BuyRequestsListing';

interface BuyRequest {
  TLDs: string;
  additionalComments: string;
  brandFeelings: string;
  budget: number;
  businessDescription: string;
  businessIndustry: string;
  createdAt: string;
  desiredKeywords: string;
  domainMaxLength: string;
  file: string;
  geographicRegions: string;
  id: string;
  isDeleted: boolean;
  isExpired: boolean;
  isFavorite: string;
  name: string;
  title?: string;
  projectType: string;
  status: number;
  submissions: string;
  targetCustomers: string;
  targetDate: string;
  total: string;
  updatedAt: string;
  userId: string;
  userName: string;
  submitCount?: number;
}

interface Props {
  enteredUserId: string;
}


const { apiConstants: { API_URL }, pageConstants: { ITEMS_ON_DASHBOARD } } = Constants;
const FETCH_BUY_REQUESTS_URL = `${API_URL}/requests/filter`;

const ContestsEntered: React.FunctionComponent<Props> = ({ enteredUserId }: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [buyRequests, setBuyRequests] = useState<Array<BuyRequest> | null>(null);

  const [searchText, setSearchText] = useState<string>('');

  const searchBuyRequests = async () => {
    setLoading(true);
    const { response } = await apiRequest(FETCH_BUY_REQUESTS_URL, 'POST', JSON.stringify({
      sortBy: 'submissions',
      sortOrder: 'DESC',
      limit: ITEMS_ON_DASHBOARD,
      filters: {
        entered: enteredUserId,
        desiredKeywords: searchText,
      }
    }));
    if (response) {
      setBuyRequests(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchBuyRequests = async () => {
      setLoading(true);
      const { response } = await apiRequest(FETCH_BUY_REQUESTS_URL, 'POST', JSON.stringify({
        sortBy: 'submissions',
        sortOrder: 'DESC',
        limit: ITEMS_ON_DASHBOARD,
        filters: {
          enteredUser: enteredUserId,
        }
      }));

      if (response) {
        setBuyRequests(response);
      }
      setLoading(false);
    }
    fetchBuyRequests();
  }, [enteredUserId]);

  return (
    <div className="favorite-contests">
      <BuyRequestsListing
        showSearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        searchList={searchBuyRequests}
        fetchingRequestsLoader={loading}
        buyRequests={buyRequests}
      />
    </div>
  )
}

export default React.memo(ContestsEntered);
