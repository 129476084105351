import React from 'react';

// SCSS
import styles from './Gallery.module.scss';

interface Gallery {
  preview: string;
  alt?: string;
  setThumbnail: (selectedImage: string) => void;
}

const Gallery = (props: Gallery) => {
  return (
    <div className={styles.otherImg} onClick={() => props.setThumbnail(props.preview)}>
      <img src={props.preview} alt={props.alt} className="img-fluid" />
    </div>
  )
}

export default React.memo(Gallery);
