import React from 'react';

import Loader from 'general/Loader/Loader';
import SearchBuyRequest from './SearchBuyRequests/SearchBuyRequests';
import BuyRequestItem from './BuyRequestItem/BuyRequestItem';

import Constants from 'Constants';

import './BuyRequests.scss';

interface BuyRequest {
  TLDs: string;
  additionalComments: string;
  brandFeelings: string;
  budget: number;
  businessDescription: string;
  businessIndustry: string;
  createdAt: string;
  desiredKeywords: string;
  domainMaxLength: string;
  file: string;
  geographicRegions: string;
  id: string;
  isDeleted: boolean;
  isExpired: boolean;
  isFavorite: string;
  name?: string;
  title?: string;
  projectType: string;
  status: number;
  submissions: string;
  targetCustomers: string;
  targetDate: string;
  total: string;
  updatedAt: string;
  userId: string;
  userName: string;
}

interface Props {
  showSearchBar?: boolean;
  searchText?: string;
  setSearchText?: (text: string) => void;
  searchList?: () => void;
  
  fetchingRequestsLoader?: boolean;
  buyRequests?: Array<BuyRequest> | null;
  favorites?: boolean;
  removeBuyRequestOnUnFavorite?: (buyRequestId: string) => void;
}

const { apiConstants : { API_URL } } = Constants;

const BuyRequestsListing: React.FunctionComponent<Props> = ({
  showSearchBar,
  searchText,
  setSearchText,
  searchList,
  fetchingRequestsLoader,
  buyRequests,
  favorites,
  removeBuyRequestOnUnFavorite,
}: Props) => {
  return (
    <div className="buy-requests-list">
      {showSearchBar && (
        <SearchBuyRequest
          setSearchText={setSearchText}
          searchList={searchList}/>
      )}
      {fetchingRequestsLoader && <Loader />}
      {!fetchingRequestsLoader && buyRequests && buyRequests.map(request => (
        <BuyRequestItem
          key={request.id}
          id={request.id}
          createdAt={request.createdAt}
          targetDate={request.targetDate}
          userName={request.userName}
          name={request.name}
          title={request.title}
          projectType={request.projectType}
          businessDescription={request.businessDescription}
          budget={request.budget.toString()}
          desiredKeywords={request.desiredKeywords}
          submitCount={request.submissions}
          isFavorite={favorites ? true : (request.isFavorite ? (request.isFavorite === '0' ? false : true) : false)}
          removeBuyRequestOnUnFavorite={removeBuyRequestOnUnFavorite}
          addToFavouriteURL={`${API_URL}/requests/favorite`}
        />
      ))}
      {!fetchingRequestsLoader && buyRequests && !buyRequests.length && !searchText && (
        <div className="no-buy-requests">No Buy Requests Yet</div>
      )}
      {!fetchingRequestsLoader && buyRequests && !buyRequests.length && searchText && (
        <div className="no-buy-requests">Buy Requests Not Found</div>
      )}
      
    </div>
  )
}

export default React.memo(BuyRequestsListing);
