import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

// Context
import { FavoriteDomainsProvider } from 'contexts/FavoriteDomains.context';
// import GlobalContext from 'contexts/Global.context';

// Page Components
import DashboardMenu from './components/DashboardMenu/DashboardMenu';

// Dashboard Menu
import Favorites from './Favorites/Favorites';
import MyContests from './MyContests/MyContests';
import MyDomains from './MyDomains/MyDomains';
import Messages from './Messages/Messages';
import MessagesInner from './Messages/MessageInner/MessageInner';
import Notifications from './Notifications/Notifications';
import TransactionList from './TransactionList/TransactionList';

// User Menu
import AccountPreferences from './AccountPreferences/AccountPreferences';

// import sumSubWebSdk from 'services/verification-service';
// import { Sum_Sub_SDK_URL, SUM_SUB_SDK_FLOW_TYPE } from 'config/Config';

// SCSS
import styles from './Dashboard.module.scss';

interface APIResponse {
  token: string;
  status?: string;
  accessToken?: string;
}

// const apiURL = Sum_Sub_SDK_URL();

const Dashboard: React.FunctionComponent = () => {
  const { url, path } = useRouteMatch();

  // const { userDetails: { email } } = useContext(GlobalContext);

  // const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('accessToken'));

  // const onVerificationCompletion = (payload: any) => {
  //   if (payload.reviewStatus === 'pending') {
  //     localStorage.removeItem('accessToken');
  //     setAccessToken(null);
  //     window.location.reload();
  //   }
  // }

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Dashboard",
    });
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Favorites",
    });

    // if (accessToken) {
    //   sumSubWebSdk(apiURL, SUM_SUB_SDK_FLOW_TYPE, accessToken, email, onVerificationCompletion);
    // }

  }, [
    // accessToken, email
  ])

  // if (accessToken) {
  //   return (
  //     <Container className={styles.dashWrapper} fluid>
  //       <Container className={styles.dashInner}>
  //         <div id="sumsub-websdk-container" />
  //       </Container>
  //     </Container>
  //   )
  // }

  return (
    <Container className={styles.dashWrapper} fluid>
      <Container className={styles.dashInner}>

        <Row>
          <Col xl={3} lg={3} md={12} sm={12}>
            <DashboardMenu url={url} />
          </Col>

          <Col xl={9} lg={9} md={12} sm={12}>
            <Switch>
              <Route path={`${path}/favorites`}>
                <FavoriteDomainsProvider>
                  <Favorites />
                </FavoriteDomainsProvider>
              </Route>
              <Route path={`${path}/my-buy-requests`}>
                <MyContests />
              </Route>
              <Route path={`${path}/my-domains`}>
                <FavoriteDomainsProvider>
                  <MyDomains />
                </FavoriteDomainsProvider>
              </Route>
              <Route exact path={`${path}/messages`}>
                <Messages />
              </Route>
              <Route exact path={`${path}/messages/:partnerId`}>
                <MessagesInner />
              </Route>
              <Route path={`${path}/notifications`}>
                <Notifications />
              </Route>
              <Route path={`${path}/transactions-list`}>
                <TransactionList />
              </Route>
              <Route path={`${path}/account-preferences`}>
                <AccountPreferences />
              </Route>
              <Route component={Favorites}>
                <FavoriteDomainsProvider>
                  <Favorites />
                </FavoriteDomainsProvider>
              </Route>
            </Switch>
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default React.memo(Dashboard);
