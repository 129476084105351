import React, { useState, useEffect, useContext, useCallback } from 'react';

import { useParams, useHistory, Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import downloadjs from 'downloadjs';
import ReactGA from "react-ga4";

import moment from 'moment';
import numeral from 'numeral';

import { Container, Row, Col, Button, Tabs, Tab, Spinner } from 'react-bootstrap';

// Context
import GloabalContext from 'contexts/Global.context';
import { BuyRequestContext } from 'contexts/BuyRequest.context';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShieldAlt, faCog } from '@fortawesome/free-solid-svg-icons';

// Components
import ContestBrief from './ContestBrief/ContestBrief';
// import ViewEntries from './ViewEntries/ViewEntries';
import ViewEntries from './ViewEntries/ViewEntries';

import QuestionsAnnouncements from './QuestionsAnnouncements/QuestionsAnnouncements';
import Subscribe from '~/../components/Subscribe/Subscribe';
import SubmitEntry from './SubmitEntry/SubmitEntry';
import YourStats from './YourStats/YourStats';

// Utils
import { apiRequest } from 'utils/ApiRequest';
import { exportCSV } from 'utils/ExportCSV';
import scrollToHandler from 'utils/ScrollHandler';
import capitalize from 'utils/functions/capitalizeWords'

// Constants
import constants from 'Constants';

import './Contest.scss';

interface Details {
  id: string;
  projectType: string;
  name?: string;
  title?: string;
  businessDescription: string;
  businessIndustry: string;
  brandFeelings: string;
  desiredKeywords: string;
  domainMaxLength: string;
  TLDs: string;
  budget: number;
  targetCustomers: string;
  geographicRegions: string;
  targetDate: string;
  additionalComments: string;
  status: number;
  isDeleted: boolean;
  file: string;
  isExpired: boolean;
  createdAt: string;
  updatedAt: string;
  signedUp: string;
  userId: string;
  userName: string;
  billingCountry: string;
  requests: string;
  entries: string;
  participants: string;
  likes: number;
  awarded: number;
  email: string;
}

interface Stats {
  entriesSubmitted: string;
  loved: string;
  liked: string;
  onRightTrack: string;
  noThankYou: string;
  unrated: string;
}

const { apiConstants: { API_URL } } = constants;
const FETCH_ENTRIES_URL = `${API_URL}/entries`;
const FETCH_DOMAINS_URL = `${API_URL}/domains`;

const ADD_TOF_FAVORITES_URL = `${API_URL}/requests/favorite`;

const Contest: React.FunctionComponent = () => {

  const gloabalContext = useContext(GloabalContext);

  const buyRequestContext = useContext(BuyRequestContext);

  const {
    buyRequestDetails: details,
    setLoader,
    setBuyRequestDetails,
    loading,
    detailsError,
    setEntriesLoader,
    setEntries,
  } = buyRequestContext;

  const { isLoggedIn, userDetails } = gloabalContext;

  const { profilePicURL, id: loggedInUserId, email, isVerified } = userDetails;

  const { id } = useParams();
  const history = useHistory();
  const FETCH_BUY_REQUEST_URL = `${API_URL}/requests/${id}`;
  const EXPORT_CSV_URL = `${API_URL}/requests/${id}/export`;
  const FETCH_USER_STATS_URL = `${API_URL}/requests/user/stats/${id}`;

  const QUESTIONS_ANNOUNCEMENT_URL = `${API_URL}/requests/${id}/questions`;

  const [stats, setStats] = useState<Stats | null>(null);
  const [statsError, setStatsError] = useState<string>('');

  const [showSubmitEntryModal, setShowSubmitEntryModal] = useState<boolean>(false);

  const [showAllKeywords, setShowAllKeywords] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<string>('contestBrief');

  const [showEntriesTab, setShowEntriesTab] = useState<boolean>(false);

  const [isFavorited, setIsFavorited] = useState<boolean>(false);
  const [likes, setLikes] = useState<number>(0);

  const [ isExporting, setIsExporting] = useState<boolean>(false);
  const [ showCreatorActions, setShowCreatorActions] = useState<boolean>(false);

  const onCloseModal = () => {
    setShowEntriesTab(true);
    setSelectedTab('viewEntries');
    fetchBuyRequestDetails();
    fetchStats();
  }

  const daysLeft = (targetDate: string): number => {
    return moment(targetDate).diff(moment(), 'days') + 1;
  }

  const showModal = () => {
    if (isLoggedIn) {
      if (isVerified && daysLeft(details?.targetDate) > 0) {
        setShowSubmitEntryModal(true);
      }
    } else {
      history.push('/login?destination='+window.location.pathname);
    }
  }

  const fetchBuyRequestDetails = useCallback(async () => {
    setLoader(true);
    const { response, error } = await apiRequest(FETCH_BUY_REQUEST_URL, 'GET');
    if (response) {
      setBuyRequestDetails(response, '');
      setIsFavorited(response.isFavorite ? (response.isFavorite === '0' ? false : true) : false);
      setLikes(response.likes ? parseInt(response.likes) : 0);
      const { response: entriesResponse } = await apiRequest(`${FETCH_ENTRIES_URL}?buyRequestId=${response.id}`, 'GET');

      if (entriesResponse) {
        const { entries } = entriesResponse;
        if ((entries && entries.length) || loggedInUserId === response.userId) {
          setShowEntriesTab(true);
        }
      }
    } else if (error) {
      setBuyRequestDetails(null, error);
    }
    setLoader(false);
  }, [setLoader, setBuyRequestDetails, FETCH_BUY_REQUEST_URL, loggedInUserId])

  const toggleFavourite = async () => {
    if (isLoggedIn && isVerified) {
      const { response } = await apiRequest(ADD_TOF_FAVORITES_URL, 'POST', JSON.stringify({
        id,
      }))
      if (response && response.success) {
        if (isFavorited) {
          setIsFavorited(false);
          setLikes(likes => likes - 1);
        } else {
          setIsFavorited(true);
          setLikes(likes => likes + 1);
        }
      }
    }
  }

  const exportNamesToCSV = async () => {
    setIsExporting(true);
    setShowCreatorActions(false);
    const { data, error } = await exportCSV(EXPORT_CSV_URL, 'GET');
    if (!error) {
      downloadjs(data, 'names.csv', 'text/csv'); 
    }
    setIsExporting(false);
  };

  const fetchStats = useCallback(async () => {
    const { response: statsResponse, error: statsError } = await apiRequest(FETCH_USER_STATS_URL, 'GET');
    if (statsResponse) {
      setStats(statsResponse);
    } else if (statsError) {
      setStatsError(`Error occured while fetching stats due to: ${statsError}`);
    }
  }, [setStats, setStatsError, FETCH_USER_STATS_URL]);

  useEffect(() => {
    fetchBuyRequestDetails();
  }, [fetchBuyRequestDetails])

  useEffect(() => {
    if (isLoggedIn) {
      fetchStats();
    }
  }, [isLoggedIn, fetchStats])

  useEffect(() => {
    scrollToHandler();
    // window.analytics.page('BuyRequestDetails', {
    //   title: 'Buy Request Details',
    // });
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Buy Request Details",
    });
  }, []);

  const refetchAllData = () => {
    fetchBuyRequestDetails();
    fetchStats();
  };

  const renderDesiredKeywords = () => {
    const { desiredKeywords } = details;
    const desiredKeyWordsArr = desiredKeywords ? desiredKeywords.split(',') : []
    return (
      <div className="result">
        <ul className="keywords">
          {!showAllKeywords && 
          desiredKeyWordsArr.slice(0, 3).map((keyword: string, index: number) => (
            <Link
              key={`${keyword} - ${index}`} 
              to={{
                pathname: '/requests',
                state: keyword.trim(),
              }}
            >
              <li key={`${keyword} - ${index}`} className="keyword">{keyword}</li>
            </Link>
          ))}
          {showAllKeywords && 
          desiredKeyWordsArr.map((keyword: string, index: number) => (
            <Link
              key={`${keyword} - ${index}`} 
              to={{
                pathname: '/requests',
                state: keyword.trim(),
              }}
            >
              <li key={`${keyword} - ${index}`} className="keyword">{keyword}</li>
            </Link>
          ))}
          {desiredKeyWordsArr.length > 3 && !showAllKeywords && (
            <li className="more" onClick={() => setShowAllKeywords(true)}>
              {`+ ${desiredKeyWordsArr.length - 3} more`}
            </li>)}
        </ul>
      </div>
    )

  }

  return (
    <Container id="contest" className="p-0" fluid>

      <Container id="header">
        <Row className="contest-info">
          {loading && (
            <div className="loader">
              <Spinner animation="border" variant="primary" role="status" />
            </div>
          )}

          {!loading && !details && detailsError && (<div className="error">
            {`Error occured while fetching buy request details due to: ${detailsError}`}
          </div>
          )}

          {!loading && !detailsError && details && (
            <Col xl={8} lg={8} md={12} sm={12}>
              <Helmet>
                <title>
                  {details?.title || details?.name} | Brandmo
                </title>
              </Helmet>
              <Row>

                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className="title">{details?.title ? '' : 'Seeking Brand Name For A '}<b>{details?.title || details?.name}</b></div>
                </Col>

                <Col xl={4} lg={4} md={12} sm={12}>
                  <div className="label">Request Type</div>
                  <div className="result">{capitalize(details?.projectType)}</div>
                </Col>

                <Col xl={3} lg={3} md={12} sm={12}>
                  <div className="label">Industry Type</div>
                  <div className="result">{capitalize(details?.businessIndustry)}</div>
                </Col>

                <Col xl={5} lg={5} md={12} sm={12}>
                  <div className="label">Keyword Ideas</div>
                  <div className="result">
                    {details && renderDesiredKeywords()}
                  </div>
                </Col>

              </Row>
            </Col>
          )}
          <Col xl={4} lg={4} md={12} sm={12}></Col> {/* Intentionally left blank for the sidebar to intrude on Desktop. */}
        </Row>
      </Container> {/* header */}

      {!loading && !detailsError && details && (<Container id="content" fluid>
        <Container>
          <Row>

            <Col className="order-xl-0 order-lg-0 order-md-1 order-sm-1 order-1" xl={8} lg={8} md={12} sm={12}>
              <Tabs id="contestDetails"
                activeKey={selectedTab}
                onSelect={(k: string) => setSelectedTab(k)}
              >
                <Tab eventKey="contestBrief" title="Domain Brief">
                  {details && (<ContestBrief
                    file={details?.file}
                    description={details?.businessDescription}
                    creator={{
                      profilePicURL: '',
                      username: details?.userName,
                      country: details?.billingCountry,
                      requests: details?.requests,
                      budget: details?.budget,
                      totalAwarded: details?.awarded,
                      signedUp: details?.signedUp,
                    }}
                    extensions={details?.TLDs}
                  />)}
                </Tab>

                {isLoggedIn && showEntriesTab && (<Tab eventKey="viewEntries" title="View Entries">
                  <ViewEntries
                    isLoggedIn={isLoggedIn}
                    loggedInUserId={loggedInUserId}
                    loggedInEmail={email}
                    buyRequestCreatorId={details.userId}
                    buyRequestCreatorEmail={details.email}
                    refetchAllData={refetchAllData}
                    fetchEntriesURL={`${FETCH_ENTRIES_URL}?buyRequestId=${details?.id}`}
                    isVerified={isVerified}
                  />
                </Tab>)}

                <Tab eventKey="questionsAnnouncements" title="Questions & Announcements">
                  <QuestionsAnnouncements
                    questionsAnnouncementsURL={QUESTIONS_ANNOUNCEMENT_URL}
                    isLoggedIn={isLoggedIn}
                    profilePic={profilePicURL}
                    history={history}
                    isVerified={isVerified}
                  />
                </Tab>
              </Tabs>
            </Col>

            <Col className="sidebar order-xl-1 order-lg-1 order-md-0 order-sm-0 order-0" xl={4} lg={4} md={12} sm={12}>

              <div className="contest-stats">
                <div className="contest-progress">
                  <div className="progress-bar"></div>
                </div>

                <div className="stat-header">
                  <div className="prize">{numeral(details.budget).format('$0,0')}</div>
                  {daysLeft(details?.targetDate) > 0 ? (
                    <div className="days-left"><b>{` ${daysLeft(details?.targetDate)} `}</b> days left</div>
                  ) : (
                    <div className="days-left">Closed</div>
                  )}
                  {loggedInUserId === details.userId && <div className="creator-action-icon">
                    {!isExporting && <FontAwesomeIcon icon={faCog} onClick={() => setShowCreatorActions(!showCreatorActions)}/>}
                    {!isExporting && showCreatorActions &&
                      <ul className="creator-actions">
                        <li onClick={exportNamesToCSV}>Export Names to CSV</li>
                      </ul>
                    }
                    {isExporting && <div id="loader">
                      <Spinner animation="border" variant="primary" role="status" />
                    </div>}
                  </div>}
                </div>

                <div className="stats">
                  <div className="label">Entries Submitted</div>
                  <div className="result">{details?.entries}</div>

                  <div className="label">Number of Participants</div>
                  <div className="result">{details?.participants}</div>

                  <div className="label">Launched</div>
                  <div className="result">{moment(details?.createdAt).fromNow()}</div>
                </div>

                <div className="submit">
                  <Button
                    className="bm-btn sm" 
                    variant="primary"
                    disabled={daysLeft(details?.targetDate) <= 0 || details.userId === loggedInUserId || (isLoggedIn && !isVerified)}
                    onClick={showModal}
                  >
                    Submit Your Entry
                  </Button>
                </div>
                {showSubmitEntryModal && (
                  <SubmitEntry
                    show={showSubmitEntryModal}
                    hideModal={setShowSubmitEntryModal}
                    onCloseModal={onCloseModal}
                    fetchDomainsURL={`${FETCH_DOMAINS_URL}/user/${userDetails.id}`}
                    createNewEntryURL={FETCH_ENTRIES_URL}
                    buyRequest={details.id}
                    fetchEntriesURL={FETCH_ENTRIES_URL}
                    setEntriesLoader={setEntriesLoader}
                    setEntries={setEntries}
                  />
                )}
              </div>

              <div className="secure-listing">
                <FontAwesomeIcon icon={faShieldAlt} /> Verified Secure Listing
              </div>

              {/* {!stats && (
                <div className="loader">
                  <Spinner animation="border" variant="primary" role="status" />
                </div>
              )} */}

              {isLoggedIn ? (
                <div className="your-stats">
                  <YourStats
                    stats={stats}
                    error={statsError}
                  />
                </div>
              ): (<div className="your-stats">
                <Link to="/login"> Login</Link> to see your stats
              </div>)}

              {!stats && statsError ? (<div className="error">{statsError}</div>): null }

              <div className="creatives">
                <div className="title">{`${likes} `}
                  <FontAwesomeIcon className={isFavorited ? 'favorite' : ''} icon={faHeart} onClick={toggleFavourite}/>
                </div>
                <div className="subtitle">{`${likes} Brand Namers like this Buy Request`}</div>
              </div>
            </Col>

          </Row>
        </Container>
      </Container>)}  {/* content */}

      <Subscribe />

    </Container>
  );
};

export default React.memo(Contest);
