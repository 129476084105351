import React from 'react';
import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';
import './CustomInputRange.scss';

interface CustomRange {
  /* id: string;
  name: string; */
  classes?: string;
  min: number;
  max: number;
  step?: string;
  value: number;
  onChange: (value: any) => void;
  onChangeComplete: (value: any) => void;
}
const CustomRange = (props: CustomRange) => {

  return (
    <div className={props.classes}>
      <InputRange
        formatLabel={value => `${value === 15 ? `${value}+` : value}`}
        maxValue={props.max}
        minValue={props.min}
        value={props.value}
        onChange={value => (props.onChange(value))} 
        onChangeComplete={value => props.onChangeComplete(value)}
      />
    </div>
  )
}

export default React.memo(CustomRange);
