import  React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// SVGs
import TopWave from 'assets/svgs/Domain/Inner/HowItWorks/top-wave.svg';
import Blob from 'assets/svgs/Domain/Inner/HowItWorks/blob.svg';
import IMG1 from 'assets/svgs/Domain/Inner/HowItWorks/icon2.svg';
import IMG2 from 'assets/svgs/Domain/Inner/HowItWorks/icon1.svg';
import IMG3 from 'assets/svgs/Domain/Inner/HowItWorks/icon3.svg';

// SCSS
import styles from './HowItWorks.module.scss';

interface Props {
  buyable: boolean;
}

const HowItWorks: React.FunctionComponent<Props> = ({ buyable }: Props) => {
  return (
    <div className={styles.hiwRegion}>

      <SVG
        baseURL="/"
        cacheRequests={true}
        className={styles.TopWave}
        description=""
        loader={<span>Loading...</span>}
        src={TopWave}
        title=""
      />

      <Container className={styles.hiwBanner} fluid>
        <Container>

          <Row>
            <Col>
              <div className={styles.hiwRegionTitle}>How It Works</div>
            </Col>
          </Row>

          <Row>
            <Col className={styles.hiwBlock} xl={4} lg={4} md={12} sm={12}>
              <div className={styles.hiwIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.hiwBlob}
                  description=""
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="choose-package"
                  description="Choose Your Package"
                  loader={<span>Loading...</span>}
                  src={IMG1}
                  title="Choose Your Package"
                />
              </div>
              <div className={styles.hiwTitle}>{buyable ? 'Buy it Now' : 'Submit Your Offer'}</div>
              <div className={styles.hiwDescription}>{buyable ? 'Sign into your Brandmo account and click the Buy it Now button via the domain listing page. Provide the requested details that follow.' : 'Submit your offer to acquire the name via the domain listing page. We will email you within 24 hours if your offer has been accepted.'}</div>
            </Col>
            <Col className={styles.hiwBlock} xl={4} lg={4} md={12} sm={12}>
              <div className={styles.hiwIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.hiwBlob}
                  description=""
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="naming-criteria"
                  description="Provide Naming Criteria"
                  loader={<span>Loading...</span>}
                  src={IMG2}
                  title="Provide Naming Criteria"
                />
              </div>
              <div className={styles.hiwTitle}>Make Secure Payment</div>
              <div className={styles.hiwDescription}>{buyable ? 'Choose your preferred payment method and securely make the payment for the domain name. All major credit cards are accepted.' : 'Once your offer has been accepted we will create an Escrow.com transaction so that you can make a secure payment for the name.'}</div>
            </Col>
            <Col className={styles.hiwBlock} xl={4} lg={4} md={12} sm={12}>
              <div className={styles.hiwIcon}>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className={styles.hiwBlob}
                  description=""
                  loader={<span>Loading...</span>}
                  src={Blob}
                  title=""
                />
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="rank-review-names"
                  description="Rank & Review Names"
                  loader={<span>Loading...</span>}
                  src={IMG3}
                  title="Rank & Review Names"
                />
              </div>
              <div className={styles.hiwTitle}>Get Your Brand</div>
              <div className={styles.hiwDescription}>{buyable ? 'Within 24 hours we will initiate the transfer of the name to your account. Once transferred, you assume full ownership of the domain name.' : 'The name will be transferred to your account. After a successful transfer, funds are released and you assume full ownership of the domain.'}</div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default React.memo(HowItWorks);
