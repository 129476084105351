import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import numeral from 'numeral';

// Components
import { Button, Container, Row, Col } from 'react-bootstrap';
import Loader from 'general/Loader/Loader';

// Context
import GlobalContext from 'contexts/Global.context';

// Utils
import { apiRequest } from 'utils/ApiRequest';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faUser, faClock } from '@fortawesome/free-solid-svg-icons';

// SCSS
import styles from './RequestItem.module.scss';

interface Props {
  id: string;
  name?: string;
  title?: string;
  projectType?: string;
  businessDescription?: string;
  budget?: string;
  userName?: string;
  createdAt: string;
  targetDate: string;
  desiredKeywords: string;
  submitCount?: string;
  isFavorite?: boolean | number;
  addToFavouriteURL: string;
  isRecommended?: boolean;
  submissions?: string;
  isExpired: boolean;
}

const getDesiredKeywords = (desireKeywords: string) => {
  let keywords: Array<any> = []

  desireKeywords.split(',').forEach((item, index) => {
    keywords.push(
      <Link to={{
        pathname: '/requests',
        state: item.trim()
      }}
      key={item + index}
      style={{
        marginRight: '10px',
      }}
      >
        <li className={styles.keyWord}>
          {`${item} `}
        </li>
      </Link>
    )
  })
  return keywords
}

const RequestItem: React.FunctionComponent<Props> = (props: Props) => {
  const globalContext = useContext(GlobalContext);

  const [isAdded, setIsAdded] = useState<boolean | number | undefined>(props.isFavorite);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const toggleFavourite = async () => {
    if (globalContext.isLoggedIn) {
      setLoading(true);
      setError('');
      const { response } = await apiRequest(props.addToFavouriteURL, 'POST', JSON.stringify({
        id: props.id,
      }))
      if (response && response.success) {
        if (isAdded) {
          setIsAdded(false);
        } else {
          setIsAdded(true);
        }
        setLoading(false);
      }
    } else {
      setError('Please login to Add to Favorites.');
    }
  }

  const daysLeft = (targetDate: string): number => {
    return moment(targetDate).diff(moment(), 'days') + 1;
  }

  const {
    id,
    name,
    title,
    businessDescription,
    budget,
    userName,
    createdAt,
    targetDate,
    desiredKeywords,
    submitCount,
    isRecommended,
    isExpired
  } = props;

  return (
    <div className={styles.requestedItem}>

      <Container fluid>
        <Row className={isRecommended ? styles.recommended : ''}>
          <Col className="col-10">
            <Link to={`/requests/${id}`}>
              <div className={styles.requestedItemTitle}>
                {title || name}
              </div>
            </Link>
            
          </Col>

          <Col className="text-right">
            <div className={styles.addToFav} onClick={toggleFavourite}>
              {loading && <Loader />}
              {!loading && <FontAwesomeIcon className={isAdded ? styles.favourite : ''} icon={faHeart} />}
              {!loading && error && <div className={styles.error}>{error}</div>}
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.requestedItemDesc}>
              {businessDescription}
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.byUser}>
              <span className={styles.byLabel}>By</span>
              <span className={styles.byUserName}>{userName}</span>
              <span className={styles.byPole}>{' | '}</span>
              <span className={styles.byLabel}>Posted</span>
              <span className={styles.byDate}>{moment(createdAt).fromNow()}</span>
            </div>
          </Col>

          <Col className={styles.keywordArea} xl={9} lg={9} md={9} sm={9}>

            <div className={styles.submittedWrap}>
              <span className={styles.submittedResult}>
                <FontAwesomeIcon icon={faUser} /> {submitCount}
              </span>
              <span className={styles.submittedLabel}>{' submitted'}</span>

              <span style={{marginRight: '3px',}} className={styles.submittedResult}>
                <FontAwesomeIcon icon={faClock} />
                {!isExpired && daysLeft(targetDate) > 0 ? (<span>
                  {` ${daysLeft(targetDate)}d `}
                  <span className={styles.submittedLabel}>left</span>
                </span>) : (<span className={styles.submittedLabel}>{' Closed'}</span>)}
              </span>
            </div>

            <ul className={styles.keywordListing}>
              {desiredKeywords ? getDesiredKeywords(desiredKeywords) : null}
            </ul>
          </Col>

          <Col className={styles.budgetArea} xl={3} lg={3} md={3} sm={3}>
            <div className={styles.buyRequestBudget}>

              <div className={styles.buyRequestBudgetData}>
                <span className={styles.budgetLabel}>{'Budget '}</span>
                <span className={styles.budgetAmount}>{numeral(budget).format('$0,0')}</span>
              </div>

              <Link to={`/requests/${id}`}>
                <Button className={`btn bm-btn ${styles.viewContest}`}>
                  View Buy Request
                </Button>
              </Link>
            </div>
          </Col>

        </Row>
      </Container>

    </div>
  );
};

export default React.memo(RequestItem);
