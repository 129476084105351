
import React, { useEffect, useState, useCallback } from 'react';

import moment from 'moment';

import { Container, Row, Col, Button, FormControl } from 'react-bootstrap';

import { apiRequest } from 'utils/ApiRequest';

// FontAwesome
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faStar } from '@fortawesome/free-solid-svg-icons'; */

import './QuestionsAnnouncements.scss';

interface Props {
  questionsAnnouncementsURL: string;
  isLoggedIn: boolean;
  profilePic: string;
  history: any;
  isVerified: boolean;
}

interface Question {
  id: string;
  question: string;
  type: string;
  answer: string;
  user: any;
  createdAt: string;
  updatedAt: string;
}

const QuestionsAnnouncements: React.FunctionComponent<Props> = ({
  questionsAnnouncementsURL,
  isLoggedIn,
  profilePic,
  history,
  isVerified,
}: Props) => {

  const [fetchLoader, setFetchLoader] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Array<Question> | null>(null);

  const [newQuestion, setNewQuestion] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorWhileAsking, setErrorWhileAsking] = useState<string>('');


  const [error, setError] = useState<string>('');

  const fetchQuestionsAndAnnouncements = useCallback(async () => {
    setFetchLoader(true);
    const { response, error } = await apiRequest(questionsAnnouncementsURL, 'GET');
    if (response) {
      setQuestions(response);
    } else if (error) {
      setError(`There is an error while fetching questions/announcements due to: ${error}`);
    }
    setFetchLoader(false);
  }, [questionsAnnouncementsURL]);

  useEffect(() => {
    fetchQuestionsAndAnnouncements();
  }, [fetchQuestionsAndAnnouncements]);

  const askAQuestion = async () => {
    setErrorWhileAsking('');
    setLoading(true);
    const { response, error } = await apiRequest(questionsAnnouncementsURL, 'POST', JSON.stringify({
      question: newQuestion,
      type: 'question',
    }));
    if (response) {
      fetchQuestionsAndAnnouncements();
    } else if (error) {
      setErrorWhileAsking(`There is an error while asking a question due to: ${error}`);
    }
    setLoading(false);
    setNewQuestion('');
  }

  const submitNewQuestion = () => {
    if (isLoggedIn) {
      if (newQuestion && isVerified) {
        askAQuestion();
      }
    } else {
      history.push('/login?destination='+window.location.pathname);
    }
  }

  return (
    <div id="questionsAnnouncements">
      {!fetchLoader && !questions && error && (<div className="error">{error}</div>)}
      {!fetchLoader && questions && !error && (
        questions.map(question => {
          if (question.type.toLowerCase() === 'question') {
            return (
              <Container key={question.id} >
                <div className="question-post">
                  <Row className="align-items-center">
                    <Col xl={3} lg={3} md={6} sm={6}>
                      <div className="poster">
                        <img
                          className="poster-avatar"
                          src={question.user.profilePicURL || 'https://placehold.co/42x42'}
                          alt="Avatar"
                        />

                        <div className="poster-info">
                          <div className="poster-name">{question.user.userName}</div>
                          <div className="poster-date">{moment(question.createdAt).fromNow()}</div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={9} lg={9} md={6} sm={6}>
                      <div className="question">
                        {question.question}
                      </div>
                      {/* <div className="report">
                        <FontAwesomeIcon icon={faFlag} />
                      Report abuse
                      </div> */}
                    </Col>
                  </Row>
                </div>

                {question.answer && (
                  <Container className="creator-response-post">
                    <Container>
                      <Row>
                        <Col xl={4} lg={4} md={6} sm={6}>
                          <div className="poster">
                            <img
                              className="poster-avatar"
                              src='https://placehold.co/42x42'
                              alt="Avatar"
                            />

                            <div className="poster-info">
                              <div className="poster-name">{question.user.userName}</div>
                              <div className="poster-date">{moment(question.createdAt).fromNow()}</div>
                            </div>
                          </div>
                        </Col>
                        <Col xl={8} lg={8} md={6} sm={6}>
                          <div className="response">{question.answer}</div>
                        </Col>
                      </Row>
                    </Container>
                    
                    {/* <div className="label w-100">
                    <div className="float-xl-left">{question.user.userName}</div>
                    <div className="helpful float-xl-right"><b>3</b> <span>found helpful</span> <FontAwesomeIcon icon={faStar} /></div>
                  </div> */}
                  </Container>)}
              </Container>
            )
          } else {
            return (
              <div className="announcement-post">
                <div className="label">Announcement</div>
                <div className="announcement">
                  {question.question}
                </div>
                <div className="date">{moment(question.createdAt).fromNow()}</div>
              </div>
            )
          }
        }
        ))
      }

      <div className="question-form my-5">
        <Container className="p-0">
          <Row>
            <Col xl={1} lg={1} md={1} sm={1}>
              <img
                className="user-avatar"
                src={profilePic || 'https://placehold.co/48x48'}
                alt="Avatar"
              />
            </Col>
            <Col xl={11} lg={11} md={11} sm={11}>
              <FormControl
                as="textarea"
                aria-label="With textarea"
                value={newQuestion}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNewQuestion(e.target.value)}
              />
              <Button
                className="bm-btn md"
                variant="primary"
                onClick={submitNewQuestion}
                disabled={isLoggedIn && !isVerified}
              >
                {loading ? 'loading...' : 'Ask A Question'}
              </Button>
              {!loading && errorWhileAsking && (
                <div className="error">{errorWhileAsking}</div>
              )}
              
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default React.memo(QuestionsAnnouncements);
