import React from 'react';

interface wrapInterface {
	isLoggedIn: boolean;
	isLoading: boolean;
  logout: (to?: string) => void;
  userDetails: any;
  setAuth: (token: string) => void;
  setLoader: (loading: boolean) => void;
}

const wrap: wrapInterface = {
  isLoggedIn: false,
  isLoading: false,
  logout: () => {},
  userDetails: {},
  setAuth: () => {},
  setLoader: () => {},
};

export default React.createContext(wrap);
