import React, { useEffect, useState } from 'react';

import { Button, Container, Row, Col } from 'react-bootstrap';

import { apiRequest } from 'utils/ApiRequest';

// Constants
import Constants from 'Constants';

// Component
import DomainCard from '../../../../../components/DomainCard/DomainCard';
import Loader from 'general/Loader/Loader';

// SCSS
import styles from './OtherNamesRelated.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  relatedTo: string;
}

interface Domain {
  id: string;
  name: string;
  price: number;
  url: string;
  category: string;
  keywords: string;
  images: Array<string>;
  extension: string;
  total: string;
  verificationCode: string;
  isDeleted: string;
  createdAt: string;
  updatedAt: string;
  isVerified: string;
}

const { apiConstants: { API_URL } } = Constants;

const RecentlySold: React.FunctionComponent<Props> = ({ relatedTo }: Props) => {

  const RELATED_NAMES_URL = `${API_URL}/premium-domains/filter`;

  const [relatedNames, setRelatedNames] = useState<Array<Domain> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchRelatedNames = async () => {
      setLoading(true);
      const { response, error } = await apiRequest(RELATED_NAMES_URL, 'POST', JSON.stringify({
        filters: {
          category: relatedTo,
        },
        skip: 0,
        sortBy: 'likes',
        sortOrder: 'DESC',
      }))
      if (response) {
        setRelatedNames(response);
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    }

    fetchRelatedNames();
  }, [RELATED_NAMES_URL, relatedTo]);

  return (
    <Container id={styles.otherNamesRelated} fluid>
      <Container>
        {loading && <Loader />}
        {!loading && !relatedNames && error && <div className={styles.error}>{error}</div>}

        {!loading && !error && relatedNames && (
          <Row className={styles.titleRegion}>
            <Col>
              {/* @TODO: Need to relate these items to domains selected keywords */}
              <div className={styles.title}>Other Names Related to &quot;{relatedTo}&quot;</div>
            </Col>
          </Row>
        )}

        {!loading && !error && relatedNames && (
          <Row>
            {relatedNames.slice(0, 4).map(domain => (
              <DomainCard
                key={domain.id}
                id={domain.id}
                preview={(domain.images && domain?.images[0]) || 'https://placehold.co/270x200'}
                title={domain.name}
                price={domain.price}
                url={domain.url}
              />
            ))}
          </Row>
        )}

        {!loading && !error && relatedNames && (
          <Row className="d-flex justify-content-center align-items-center mt-5">
            <Link to={`/domain-listing?filterByCategory=${relatedTo.replace(/&/g, '--')}`}>
              <Button
                className={`btn bm-btn md ${styles.moreButton}`}
                disabled={parseInt(relatedNames[0].total) <= 4}
              >
              View All {relatedTo} Domains
              </Button>
            </Link>
            
          </Row>
        )}

      </Container>
    </Container>
  );
};

export default React.memo(RecentlySold);
