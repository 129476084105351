import React, { createContext, useReducer, useCallback } from 'react';

// Reducers
import BuyRequestReducer from './Reducers/BuyRequestReducer';

// Interface
import { Offer } from 'interfaces/Interfaces';

interface Message {
  sender: string;
  body: string;
  createdAt?: string;
  photo?: string;
  profilePicURL?: string;
}

const initialState: any = {
  buyRequestDetails: null,
  entries: null,
  error: null,
  loading: false,
  entriesLoader: false,
  ratingStats: null,
}

export const BuyRequestContext = createContext(initialState);

export const BuyRequestProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(BuyRequestReducer, initialState);

  const setLoader = useCallback((loader: boolean) => {
    dispatch({
      type: 'SET_BUY_REQUEST_LOADER',
      payload: { loader }
    })
  }, []);

  const setEntriesLoader = useCallback((loader: boolean) => {
    dispatch({
      type: 'ENTRIES_LOADER',
      payload: { loader }
    })
  }, []);

  const setBuyRequestDetails = useCallback((details: any, error: string) => {
    if (details) {
      dispatch({
        type: 'SET_BUY_REQUEST_DETAILS',
        payload: { details },
      })
    } else if (error) {
      dispatch({
        type: 'SET_BUY_REQUEST_DETAILS_ERROR',
        payload: { error },
      })
    }
  }, []);

  const setEntries = useCallback((response: any, error: string) => {
    const { entries, stats } = response;
    if (entries) {
      dispatch({
        type: 'SET_ENTRIES_FOR_BUY_REQUEST',
        payload: { entries, ratingStats: stats },
      })
    } else if (error) {
      dispatch({
        type: 'SET_ENTRIES_FOR_BUY_REQUEST_ERROR',
        payload: { error },
      })
    }
  }, []);

  const setRatingToAnEntry = useCallback((entryId: string, rating: number, error: string) => {
    if (rating) {
      dispatch({
        type: 'SET_A_RATING_TO_AN_ENTRY',
        payload: { entryId, rating },
      })
    } else if (error) {
      dispatch({
        type: 'SET_A_RATING_TO_AN_ENTRY_ERROR',
        payload: { error },
      })
    }
  }, []);

  const sendMessageOnBuyRequest = useCallback((entryId: string, message: Message, error: string) => {
    if (message) {
      dispatch({
        type: 'SET_MSG_ON_BUY_REQUEST',
        payload: { entryId, message },
      })
    } else if (error) {
      dispatch({
        type: 'SET_MSG_ON_BUY_REQUEST_ERROR',
        payload: { error },
      })
    }
  }, []);

  const setOffersOnEntry = useCallback((entryId: string, offer: Offer, error?: string) => {
    if (offer) {
      dispatch({
        type: 'SET_OFFERS_ON_ENTRY',
        payload: { entryId, offer },
      })
    } else if (error) {
      dispatch({
        type: 'SET_OFFERS_ON_ENTRY_ERROR',
        payload: { error },
      })
    }
  }, []);

  return (
    <BuyRequestContext.Provider value={{
      buyRequestDetails: state.buyRequestDetails,
      error: state.error,
      loading: state.loading,
      entries: state.entries,
      ratingStats: state.ratingStats,
      entriesLoader: state.entriesLoader,
      setLoader,
      setBuyRequestDetails,
      setEntriesLoader,
      setEntries,
      setRatingToAnEntry,
      sendMessageOnBuyRequest,
      setOffersOnEntry,
    }}>
      {children}
    </BuyRequestContext.Provider>)
}
