import React, { useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab, } from 'react-bootstrap';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

// Page Components
import FavoriteContests from './FavoriteContests/FavoriteContests';
import FavoriteDomains from './FavoriteDomains/FavoriteDomains';
import FavoritePremiumDomains from './FavoritePremiumDomains/FavoritePremiumDomains';

// SCSS
import './Favorites.scss';

const Favorites: React.FunctionComponent = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Favorites",
    });
  }, [])

  return (
    <Container id="favorites" fluid>
      <Helmet>
        <title>
          Favorites | Brandmo
        </title>
      </Helmet>
      <div className="section-title">Favorites</div>

      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Tabs id="myFavoritesTabs" defaultActiveKey="favoriteRequests">
            <Tab eventKey="favoriteRequests" title="Favorite Buy Requests">
              <FavoriteContests />
            </Tab>

            <Tab eventKey="favoriteDomains" title="Favorite Submissions">
              <FavoriteDomains />
            </Tab>

            <Tab eventKey="favoritePremiumDomains" title="Favorite Domains">
              <FavoritePremiumDomains />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Favorites);
