import React from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

import { Container, Row, Col, Button } from 'react-bootstrap';

import styles from './TransactionRow.module.scss';

interface SingleTransaction {
  amount: number;
  buyer: {
    activePlan: string;
    billingAddress: string;
    billingCountry: string;
    billingName: string;
    billingState: string;
    billingZIP: string;
    createdAt: string;
    email: string;
    escrowId: number;
    firstName: string;
    id: string;
    isAdmin: boolean;
    isDeleted: boolean;
    isVerified: boolean;
    lastName: string;
    paymentId: string;
    paymentMethod: string;
    profilePicURL: string;
    updatedAt: string;
    userName: string;
  };
  createdAt: string;
  domain: string;
  id: string;
  isDeleted: boolean;
  remark: string;
  seller: {
    activePlan: string;
    billingAddress: string;
    billingCountry: string;
    billingName: string;
    billingState: string;
    billingZIP: string;
    createdAt: string;
    email: string;
    escrowId: number;
    firstName: string;
    id: string;
    isAdmin: boolean;
    isDeleted: boolean;
    isVerified: boolean;
    lastName: string;
    paymentId: string;
    paymentMethod: string;
    profilePicURL: string;
    updatedAt: string;
    userName: string;
  }
  status: string;
  transactionId: string;
  type: string;
  updatedAt: string;
}

interface Props {
  transaction: SingleTransaction;
  loggedInUserId: string;
}

const TransactionRow: React.FunctionComponent<Props> = ({ transaction, loggedInUserId }: Props) => {
  return (
    <Container>
      <Row className={styles.transactionRow}>
        <Col xl={6} lg={6} md={12} sm={12}>
          <Row>
            <Col xl={6} lg={6} md={6} sm={6} className={`${styles.domain} ${styles.rowData}`}>
              <div className="domain"><b>{transaction.domain}</b></div>
              <div className="partner">{transaction.buyer.id === loggedInUserId ? `Seller: ${transaction.seller.userName}` : `Buyer: ${transaction.buyer.userName}`}</div>
            </Col>

            <Col xl={3} lg={3} md={6} sm={6} className={`py-xl-0 py-lg-0 py-md-3 py-sm-3 py-3 ${styles.rowData}`}>
              <div className="amount"><b>{numeral(transaction.amount).format('$0,0')}</b></div>
            </Col>

            <Col xl={3} lg={3} md={6} sm={6} className={`${styles.status} ${styles.rowData}`}>
              <div className="status"><b>{transaction.status}</b></div>
            </Col>

          </Row>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12}>
          <Row>
            <Col xl={6} lg={6} md={6} sm={6} className={`py-xl-0 py-lg-0 py-md-3 py-sm-3 py-3 ${styles.rowData}`}>
              <Link to="/dashboard/messages">
                <Button>
                  Send Message
                </Button>
              </Link>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} className={`py-xl-0 py-lg-0 py-md-3 py-sm-3 py-3 ${styles.rowData}`}>
              <Link to={`/purchase-status/${transaction.transactionId}`}>
                <Button>
                  Details
                </Button>
              </Link>
              
            </Col>
          </Row>
        </Col>

      </Row>
    </Container>
  )
}

export default TransactionRow;
