import  React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

// Page Components
import QA from '../../../../general/FAQ/FAQ';

// SCSS
import styles from './FAQBlock.module.scss';

const FAQ: React.FunctionComponent = () => {
  return (
    <Container className={styles.faqRegion}>

      <Row>
        <Col className={styles.faqRegionTitle} xl={12} lg={12} md={12} sm={12}>
          FAQ
        </Col>
      </Row>

      <Row>
        <Col className={styles.faqWrapper}>
          <Accordion defaultActiveKey="0">

            <QA
              eventKey="0"
              question="How long will my brand request run for?"
              answer="By default all brand requests are set to run for thirty days. If you need a one-time extension or you need to pause your buy request before the scheduled end date please get in touch via our contact page."
            />

            <QA
              eventKey="1"
              question="Who will submit names to my brand request?"
              answer="In addition to Brandmo's internal team of naming experts who will be working on your request the Brandmo community is made up of a wide variety of professionals with a range of backgrounds including individual domain owners, domain name brokers, portfolio holders, branding firms, freelancers and more. Your brand request will be marketed through a variety of channels depending on the package you select, exposing your brand request to a vast network of branding experts. "
            />

            <QA
              eventKey="2"
              question="Does Brandmo charge a success fee?"
              answer="Yes, Brandmo charges Buyers a 10% success fee when using the brand request feature to acquire a name. This fee is calculated as 10% of the total purchase price and is only invoiced if an acquisition is made on one of the entries submitted to your brand request. Marketplace acquisitions do not incur any success fee. "
            />

            <QA
              eventKey="3"
              question="Can Brandmo provide open registration names?"
              answer="Some clients have requested open registration naming options, that is domain names that are still available for registration. The quality of open registration names is typically lower than that of registered names, as the philosophy goes most of the best names have already been registered. In certain circumstances, however, it may still be possible to acquire a quality domain from open registration. If you feel you need to explore open registration naming options, please get in touch via our contact page. "
            />

            <QA
              eventKey="4"
              question="How does escrow & closing work?"
              answer="Following the acceptance of an offer to acquire a name, the parties will be routed to a Private Sales Completion Area where they may communicate, access escrow.com links and view the escrow status. Buyers may fund escrow safely and securely via bank wire or credit card. Once the funds are secured in the escrow vault, the seller can initiate the secure transfer of the name. After the name is received buyers assume full ownership, funds are released and the transaction is complete."
            />

            <QA
              eventKey="5"
              question="What other types of branding services does Brandmo offer?"
              answer="Brandmo offers a wide range of branding services falling under various categories of brand acquisitions,  brand development, brand identity, brand marketing, and brand security. These services encompass a wide range of projects including tagline creation, logo design, website & eCommerce branding, packaging design, domain name management, social media branding, and more. If you would like to discuss a custom branding project please get in touch, we would love to hear from you. "
            />

          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
