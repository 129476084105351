import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// Utils
import { apiRequest } from 'utils/ApiRequest';

// Page Components
import Header from '../SettingsHeader/Header';
import FormInput from 'general/FormInput/FormInput';
import SubmitButton from 'general/SubmitButton/SubmitButton';

// SCSS
import styles from './PersonalDetails.module.scss';

interface Props {
  firstname: string;
  lastname: string;
  apiURL: string;
  setNewToken: (token: string) => void;
}

interface Names {
  firstName: string;
  lastName: string;
}

interface APIResponse {
  token: string;
  id: string;
}

const PERSONAL_DETAILS_ERRORS: any = {
  firstName: 'Please enter your first name',
  lastName: 'Please enter your last name',
}

const PersonalDetails: React.FC<Props> = ({ firstname, lastname, apiURL, setNewToken }: Props) => {
  const [names, setNames] = useState<Names | any>({
    firstName: firstname,
    lastName: lastname,
  });

  const [errors, setErrors] = useState<Names>({
    firstName: '',
    lastName: '',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const [updateSuccessfull, setUpdateSuccessfull] = useState<boolean>(false);

  const apiConfig = {
    method: 'PUT',
    body: JSON.stringify({ ...names }),
  }

  const updateProfileDetails = async () => {
    setLoading(true);
    setUpdateSuccessfull(false);
    try {
      const { response, error } = await apiRequest(apiURL, apiConfig.method, apiConfig.body);
      if (response) {
        if (response.token !== localStorage.getItem('token')) {
          setNewToken(response.token);
        }
        setUpdateSuccessfull(true);
      } else if (error) {
        setApiError(error);
      }
    } catch(e) {
      setApiError(e.toString())
    }
    
    setLoading(false);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!value) {
      setErrors({
        ...errors,
        [name]: PERSONAL_DETAILS_ERRORS[name],
      })
    } else {
      if (value.length > 20) {
        setErrors({
          ...errors,
          [name]: 'Maximum 20 characters are allowed',
        })
      } else {
        setErrors({
          ...errors,
          [name]: '',
        })
      }
    }
    setNames({
      ...names,
      [name]: value,
    })
  }

  const validateData = () => {
    if (names.firstName
      && names.firstName.length <= 20
      && names.lastName
      && names.lastName.length <= 20
      && (firstname !== names.firstName || lastname !== names.lastName)
    ) {
      return true;
    }

    let formErrors: any = {}
    Object.keys(names).map(field => {
      if (!names[field]) {
        switch (field) {
        case 'firstName':
          formErrors[field] = PERSONAL_DETAILS_ERRORS[field]
          break;
          
        case 'lastName':
          formErrors[field] = PERSONAL_DETAILS_ERRORS[field]
          break;

        default:
          break;
        }
        setErrors({
          ...errors,
          ...formErrors,
        })
      }
      return field;
    })
    return false;

  }

  const handleSubmit = () => {
    if (validateData()) {
      updateProfileDetails();
    }
  }

  return(
    <Container className={styles.personalDetails}>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Col xl={12} lg={12} md={12} sm={12}>
            <form>
              <Header title="PERSONAL DETAILS"/>
              <div className={styles.prefLabel}>First Name</div>
              <FormInput
                name="firstName"
                placeholder="First Name"
                label="account-firstname"
                value={names.firstName}
                onChange={handleChange}
                large
              />
              {errors.firstName && <div className={styles.error}>{errors.firstName}</div>}

              <div className={styles.prefLabel}>Last Name</div>
              <FormInput
                name="lastName"
                placeholder="Last Name"
                label="account-lastname"
                value={names.lastName}
                onChange={handleChange}
                large
              />
              {errors.lastName && <div className={`${styles.error} mb-3`}>{errors.lastName}</div>}

              <SubmitButton isLoading={loading} onSubmit={handleSubmit} />
              {apiError && <div className={`${styles.error} ${styles.apiError}`}>{apiError}</div>}
              {updateSuccessfull && <div className={`${styles.updatedSuccessfully}`}>Information Updated Successfully</div>}
            </form>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(PersonalDetails);
