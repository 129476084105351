import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import scrollToHandler from 'utils/ScrollHandler';

import Stepper from './components/Stepper/Stepper';
import QuestionnaireForm from './components/QuestionnaireForm/QuestionnaireForm';

import Constants from 'Constants';

import './BuyRequestQuestionnaire.scss';

const { STRIPE_KEY } = Constants;

const STRIPE_LOAD = loadStripe(STRIPE_KEY);

const BuyRequestQuestionnaire: React.FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [stepsCompleted, setStepsCompleted] = useState<Array<number>>([]);
  const [isBuyRequestCreated, setIsBuyRequestCreated] = useState<boolean>(false);

  const setCurrentPageAndSteps = (step: number) => {
    setCurrentPage(step);
    setStepsCompleted(
      [...stepsCompleted, step]
    )
  }

  useEffect(() => {
    scrollToHandler();
  }, [currentPage]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Launch Buy Request",
    });
  }, []);

  return (
    <Container id="questionnaire" fluid>
      <Helmet>
        <title>
          Launch Buy Request | Brandmo
        </title>
      </Helmet>

      {!isBuyRequestCreated && (<Container id="header">
        <Row>

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="title">Answer just a few <span>questions.</span></div>
            <div className="description">Please fill out the following questionnaire with as many details as possible.
            Feel free to contact us with any questions, we&apos;re here to help.</div>
          </Col>

        </Row>
      </Container>)}

      {!isBuyRequestCreated && (<Container id="pageCounter">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Stepper stepsCompleted={stepsCompleted} />
          </Col>
        </Row>
      </Container>)}

      <Elements stripe={STRIPE_LOAD}>
        <QuestionnaireForm
          page={currentPage}
          // setCurrentPage={setCurrentPage}
          setCurrentPage={setCurrentPageAndSteps}
          setIsBuyRequestCreated={setIsBuyRequestCreated}
        />
      </Elements>
    </Container>
  );
};

export default React.memo(BuyRequestQuestionnaire);
