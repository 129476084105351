import React from 'react';

import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import Radio from '../../../../../general/CustomRadio/CustomRadio';

// SCSS
import styles from './MessageItem.module.scss';

interface MessageItem {
  id: string;
  avatar: string;
  messageLink: string;
  title: string;
  timeAgo: string;
  userName: string;
  isThreadRead: boolean;
}

const MessageItem = (props: MessageItem) => {
  return (
    <Row className={styles.messageItem}>
      <Col className="d-xl-block d-lg-block d-md-block d-sm-none d-none col-1">
        <div className={styles.messageAvatar}>
          <img src={props.avatar} alt={props.userName} />
        </div>
      </Col>
      <Col className="col-10">
        <div className={styles.messageTitle}>
          <Link to={props.messageLink}>
            <div className={styles.messageTitleWrap}>
              {props.title}
            </div>
          </Link>
        </div>
        <div className={styles.messageAgo}>
          {props.timeAgo}
        </div>
        <div className={styles.messageFrom}>
          {props.userName}
        </div>
      </Col>
      <Col className="pr-0 col-1">
        <Radio
          id={props.id}
          name={props.id}
          label=""
          isChecked={props.isThreadRead}
          changeCallback={() => {}}
        />
      </Col>
    </Row>
  )
}

export default React.memo(MessageItem);
