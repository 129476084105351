import React from 'react';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

// SCSS
import styles from './CustomFile.module.scss';

interface InputCheck {
  label?: string;
  file?: File | null;
  handleFileChnage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// const InputCheck = (props: InputCheck) => {
const InputCheck = (props: InputCheck) => {
  // Comment for Devs - There needs to be some sort of text replacement for "Attach File"
  // to confirm that a file was selected after choosing one.
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleFileChnage(event);
  }

  const getLabel = () => {
    if (props.file) {
      return props.file.name;
    } else if (props.label) {
      return props.label;
    } else {
      return 'Attach File';
    }
  }

  return (
    <div className={`mb-3 ${styles.bmFile}`}>
      <input
        type="file"
        name="file"
        id="file"
        accept=".png,.jpg,.jpeg,.gif,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        onChange={handleOnChange}
      />
      <label
        htmlFor="file"
        className={props.file ? styles.fileSelected : ''}
      >
        {!props.file && <FontAwesomeIcon icon={faPlus} />}
        {getLabel()}
      </label>
    </div>
  )
}

export default React.memo(InputCheck);
