import React, { useState, useEffect, useContext } from 'react';

import { useRouteMatch, useLocation } from 'react-router-dom';
import moment from 'moment';
import GlobalContext from 'contexts/Global.context';
import { Container, Row, Col } from 'react-bootstrap';

import { Helmet } from 'react-helmet';

// Page Components
import Input from '../../../general/FormInput/FormInput';
import MessageItem from './components/MessageItem/MessageItem';

// Utils
import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

// SCSS
import styles from './Messages.module.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Loader from 'general/Loader/Loader';

interface Message {
  body: string;
  buyRequestEntryId: string;
  createdAt: string;
  id: string;
  isDeleted: boolean;
  parentId: string | null;
  profilePicURL: string;
  read: boolean;
  receiverId: string;
  senderId: string;
  submissionId?: string;
  escrowId?: string;
  total: number;
  updatedAt: string;
  userName: string;
}

const { apiConstants: { API_URL }, pageConstants: { ITEMS_ON_DASHBOARD }} = Constants;

const MESSAGES_URL = `${API_URL}/messages?limit=${ITEMS_ON_DASHBOARD}&skip=0`;

const Messages: React.FunctionComponent = () => {

  const { url } = useRouteMatch();

  const { state: unreadMessages } = useLocation();

  const { userDetails: { id: loggedInUserId } } = useContext(GlobalContext);

  const [messageURL, setMessageURL] = useState<string>(MESSAGES_URL);

  const newUrl = new URL(messageURL);

  const [searchMessages, setSearchMessages] = useState<string>('');

  const [messages, setMessages] = useState<Array<Message> | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === 'searchMessage') {
      setSearchMessages(value);
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13 || event.charCode === 13) {
      searchMessageThread();
    }
  }

  const showUnreadMessages = () => {
    if (newUrl.searchParams.has('unread')) {
      newUrl.searchParams.set('unread', 'true');
      setMessageURL(newUrl.href);
    } else {
      newUrl.searchParams.append('unread', 'true');
      setMessageURL(newUrl.href);
    }
  }

  const searchMessageThread = () => {
    if (newUrl.searchParams.has('body')) {
      newUrl.searchParams.set('body', searchMessages);
      setMessageURL(newUrl.href);
    } else {
      newUrl.searchParams.append('body', searchMessages);
      setMessageURL(newUrl.href);
    }
  }

  const showAllMessages = () => {
    if (newUrl.searchParams.has('unread')) {
      newUrl.searchParams.delete('unread');
      setMessageURL(newUrl.href);
    }
  }

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      const { response } = await apiRequest(messageURL, 'GET');
      if (response) {
        setMessages(response);
      }
      setLoading(false);
    }

    fetchMessages();
  }, [messageURL])

  useEffect(() => {
    window.analytics.page('Messages', {
      title: 'Messages',
      path: '/dashboard/messages',
    });
  }, []);

  const appendDomainId = (thread: Message) => {
    if (thread.submissionId) {
      return `?submissionId=${thread.submissionId}`;
    }
    if (thread.escrowId) {
      return `?escrowId=${thread.escrowId}`;
    }
    return '';
  }

  return (
    <Container className={styles.dashWrapper} fluid>
      <Helmet>
        <title>
          Messages | Brandmo
        </title>
      </Helmet>
      <Container className={styles.dashInner}>

        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.sectionTitle}>Messages</div>
            <Row className="m-0">
              <Col className={styles.messagesContainer}>

                <div className={styles.messagesFilters}>
                  <Container>
                    <Row className="align-items-center">

                      <Col className={`pl-0 ${styles.textFilter}`} xl={7} lg={7} md={7} sm={12}>
                        <ul className={styles.textFilterList}>
                          <li className={styles.filterUnread} onClick={showUnreadMessages}>
                            <div>Unread (
                              <span className={styles.notZero}>
                                {(unreadMessages) || 0}
                              </span>)
                            </div>
                          </li>
                          <li className={styles.filterAll} onClick={showAllMessages}>
                            <div>All</div>
                          </li>
                        </ul>
                      </Col>
                      <Col className={`px-0 ${styles.addStyling} ${styles.searchFilter}`} xl={5} lg={5} md={5} sm={12}>
                        <Input
                          name="searchMessage"
                          placeholder="Search Messages"
                          label="Search Messages"
                          value={searchMessages}
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />

                        <div className={styles.searchButton} onClick={searchMessages ? searchMessageThread : () => {}}>
                          <FontAwesomeIcon icon={ faSearch } />
                        </div>
                      </Col>

                    </Row>
                  </Container>
                </div>

                <div className={styles.messagesWrapper}>
                  {loading && <Loader />}
                  {!loading && messages && messages.map((thread: Message) => (
                    <MessageItem
                      key={thread.id}
                      id={thread.id}
                      messageLink={`${url}/${loggedInUserId === thread.senderId ? thread.receiverId : thread.senderId }${appendDomainId(thread)}`}
                      avatar={thread.profilePicURL || 'https://placehold.co/55x55'}
                      title={thread.body}
                      timeAgo={moment(thread.createdAt).fromNow()}
                      userName={thread.userName}
                      isThreadRead={thread.read}
                    />
                  ))}
                  {!loading && messages && !messages.length && (
                    <div className={styles.noMessages}>No Messages Found</div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default React.memo(Messages);
