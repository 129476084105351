import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Helmet } from 'react-helmet';

// Page Components
import GetInTouch from '~/../components/GetInTouch/GetInTouch';
import HowItWorks from '~/../components/HowItWorks/HowItWorks';
import FAQ from './components/FAQBlock/FAQBlock';
import NamingExamples from '~/../components/NamingExamples/NamingExamples'
import Subscribe from '~/../components/Subscribe/Subscribe';

import scrollToHandler from 'utils/ScrollHandler';

// SCSS
import styles from './About.module.scss';

const About: React.FunctionComponent = () => {

  useEffect(() => {
    scrollToHandler('');
  }, []);

  return (
    <Container className={`p-0 ${styles.aboutPage}`} fluid>
      <Helmet>
        <title>
          About Us | Brandmo
        </title>
      </Helmet>

      <Container>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.headerTitle}>
              Introducing a new branding experience to the public.
            </div>
          </Col>

          <Col className={styles.headerData}>
            <Row>
              <Col xl={4} lg={4} md={12} sm={12}>
                <div className={styles.headerLeft}>
                Transforming ideas into brands with personality.
                </div>
              </Col>
              <Col xl={1} lg={1} md sm></Col>
              <Col xl={7} lg={7} md={12} sm={12}>
                <div className={styles.headerRight}>
                  <p>
                    We build, manage, and create the world’s most dynamic brands. Our clients include Fortune 500 companies and venture backed startups as well as first-time entrepreneurs. No matter what type of brand you are building, we are up for the challenge and our branding experts are standing by ready to help you with your project.
                  </p>
                  <p>
                  If you would like to discuss a custom branding job or are interested in selling your domain names on the Brandmo marketplace, please send us a message via the contact page. 
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Row className="m-0">
        <Col className="p-0" xl={12} lg={12} md={12} sm={12}>
          <GetInTouch />
        </Col>

        <Col className="p-0" xl={12} lg={12} md={12} sm={12}>
          <HowItWorks />
        </Col>

        <Col className="p-0" xl={12} lg={12} md={12} sm={12}>
          <FAQ />
        </Col>

        <Col className="p-0" xl={12} lg={12} md={12} sm={12}>
          <NamingExamples />
        </Col>

        <Col className="p-0" xl={12} lg={12} md={12} sm={12}>
          <Subscribe />
        </Col>
      </Row>

    </Container>
  );
};

export default React.memo(About);
