import React, { useState, useEffect, useRef, useContext } from 'react';

import { useParams, useLocation, Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

import numeral from 'numeral';

import { Button, Container, Row, Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

import { apiRequest } from 'utils/ApiRequest';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import Constants from 'Constants';

// Utils
import scrollToHandler from 'utils/ScrollHandler';

// Page Components
import Gallery from './components/Gallery/Gallery';
import FormInput from '../../../general/FormInput/FormInput';
import TextArea from '../../../general/CustomTextArea/CustomTextArea';
import HowItWorks from './components/HowItWorks/HowItWorks';
import DesignConcept from './components/DesignConcept/DesignConcept';
import OtherNamesRelated from './components/OtherNamesRelated/OtherNamesRelated';
import FAQ from './components/FAQBlock/FAQBlock';
import RecentlySold from '../../../components/RecentlySold/RecentlySold';
import Subscribe from '../../../components/Subscribe/Subscribe';
import Loader from 'general/Loader/Loader';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

// SCSS
import styles from './DomainInner.module.scss';

interface Domain {
  id: string;
  name: string;
  price?: number;
  category: string;
  keywords: string;
  images: Array<string>;
  extension: string;
  total: string;
  verificationCode: string;
  nameIncluded: boolean;
  conceptIncluded: boolean;
  logoIncluded: boolean;
  inquiryOnly: boolean;
  designConcepts: Array<string>;
  isDeleted: string;
  createdAt: string;
  updatedAt: string;
  isVerified: string;
  soldAt: string | null;
  shortDescription: string;
  description: string;
  url?: string;
}

const {
  apiConstants: { API_URL },
  regExValidators: { EMAIL_VALIDATOR_REGEX },
  brandmoSocialLinks,
} = Constants;

const ADD_PREMIUM_DOMAIN_AS_FAV_URL = `${API_URL}/premium-domains/favorite`;

const MAKE_AN_INQUIRY_URL = `${API_URL}/premium-domains/inquiry`;

const SHARE_OVER_SOCIAL_SITES = [
  {
    name: 'Twitter',
    url: `${brandmoSocialLinks.TWITTER}/intent/tweet?url=${window.location.href}`,
    icon: faTwitter
  },
  {
    name: 'Facebook',
    url: `${brandmoSocialLinks.FACEBOOK}/sharer/sharer.php?u=${window.location.href}`,
    icon: faFacebook
  },
  {
    name: 'LinkedIn',
    url: `${brandmoSocialLinks.LINKEDIN}/sharing/share-offsite/?url=${window.location.href}`,
    icon: faLinkedinIn
  },
]

const DomainInner: React.FunctionComponent = () => {

  const gloabalContext = useContext(GlobalContext);
  const { userDetails, isLoggedIn } = gloabalContext;

  const location = useLocation();
  const domainDetailRef = useRef(null);

  const { url } = useParams();

  const { id: userId, firstName, lastName , email, isVerified } = userDetails;

  const FETCH_DOMAIN_DETAILS_URL = `${API_URL}/premium-domains/${url}/url`;

  const FETCH_FAV_PREMIUM_DOMAINS_URL = `${API_URL}/premium-domains/`;

  const [loading, setLoading] = useState<boolean>(false);
  const [domainDetails, setDomainDetails] = useState<Domain | null>(null);
  const [error, setError] = useState<string>('');

  const [inquiryName, setInquiryName] = useState<string>('');
  const [inquiryEmail, setInquiryEmail] = useState<string>('');
  const [inquiryPhone, setInquiryPhone] = useState<string>('');
  const [inquiryComments, setInquiryComments] = useState<string>('');

  const [inquiryErrors, setInquiryErrors] = useState<any>({
    inquiryName: '',
    inquiryEmail: '',
    inquiryPhone: '',
    inquiryComments: '',
  })

  const [thumbnail, setThumbnail] = useState<string>('');

  const [favLoading, setFavLoading] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);

  const [inquirySuccessfull, setInquirySuccessfull] = useState<boolean>(false);
  const [inquiryLoading, setInquiryLoading] = useState<boolean>(false);
  const [inquiryError, setInquiryError] = useState<string>('');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target

    if (name === 'inquiryName') {
      if (!value) {
        setInquiryErrors({
          ...inquiryErrors,
          [name]: 'Please enter your name'
        })
      } else {
        setInquiryErrors({
          ...inquiryErrors,
          [name]: ''
        })
      }
      setInquiryName(value);
    }

    if (name === 'inquiryEmail') {
      if (value) {
        setInquiryEmail(value);
        if (!EMAIL_VALIDATOR_REGEX.test(value)) {
          setInquiryErrors({
            ...inquiryErrors,
            [name]: 'Please enter a valid email address'
          })
        } else {
          setInquiryErrors({
            ...inquiryErrors,
            [name]: ''
          })
        }
      } else {
        setInquiryEmail(value);
        setInquiryErrors({
          ...inquiryErrors,
          [name]: 'Please enter your email'
        })
      }
    }

    if (name === 'inquiryPhone') {
      if (!value) {
        setInquiryErrors({
          ...inquiryErrors,
          [name]: 'Please enter your phone number'
        })
      } else {
        setInquiryErrors({
          ...inquiryErrors,
          [name]: ''
        })
      }
      setInquiryPhone(value);
    }

    if (name === 'inquiryComments') {
      if (!value) {
        setInquiryErrors({
          ...inquiryErrors,
          [name]: 'Please enter comments'
        })
      } else {
        setInquiryErrors({
          ...inquiryErrors,
          [name]: ''
        })
      }
      setInquiryComments(value);
    }
  }

  const toggleFavourite = async (id: string) => {
    setFavLoading(true);
    const { response } = await apiRequest(ADD_PREMIUM_DOMAIN_AS_FAV_URL, 'POST', JSON.stringify({
      id,
    }))
    if (response && response.success) {
      if (isAdded) {
        setIsAdded(false);
      } else {
        setIsAdded(true);
      }
    }
    setFavLoading(false);
  }

  const isDisabled = () => {
    if (!inquiryEmail || !EMAIL_VALIDATOR_REGEX.test(inquiryEmail) || !inquiryName || !inquiryPhone || !inquiryComments) {
      return true;
    }
  }

  const makeAnInquiry = async () => {
    setInquiryLoading(true);
    const apiBody = {
      email: inquiryEmail,
      name: inquiryName,
      phone: inquiryPhone,
      comments: inquiryComments,
      domain: domainDetails?.name,
    }
    const { response, error } = await apiRequest(MAKE_AN_INQUIRY_URL, 'POST', JSON.stringify({
      ...apiBody,
    }))
    if (response && response.result === 'success') {
      setInquirySuccessfull(true);
    } else if (error) {
      setInquiryError(error);
    }
    setInquiryLoading(false);
  }

  const createMarkup = (title: string) => {
    return { __html: title };
  }

  useEffect(() => {
    scrollToHandler();
  }, [location]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Premium Domain Details",
    });
  }, []);

  useEffect(() =>{
    const fetchDomainDetails = async () => {
      setLoading(true);
      const { response, error } = await apiRequest(FETCH_DOMAIN_DETAILS_URL, 'GET');
      if (response) {
        setDomainDetails(response);
        setThumbnail(response.images && response.images[0] ? response.images[0] : 'https://placehold.co/880x440');
        if (userId) {
          const { response: favResponse } = await apiRequest(`${FETCH_FAV_PREMIUM_DOMAINS_URL}${response.id}?userId=${userId}`, 'GET');
          if (favResponse) {
            setIsAdded(Boolean(favResponse.isFavorite));
          }
        }
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    }

    fetchDomainDetails();
    if (isLoggedIn) {
      setInquiryName(`${firstName} ${lastName}`);
      setInquiryEmail(email);
    }
  }, [
    FETCH_DOMAIN_DETAILS_URL,
    FETCH_FAV_PREMIUM_DOMAINS_URL,
    isLoggedIn,
    email,
    firstName,
    lastName,
    userId,
  ])

  if (loading && !domainDetails) {
    return (
      <Container className={styles.domainInner} fluid>
        <Loader />
      </Container>
    )
  }

  if (error && !loading && !domainDetails) {
    return (
      <Container className={styles.domainInner} fluid>
        <div className={styles.error}>
          {error}
        </div>
      </Container>
    )
  }

  return (
    <Container className="p-0" fluid ref={domainDetailRef}>
      <Container className={styles.domainInner} fluid>
        <Container>

          <Row className={styles.titleRegion}>
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.subTitle}>For Sale</div>
              <div className={styles.title}>{domainDetails?.name}</div>
            </Col>
          </Row>
          {domainDetails && (<Row>
            <Helmet>
              <title>
                {domainDetails?.name} is For Sale | Brandmo
              </title>
            </Helmet>
            <Col xl={8} lg={8} md={12} sm={12}>
              <div className={styles.contentWrap}>
                <div className={styles.mainImage}>
                  <img
                    // src={domainDetails?.images && domainDetails?.images[0] ? domainDetails?.images[0] : 'https://placehold.co/880x440'}
                    src={thumbnail}
                    alt="Placeholder"
                    className="img-fluid"
                    onClick={handleShow}
                  />
                </div>
                <div className={styles.otherImages}>
                  {domainDetails && domainDetails.images && domainDetails.images.map(image => (
                    <Gallery
                      key={image}
                      preview={image || 'https://placehold.co/140x90'}
                      // full={image || 'https://placehold.co/4000x2000'}
                      setThumbnail={setThumbnail}
                      alt="testing"
                    />
                  ))}
                  <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName={styles.modal90}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Body>
                      <img src={thumbnail} alt={thumbnail} className="img-fluid" />
                    </Modal.Body>
                  </Modal>
                </div>

                <div className={styles.contentTitle}>
                  {domainDetails?.shortDescription}
                </div>

                <div className={styles.contentDescription}>
                  <div dangerouslySetInnerHTML={createMarkup(domainDetails?.description)} />
                </div>

                <div className={styles.shareRegion}>
                  <ul>
                    <li>Share</li>
                    {SHARE_OVER_SOCIAL_SITES.map(site => (
                      <li key={site.url} className={styles.shareButton}>
                        <a href={site.url} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={site.icon} />
                          {site.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className={styles.keywordsWrap}>
                  <div className={styles.keyCatLabel}>Keywords</div>
                  <ul className={styles.keywords}>
                    {domainDetails &&
                    domainDetails.keywords &&
                    domainDetails.keywords.split(',').map(keyword => (
                      <Link
                        key={keyword}
                        to={{
                          pathname: '/domain-listing',
                          search: `?filterByKeyword=${keyword.trim()}`,
                        }}
                        style={{
                          marginRight: '10px',
                        }}>
                        <li key={keyword}>
                          <a
                            className={styles.keyword}
                            href="/"
                          >
                            {keyword}
                          </a>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>

                <div className={styles.categoriesWrap}>
                  <div className={styles.keyCatLabel}>Categories</div>
                  <ul className={styles.categories}>
                    {domainDetails?.category && domainDetails.category.split(',').map(cat => (
                      <Link
                        key={cat}
                        to={{
                          pathname: '/domain-listing',
                          search: `?filterByCategory=${cat.trim()}`,
                        }}
                      >
                        <li key={cat}>
                          <a
                            className={styles.category}
                            href="/"
                          >
                            {cat}
                          </a>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>

              </div>
            </Col>

            <Col xl={4} lg={4} md={12} sm={12}>
              {!domainDetails.inquiryOnly && <div className={styles.sidebarBlock}>
                <div className={styles.buyItNow}>

                  <Row className={styles.buyItNowHeader}>
                    <Col className="col-6">
                      <div className={styles.buyItNowAmount}>{domainDetails.price !== null ? numeral(domainDetails?.price).format('$0,00') : 'Make Offer'}</div>
                    </Col>
                    <Col className="col-6 text-right">
                      <div className={`${styles.buyItFavorite} ${!isLoggedIn ? styles.disabledFav : ''}`}>
                        {favLoading ? <Loader style={{ right: '-50px', }} /> : <FontAwesomeIcon
                          icon={faHeart}
                          className={isAdded ? styles.heartFav : ''}
                          onClick={() => toggleFavourite(domainDetails.id)}
                        />}
                      </div>
                    </Col>
                  </Row>

                  <div className={styles.buyItNowTitle}>What you get:</div>

                  <ul className={styles.whatYouGet}>
                    {domainDetails?.nameIncluded && <li className={styles.getItem}>Domain Name</li>}
                    {domainDetails?.logoIncluded && <li className={styles.getItem}>Logo Design</li>}
                    {domainDetails?.conceptIncluded && <li className={styles.getItem}>Design Concepts</li>}
                  </ul>

                  <Link to={`/checkout/${domainDetails.id}`}>
                    <Button
                      className={`btn bm-btn ${styles.buyItNowButton}`}
                      disabled={!!domainDetails.soldAt || (isLoggedIn && !isVerified)}
                    >
                      Buy It Now
                    </Button>
                  </Link>
                </div>
              </div>}


              {/* @DEVS:  This will be visible in a later phase. */}

              {/* <div className={styles.sidebarBlock}>
                <div className={styles.selectPayment}>

                  <div className={styles.selectPaymentTitle}>
                    Pay With A Payment Plan
                  </div>

                  <div className={styles.selectPaymentDesc}>
                    Select the number of installments:
                  </div>

                  <ul className={styles.selectPaymentAmount}>
                    <li><a className={styles.selectPaymentItem} href="/">3</a></li>
                    <li><a className={styles.selectPaymentItem} href="/">6</a></li>
                    <li><a className={styles.selectPaymentItem} href="/">9</a></li>
                    <li><a className={styles.selectPaymentItem} href="/">12</a></li>
                  </ul>

                  <div className={styles.selectPaymentPrice}>
                    <strong>$</strong><span>222</span> <strong>USD</strong> Per Month
                  </div>

                  <div className={styles.selectPaymentSubmit}>
                    <Button className={`btn bm-btn ${styles.paymentSubmit}`}>Setup Payment Method</Button>
                  </div>
                </div>
              </div> */}

              <div className={styles.sidebarBlock}>
                {!inquirySuccessfull && <div className={`${styles.inquireForm} ${domainDetails.inquiryOnly ? styles.buyItNow : ''}`}>
                  {domainDetails.inquiryOnly && <Row className={styles.buyItNowHeader}>
                    <Col className="col-6">
                      <div className={styles.buyItNowAmount}>{domainDetails.price !== null ? numeral(domainDetails?.price).format('$0,00') : 'Make Offer'}</div>
                    </Col>
                    <Col className="col-6 text-right">
                      <div className={`${styles.buyItFavorite} ${!isLoggedIn ? styles.disabledFav : ''}`}>
                        {favLoading ? <Loader style={{ right: '-50px', }} /> : <FontAwesomeIcon
                          icon={faHeart}
                          className={isAdded ? styles.heartFav : ''}
                          onClick={() => toggleFavourite(domainDetails.id)}
                        />}
                      </div>
                    </Col>
                  </Row>}
                  <div className={styles.inquireTitle}>
                    Inquire About This Domain
                  </div>

                  <div className={`mt-3 ${styles.addBorder} ${styles.inquireName}`}>
                    <FormInput
                      marginBottom="mb-2"
                      name="inquiryName"
                      label="Name"
                      value={inquiryName}
                      placeholder="Name"
                      onChange={e => handleInputChange(e)}
                    />
                    {inquiryErrors.inquiryName && <div className={styles.error}>{inquiryErrors.inquiryName}</div>}
                  </div>

                  <div className={`mt-3 ${styles.addBorder} ${styles.inquireEmail}`}>
                    <FormInput
                      marginBottom="mb-2"
                      name="inquiryEmail"
                      label="Email"
                      value={inquiryEmail}
                      placeholder="Email"
                      onChange={e => handleInputChange(e)}
                    />
                    {inquiryErrors.inquiryEmail && <div className={styles.error}>{inquiryErrors.inquiryEmail}</div>}
                  </div>

                  <div className={`mt-3 ${styles.addBorder} ${styles.inquirePhone}`}>
                    <FormInput
                      marginBottom="mb-2"
                      name="inquiryPhone"
                      label="Phone"
                      value={inquiryPhone}
                      placeholder="Phone"
                      onChange={e => handleInputChange(e)}
                    />
                    {inquiryErrors.inquiryPhone && <div className={styles.error}>{inquiryErrors.inquiryPhone}</div>}
                  </div>

                  <div className={`mt-3 ${styles.addBorder} ${styles.inquireComments}`}>
                    <TextArea
                      marginBottom="mb-2"
                      name="inquiryComments"
                      placeholder="Additional Comments"
                      value={inquiryComments}
                      onChange={e => handleInputChange(e)}
                    />
                    {inquiryErrors.inquiryComments && <div className={styles.error}>{inquiryErrors.inquiryComments}</div>}
                  </div>

                  <div className={`mt-3 ${styles.inquireSubmitWrap}`}>
                    <Button
                      className={`btn bm-btn ${styles.inquireSubmit}`}
                      variant="outline-primary"
                      disabled={isDisabled()}
                      onClick={makeAnInquiry}
                    >
                      {!inquiryLoading ? 'Make Inquiry' : 'Loading...'}
                    </Button>
                  </div>
                  {inquiryLoading && !inquirySuccessfull && !inquiryError && (
                    <Loader />
                  )}
                </div>}

                {inquirySuccessfull && !inquiryLoading && (
                  <div className="alert alert-success">
                    Your inquiry has been submitted successfully.
                  </div>
                )}
                {!inquirySuccessfull && !inquiryLoading && inquiryError && (
                  <div className="alert alert-danger">
                    {`An error occured while making an inquiry due to: ${inquiryError}`}
                  </div>
                )}
              </div>
            </Col>
          </Row>)}

        </Container>
      </Container>

      <HowItWorks buyable={!domainDetails?.inquiryOnly} />

      {domainDetails && (
        <DesignConcept
          title={domainDetails?.name}
          conceptImages={domainDetails?.designConcepts}
        />)}

      {domainDetails && (<OtherNamesRelated relatedTo={domainDetails.category.split(',')[0]} />)}

      <FAQ />

      <RecentlySold />

      <Subscribe />

    </Container>
  );
};

export default React.memo(DomainInner);
