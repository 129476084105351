import React, { useState, useContext } from 'react';
// import jwt from 'jwt-decode';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../contexts/Global.context';
import { Link, Redirect } from 'react-router-dom';
import { apiRequest } from 'utils/ApiRequest';
import SVG from 'react-inlinesvg';
import constants from '../../Constants';
import { FBLogin } from 'components/FacebookLogin/FacebookLogin';

// Page components
import SubmitButton from 'general/SubmitButton/SubmitButton';
import FormInput from 'general/FormInput/FormInput';

// Interfaces
// import { User } from 'interfaces/Interfaces';

// SCSS
import styles from './Login.module.scss';

// SVGs
import LoginLogo from 'assets/svgs/Branding/logo-login.svg';

interface Errors {
  email: string,
  password: string,
}

interface APIResponse {
  token: string;
  id: string;
  isNew?: boolean;
}

const { apiConstants : { API_URL }, errorConstants, regExValidators } = constants;

const LOGIN_URL = `${API_URL}/login`;
const { EMAIL_VALIDATOR_REGEX } = regExValidators;

const Login: React.FunctionComponent = () => {
  const globalContext = useContext(GlobalContext);
  const { isLoggedIn } = globalContext;
  const {
    EMAIL_NOT_ENTERED_ERROR,
    EMAIL_IS_NOT_VALID,
    PASSWORD_NOT_ENTERED_ERROR,
    // PASSWORD_CHAR_LENGTH_ERROR,
  } = errorConstants;

  const [email, setEmailID] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [response, setResponse] = useState<any>(null);
  const [apiError, setApiError] = useState<string>('');

  const [errors, setErrors] = useState<Errors>({
    email: '',
    password: '',
  });

  const apiConfig = {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  }

  // The below method is used to handle the change of input fields
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'email') {
      if (value) {
        setEmailID(value);
        if (!EMAIL_VALIDATOR_REGEX.test(value)) {
          setErrors({ ...errors, email: EMAIL_IS_NOT_VALID });
        } else {
          setErrors({ ...errors, email: '' });
        }
      } else {
        setEmailID(value);
        setErrors({ ...errors, email: EMAIL_NOT_ENTERED_ERROR });
      }
    } else if (name === 'password') {
      if (value) {
        setPassword(value);
        setErrors({ ...errors, password: '' });
        /* if (value.length < 8) {
          setErrors({ ...errors, password: PASSWORD_CHAR_LENGTH_ERROR });
        } else {
          setErrors({ ...errors, password: '' });
        } */
      } else {
        setPassword(value);
        setErrors({ ...errors, password: PASSWORD_NOT_ENTERED_ERROR });
      }
    }
  }

  const handleKeyPress = (event: any) => {
    if (event.which === 13 || event.charCode === 13) {
      handleSubmit();
    }
  }

  // The below method is used to validate the credentials entered by user
  const validateCredentails = (): boolean => {
    if (email && password) {
      if (EMAIL_VALIDATOR_REGEX.test(email) && password && password.length > 7) {
        return true;
      }
    } else if (!email && !password) {
      setErrors({
        ...errors,
        email: EMAIL_NOT_ENTERED_ERROR,
        password: PASSWORD_NOT_ENTERED_ERROR
      });
    } else if (!email) {
      setErrors({ ...errors, password: EMAIL_NOT_ENTERED_ERROR });
    } else if (!password) {
      setErrors({ ...errors, password: PASSWORD_NOT_ENTERED_ERROR });
    }
    return false;
  }

  // This method is used to validate and then submit the data to login API
  const handleSubmit = (): void => {
    if(validateCredentails()) {
      // sendRequest();
      setApiError('');
      globalContext.setLoader(true);
      requestLogin()
    }
  }

  // const callAnalytics = (response: APIResponse) => {
  //   const { token } = response;
  //   const user: User = jwt(token);
  //   window.analytics.identify({
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     email: user.email,
  //     username: user.userName,
  //     id: user.id,
  //     userId: user.id,
  //   })
  // }

  const requestLogin = async () => {
    const { response, error } = await apiRequest(LOGIN_URL, 'POST', apiConfig.body);
    if (response) {
      setResponse(response);
      // callAnalytics(response);
    } else if (error) {
      setApiError(error)
    }
    globalContext.setLoader(false);
  }

  if (isLoggedIn) {
    const currentUrl = window.location.href;
    if (response && response.isNew) {
      return (<Redirect to="/verification" />)
    }
    else if (currentUrl.indexOf('destination=') > -1) {
      const toUrl = currentUrl.substring(currentUrl.lastIndexOf('=') + 1);
      return (
        <Redirect to={toUrl} />
      )
    }
    return (
      <Redirect to="/dashboard" />
    )
  }

  if (response) {
    const {
      token,
      //accessToken
    } = response;
    if (token) {
      globalContext.setAuth(token);
    }

    // if (accessToken) {
    //   localStorage.setItem('accessToken', accessToken);
    // }
  }

  const onFBLoginSuccess = (res: APIResponse) => {
    globalContext.setLoader(false);
    setResponse(res);
    // callAnalytics(res);
  }

  return (
    <Container className={`p-0 ${styles.authBG}`} fluid>
      <Row className={styles.authWrap}>
        <Col>
          <div className={styles.login}>

            <div className={styles.authLogo}>
              <Link to="/">
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="site-logo-login"
                  description="Brandmo"
                  loader={<span>Loading...</span>}
                  src={LoginLogo}
                  title="Brandmo"
                />
              </Link>
              
            </div>

            <form className={styles.loginForm}>
              <div className={styles.loginTitle}>Login</div>
              <Container>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.inputWrap}>
                      <FormInput
                        name="email"
                        label="Email"
                        value={email}
                        placeholder="Email"
                        onChange={e => handleChange(e)}
                      />
                      {errors.email && (<div className={styles.error}>{errors.email}</div>)}
                    </div>
                  </Col>
                  <Col className="mt-3 mb-3" xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.inputWrap}>
                      <FormInput
                        name="password"
                        label="Password"
                        type="password"
                        value={password}
                        placeholder="Password"
                        onChange={e => handleChange(e)}
                        onKeyPress={handleKeyPress}
                      />
                      <div className={`mt-1 mb-4 ${styles.forgotPW}`}>
                        <Link to="/forgot-password">Forgot password?</Link>
                      </div>
                      {errors.password && (<div className={styles.error}>{errors.password}</div>)}
                    </div>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <SubmitButton
                      caption="Login"
                      isLoading={globalContext.isLoading}
                      onSubmit={!globalContext.isLoading ? handleSubmit : () => {}}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    {apiError && <div className={`${styles.error} ${styles.apiError}`}>{apiError}</div>}
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.orLabel}>or</div>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <FBLogin
                      onClick={() => globalContext.setLoader(true)}
                      onSuccess={onFBLoginSuccess}
                      onError={(error) => {
                        globalContext.setLoader(false);
                        setApiError(error);
                      }}
                      isLoading={globalContext.isLoading}
                    />
                  </Col>

                </Row>
              </Container>

              {/* error && (<div className={styles.error}>{error.toString()}</div>) */}
            </form>

            <div className={styles.formBottom}>
              <Link to="/register">Don&apos;t have an account? Sign Up</Link>
            </div>

          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Login);
