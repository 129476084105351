import  React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';

// SCSS
import styles from './FAQ.module.scss';


interface FAQ {
  question: string;
  answer: string;
  eventKey: string;
}

const FAQ = (props: FAQ) => {
  return (
    <Card className={`${styles.qaCard} ${styles.qaWrapper}`}>
      <Card.Header className={styles.qaQuestion}>
        <Accordion.Toggle as={Button} variant="link" eventKey={props.eventKey}>
          {props.question}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={props.eventKey}>
        <Card.Body className={styles.qaAnswer}>
          {props.answer}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default React.memo(FAQ);
