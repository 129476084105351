// This function is a utility function to remove a particular property from an object

const removeProperty = (obj: any, property: string) => Object.keys(obj).reduce((acc, key) => {
  if (key !== property) {
    return { ...acc, [key]: obj[key]}
  }
  return acc
}, {})

export default removeProperty
