import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, FormControl, Button } from 'react-bootstrap';

import { Helmet } from 'react-helmet';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';
import GlobalContext from 'contexts/Global.context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import scrollToHandler from 'utils/ScrollHandler';

import Loader from 'general/Loader/Loader';
import TransactionRow from './TransactionRow/TransactionRow';
import Pagination from 'general/Pagination/Pagination';

import styles from './TransactionList.module.scss';

interface Transaction {
  amount: number;
  buyer: {
    activePlan: string;
    billingAddress: string;
    billingCountry: string;
    billingName: string;
    billingState: string;
    billingZIP: string;
    createdAt: string;
    email: string;
    escrowId: number;
    firstName: string;
    id: string;
    isAdmin: boolean;
    isDeleted: boolean;
    isVerified: boolean;
    lastName: string;
    paymentId: string;
    paymentMethod: string;
    profilePicURL: string;
    updatedAt: string;
    userName: string;
  };
  createdAt: string;
  domain: string;
  id: string;
  isDeleted: boolean;
  remark: string;
  seller: {
    activePlan: string;
    billingAddress: string;
    billingCountry: string;
    billingName: string;
    billingState: string;
    billingZIP: string;
    createdAt: string;
    email: string;
    escrowId: number;
    firstName: string;
    id: string;
    isAdmin: boolean;
    isDeleted: boolean;
    isVerified: boolean;
    lastName: string;
    paymentId: string;
    paymentMethod: string;
    profilePicURL: string;
    updatedAt: string;
    userName: string;
  }
  status: string;
  transactionId: string;
  type: string;
  updatedAt: string;
}

const { apiConstants: { API_URL } } = Constants;
const ITEMS_PER_PAGE = 40;
const GET_TRANSACTIONS_LIST_URL = `${API_URL}/transactions?limit=${ITEMS_PER_PAGE}&skip=0`;

const TransactionList: React.FunctionComponent = () => {

  const [fetchTransactionsURL, setFetchTransactionsURL] = useState<string>(GET_TRANSACTIONS_LIST_URL);
  const url = new URL(fetchTransactionsURL);

  const { userDetails: { id: loggedInUserId } } = useContext(GlobalContext);

  const [searchText, setSearchText] = useState<string>('');

  const [transactions, setTransactions] = useState<Array<Transaction> | null>(null);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [activePage, setActivePage] = useState<number>(1);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.which === 13 || event.charCode === 13)) {
      url.searchParams.set('transactionId', searchText);
      setFetchTransactionsURL(url.href);
    }
  }

  const handleSearchClick = () => {
    if (searchText) {
      url.searchParams.set('transactionId', searchText);
      setFetchTransactionsURL(url.href);
    }
  }

  const setPageAndSkip = (page: number) => {
    setActivePage(page);
    url.searchParams.set('skip', String((page - 1) * ITEMS_PER_PAGE));
    setFetchTransactionsURL(url.href);
  }

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      setTransactions(null);
      const { response, error } = await apiRequest(fetchTransactionsURL, 'GET');
      if (response) {
        const { transactions, count } = response;
        setTransactions(transactions);
        setCount(count);
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    }

    fetchTransactions();
  }, [fetchTransactionsURL])

  useEffect(() => {
    scrollToHandler();
  }, [activePage])

  return (
    <Container className={styles.dashWrapper} fluid>
      <Helmet>
        <title>
          Transactions | Brandmo
        </title>
      </Helmet>
      <Container className={styles.dashInner}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.sectionTitle}>Transactions</div>
          </Col>
          <Col>
            <FormControl
              placeholder="Search transactions"
              aria-label="Search transactions"
              value={searchText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Button
              variant="outline-secondary"
              className={styles.searchIcon}
              onClick={handleSearchClick}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </Col>
        </Row>

        <Col xl={12} lg={12} md={12} sm={12} className="border-top my-3"></Col>

        <div className="transaction-list">
          {loading && <Loader />}
          {transactions && transactions.map(transaction => (
            <TransactionRow
              key={transaction.id}
              transaction={transaction}
              loggedInUserId={loggedInUserId}
            />
          ))}
          {error && <div className={styles.error}>{error}</div>}
        </div>

        {transactions && count > 0 && Math.ceil(count/ITEMS_PER_PAGE) > 1 && <Pagination
          totalCount={count}
          itemsPerPage={ITEMS_PER_PAGE}
          pageCount={count ? Math.ceil(count/ITEMS_PER_PAGE) : 0}
          windowSize={4}
          page={activePage}
          getActivePage={setPageAndSkip}
        />}

        {!loading && transactions && !transactions.length && !searchText && (
          <div className={styles.noTransactions}>No transactions yet</div>
        )}

        {!loading && transactions && !transactions.length && searchText && (
          <div className={styles.noTransactions}>No transactions found</div>
        )}
      </Container>
    </Container>
  )
}

export default TransactionList;
