export const SORT_BY_DROPDOWN = [
  { key: 'submissions', value: 'submissions', label: 'Most Popular' },
  { key: 'createdAt', value: 'createdAtDesc', label: 'Most Recent' },
  { key: 'budget', value: 'budgetDesc', label: 'Highest Budget' },
  { key: 'budget', label: 'Lowest Budget' },
  { key: 'targetDate', value: 'targetDate', label: 'Ending Soon' },
]

export const PRICE_DROPDOWN = [
  {value: '', label: 'All' },
  {value: [0, 500], label: '$0 - $500'},
  {value: [500, 1000], label: '$500 - $1000'},
  {value: [1000, 2500], label: '$1000 - $2500'},
  {value: [2500, 5000], label: '$2500 - $5000'},
  {value: [5000, 10000000],  label: '$5,000+'},
]
