import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

import { Container, Row, Col } from 'react-bootstrap';

// Page Components
import PricingTable from './components/Table/Table';
import GetInTouch from '~/../components/GetInTouch/GetInTouch';
import HowItWorks from '~/../components/HowItWorks/HowItWorks';
import FAQ from './components/FAQBlock/FAQBlock';
import NamingExamples from '~/../components/NamingExamples/NamingExamples'
import Subscribe from '~/../components/Subscribe/Subscribe';

// Utils
import scrollToHandler from 'utils/ScrollHandler';

// SCSS
import './Pricing.scss';

const Pricing: React.FunctionComponent = () => {
  const { hash } = useLocation();
  const faqRef = useRef(null);
  const howItWorksRef = useRef(null);


  useEffect(() => {
    let scrollToSection: any = null;
    if (hash.includes('faq')) {
      scrollToSection = faqRef;
    }

    if (hash.includes('howItWorks')) {
      scrollToSection = howItWorksRef;
    }
    scrollToHandler(scrollToSection || '');
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "See out Pricing",
    });
  }, [hash]);

  return (
    <Container className="p-0" fluid>
      <Helmet>
        <title>
          Pricing | Brandmo
        </title>
      </Helmet>
      <Container>
        <Row>
          <Col className="pricing-title" xl={12} lg={12} md={12} sm={12}>
            <div className="title">See our <span>pricing</span>.</div>
            <div className="subtitle">Let’s name your business! Our brand naming experts will provide you with unique, catchy and creative .com domains available for acquisition. If you have a larger budget we can send your request out to our private network of naming experts.</div>
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <PricingTable />
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <GetInTouch />
          </Col>
        </Row>
      </Container>

      <Row className="m-0" ref={howItWorksRef}>
        <Col className="p-0" xl={12} lg={12} md={12} sm={12}>
          <HowItWorks />
        </Col>
      </Row>

      <Container ref={faqRef}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <FAQ />
          </Col>
        </Row>
      </Container>


      <Row className="m-0">
        <Col className="p-0" xl={12} lg={12} md={12} sm={12}>
          <NamingExamples />
        </Col>
      </Row>

      <Subscribe />
    </Container>
  );
};

export default React.memo(Pricing);
