import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Nav } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// Constants
import Constants from 'Constants';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTwitter, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';

import styles from './Footer.module.scss';

// SVGs
import FooterLogo from 'assets/svgs/Branding/logo-footer.svg';

const { brandmoSocialLinks } = Constants;

const Footer: React.FunctionComponent = () => {
  return (
    <Container id={styles.footer}>
      <Row>

        <Col xl={3} lg={3} md={12} sm={12}>
          <div className={styles.branding}>
            <Link to="/">
              <SVG
                baseURL="/"
                cacheRequests={true}
                className={styles.siteLogoFooter}
                description="Brandmo"
                loader={<span>Loading...</span>}
                src={FooterLogo}
                title="Brandmo"
              />
            </Link>
          </div>
          <div className={styles.copyright}>&copy; {new Date().getFullYear()} Brandmo, LLC.</div>
        </Col>

        <Col xl={3} lg={3} md={12} sm={12}>
          <div className={styles.menuTitle}>Sitemap</div>
          <Container className="p-0">
            <Row className="m-0">
              <Col className="p-0" xl={6} lg={6} md={12} sm={12}>
                <Nav className={`mb-0 flex-column ${styles.footerMenu}`}>
                  <Nav.Link href="https://brandmo.com/blog">Our Blog</Nav.Link>
                  <Nav.Link as={Link} to="/about">About Us</Nav.Link>
                  <Nav.Link as={Link} to="/pricing#faq">FAQ</Nav.Link>
                  <Nav.Link href="https://brandmo.com/blog/careers/">Careers</Nav.Link>
                  <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                </Nav>
              </Col>
              <Col className="p-0" xl={6} lg={6} md={12} sm={12}>
                <Nav className={`mb-0 flex-column ${styles.footerMenu}`}>
                  <Nav.Link as={Link} to="/pricing#howItWorks">How It Works</Nav.Link>
                  <Nav.Link as={Link} to="/#testimonials">Testimonials</Nav.Link>
                  <Nav.Link as={Link} to="/privacy-policy">Privacy Policy</Nav.Link>
                  <Nav.Link as={Link} to="/terms">Terms & Conditions</Nav.Link>
                  <Nav.Link href="https://support.brandmo.com/" target="_blank">Support</Nav.Link>
                </Nav>
              </Col>
            </Row>
          </Container>
        </Col>

        <Col xl={2} lg={2} md={12} sm={12}>
          <div className={styles.menuTitle}>Marketplace</div>
          <Nav className={`flex-column ${styles.footerMenu}`}>
            <Nav.Link as={Link} to="/domain-listing">All Names</Nav.Link>
            <Nav.Link as={Link} to="/domain-listing">Top 100 Names</Nav.Link>
            <Nav.Link as={Link} to="/domain-listing?sortBy=createdAt">Recently Listed</Nav.Link>
            <Nav.Link as={Link} to="/#recentlySold">Recently Sold</Nav.Link>
          </Nav>
        </Col>

        <Col xl={2} lg={2} md={12} sm={12}>
          <div className={styles.menuTitle}>Branding</div>
          <Nav className={`flex-column ${styles.footerMenu}`}>
            <Nav.Link as={Link} to="/requests">Buy Requests</Nav.Link>
            <Nav.Link as={Link} to="/pricing">Naming Services</Nav.Link>
          </Nav>
          <div className={styles.menuTitle}>Sellers</div>
          <Nav className={`flex-column ${styles.footerMenu}`}>
            <Nav.Link as={Link} to="/contact">Sell Your Domains</Nav.Link>
            <Nav.Link as={Link} to="/contact">Domain Name Valuations</Nav.Link>
          </Nav>
        </Col>

        <Col xl={1} lg={1} md={12} sm={12}>
          <div className={styles.menuTitle}>Social</div>
          <Nav className={`p-0 mb-0 flex-row ${styles.socialMenu} ${styles.footerMenu}`}>
            <Nav.Link href={`${brandmoSocialLinks.LINKEDIN}/company/brandmo`} className="mr-3" target="_blank">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </Nav.Link>
            <Nav.Link href={`${brandmoSocialLinks.FACEBOOK}/brandmo`} className={styles.dynamicGap} target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </Nav.Link>
            <Nav.Link href={`${brandmoSocialLinks.TWITTER}/brandmo`} className="mr-3" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
            </Nav.Link>
            <Nav.Link href={`${brandmoSocialLinks.YOUTUBE}/channel/UCLyo__UHsGQig80wV6esuSA/`} target="_blank">
              <FontAwesomeIcon icon={faYoutube} />
            </Nav.Link>
          </Nav>
        </Col>

      </Row>
    </Container>
  );
};

export default React.memo(Footer);
