import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

// Components
import FormInput from 'general/FormInput/FormInput';
import Select from 'general/CustomSelect/CustomSelect';

// Constants
import constants from '../../../../Constants';
import { PRICE_DROPDOWN } from '../../Constants/Constants';

// SCSS
import styles from './KeywordFilter.module.scss';

const { pageConstants: { INDUSTRY_DROPDOWN }} = constants;

interface Filters {
  desiredKeywords: string;
  businessIndustry: string;
  budget: string;
}

interface Props {
  getFilters: (filter: any) => any;
  filterByKeyword: string;
}
const KeywordFilter: React.FunctionComponent<Props> = ({ getFilters, filterByKeyword }: Props) => {

  const [searchText, setSearchText] = useState<string>(filterByKeyword);
  const [industry, setIndustry] = useState<string>('');
  const [price, setPrice] = useState<string>('All');

  useEffect(() => {
    setSearchText(filterByKeyword);
    getFilters({
      desiredKeywords: filterByKeyword || '',
    })
  }, [filterByKeyword, getFilters])

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
  }

  const handleSelectIndustry = (event: any) => {
    const { value } = event.target;
    if (value !== 'Select') {
      setIndustry(value);
    } else {
      setIndustry('');
    }
  }

  const handleSelectPrice = (event: any) => {
    const { value } = event.target;
    setPrice(value);
  }

  return (
    <div className={styles.keywordFilterWrap}>
      <div className={styles.keywordFilter}>

        <div className={styles.searchLabel}>Keyword</div>
        <div className={styles.addBorder}>
          <FormInput
            name="keyword-search"
            placeholder="Enter a keyword or category"
            label="keyword-search-input"
            value={searchText}
            onChange={handleSearchText}
          />
        </div>

        <div className={styles.keywordBar}></div>

        <div className={styles.filterInputItem}>
          <div className={styles.searchLabel}>Industry</div>
          <div className={styles.addBorder}>
            <Select
              id={styles.industrySelect}
              selectItems={INDUSTRY_DROPDOWN}
              value={industry}
              onChange={handleSelectIndustry} />
          </div>
        </div>

        <div className={styles.filterInputItem}>
          <div className={styles.searchLabel}>Price Amount</div>
          <div className={styles.addBorder}>
            <Select
              id={styles.priceSelect}
              selectItems={PRICE_DROPDOWN}
              value={price}
              onChange={handleSelectPrice} />
          </div>
        </div>

      </div>
      <div className={styles.applyFilter}>
        <Button
          className={`btn bm-btn ${styles.applyFilterButton}`}
          type="button" 
          onClick={() => getFilters({
            desiredKeywords: searchText,
            businessIndustry: industry,
            budget: PRICE_DROPDOWN.filter(i => i.label === price)[0].value,
          })}
        >
          Apply Filter
        </Button>
      </div>
    </div>
  )
}

export default React.memo(KeywordFilter)
