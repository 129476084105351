import React from 'react';
import { FormControl } from 'react-bootstrap';
import cx from 'classnames';

import styles from './FormInput.module.scss';

interface FormInput {
  marginBottom?: string;
  type?: string;
  name: string;
  placeholder: string;
  label?: string;
  small?: boolean;
  large?: boolean;
  value: string;
  readonly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: any) => void;
  onBlur?: (event: any) => void;
}

const FormInput = (props: FormInput) => {
  const sizingClass = cx({
    'form-control-sm': props.small,
    'form-control-lg': props.large
  });

  return (
    <div className={`${props.marginBottom || 'mb-3'} ${styles.bmInput}`}>
      <FormControl
        aria-label={props.label}
        className={sizingClass}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        readOnly={props.readonly}
        onChange={props.onChange}
        onKeyPress={props?.onKeyPress}
        onBlur={props?.onBlur}
      />
    </div>
  )
}

export default React.memo(FormInput);
