import React, { useState, useContext, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import numeral from 'numeral';
import ReactGA from "react-ga4";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Container, Row, Col } from 'react-bootstrap';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import BillingDetails from '../BillingDetails/BillingDetails';
import SubmitButton from 'general/SubmitButton/SubmitButton';

// Context
import GlobalContext from 'contexts/Global.context';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

import './Checkout.scss';

interface Domain {
  id: string;
  name: string;
  price: number;
  category: string;
  keywords: string;
  images: Array<string>;
  extension: string;
  total: string;
  verificationCode: string;
  nameIncluded: boolean;
  conceptIncluded: boolean;
  logoIncluded: boolean;
  designConcepts: Array<string>;
  isDeleted: string;
  createdAt: string;
  updatedAt: string;
  isVerified: string;
}

interface Response {
  token: string;
  transactionId: string;
  success: boolean | string;
}

const { apiConstants: { API_URL }, STRIPE_KEY, pageConstants: { COUNTRY_DROPDOWN, USA }  } = Constants;
const DOMAIN_PURCHASE_URL = `${API_URL}/premium-domains/purchase`;
const GET_USERS_CARDS_URL = `${API_URL}/cards`;

const STRIPE_LOAD = loadStripe(STRIPE_KEY);

const BILLING_DETAILS_ERRORS: any = {
  name: 'Please enter the billing name',
  address: 'Please enter the address',
  state: 'Please select the state',
  zipCode: 'Please enter the zip code',
  country: 'Please select the Country',
}

const CheckoutForm: React.FunctionComponent = () => {

  const { domainId } = useParams();

  const globalContext = useContext(GlobalContext);

  const { userDetails, setAuth } = globalContext;

  const { email, id, paymentId, billingName, billingAddress, billingState, billingZIP, billingCountry, isVerified } = userDetails;

  const updateBillingDetailsURL = `${API_URL}/users/${id}`;
  const fetchDomainDetailsURL = `${API_URL}/premium-domains/${domainId}`;

  const [billingDetails, setBillingDetails] = useState<any>({
    name: billingName || '',
    address: billingAddress || '',
    state: billingState || '',
    zipCode: billingZIP || '',
    country: billingCountry || COUNTRY_DROPDOWN.filter(country => country.value === 'United States')[0].value,
  })

  const [errors, setErrors] = useState({
    name: '',
    address: '',
    state: '',
    zipCode: '',
    country: '',
  })

  const [selectedCard, setSelectedCard] = useState<string>('');

  const [domainDetails, setDomainDetails] = useState<Domain | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<Response | null>(null);
  const [error, setError] = useState<string>('');

  const cardSelectedForPayment = (selectedCardId: string) => {
    setSelectedCard(selectedCardId);
  }

  const apiConfig = {
    method: 'PUT',
    body: JSON.stringify({
      billingName: billingDetails.name,
      billingAddress: billingDetails.address,
      billingState: billingDetails.state,
      billingZIP: billingDetails.zipCode,
      billingCountry: billingDetails.country,
    }),
  }

  const updateBillingDetails = async () => {
    setLoading(true);
    const { response, error } = await apiRequest(updateBillingDetailsURL, apiConfig.method, apiConfig.body);
    return { billingResponse: response, billingError: error }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'name') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: BILLING_DETAILS_ERRORS[name],
        })
      } else {
        if (value.length > 40) {
          setErrors({
            ...errors,
            [name]: 'Maximum 40 characters are allowed',
          })
        } else {
          setErrors({
            ...errors,
            [name]: '',
          })
        }
      }
    }

    if (name === 'address') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: BILLING_DETAILS_ERRORS[name],
        })
      } else {
        if (value.length > 75) {
          setErrors({
            ...errors,
            [name]: 'Maximum 75 characters are allowed',
          })
        } else {
          setErrors({
            ...errors,
            [name]: '',
          })
        }
      }
    }

    if (name === 'zipCode') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: BILLING_DETAILS_ERRORS[name],
        })
      } else {
        if (value.length > 10) {
          setErrors({
            ...errors,
            [name]: 'Maximum 10 characters are allowed',
          })
        } else {
          setErrors({
            ...errors,
            [name]: '',
          })
        }
      }
    }
    
    if (name === 'state') {
      if (!value) {
        setErrors({
          ...errors,
          [name]: 'Please enter province or state',
        })
      } else {
        setErrors({
          ...errors,
          [name]: '',
        })
      }
    }

    setBillingDetails({
      ...billingDetails,
      [name]: value,
    })
  }

  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    let newBillingDetails = { ...billingDetails };
    if (name === 'country') {
      newBillingDetails = {  ...newBillingDetails, state: '' };
    }
    if (value === 'Select') {
      setErrors({
        ...errors,
        [name]: `Please select the ${name}`,
      })
    } else {
      setErrors({
        ...errors,
        [name]: '',
      })
    }
    newBillingDetails = { ...newBillingDetails, [name]: value };
    setBillingDetails(newBillingDetails);
  }

  const validateData = () => {
    const { name, address, state, zipCode, country } = billingDetails;
    if (name
        && name.length <= 40
        && address
        && address.length <= 75
        && state
        && zipCode
        && zipCode.length <= 10
        && country
    ) {
      return true;
    }

    let formErrors: any = {}
    Object.keys(billingDetails).map(field => {
      if (!billingDetails[field]) {
        switch (field) {
        case 'name':
          formErrors[field] = BILLING_DETAILS_ERRORS[field]
          break;

        case 'address':
          formErrors[field] = BILLING_DETAILS_ERRORS[field]
          break;

        case 'state':
          if (billingDetails.country !== USA) {
            formErrors[field] = 'Please enter province or state';
          } else {
            formErrors[field] = BILLING_DETAILS_ERRORS[field];
          }
          break;

        case 'zipCode':
          formErrors[field] = BILLING_DETAILS_ERRORS[field]
          break;

        case 'country':
          formErrors[field] = BILLING_DETAILS_ERRORS[field]
          break;

        default:
          break;
        }
        setErrors({
          ...errors,
          ...formErrors,
        })
      }
      return field;
    })
    return false;
  }

  const callAnalytics = () => {
    ReactGA.event('Premium Domain Purchase', {
      premiumDomain: domainDetails?.name,
      pruchaseAmount: domainDetails?.price,
      purchasedByEmail: email,
      seller: 'Brandmo',
    })
  }

  const makeAPayment = async () => {
    setLoading(true);
    const { response, error } = await apiRequest(DOMAIN_PURCHASE_URL, 'POST', JSON.stringify({
      cardId: selectedCard,
      domainId,
    }));
    if (response) {
      setResponse(response);
      callAnalytics();
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }

  const handleSubmit = async () => {
    setError('');
    if (validateData() && selectedCard && domainId && isVerified) {
      const { billingResponse, billingError } = await updateBillingDetails();
      if (billingResponse) {
        setAuth(billingResponse.token);
        makeAPayment();
      } else if (billingError) {
        setError(`An error occurred while updating billing details due to ${billingError}`)
      }
    }
  }

  useEffect(() => {
    const fetchCards = async () => {
      const { response } = await apiRequest(GET_USERS_CARDS_URL, 'GET');
      if (response && response.sources && response.sources.data && response.sources.data.length) {
        setSelectedCard(response.sources.data[0].id);
      }
    }
    if (paymentId) {
      fetchCards();
    }
  }, [paymentId]);

  useEffect(() => {
    const fetchDomainDetails = async () => {
      setLoading(true);
      const { response, error } = await apiRequest(fetchDomainDetailsURL, 'GET');
      if (response) {
        setDomainDetails(response);
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    }

    fetchDomainDetails();
  }, [fetchDomainDetailsURL])

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Checkout",
    });
  }, []);

  if (response && response?.success) {
    return (
      <Container id="checkout">
        <div className="alert alert-success domain-purchase-success">
          Thank you! Your payment for the domain
          <span className="domain-name-text">{` ${domainDetails?.name} `}</span>
            completed successfully.
            You can follow the status of your transfer on the
          <Link to={`/purchase-status/${response.transactionId}`}>
            {' status page'}
          </Link>
        </div>
      </Container>
    )
  }

  const includes = () => {
    if (domainDetails?.logoIncluded && domainDetails?.nameIncluded) {
      return 'Includes domain and logo files.';
    }

    if (domainDetails?.logoIncluded && !domainDetails?.nameIncluded) {
      return 'Includes logo files.';
    }

    if (!domainDetails?.logoIncluded && domainDetails?.nameIncluded) {
      return 'Includes domain.';
    }
  }

  return (
    <Elements stripe={STRIPE_LOAD}>
      <Container id="checkout" fluid>
        <Container id="header">
          <Row>

            <Col xl={12} lg={12} md={12} sm={12}>
              <div className="title">Checkout</div>
            </Col>

          </Row>
        </Container>

        <Container id="checkoutForm">
          <Row className="ml-3">
            <Col className="step-name" xl={12} lg={12} md={12} sm={12}>
              DOMAIN
            </Col>
            <Col  xl={12} lg={12} md={12} sm={12}>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className="checkoutDomainTitle">{domainDetails?.name}</div>
                </Col>

                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className="checkoutDomainPrice text-xl-right text-lg-right">{numeral(domainDetails?.price).format('$0,0')}</div>
                </Col>
              </Row>

              <div className="includes">{includes()}</div>
            </Col>
          </Row>

          <Row className="ml-3">
            <Col className="step-name mt-5" >
              Payment Information
            </Col>
            <Col xl={12} lg={12} md={12} sm={12}>
              <PaymentMethods cardSelectedForPayment={cardSelectedForPayment}/>
            </Col>
          </Row>

          <BillingDetails
            billingDetails={billingDetails}
            errors={errors}
            handleChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            apiError={error}
          />

          <SubmitButton
            caption={`Confirm payment of ${numeral(domainDetails?.price).format('$0,0')} by card`}
            isLoading={loading}
            disabled={!selectedCard || !domainId || !isVerified}
            onSubmit={handleSubmit}
          />
          {error && <div className="error">{error}</div>}
        </Container>
      </Container>
    </Elements>
  )
}

export default React.memo(CheckoutForm);
