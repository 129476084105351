import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// Page Components
import NeedHelp from './NeedHelp/NeedHelp'

// SCSS
import styles from './SearchByCategory.module.scss';

// SVGs
import TopWave from 'assets/svgs/Domain/Listing/top-wave.svg';

interface Props {
  categories: Array<{label: string}>;
  searchByCategory: (cat: string) => void;
}

const SearchByCategory: React.FunctionComponent<Props> = ({
  categories,
  searchByCategory,
}: Props) => {
  return (
    <Container className={`p-0 ${styles.searchCategoryRegion}`} fluid>
      <SVG
        baseURL="/"
        cacheRequests={true}
        className={styles.categoryWave}
        description=""
        loader={<span>Loading...</span>}
        src={TopWave}
        title=""
      />

      <Container className={styles.sbcRegion} fluid>
        <Container>

          <Row className={styles.titleRegion}>
            <Col>
              <div className={styles.title}>Search By Category</div>
            </Col>
          </Row>

          <Row>
            <Col className={styles.categoryBlockListWrap}>

              <ul className={styles.categoryBlockList}>
                {categories.map(cat => (
                  <li
                    key={cat.label}
                    className={styles.categoryBlock}
                    onClick={() => searchByCategory(cat.label)}
                  >
                    <Link to={'/domain-listing#domains-list'} className={styles.categoryBlockLink}>
                      {cat.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

        </Container>

        <NeedHelp />

      </Container>

    </Container>
  );
};

export default React.memo(SearchByCategory);
