import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import GlobalContext from 'contexts/Global.context';

import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import SVG from 'react-inlinesvg'

// Page Components
import CustomNavDropdown from '../../general/CustomNavDropdown/CustomNavDropdown';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// SCSS
import './Header.scss';

// SVGs
import Logo from 'assets/svgs/Branding/logo.svg';

library.add(fas)

const Marketplace_Dropdowns = [
  { type: 'title', label: 'Domains' },
  { type: 'item', label: 'All Names', href:'/domain-listing', icon: 'globe' },
  { type: 'item', label: 'Most Popular', href:'/domain-listing?sortBy=likes', icon: 'chart-line' },
  { type: 'item', label: 'Recently Listed', href:'/domain-listing?sortBy=createdAt', icon: 'mouse-pointer'},
  { type: 'title', label: 'Start Selling' },
  { type: 'item', label: 'Sell on Brandmo', href:'/contact', icon: 'tag'  },
]

const BuyRequests_Dropdowns = [
  { type: 'title', label: 'Buy Requests' },
  { type: 'item', label: 'All Buy Requests', href:'/requests', icon: 'plus-square' },
  { type: 'item', label: 'Most Popular', href:'/requests?sortBy=submissions', icon: 'thumbs-up' },
  { type: 'item', label: 'Ending Soon', href:'/requests?sortBy=targetDate&sortOrder=ASC', icon: 'hourglass-half'},
  /* { type: 'title', label: 'Start Requests' }, */
  /* { type: 'item', label: 'Launch Buy Requests', href:'/questionnaire', icon: 'file-alt' }, */
]

const LOGGED_IN_USER_OPTIONS = [
  { type: 'item', label: 'Dashboard', href:'/dashboard' },
  { type: 'item', label: 'Preferences', href:'/dashboard/account-preferences' },
  { type: 'item', label: 'Logout', href:'/', onClick: () => {}, },
]

const Header: React.FunctionComponent = () => {

  const globalContext = useContext(GlobalContext);
  const { isLoggedIn } = globalContext;

  const { userDetails: { userName, profilePicURL, isVerified }, logout } = globalContext;

  LOGGED_IN_USER_OPTIONS.filter(o => o.label === 'Logout')[0]['onClick'] = logout;

  return (
    <Container className="p-0" fluid>
      <Container className="p-0">
        <Navbar className="main-navigation" expand="lg">

          <Navbar.Brand className="branding" as={Link} to="/">
            <SVG
              baseURL="/"
              cacheRequests={true}
              className="site-logo"
              description="Brandmo"
              loader={<span>Loading...</span>}
              src={Logo}
              title="Brandmo"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="main-nav" />

          <Navbar.Collapse id="main-nav">

            <Nav className="ml-xl-4 mr-xl-auto">

              <CustomNavDropdown
                title="Marketplace"
                id="menu-dropdown"
                dropdownItems={Marketplace_Dropdowns}
              />

              <CustomNavDropdown
                title="Buy Requests"
                id="menu-dropdown"
                dropdownItems={BuyRequests_Dropdowns}
              />

              <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link href="https://www.brandmo.com/blog">Blog</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              {!isLoggedIn ? <Nav.Link className="d-xl-none d-lg-none d-md-block d-sm-block" as={Link} to="/login">Login</Nav.Link> : ''}

              {/*
              <NavDropdown title="Marketplace" id="menu-dropdown">
                <div className="dropdown-title">Domains</div>
                <NavDropdown.Item href="#1"><FontAwesomeIcon icon={faGlobe} /> All Names</NavDropdown.Item>
                <NavDropdown.Item href="#2"><FontAwesomeIcon icon={faChartLine} /> Most Popular</NavDropdown.Item>
                <NavDropdown.Item href="#3"><FontAwesomeIcon icon={faMousePointer} /> Recently Listed</NavDropdown.Item>
                <div className="dropdown-title">Start Selling</div>
                <NavDropdown.Item href="#4"><FontAwesomeIcon className="alt" icon={faTag} /> Sell On Brandmo</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Buy Request" id="menu-dropdown">
              <div className="dropdown-title">Buy Requests</div>
                <NavDropdown.Item href="#5"><FontAwesomeIcon icon={faPlusSquare} /> All Buy Requests</NavDropdown.Item>
                <NavDropdown.Item href="#6"><FontAwesomeIcon icon={faThumbsUp} /> Most Popular</NavDropdown.Item>
                <NavDropdown.Item href="#7"><FontAwesomeIcon icon={faHourglassHalf} /> Ending Soon</NavDropdown.Item>
                <div className="dropdown-title">Start Requests</div>
                <NavDropdown.Item href="#8"><FontAwesomeIcon icon={faFileAlt} /> Launch Buy Request</NavDropdown.Item>
              </NavDropdown> */}

            </Nav>

            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
              {!isLoggedIn ? (
                <Link to="/login" className="mr-2">
                  <Button
                    className="bm-btn px-3 py-2 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"
                    variant="outline-primary"
                  >
                    Login
                  </Button>
                </Link>
              ) : (
                <div className="logged-in-user">
                  <img
                    src={profilePicURL ? profilePicURL : 'https://placehold.co/55x55'}
                    className="profile-pic"
                    alt={profilePicURL}
                  />
                  <CustomNavDropdown
                    title={userName}
                    id="menu-dropdown"
                    dropdownItems={LOGGED_IN_USER_OPTIONS}
                  />
                </div>
              )}

              <Link to="/questionnaire">
                <Button
                  className="bm-btn px-3 py-2 d-xl-block d-lg-block d-md-none d-sm-none d-none"
                  variant="outline-primary"
                  disabled={isLoggedIn && !isVerified}
                >
                Launch
                </Button>
              </Link>

            </div>
          </Navbar.Collapse>

        </Navbar>
      </Container>
    </Container>
  );
};

export default React.memo(Header);
