import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
/* import SVG from 'react-inlinesvg' */

// Costants
import Constants from 'Constants';

// Page Components
import FormInput from 'general/FormInput/FormInput';
import Select from 'general/CustomSelect/CustomSelect';

// SCSS
import styles from './BillingDetails.module.scss';

interface BillingDetailsProps {
  name: string;
  address: string;
  state: string;
  zipCode: string;
  country: string;
}

interface Props {
  billingDetails: BillingDetailsProps;
  errors: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (e: any) => void;
  apiError: string;
}

interface APIResponse {
  token: string;
  id: string;
}

const { pageConstants: { STATE_DROPDOWN, COUNTRY_DROPDOWN, USA } } = Constants;

const BillingDetails: React.FC<Props> = ({
  billingDetails,
  errors,
  handleChange,
  handleSelectChange,
  apiError,
}: Props) => {
  return(
    <Container className={styles.billingDetails}>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Col xl={12} lg={12} md={12} sm={12}>
            <form>

              {/* <Header title="BILLING" /> */}

              <div className={styles.prefLabel}>Name</div>
              <FormInput
                name="name"
                placeholder=""
                label="billing-name"
                value={billingDetails.name}
                onChange={handleChange}
                large
              />
              {errors.name && <div className={styles.error}>{errors.name}</div>}

              <div className={styles.prefLabel}>Address</div>
              <FormInput
                name="address"
                placeholder=""
                label="billing-address"
                value={billingDetails.address}
                onChange={handleChange}
                large
              />
              {errors.address && <div className={styles.error}>{errors.address}</div>}

              <Row>
                {billingDetails.country === USA ? (
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.prefLabel}>State</div>
                    <Select
                      id="state"
                      name="state"
                      selectItems={STATE_DROPDOWN}
                      value={billingDetails.state}
                      onChange={handleSelectChange}
                    />
                    {errors.state && <div className={styles.error}>{errors.state}</div>}
                  </Col>
                ) : (
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.prefLabel}>Province</div>
                    <FormInput
                      name="state"
                      placeholder="Province"
                      label="billing-state"
                      value={billingDetails.state}
                      onChange={handleChange}
                      large
                    />
                    {errors.state && <div className={styles.error}>{errors.state}</div>}
                  </Col>
                )}

                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.prefLabel}>Zip Code</div>
                  <FormInput
                    name="zipCode"
                    placeholder=""
                    label="billing-zip"
                    value={billingDetails.zipCode}
                    onChange={handleChange}
                    large
                  />
                  {errors.zipCode && <div className={styles.error}>{errors.zipCode}</div>}
                </Col>
              </Row>

              <div className={styles.prefLabel}>Country</div>
              <Select
                id="country"
                name="country"
                selectItems={COUNTRY_DROPDOWN}
                value={billingDetails.country}
                onChange={handleSelectChange}
              />
              {errors.country && <div className={`${styles.error} mb-3`}>{errors.country}</div>}
              {apiError && <div className={`${styles.error} ${styles.apiError}`}>{apiError}</div>}
            </form>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

export default BillingDetails;
