import React from 'react';

interface Stats {
  entriesSubmitted: string;
  loved: string;
  liked: string;
  onRightTrack: string;
  noThankYou: string;
  unrated: string;
}

interface Props {
  error: string;
  stats: Stats | null;
}

const YourStats: React.FunctionComponent<Props> = ({ error, stats }: Props) => {
  return (
    <>
      <div className="title">Your Stats</div>
      {error && !stats && (<div className="error">{error}</div>)}
      {!error && stats && (<table className="table">
        <tbody>
          <tr>
            <th>Entries Submitted By You</th>
            <td>{stats.entriesSubmitted}</td>
          </tr>
          <tr>
            <th>Love It</th>
            <td>{stats.loved}</td>
          </tr>
          <tr>
            <th>Like It</th>
            <td>{stats.liked}</td>
          </tr>
          <tr>
            <th>On Right Track</th>
            <td>{stats.onRightTrack}</td>
          </tr>
          <tr>
            <th>No, Thank You</th>
            <td>{stats.noThankYou}</td>
          </tr>
          <tr>
            <th>Not Rated</th>
            <td>{stats.unrated}</td>
          </tr>
        </tbody>
      </table>)}
    </>
  )
}

export default React.memo(YourStats);
