import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";

// Context
import GlobalContext from '../../../contexts/Global.context';

// Constants
import constants from '../../../Constants';

// Page Components
import UserProfile from './UserProfile/UserProfile';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import BillingDetails from './BillingDetails/BillingDetails';
import ChangePassword from './ChangePassword/ChangePassword';
import ChangeEmail from './ChangeEmail/ChangeEmail';

// SCSS
import styles from './AccountPreferences.module.scss';

interface Props {
  userStatus?: string;
}

const { apiConstants: { API_URL } } = constants;

const AccountPreferences: React.FC<Props> = ({ userStatus }: Props) => {
  const globalContext = useContext(GlobalContext);

  const { userDetails, setAuth } = globalContext;
  const { isVerified } = userDetails;

  const isUserVerified = userStatus ? userStatus : (isVerified ? 'Verified' : 'Unverified');

  const {
    firstName: fname,
    lastName: lname,
    userName,
    profilePicURL,
  } = userDetails;

  const billingDetails = {
    billingPaymentId: userDetails.paymentId,
    bName: userDetails.billingName,
    bAddress: userDetails.billingAddress,
    bState: userDetails.billingState,
    bZip: userDetails.billingZIP,
    bCountry: userDetails.billingCountry,
  };

  const { email, password, id } = userDetails;
  const UPDATE_USER_DETAIL_API = `${API_URL}/users/${id}`
  const UPLOAD_PROFILE_PIC_URL = `${API_URL}/get-attachment-url`;

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Account Preferences",
    });
  }, [])

  return(
    <Container className={styles.dashWrapper} fluid>
      <Helmet>
        <title>
          Account Preferences | Brandmo
        </title>
      </Helmet>
      <Container className={styles.dashInner}>

        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.sectionTitle}>Account Preferences</div>
            <span className={`${styles.userVerificationStatus} ${isVerified ? styles.verified : styles.unverified}`}>{isUserVerified}</span>
            <UserProfile
              profilePictureURL={profilePicURL}
              userName={userName}
              apiURL={UPDATE_USER_DETAIL_API}
              setNewToken={setAuth}
              uploadProfilePicURL={UPLOAD_PROFILE_PIC_URL}
            />

            <PersonalDetails
              firstname={fname}
              lastname={lname}
              apiURL={UPDATE_USER_DETAIL_API}
              setNewToken={setAuth}
            />

            <BillingDetails
              billingDetailsProps={billingDetails}
              apiURL={UPDATE_USER_DETAIL_API}
              setNewToken={setAuth}
            />

            <ChangePassword
              password={password}
              apiURL={UPDATE_USER_DETAIL_API}
              setNewToken={setAuth}
            />

            <ChangeEmail
              currentEmail={email}
              apiURL={UPDATE_USER_DETAIL_API}
              setNewToken={setAuth}
            />

          </Col>
        </Row>

      </Container>
    </Container>
  )
}

export default AccountPreferences;
