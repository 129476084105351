import React from 'react';
import InputMask from 'react-input-mask';
import cx from 'classnames';

import styles from './FormDateInput.module.scss';

interface FormDateInput {
  name: string;
  placeholder: string;
  label: string;
  small?: boolean;
  large?: boolean;
  mask: string;
  maskPattern?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormDateInput = (props: FormDateInput) => {
  const sizingClass = cx({
    'form-control-sm': props.small,
    'form-control-lg': props.large
  });

  return (
    <div className={`mb-3 ${styles.bmInput}`}>
      <InputMask 
        aria-label={props.label}
        className={`${sizingClass} form-control`}
        name={props.name}
        placeholder={props.placeholder}
        inputMode="numeric"
        mask={props.mask}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  )
}

export default React.memo(FormDateInput);
