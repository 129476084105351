export const SELECT_ITEMS = [
  { label: 'Select', },
  { label: 'Domains', },
  { label: 'Marketing', },
  { label: 'Sales', },
  { label: 'Contact', },
  { label: 'Example', },
]

export const MAX_LENGTH_FOR_DOMAIN_OPTIONS = [
  { id: '5 to 7 characters', name: 'domainMaxLength', label: '5 to 7 characters', },
  { id: '7 to 10 characters', name: 'domainMaxLength', label: '7 to 10 characters', },
  { id: '10 to 15 characters', name: 'domainMaxLength', label: '10 to 15 characters', },
  { id: 'No constraints, as long as it\'s a good name!', name: 'domainMaxLength', label: 'No constraints, as long as it\'s a good name!', },
  { id: 'Other', name: 'domainMaxLength', label: 'Other:', },
]

export const DOMAIN_EXTESNIONS = [
  { id: 'checkExt1', name: 'all', label: 'I\'d like all domain extensions available', },
  { id:'checkExt2', name:'.com', label:'.com', },
  { id:'checkExt3', name:'.org', label:'.org', },
  { id:'checkExt4', name:'.net', label:'.net', },
  { id:'checkExt5', name:'.co', label:'.co', },
  { id:'checkExt6', name:'.io', label:'.io', },
  { id:'checkExt7', name:'.us', label:'.us', },
  { id:'checkExt8', name:'.com.au', label:'.com.au', },
  { id:'checkExt9', name:'.com.uk', label:'.com.uk', },
  { id:'checkExt10', name:'.mx', label:'.mx', },
  { id:'checkExt11', name:'.ly', label:'.ly', },
  { id:'checkExt12', name:'.ca', label:'.ca', },
  { id:'checkExt13', name:'.xyz', label:'.xyz', },
]
