import React, { useState, useEffect } from 'react';

import Constants from 'Constants';
import { apiRequest } from 'utils/ApiRequest';

import BuyRequestsListing from 'components/BuyRequestsListing/BuyRequestsListing';

interface BuyRequest {
  TLDs: string;
  additionalComments: string;
  brandFeelings: string;
  budget: number;
  businessDescription: string;
  businessIndustry: string;
  createdAt: string;
  desiredKeywords: string;
  domainMaxLength: string;
  file: string;
  geographicRegions: string;
  id: string;
  isDeleted: boolean;
  isExpired: boolean;
  isFavorite: string;
  name: string;
  title?: string;
  projectType: string;
  status: number;
  submissions: string;
  targetCustomers: string;
  targetDate: string;
  total: string;
  updatedAt: string;
  userId: string;
  userName: string;
  submitCount?: number;
}


const { apiConstants: { API_URL } } = Constants;
const FETCH_FAVORITE_BUYREQUESTS_URL = `${API_URL}/requests/user/favorite`;

const FavoriteContests: React.FunctionComponent = () => {

  const [fetchFavoriteRequestsURL, setFavRequestsURL] = useState<string>(FETCH_FAVORITE_BUYREQUESTS_URL);

  const newUrl = new URL(fetchFavoriteRequestsURL);
  const [loading, setLoading] = useState<boolean>(false);
  const [buyRequests, setBuyRequests] = useState<Array<BuyRequest> | null>(null);

  const [searchText, setSearchText] = useState<string>('');

  const searchBuyRequests = () => {
    if (newUrl.searchParams.has('name')) {
      newUrl.searchParams.set('name', searchText);
      setFavRequestsURL(newUrl.href);
    } else {
      newUrl.searchParams.append('name', searchText);
      setFavRequestsURL(newUrl.href);
    }
  }

  const removeBuyRequestOnUnFavorite = (buyRequestId: string) => {
    if (buyRequests) {
      const newBuyRequests = buyRequests.filter(buyRequest => buyRequest.id !== buyRequestId);
      setBuyRequests(newBuyRequests);
    }
  }

  useEffect(() => {
    const fetchBuyRequests = async () => {
      setLoading(true);
      const { response } = await apiRequest(fetchFavoriteRequestsURL, 'GET');

      if (response) {
        setBuyRequests(response);
      }
      setLoading(false);
    }
    fetchBuyRequests();
  }, [fetchFavoriteRequestsURL]);

  return (
    <div className="favorite-contests">
      <BuyRequestsListing
        showSearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        searchList={searchBuyRequests}
        fetchingRequestsLoader={loading}
        buyRequests={buyRequests}
        favorites={true}
        removeBuyRequestOnUnFavorite={removeBuyRequestOnUnFavorite}
      />
    </div>
  )
}

export default React.memo(FavoriteContests);
