import React, { useContext, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

import GlobalContext from 'contexts/Global.context'; 

import ContestsCreated from './ContestsCreated/ContestsCreated';
import ContestsEntered from './ContestEntered/ContestsEntered';

import './MyContests.scss';

const Favorites: React.FunctionComponent = () => {

  const globalContext = useContext(GlobalContext);

  const { userDetails: { id }} = globalContext;

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "My Buy Requests",
    });
  }, [])

  return (
    <Container id="my-contests" fluid>
      <Helmet>
        <title>
          My Buy Requests | Brandmo
        </title>
      </Helmet>
      <div className="section-title">My Buy Requests</div>

      <Row>

        <Col className="order-xl-0 order-lg-0 order-md-1 order-sm-1 order-1">
          <Tabs id="contests"
            defaultActiveKey="contestsCreated"
          >
            <Tab eventKey="contestsCreated" title="Buy Requests I've Created">
              <ContestsCreated createdUserId={id} />
            </Tab>

            <Tab eventKey="contestsEntered" title="Buy Requests I've Entered">
              <ContestsEntered enteredUserId={id} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>

  )
}

export default React.memo(Favorites);
