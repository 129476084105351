import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import Testimonial from './Testimonial/Testimonial';

// Images
import Testimonial1 from 'assets/imgs/Testimonials/testimonial1.png';
import Testimonial2 from 'assets/imgs/Testimonials/testimonial2.jpeg';
import Testimonial3 from 'assets/imgs/Testimonials/testimonial3.jpeg';

// SCSS
import './Testimonials.scss';

const CAROUSEL_ITEMS = [
  {
    testimonial: 'The Brandmo team were terrific to deal with and presented us with a number of creative ideas for our branding project. They listened carefully and clearly "got" what we wanted our brand to convey. I couldn\'t have been happier.',
    personName: 'Greg Hayne',
    personPicture: Testimonial1,
    personProfession: 'Hayne Coaching',
    link: 'https://haynecoachinggroup.com/',
  },
  { testimonial: 'They helped me rebrand to a new company name, and delivered amazing work beyond my expectations. I highly recommend this service if you are seeking an insightful branding experience, and thoughtful and creative naming options.',
    personName: 'Amy Godwin',
    personPicture: Testimonial2,
    personProfession: 'Essentialtopia',
    link: 'https://essentialtopia.com/',
  },
  { testimonial: 'The Brandmo team did a great job on the naming and logo design for my project. Excellent collaboration, defined deadlines, and they delivered the results on time; I am very pleased that I have worked with them on this project.',
    personName: 'Steven Brandt',
    personPicture: Testimonial3,
    personProfession: 'Zynapse',
    link: 'https://zynapse.ai',
  },
]

const Testimonials: React.FunctionComponent = () => {

  const [index, setIndex] = useState(0);

  const handleSelect = (index: number) => {
    setIndex(index);
  }

  return (
    <Fragment>
      <Container id="testimonials">
        <Container>

          <Row className="titleRegion">
            <Col>
              <div className="title">Testimonials</div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
                {CAROUSEL_ITEMS.map((item, index) => (
                  <Carousel.Item key={index}>
                    <Testimonial testimonial={item.testimonial} />
                  </Carousel.Item>
                ))}
              </Carousel>
              <ol className="custom-carousel-indicators">
                {CAROUSEL_ITEMS.map((item, indx) => {
                  return (
                    <li
                      key={indx}
                      onClick={() => setIndex(indx)}
                      className={indx === index ? 'active' : ''}
                    >
                      <div className="thumbnail">
                        <img
                          key={item.personName}
                          className="person-image"
                          alt={item.personName}
                          src={item.personPicture}
                        />
                        {index === indx && (
                          <Fragment>
                            <div className="person-name">
                              {item.personName}
                            </div>
                            <div className="person-profession">
                              <a href={item.link} target="__blank">{item.personProfession}</a>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ol>
            </Col>
          </Row>

        </Container>
      </Container>
    </Fragment>
  );
};

export default React.memo(Testimonials);
