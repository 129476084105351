import React, { useEffect } from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import { Helmet } from 'react-helmet';
import scrollToHandler from 'utils/ScrollHandler';

// Styles
import styles from './Privacy.module.scss';

const Privacy = () => {

  useEffect(() => {
    scrollToHandler('');
  }, []);

  return (
    <div className={styles.privacy}>
      <Helmet>
        <title>
          Privacy | Brandmo
        </title>
      </Helmet>
      <Container className="d-flex">
        <Row>
          <Col md={12} className="justify-content-center">
            <div className={styles.privacyContainer}>
              <div className={styles.pageHeader}>Privacy Policy</div>
              <div className={styles.section}>
                <p>
                  Protecting your private information is our priority. This Statement of Privacy applies to brandmo.com and Brandmo, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Brandmo, LLC include brandmo.com and Brandmo. The Brandmo website is a domain name marketplace & crowd-sourced branding site. Brandmo offers a wide range of branding services falling under various categories of brand acquisitions, brand development, brand identity, brand marketing, and brand security. Services offered include but are not limited to brand name creation, tagline creation, logo design, domain name sales, domain name management, domain name brokerage, domain name valuations, domain name acquisitions, website & eCommerce branding, packaging design, social media branding, personal branding, etc. By using the Brandmo website, you consent to the data practices described in this statement. 
                </p>
              </div>
              
              {/* Collection of your personal Info */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Collection of your Personal Information 
                </div>
                <p>
                  In order to better provide you with products and services offered on our Site, Brandmo may collect personally identifiable information, such as your: 
                </p>
                <ul className={styles.personalInfoList}>
                  <li>-	First and Last Name </li>
                  <li>-	Mailing Address </li>
                  <li>-	E-mail Address  </li>
                  <li>-	Phone Number </li>
                </ul>
                
                <p>If you purchase Brandmo&apos;s products and services, we collect billing and credit card information. This information is used to complete the purchase transaction. </p>
                <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Brandmo&apos;s public message boards, this information may be collected and used by others. </p>
                <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we may use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. </p>
              </div>
              {/* Use of your personal info section */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Use of your Personal Information
                </div>
                <p>
                  Brandmo collects and uses your personal information to operate its website(s) and deliver the services you have requested. 
                </p>
                <p>
                  Brandmo may also use your personally identifiable information to inform you of other products or services available from Brandmo and its affiliates.
                </p>
              </div>
              {/* Sharing Information with Third Parties  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Sharing Information with Third Parties 
                </div>
                <div className={styles.sectionContent}>
                  <p>Brandmo does not sell, rent or lease its customer lists to third parties. </p>

                  <p>Brandmo may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. Brandmo may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Brandmo, and they are required to maintain the confidentiality of your information.</p>

                  <p>Brandmo may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Brandmo or the site; (b) protect and defend the rights or property of Brandmo; and/or (c) act under exigent circumstances to protect the personal safety of users of Brandmo, or the public.</p>
                </div>
              </div>

              {/* Tracking User Behavior  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Tracking User Behavior 
                </div>

                <p>
                  Brandmo may keep track of the websites and pages our users visit within Brandmo, in order to determine what Brandmo services are the most popular. This data is used to deliver customized content and advertising within Brandmo to customers whose behavior indicates that they are interested in a particular subject area. 
                </p>
              </div>

              {/* Automatically Collected Information   */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Automatically Collected Information 
                </div>

                <p>
                  Information about your computer hardware and software may be automatically collected by Brandmo. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Brandmo website. 
                </p>
              </div>

              {/* Use of Cookies  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Use of Cookies 
                </div>

                <p>
                The Brandmo website may use &lsquo;cookies&rsquo; to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
                </p>

                <p>
                  One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Brandmo pages, or register with Brandmo site or services, a cookie helps Brandmo to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Brandmo website, the information you previously provided can be retrieved, so you can easily use the Brandmo features that you customized
                </p>

                <p>
                  You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Brandmo services or websites you visit. 
                </p>
              </div>

              {/* Links */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>Links</div>
                <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information. </p>
              </div>

              {/* Security of your Personal Information  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>Security of your Personal Information</div>
                <p>Brandmo secures your personal information from unauthorized access, use, or disclosure. Brandmo uses the following methods for this purpose: </p>
                <p> -	SSL Protocol </p>
                <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. </p>
                <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. </p>
              </div>

              {/* Right to Deletion  */}
              <div className={styles.section}>
                <div>Right to Deletion</div>
                <p>
                  Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
                  <ul>
                    <li>Delete your personal information from our records; and </li>
                    <li>Direct any service providers to delete your personal information from their records</li>
                  </ul>
                </p>

                <p>
                  Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
                  <ul>
                    <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; </li>
                    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; </li>
                    <li>Debug to identify and repair errors that impair existing intended functionality; </li>
                    <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </li>
                    <li>Comply with the California Electronic Communications Privacy Act; </li>
                    <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</li>
                    <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; </li>
                    <li>Comply with an existing legal obligation; </li>
                    <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
                  </ul>
                </p>
              </div>
              {/* Children Under Thirteen  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Children Under Thirteen 
                </div>
                <p>
                  Brandmo does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website
                </p>
              </div>

              {/* Disconnecting your Brandmo Account from Third Party Websites  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Disconnecting your Brandmo Account from Third Party Websites
                </div>
                <p>You will be able to connect your Brandmo account to third party accounts. BY CONNECTING YOUR BRANDMO ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third party account at any time. Users may send a message to Brandmo support via the contact page to request access to their accounts on third party sites be disconnected. </p>
              </div>

              {/* E-mail Communications  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  E-mail Communications
                </div>
                <p>
                  From time to time, Brandmo may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Brandmo or click on a link therein. 
                </p>
                <p>If you would like to stop receiving marketing or promotional communications via email from Brandmo, you may opt out of such communications by Customers may unsubscribe from promotional emails by clicking on the UNSUBSCRIBE button at the bottom these emails.</p>
              </div>

              {/* External Data Storage Sites  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  External Data Storage Sites 
                </div>
                <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted. </p>
              </div>

              {/* Changes to this Statement  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Changes to this Statement 
                </div>
                <p>
                  Brandmo reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
                </p>
              </div>

              {/* Contact Information  */}
              <div className={styles.section}>
                <div className={styles.sectionHeader}>
                  Contact Information
                </div>
                <p>
                  Brandmo welcomes your questions or comments regarding this Statement of Privacy. If you believe that Brandmo has not adhered to this Statement, please contact Brandmo at:
                </p>
                <p className="mb-3">
                  <div>Brandmo, LLC </div>
                  <div>30 N Gould Street Suite 11219 </div>
                  <div>Sheridan, Wyoming 82801 </div>
                </p>

                <p className="mb-3">
                  <div>Email Address: </div>
                  <div>info@brandmo.com</div>
                </p>

                <p className="mb-3">
                  <div>Telephone number:</div>
                  <div>+13072174045</div>
                </p>
                <p>Effective as of September 30, 2020 </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default React.memo(Privacy);
