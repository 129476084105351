import React, { useEffect, useContext } from 'react';

import { Router, Route, Switch, withRouter } from 'react-router-dom';


import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';

// Components
import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Login from './pages/Login/Login';
import Registration from './pages/Registration/Registration';
import Verify from './pages/Verify/Verify';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Dashboard from './pages/Dashboard/Dashboard';

import About from './pages/About/About';
import Contact from './pages/Contact/Contact';


import Contest from './pages/Contest/Contest';
import BuyRequestQuestionnaire from './pages/BuyRequestQuestionnaire/BuyRequestQuestionnaire';

import Pricing from './pages/Pricing/Pricing';
import DomainListing from './pages/DomainListing/DomainListing';

import DomainInner from './pages/DomainListing/DomainInner/DomainInner';
import Checkout from 'components/Checkout/Checkout';

import PurchaseStatus from './pages/PurchaseStatus/PurchaseStatus';

import BuyRequests from './pages/BuyRequests/BuyRequests';

import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';

import Verification from './pages/Verification/Verification';

// Context
import GlobalContext from './contexts/Global.context';

import { BuyRequestProvider } from './contexts/BuyRequest.context';

// History
import History from './utils/History';

import { PrivateRoute } from './routes/Private.routes';
import ErrorBoundary from './ErrorBoundary';

// Constants
import Constants from 'Constants';

// Hooks
// import useInterval from 'hooks/useInterval';

// Utils
// import { apiRequest } from 'utils/ApiRequest';

declare global {
  interface Window { analytics: any; }
}

// interface APIResponse {
//   token: string;
//   status?: string;
//   accessToken?: string;
// }

const showHeaderFooter = (path: string) => {
  const HeaderFooterShow = path !== '/login'
  && path !== '/register'
  && path !== '/forgot-password'
  && path.indexOf('/reset-password') === -1
  && path.indexOf('/verify') === -1

  return HeaderFooterShow;
}

const { apiConstants: { API_URL } } = Constants;
// const FETCH_USER_STATUS_URL = `${API_URL}/users/me`;

const CHECK_IF_TOKEN_IS_VALID = `${API_URL}/authorize`;

// const POLLING_USER_STATUS_TIME = 15 * 60 * 1000; // polling every 15 minutes

export const App = withRouter(({ location }) => {

  const context = useContext(GlobalContext);

  const { logout } = context;

  const HeaderFooterShow = showHeaderFooter(location.pathname);

  const currentToken = localStorage.getItem('token');

  const isLoggedIn = localStorage.getItem('token') ? true : false;

  // const getUserStatus = () => {
  //   if (localStorage.getItem('token')) {
  //     const { isVerified: initialUserState } = jwt(localStorage.getItem('token') || '');
  //     return initialUserState;
  //   }
  //   return false;
  // }

  // const getAccessToken = () => {
  //   return localStorage.getItem('accessToken') || null;
  // }

  // const [isVerified, setIsVerified] = useState<boolean>(getUserStatus() || false);
  // const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('accessToken'));
  // const [startPolling, setStartPolling] = useState<boolean>(false);
  // const [isRejected, setIsRejected] = useState<string>('');

  // const fetchUserStatus = useCallback(async () => {
  //   const { response }: { response: APIResponse } = await apiRequest(FETCH_USER_STATUS_URL, 'GET');
  //   if (response) {
  //     const { token, status, accessToken } = response;
  //     const { isVerified } = jwt(token);
  //     setIsVerified(isVerified);
  //     localStorage.setItem('token', token)

  //     if (accessToken) {
  //       setAccessToken(accessToken);
  //     }

  //     if (status) {
  //       // setIsRejected(status);
  //     }
  //   }
  // }, [])

  // const [stopPolling] = useInterval({
  //   callback: fetchUserStatus,
  //   delay: POLLING_USER_STATUS_TIME,
  //   startPolling: startPolling && isLoggedIn,
  // });

  useEffect(() => {
    const isTokenValid = async () => {
      const response = await fetch(CHECK_IF_TOKEN_IS_VALID, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${currentToken}`,
        }
      });
      if (!response.ok) {
        const { status } = response;
        if (status === 401) {
          logout('/login');
        }
      }
    }

    if (isLoggedIn) {
      isTokenValid();
      // setIsVerified(getUserStatus());
      // setAccessToken(getAccessToken());
    }
  }, [isLoggedIn, logout, currentToken])

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     if (!accessToken && !isVerified) {
  //       setStartPolling(true);
  //     } else {
  //       setStartPolling(false);
  //       stopPolling();
  //     }
  //   }
  // }, [isLoggedIn, accessToken, isVerified, stopPolling])

  // useEffect(() => {
  //   if (localStorage.getItem('token')) {
  //     const { isVerified } = jwt(localStorage.getItem('token') || '');
  //     if (isVerified) {
  //       if (!pathname.includes('/dashboard') && pathname !== '/') {
  //         History.push('/dashboard');
  //       }
  //     }
  //   }
  // }, [ pathname ]);

  return (
    <ErrorBoundary>
      <Container className="p-0" fluid>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Buy and sell premium brandable domain names using our marketplace, or connect with one of our naming experts through our business naming service and get exclusive hand picked business names to develop the perfect brand." />
          <title>Brandmo</title>
          <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        </Helmet>
        { HeaderFooterShow ? <Header />: '' }
        <Router history={History}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Registration} />
            <Route exact path="/verify/:token" component={Verify} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/contest" component={Contest} />
            <Route exact path="/requests" component={BuyRequests} />
            <Route exact path="/requests/:id">
              <BuyRequestProvider>
                <Contest />
              </BuyRequestProvider>
            </Route>
            <PrivateRoute path="/questionnaire" component={BuyRequestQuestionnaire} isLoggedIn={context.isLoggedIn} />
            <PrivateRoute path="/verification" component={Verification} isLoggedIn={context.isLoggedIn} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/domain-listing" component={DomainListing} />
            <Route exact path="/domain-listing/:url" component={DomainInner} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <PrivateRoute path="/checkout/:domainId" component={Checkout} isLoggedIn={context.isLoggedIn} />
            <PrivateRoute path="/dashboard" component={Dashboard} isLoggedIn={context.isLoggedIn} />
            <PrivateRoute path="/purchase-status/:transactionId" component={PurchaseStatus} isLoggedIn={context.isLoggedIn} />
          </Switch>
        </Router>
        { HeaderFooterShow ? <Footer />: '' }
      </Container>
    </ErrorBoundary>
  );
});
