export const exportCSV = async (
  url: string,
  method: string,
  bodyParams?: any
): Promise<any> => {
  const response = await fetch(url, {
    method,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'x-api-key': '8R@nDM0@p!',
    },
    body: bodyParams ? bodyParams : undefined
  })
  if (response) {
    if (!response.ok) {
      return { error: await response.clone().text() || 'Internal server error' }
    } else {
      const csv = await response.blob();
      return { data: csv };
    }
  }
};